import isNode from "detect-node";
import mixpanel from "mixpanel-browser";
import { useRouter } from "next/router";
import React, { createContext, useContext, useEffect, useState } from "react";

import { PATHS_WITH_COMPANY_INFO } from "./constants";

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN);

const MixpanelContext = createContext<{
  mixpanel;
  prevPathname: string;
  setPrevPathname: (prevPathname: string) => void;
}>({
  mixpanel,
  prevPathname: "",
  setPrevPathname: null,
});
const MixpanelProvider = ({ children }) => {
  const router = useRouter();
  const { pathname, asPath } = router;
  const [prevPathname, setPrevPathname] = useState(pathname);
  const isPathWithCompany = PATHS_WITH_COMPANY_INFO.includes(pathname);

  useEffect(() => {
    if (!isNode && !isPathWithCompany) {
      mixpanel.register({ currentPathname: pathname });
      mixpanel.track("pageView", { prevPathname });
      mixpanel.time_event("pageView");
      setPrevPathname(pathname);
    }
  }, [asPath]);

  return (
    <MixpanelContext.Provider
      value={{ mixpanel, prevPathname, setPrevPathname }}
    >
      {children}
    </MixpanelContext.Provider>
  );
};

const MixpanelConsumer = MixpanelContext.Consumer;
const useMixpanel = () => useContext(MixpanelContext);

export { MixpanelContext, MixpanelConsumer, MixpanelProvider, useMixpanel };
