const PageTitleTranslator = (route, intl) => {
  const parsedRoute = route.split("/")[1];

  if (parsedRoute) {
    return `${intl.formatMessage({
      id: parsedRoute,
    })} | KLog.co`;
  }
  return "KLog.co";
};
export default PageTitleTranslator;
