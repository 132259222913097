import { Card as AntdCard } from "antd";
import { CardProps as AntdCardProps } from "antd/lib/card";
import classnames from "classnames/bind";
import React, { ReactNode } from "react";

import styles from "./Card.module.css";

const cx = classnames.bind(styles);

type CardProps = {
  background?: boolean;
  disabled?: boolean;
  shadowed?: boolean;
};

type TitleProps = {
  text: ReactNode | string;
  className?: any;
};

type WrapperProps = {
  className?: any;
};
export const Card: React.FunctionComponent<AntdCardProps & CardProps> = ({
  children,
  background = false,
  disabled = false,
  bordered = false,
  shadowed = false,
  ...antdProps
}) => {
  return (
    <div
      className={cx({
        background,
        disabled,
        bordered,
        shadowed,
        card: true,
      })}
    >
      <AntdCard {...antdProps}>{children}</AntdCard>
    </div>
  );
};

export const Wrapper: React.FunctionComponent<CardProps & WrapperProps> = ({
  children,
  className = "",
}) => {
  return <div className={`${styles.cardBorder} ${className}`}>{children}</div>;
};

export const Title: React.FunctionComponent<TitleProps> = ({
  text,
  className = "",
}) => <div className={`${styles.title} ${className}`}>{text}</div>;
