import { Modal } from "antd";
import React from "react";

import { Button } from "..";

import ClickHereSvg from "@/public/static/images/icons/icon-click_here--box.svg";

import styles from "./QuotingModal.module.scss";

export const QuotingModal = ({
  title,
  body,
  okText = "OK",
  okButton,
  ...props
}) => (
  <Modal
    className={styles.modal}
    width="100%"
    footer={null}
    destroyOnClose
    closable={false}
    {...props}
  >
    <ClickHereSvg className={styles.icon} />
    <h4>{title}</h4>
    <p>{body}</p>
    <div className={styles.buttonWrapper}>
      {okButton || (
        <Button color="success" onClick={props.onOk}>
          {okText}
        </Button>
      )}
    </div>
  </Modal>
);
