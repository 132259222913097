import { Tag as AntdTag } from "antd";
import { TagProps } from "antd/lib/tag";
import React from "react";

type Props = {};

import styles from "./Tag.module.css";

export const Tag: React.FunctionComponent<TagProps & Props> = ({
  children,
  ...tagProps
}) => {
  return (
    <div className={styles.tag}>
      <AntdTag {...tagProps}>{children}</AntdTag>
    </div>
  );
};
