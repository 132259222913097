import Icon, {
  IconComponentProps,
} from "@ant-design/icons/lib/components/Icon";
import classNames from "classnames/bind";
import { Radio as FormikRadio, RadioGroupProps } from "formik-antd";
import React, { ReactNode } from "react";

import { FormRadio } from "@/components/Klog";

import styles from "./RadioCard.module.scss";

type Props = {
  title: string | ReactNode;
  icon: IconComponentProps["component"];
  selectText?: string | ReactNode;
} & RadioGroupProps;

const cx = classNames.bind(styles);

export const RadioCardGroup: React.FunctionComponent<RadioGroupProps> = ({
  children,
  ...props
}) => {
  return (
    <FormikRadio.Group className={styles.radioGroup} {...props}>
      {children}
    </FormikRadio.Group>
  );
};

export const RadioCard: React.FunctionComponent<Props> = ({
  title,
  icon,
  selectText,
  ...props
}) => {
  return (
    <label
      className={cx({
        item: true,
        selected: false,
      })}
    >
      <div className={styles.overlay} />
      <div className={styles.iconWrapper}>
        <Icon component={icon} />
      </div>
      <div className={styles.content}>
        <h4 className={styles.serviceName}>{title}</h4>
        <FormRadio {...props}>{selectText}</FormRadio>
      </div>
    </label>
  );
};
