import React from "react";

import styles from "./StepItemIcon.module.scss";

type Props = {
  stepIcon: any;
};

export const StepItemIcon: React.FunctionComponent<Props> = ({ stepIcon }) => {
  return (
    <div className={styles.stepItem}>
      <span className={styles.stepIcon}>{stepIcon}</span>
    </div>
  );
};
