import classNames from "classnames/bind";
import React from "react";
import { motion, MotionProps } from "framer-motion";

import styles from "./Gradient.module.scss";

const cx = classNames.bind(styles);

type Props = {
  isSmall?: boolean;
};

export const Gradient: React.FunctionComponent<Props & MotionProps> = ({
  isSmall,
  children,
  ...motionProps
}) => {
  return (
    <motion.div
      className={cx({
        gradientContainer: true,
        gradientContainerSmall: isSmall,
      })}
      {...motionProps}
    >
      {children}
    </motion.div>
  );
};
