import "moment/locale/es";
import moment from "moment";
import { useRouter } from "next/router";
import Cookie from "js-cookie";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IntlProvider } from "react-intl";

import { useAuth } from "@/lib/AuthContext";

type LanguagesType = "es" | "en";

export const LANGUAGES: { [key: string]: LanguagesType } = {
  ES: "es",
  EN: "en",
};
const LANGUAGE_COOKIE = "language";

export interface ILanguageContext {
  language: LanguagesType;
  setLanguage: (newLanguage: LanguagesType) => void;
}

const LanguageContext = React.createContext<ILanguageContext>({} as any);

const LanguageProvider = ({ children }) => {
  const { user } = useAuth();
  const { query } = useRouter();
  const userLanguage =
    user && user.userMetadata ? user.userMetadata.language : false;
  const [language, setLanguage] = useState<LanguagesType>(
    userLanguage || LANGUAGES.ES
  );
  const messages = require(`../../translations/${language}.json`);

  const handleSetLanguage = useCallback((lang) => {
    if (typeof lang === "string" && Object.values(LANGUAGES).includes(lang)) {
      setLanguage(lang);
      Cookie.set(LANGUAGE_COOKIE, lang);
      moment.locale(lang);
    }
  }, []);

  useEffect(() => {
    const cookieLanguage = Cookie.get(LANGUAGE_COOKIE);
    const definitiveLanguage = query.lang || userLanguage || cookieLanguage;
    handleSetLanguage(definitiveLanguage);
  }, [userLanguage, query.lang]);

  const value = useMemo(
    () => ({ language, setLanguage: handleSetLanguage }),
    [language, handleSetLanguage]
  );
  return (
    <LanguageContext.Provider value={value}>
      <IntlProvider locale={language} messages={messages} textComponent="span">
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

const useLanguage = () => useContext(LanguageContext);

export { LanguageProvider, LanguageContext, useLanguage };
