import { Checkbox as AntdCheckbox } from "antd";
import { CheckboxProps as AntdCheckboxProps } from "antd/lib/checkbox";
import { Checkbox as FormikCheckbox, CheckboxProps } from "formik-antd";
import classNames from "classnames/bind";

import styles from "./Checkbox.module.scss";

const cx = classNames.bind(styles);

type Props = {
  name?: string;
  selected?: boolean;
  disabled?: boolean;
};

export const Checkbox: React.FunctionComponent<Props & AntdCheckboxProps> = ({
  selected = false,
  disabled = false,
  ...antdCheckboxProps
}) => {
  const { checked } = antdCheckboxProps;

  return (
    <AntdCheckbox
      className={cx({ disabled, default: true, selected: checked })}
      {...antdCheckboxProps}
    />
  );
};

export const CheckboxForm: React.FunctionComponent<Props & CheckboxProps> = ({
  name,
  disabled = false,
  ...checkboxProps
}) => {
  const { checked } = checkboxProps;

  return (
    <FormikCheckbox
      className={cx({ disabled, default: true, selected: checked })}
      name={name}
      {...checkboxProps}
    />
  );
};
