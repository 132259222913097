import Cookie from "js-cookie";
import { createContext, useContext, useState } from "react";
import { useAuth } from "@/lib/AuthContext";
import { OFFICE_CODE_COOKIE } from "./constants";

export interface IOfficeContext {
  officeCode?: string;
  setOfficeCode?: (officeCode: string) => void;
  officeStatus: {
    isMexicanOffice?: boolean;
    isChileanOffice?: boolean;
    isPeruvianOffice?: boolean;
    isBolivianOffice?: boolean;
  };
}

const OfficeContext = createContext<IOfficeContext>({});

const OfficeProvider = ({ children }) => {
  const { user: { offices = ["CL"] } = {} } = useAuth();
  const currentOfficeId = Cookie.get(OFFICE_CODE_COOKIE);
  const [officeCode, setOfficeCode] = useState(currentOfficeId || offices[0]);
  const isChileanOffice = officeCode === "CL";
  const isPeruvianOffice = officeCode === "PE";
  const isMexicanOffice = officeCode === "MX";
  const isBolivianOffice = officeCode === "BO";
  const officeStatus = {
    isChileanOffice,
    isPeruvianOffice,
    isMexicanOffice,
    isBolivianOffice,
  };

  return (
    <OfficeContext.Provider value={{ officeCode, setOfficeCode, officeStatus }}>
      {children}
    </OfficeContext.Provider>
  );
};

const OfficeConsumer = OfficeContext.Consumer;
const useOffice = () => useContext(OfficeContext);

export { OfficeContext, OfficeConsumer, OfficeProvider, useOffice };
