import Cookie from "js-cookie";
import { createContext, useContext, useEffect, useState } from "react";
import { YEAR_COOKIE } from "../constants";

export interface IYearContext {
  year?: number;
  setYear?: (officeCode: string) => void;
}

const YearContext = createContext<IYearContext>({});

const YearProvider = ({ children }) => {
  const [year, setYear] = useState("");
  useEffect(() => {
    setYear(Cookie.get(YEAR_COOKIE));
  }, []);

  return (
    <YearContext.Provider value={{ year, setYear }}>
      {children}
    </YearContext.Provider>
  );
};

const YearConsumer = YearContext.Consumer;
const useYear = () => useContext(YearContext);

export { YearContext, YearConsumer, YearProvider, useYear };
