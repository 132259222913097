import { createContext, useContext, useReducer } from "react";

export interface IOperationTypeContext {
  operationTypes?: string[];
  setOperationTypes?: (reducer: { key: string; value: string }) => void;
}

const OperationTypesContext = createContext<IOperationTypeContext>({
  operationTypes: [],
});

const OperationTypesProvider = ({ children }) => {
  const reducer = (oldState, { key, value }) => {
    const copiedState = [...oldState];
    if (value) {
      copiedState.push(key);
      return copiedState;
    }
    const filteredState = copiedState.filter((oldKey) => oldKey !== key);
    if (!filteredState.length) return copiedState;
    return filteredState;
  };
  const initialOperationTypes = ["IMPORT", "EXPORT"];
  const [operationTypes, setOperationTypes] = useReducer(
    reducer,
    initialOperationTypes
  );
  return (
    <OperationTypesContext.Provider
      value={{ operationTypes, setOperationTypes }}
    >
      {children}
    </OperationTypesContext.Provider>
  );
};

const OperationTypesConsumer = OperationTypesContext.Consumer;
const useOperationTypes = () => useContext(OperationTypesContext);
export {
  OperationTypesContext,
  OperationTypesConsumer,
  OperationTypesProvider,
  useOperationTypes,
};
