import React, { useContext } from "react";
import SendBird, { SendBirdInstance } from "sendbird";

import { useAuth } from "../../lib/AuthContext";

type Context = {
  sb: SendBirdInstance;
  connect: () => void;
  disconnect: () => void;
};

const sb = new SendBird({ appId: process.env.NEXT_PUBLIC_SENDBIRD_APP_ID });
const ChatContext = React.createContext<Context>({
  sb,
  connect: null,
  disconnect: null,
});

const ChatProvider = ({ children }) => {
  const { user } = useAuth();
  const connect = () => sb.connect(user.auth0Id, user.sendbirdAccessToken);
  const disconnect = () => sb.disconnect();
  return (
    <ChatContext.Provider value={{ sb, connect, disconnect }}>
      {children}
    </ChatContext.Provider>
  );
};

const useChat = () => useContext(ChatContext);

export { ChatProvider, ChatContext, useChat };
