// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

!!SENTRY_DSN && Sentry.init({
  dsn: SENTRY_DSN,
  ignoreErrors: ['Non-Error promise rejection captured'],
  integrations: [
    new Sentry.Integrations.RewriteFrames({
      iteratee: (frame) => {
        // Remove the path to the node_modules folder
        if (frame.filename.includes('node_modules')) {
          return null;
        }
        return frame;
      },
    }),
    new Sentry.Integrations.BrowserTracing({
      tracingOrigins: ['prodapi.teuai.net'],
    }),
    new Sentry.Integrations.CaptureConsole({
      levels: ['error', 'warn', 'info', 'debug'],
    }),
  ],

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
