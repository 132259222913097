export const TRANSLOG_AGENT_CODES = [
  {
    id: 1,
    name: "AIR VORTEX LTDA ",
  },
  {
    id: 2,
    name: "ALLIANCE TRANSPORT LOGISTICS ",
  },
  {
    id: 3,
    name: "ANDES LOGISTICS DE CHILE S.A. ",
  },
  {
    id: 4,
    name: "ANDES LOGISTICS DE PARAGUAY S.A. ",
  },
  {
    id: 5,
    name: "ANDES LOGISTICS DEL PERU SAC ",
  },
  {
    id: 6,
    name: "AVIOMAR ",
  },
  {
    id: 7,
    name: "BALNAK LOGISTICS GROUP ",
  },
  {
    id: 8,
    name: "BANSARD INTERNATIONAL ",
  },
  {
    id: 9,
    name: "Rhenus Logistics Canada ",
  },
  {
    id: 10,
    name: "BERGER LOGISTIK GMBH ",
  },
  {
    id: 11,
    name: "BICS BAHRIAN INTERNATIONAL CARGO SERVICES W.L.L. ",
  },
  {
    id: 12,
    name: "BLU LOGISTICS ",
  },
  {
    id: 13,
    name: "BRASERVICE ASSESSORIA EM COM.C/O INTERATHENA DESP ",
  },
  {
    id: 14,
    name: "BRASERVICE TRANSPORTES INTERNACIONAIS LTDA EPP ",
  },
  {
    id: 15,
    name: "BRING CARGO AS ",
  },
  {
    id: 16,
    name: "BRING CARGO DANMARK A/S ",
  },
  {
    id: 17,
    name: "BRING CARGO WEST AB ",
  },
  {
    id: 18,
    name: "CARGO MASTER' S INTERNATIONAL S.A. DE C.V. ",
  },
  {
    id: 19,
    name: "CRAFT MULTIMODAL CHILE SPA ",
  },
  {
    id: 20,
    name: "DEBEAUX TRANSIST ",
  },
  {
    id: 21,
    name: "EXPOLANKA FREIGHT (PTY) LTD ",
  },
  {
    id: 22,
    name: "FDI CO LTD ",
  },
  {
    id: 23,
    name: "FERCAM SPA ",
  },
  {
    id: 24,
    name: "GLOBAL CARGO ",
  },
  {
    id: 25,
    name: "HENCO LOGISTICS SA DE CV ",
  },
  {
    id: 26,
    name: "INCA LINES S.A.C. ",
  },
  {
    id: 27,
    name: "ICS LOGISTICS (PVT) LTD. ",
  },
  {
    id: 28,
    name: "ISTAR CARGO S.A. ",
  },
  {
    id: 29,
    name: "JET CARGO S.A. ",
  },
  {
    id: 30,
    name: "JET-SPEED LOGISTICS MIAMI ",
  },
  {
    id: 31,
    name: "K LOGISTICS DE CHILE S.A. ",
  },
  {
    id: 32,
    name: "KERRY (DALIAN) ",
  },
  {
    id: 33,
    name: "KERRY ADCO LOGISTICS ",
  },
  {
    id: 34,
    name: "KERRY LOGISTICS (PHILS), INC ",
  },
  {
    id: 35,
    name: "KERRY EAS LOGISTICS ",
  },
  {
    id: 36,
    name: "KERRY EAS LOGISTICS (GUANGZHOU) ",
  },
  {
    id: 37,
    name: "KERRY EAS LOGISTICS (NINGBO) ",
  },
  {
    id: 38,
    name: "KERRY EAS LOGISTICS (QINGDAO) ",
  },
  {
    id: 39,
    name: "KERRY EAS LOGISTICS (SHANGHAI) ",
  },
  {
    id: 40,
    name: "KERRY EAS LOGISTICS (SHANTOU,CHINA) ",
  },
  {
    id: 41,
    name: "KERRY EAS LOGISTICS FUZHOU BRANCH ",
  },
  {
    id: 42,
    name: "KERRY EAS LOGISTICS JILIN BRANCH ",
  },
  {
    id: 43,
    name: "KERRY EAS LOGISTICS LIMITED (TIANJIN) ",
  },
  {
    id: 44,
    name: "KERRY EAS LOGISTICS LIMITED XIAMEN BRANCH ",
  },
  {
    id: 45,
    name: "KERRY EAS LOGISTICS LIMITED YANTAI BRANCH ",
  },
  {
    id: 46,
    name: "KERRY EAS LOGISTICS LTD YANGZHOU BRANCH ",
  },
  {
    id: 47,
    name: "KERRY EAS LOGISTICS LTD. HUBEI BRANCH ",
  },
  {
    id: 48,
    name: "KERRY EAS LOGISTICS LTD.CHONGQING BRANCH ",
  },
  {
    id: 49,
    name: "KERRY EAS LOGISTICS, (SHENZHEN) ",
  },
  {
    id: 50,
    name: "KERRY FAR EAST LOGISTICS (BD) LTD. ",
  },
  {
    id: 51,
    name: "KERRY FREIGHT (AUSTRALIA) PTY LTD KERRY FREIGHT (A ",
  },
  {
    id: 52,
    name: "KERRY FREIGHT (HONG KONG) LIMITED ",
  },
  {
    id: 53,
    name: "KERRY FREIGHT (KOREA) INC ",
  },
  {
    id: 54,
    name: "KERRY FREIGHT (SINGAPORE) PTE LTD ",
  },
  {
    id: 55,
    name: "KERRY SPEEDY LOGISTICS CO. LTD (TAIWAN) ",
  },
  {
    id: 56,
    name: "KERRY - APEX (THAILAND) CO., LTD ",
  },
  {
    id: 57,
    name: "KERRY FREIGHT (USA) INC, LOS ANGELES (HQ) ",
  },
  {
    id: 58,
    name: "KERRY FREIGHT (USA) INC NEW YORK ",
  },
  {
    id: 59,
    name: "KERRY FREIGHT MYANMAR LTD. ",
  },
  {
    id: 60,
    name: "KERRY LOGISTICS (CAMBODIA) PTE LTD ",
  },
  {
    id: 61,
    name: "KERRY LOGISTICS (CZECH REPUBLIC) S.R.O. ",
  },
  {
    id: 62,
    name: "KERRY LOGISTICS (GERMANY) GMBH ",
  },
  {
    id: 63,
    name: "KERRY LOGISTICS (GERMANY) GMBH FRANKFURT ",
  },
  {
    id: 64,
    name: "KERRY INDEV LOGISTICS (INDIA) PVT. LTD. ",
  },
  {
    id: 65,
    name: "KERRY LOGISTICS (M) SDN BHD ",
  },
  {
    id: 66,
    name: "KERRY LOGISTICS (MALAYSIA) SDN BHD ",
  },
  {
    id: 67,
    name: "KERRY LOGISTICS (SPAIN) SAU MADRID ",
  },
  {
    id: 68,
    name: "KERRY LOGISTICS (SPAIN), SAU ",
  },
  {
    id: 69,
    name: "KERRY LOGISTICS (SWITZERLAND) GMBH BASEL ",
  },
  {
    id: 70,
    name: "KERRY LOGISTICS (UK) LTD ",
  },
  {
    id: 71,
    name: "KERRY LOGISTICS NETHERLANDS BV ",
  },
  {
    id: 72,
    name: "KERRY LOGISTICS ANTWERP ",
  },
  {
    id: 73,
    name: "KERRY LOGISTICS BE - BRUCARGO ",
  },
  {
    id: 74,
    name: "KERRY LOGISTICS BELGIUM BVBA ",
  },
  {
    id: 75,
    name: "KERRY LOGISTICS DO BRASIL - TRANSPORTE INTERNACION ",
  },
  {
    id: 76,
    name: "KERRY LOGISTICS FRANCE SARL ",
  },
  {
    id: 77,
    name: "KERRY LOGISTICS GMBH ",
  },
  {
    id: 78,
    name: "KERRY KSA LOGISTICS (JAPAN) LIMITED ",
  },
  {
    id: 79,
    name: "KERRY LOGISTICS MEXICO S.A. DE C.V. ",
  },
  {
    id: 80,
    name: "KERRY LOGISTICS OCEANIA PTY LTD ",
  },
  {
    id: 81,
    name: "KERRY RELIABLE LOGISTICS PVT. LTD, ",
  },
  {
    id: 82,
    name: "KERRY SALVAT LOGISTICS S.A. ",
  },
  {
    id: 83,
    name: "KEYLOGISTICS S.A.S ",
  },
  {
    id: 84,
    name: "LEAN LOGISTICS SAS ",
  },
  {
    id: 85,
    name: "OMPM LOGISTICS S.A. ",
  },
  {
    id: 86,
    name: "PT. KERRY LOGISTICS (INDONESIA) ",
  },
  {
    id: 87,
    name: "SEA LEVANT HELLAS LTD ",
  },
  {
    id: 88,
    name: "SERV. PORTUARIOS Y AEROPORT. Y DE CARGA CAROTRANS ",
  },
  {
    id: 89,
    name: "SHASHATI FREIGHT SERVICE ",
  },
  {
    id: 90,
    name: "T.L.I TOAM LOGISTICS INTERNATIONAL ",
  },
  {
    id: 91,
    name: "TALWIN TRANSPORT SERVICE LLC ",
  },
  {
    id: 92,
    name: "TCI FREIGHT FORWARDING CO. LTD ",
  },
  {
    id: 93,
    name: "TLP TOTAL LOGISTICS PARTNER OCEANCONSOLIDATORS INC ",
  },
  {
    id: 94,
    name: "TRANSCARGO S.A. ",
  },
  {
    id: 95,
    name: "TRANSMODE OVERSEAS ",
  },
  {
    id: 96,
    name: "TRANSOCEAN LOGISTICS CORP. S.A. ",
  },
  {
    id: 97,
    name: "TRANSPORTES INTERNACIONALES TICAL S.A. ",
  },
  {
    id: 98,
    name: 'UAB "OIA GLOBAL" ',
  },
  {
    id: 99,
    name: "UNION CARGO (PVT) LTD ",
  },
  {
    id: 100,
    name: "VILLANOVA S.P.A. ",
  },
  {
    id: 101,
    name: "VLG PERU SAC ",
  },
  {
    id: 102,
    name: "KERRY EAS LOGISTICS JIANGSU BRANCH ",
  },
  {
    id: 103,
    name: "KERRY EAS LOGISTICS LTD ",
  },
  {
    id: 104,
    name: "KERRY FREIGHT (USA) INCORPORATED MIAMI ",
  },
  {
    id: 105,
    name: "SECORA-TRANS ",
  },
  {
    id: 106,
    name: "Garland Transport Solutions, LDA ",
  },
  {
    id: 107,
    name: "KERRY EAS LOGISTICS LIMITED ZHONGSHAN BRANCH ",
  },
  {
    id: 108,
    name: "CRAFT MULTIMODAL CHILE LIMITADA ",
  },
  {
    id: 109,
    name: "ANDES LOGISTICS BOLIVIA S.R.L. ",
  },
  {
    id: 110,
    name: "TRANSOCEAN LOGISTICS CORP S.A. ",
  },
  {
    id: 111,
    name: "BORUSAN LOJISTIK DAG. DEP. TAS. ",
  },
  {
    id: 113,
    name: "TRANSOCEAN LOGISTICS CORP ",
  },
  {
    id: 115,
    name: "IBL LOGISTICS LTD ",
  },
  {
    id: 116,
    name: "KERRY LOGISTICS OCEANIA LTD ",
  },
  {
    id: 117,
    name: "BANSARD INTERNATIONAL HONG KONG LIMITED ",
  },
  {
    id: 118,
    name: "ROYAL EXPRESS CARGO PVT. LTD ",
  },
  {
    id: 119,
    name: "Kerry Logistics Lanka Pvt Ltd ",
  },
  {
    id: 120,
    name: "O.T.S. ASTRACON ",
  },
  {
    id: 122,
    name: "GREEN LOG SRL ",
  },
  {
    id: 123,
    name: "VENTANA SERRA SPA ",
  },
  {
    id: 124,
    name: "KERRY LOGISTICS (CANADA) INC ",
  },
  {
    id: 127,
    name: "KERRY EAS LOGISTICS WUHU BRANCH ",
  },
  {
    id: 129,
    name: "TRANS OKYANUS ",
  },
  {
    id: 130,
    name: "LYN CARGO EXPRESS ",
  },
  {
    id: 131,
    name: "ROTTERDAM OVERSEAS NETWORK ",
  },
  {
    id: 132,
    name: "F.D.I Co., LTD ",
  },
  {
    id: 133,
    name: "R.S. BRING EXPRESS SUOMI OY ",
  },
  {
    id: 134,
    name: "IDL EXPRESS BRASIL ",
  },
  {
    id: 135,
    name: "APEX MARITIME, KOREA ",
  },
  {
    id: 136,
    name: "CROSS-TRADES SHIPPING SERVICES B.V. ",
  },
  {
    id: 138,
    name: "CNE LOGISTICA INTERNACIONAL (WORLDWIDE LOGISTICS) ",
  },
  {
    id: 139,
    name: "MTS MASCHINENBAU GMBH ",
  },
  {
    id: 140,
    name: "DEMEX CARGO SERVICES (GROUP) CO. LTD ",
  },
  {
    id: 141,
    name: "VAN DONGE & DE ROOM B.V. ",
  },
  {
    id: 142,
    name: "TUVIA ITALIA SPA ",
  },
  {
    id: 143,
    name: "APEX HOLDING GROUP LIMITED ",
  },
  {
    id: 144,
    name: "FACILITY CARGO ",
  },
  {
    id: 145,
    name: "REX CARGO PANAMA S.A. ",
  },
  {
    id: 146,
    name: "EXPRESS INTERNATIONAL FREIGHT ",
  },
  {
    id: 151,
    name: "KERRY LOGISTICS (PORTUGAL) UNIPESSOAL LTDA ",
  },
  {
    id: 152,
    name: "KERRY LOGISTICS (POLAND) SP. Z O.O. ",
  },
  {
    id: 153,
    name: "APEX SHIPPING CO. (NYC), INC ",
  },
  {
    id: 154,
    name: "REX INTERNACIONAL HONDURAS ",
  },
  {
    id: 155,
    name: "REXCARGO LOGISTICS GUATEMALA S.A. ",
  },
  {
    id: 156,
    name: "SHIPLOG ",
  },
  {
    id: 157,
    name: "S&R AGENCY LLC ",
  },
  {
    id: 158,
    name: "KERRY LOGISTIS (SWEDEN) AB ",
  },
  {
    id: 160,
    name: "SHARE LOGISTICS ",
  },
  {
    id: 161,
    name: "CARICO BOLIVIA SRL ",
  },
  {
    id: 162,
    name: "K-APEX LOGISTICS (HK) CO LTD-SHANGHAI",
  },
  {
    id: 163,
    name: "KERRY-Al Qudrah Logistics Services Co. ",
  },
  {
    id: 164,
    name: "MSL CHILE S A ",
  },
  {
    id: 165,
    name: "PLUSCARGO CHILE LIMITADA ",
  },
  {
    id: 166,
    name: "APEX MARITIME CO. (LAX) INC. ",
  },
  {
    id: 167,
    name: "REXCARGO EL SALVADOR S.A. DE C.V. ",
  },
  {
    id: 168,
    name: "HAITI INTERNATIONAL FORWARDING ",
  },
  {
    id: 169,
    name: "DAN SHIPPING I.K.E. ",
  },
  {
    id: 171,
    name: "CLEARFREIGHT CHILE SPA ",
  },
  {
    id: 173,
    name: "TNL INTERNATIONAL (AUSTRALIA) PTY LTD ",
  },
  {
    id: 175,
    name: "K APEX LOGISTICS (M) SDN BHD ",
  },
  {
    id: 176,
    name: "KERRY FREIGHT PAKISTAN (PRIVATE) LIMITED ",
  },
  {
    id: 177,
    name: "Globalink Logistics Group LLC ",
  },
  {
    id: 178,
    name: "LOGISTICA MARITIMA INTEGRAL S.A. MARILOGINSA ",
  },
  {
    id: 179,
    name: "SIGMAI TRADE & LOGISTICS LTD ",
  },
  {
    id: 183,
    name: "ASIMEX WORLDWIDE LOGISTICS ",
  },
  {
    id: 186,
    name: "Kerry EAS Logistics Limited Xiangyang Branch ",
  },
  {
    id: 187,
    name: "KERRY EAS LOGISTICS LIMITED HENAN BRANCH ",
  },
  {
    id: 191,
    name: "ASAV INTERNATIONAL TRANSPORT ",
  },
  {
    id: 192,
    name: "EMA INTERNATIONAL LOGISTICS SAC ",
  },
  {
    id: 193,
    name: "FREIGHTRIGHT GLOBAL LOGISTICS ",
  },
  {
    id: 195,
    name: "TRX GLOBAL LOGISTICS USA, LLC ",
  },
  {
    id: 196,
    name: "GSA FORCE LLC ",
  },
  {
    id: 197,
    name: "AM CARGO CASTELLON, S.L. ",
  },
  {
    id: 198,
    name: "IVEZOON AGENCIAMENTO DE TRANP AEREOS INT LTDA ",
  },
  {
    id: 199,
    name: "Ocean Insights GmbH ",
  },
  {
    id: 200,
    name: "Taggart International, Ltd. ",
  },
  {
    id: 201,
    name: "Master International Logistics SRL ",
  },
  {
    id: 202,
    name: "LONGSAIL INTERNATIONAL LOGISTICS CO., LTD ",
  },
  {
    id: 204,
    name: "ZLogistik, LLC. ",
  },
  {
    id: 205,
    name: "PAN WORLD AIR & SEA CO., LTD. ",
  },
  {
    id: 206,
    name: "KERRY LOGISTICS NETWORK LIMITED ",
  },
  {
    id: 207,
    name: "FIBIMAC S.R.L ",
  },
  {
    id: 208,
    name: "DOMINITRANS EIRL ",
  },
  {
    id: 209,
    name: "BUSINESS BY AIR ",
  },
  {
    id: 210,
    name: "KERRY FREIGHT INTERNATIONAL COMPANY LIMITED - TAIWAN ",
  },
  {
    id: 211,
    name: "Kerry Logistics (South Africa) Pty Ltd ",
  },
  {
    id: 213,
    name: "Asia Ship Co.,Ltd(NVOCC). ",
  },
  {
    id: 214,
    name: "DGX-Dependable Global Express ",
  },
  {
    id: 215,
    name: "Charter Link Logistics Chile SpA ",
  },
  {
    id: 216,
    name: "KLOG. CO S.A.C. ",
  },
  {
    id: 217,
    name: "YiWu XiaoTian Bag&Case Manufactory ",
  },
  {
    id: 218,
    name: "NOATUM LOGISTICS ",
  },
  {
    id: 219,
    name: "SHADO SRL ",
  },
  {
    id: 220,
    name: "FORMOSA TRANSPORTATION CO. LTD ",
  },
  {
    id: 222,
    name: "KC GLOBAL LOGISTICS ",
  },
  {
    id: 223,
    name: "SKYWAYS AIR SERVICES PVT LTD ",
  },
  {
    id: 224,
    name: "FORIN CONTAINER LINE ",
  },
  {
    id: 225,
    name: "SIAMAR NVOCC CONSOLIDATORS ",
  },
  {
    id: 226,
    name: "GLOBEWIN INTERNATIONAL LIMITED ",
  },
  {
    id: 228,
    name: "B.I. LOGISTIQUE ",
  },
  {
    id: 229,
    name: "youRcentral Logistics B.V. ",
  },
  {
    id: 230,
    name: "GRUPO TLA EL SALVADOR, S.A. DE CV ",
  },
  {
    id: 231,
    name: "LJ Williams Ltd ",
  },
  {
    id: 232,
    name: "QCS QUICK CARGO SERVICE GMBH (EUROPEAN CARGO) ",
  },
  {
    id: 233,
    name: "NEW TIME LOGISTICS ",
  },
  {
    id: 234,
    name: "SACO SHIPPING S.A. ",
  },
  {
    id: 236,
    name: "TGD CONSOLIDATIONS ",
  },
  {
    id: 237,
    name: "LTS Logistics Transport Solution ",
  },
  {
    id: 238,
    name: "MEGASHIP SOLUCIONES LOGISTICAS ",
  },
  {
    id: 239,
    name: "Kerry Logistics Oceania",
  },
  {
    id: 235,
    name: "CARGO LOGISTICS EXIM SRL",
  },
  {
    id: 241,
    name: "C.ALMAR INTERNACIONAL DE URUGUAY S.A",
  },
  {
    id: 243,
    name: "KLOG.CO TRANSPORTES MEXICANA SA DE CV",
  },
  {
    id: 247,
    name: "ANDES LOGISTICS COLOMBIA S.A.S",
  },
  {
    id: 246,
    name: "GRUPO TLA GUATEMALA S.A.",
  },
  {
    id: 249,
    name: "COMMODITY SUPPLIES GLOBAL LOGISTICS CO., LTD",
  },
  {
    id: 250,
    name: "WSL LINES LTD",
  },
  {
    id: 251,
    name: "SKYWAYS SLS CARGO SERVICES L.L.C.",
  },
  {
    id: 2663,
    name: "LENOVO AGENCIA EN CHILE",
  },
];
export const TRANSLOG_TRANSPORTISTS = [
  {
    id: 1,
    name: "TRANSPORTES G Y S ",
  },
  {
    id: 2,
    name: "REYNALDO GARCIA ROJAS ",
  },
  {
    id: 3,
    name: "DATADIE ",
  },
  {
    id: 4,
    name: "INGECOMINT ",
  },
  {
    id: 5,
    name: "TRANSPORTES CARGO TRACKING ",
  },
  {
    id: 7,
    name: "FEDERAL EXPRESS AGENCIA EN CHILE ",
  },
  {
    id: 8,
    name: "(no usar)LOGISTICA EN TRANSPORTE Y ALMACENAJE LTDA ",
  },
  {
    id: 9,
    name: "FRANCISCO JAVIER PERALTA HENRIQUEZ TRANSPORTES EI ",
  },
  {
    id: 10,
    name: "JULIO EDUARDO VALLEJOS ABARZA ",
  },
  {
    id: 11,
    name: "TRANSPORTES LOS ANDES ",
  },
  {
    id: 12,
    name: "DHL EXPRESS (CHILE) LTDA ",
  },
  {
    id: 13,
    name: "HUMBERTO MORALES Y CIA LTDA ",
  },
  {
    id: 14,
    name: "TRANSPORTE INTERNACIONAL MK LTDA ",
  },
  {
    id: 15,
    name: "ROCO Y CIA LTDA ",
  },
  {
    id: 16,
    name: "DIEGO PATRICIO AGUILERA ARIAS ",
  },
  {
    id: 17,
    name: "TRANSPORTES VT TRUCK SOLUTIONS SPA ",
  },
  {
    id: 18,
    name: "ASESORIA TRANSPORTES E INVERSIONES ALECAMI LIMITAD ",
  },
  {
    id: 19,
    name: "HUERTA Y PONCE FRUTOS DEL CAMPO LIMITADA ",
  },
  {
    id: 20,
    name: "CARGO HANDLING LIMITADA ",
  },
  {
    id: 21,
    name: "INLAND TRANSPORTES S.A. ",
  },
  {
    id: 22,
    name: "LOGISTICA EN TRANSPORTES Y ALMACENAJE LIMITADA ",
  },
  {
    id: 23,
    name: "LOGISTICA EN TRANSPORTES Y ALMACENAJE PATRICIO OVA ",
  },
  {
    id: 24,
    name: "TRANSPORTES CONEXXION SPA ",
  },
  {
    id: 25,
    name: "MEDLOG CHILE S.A ",
  },
  {
    id: 26,
    name: "TRANSPORTES ROJAS ROJAS Y CIA LTDA ",
  },
  {
    id: 28,
    name: "JULIO AGUILERA SAA TRANSPORTE LOGISTICA Y DISTRIBU ",
  },
  {
    id: 29,
    name: "TRANSPORTES Y LOGISTICA CARGOTRACKING SPA ",
  },
  {
    id: 30,
    name: "TRANSPORTES TRANSBYM SPA ",
  },
  {
    id: 31,
    name: "CARGO SERVICE S A ",
  },
  {
    id: 32,
    name: "SOCIEDAD DE TRANSPORTE Y LOGISTICA M & S SPA ",
  },
  {
    id: 33,
    name: "T&L TRANSPORTES SPA ",
  },
  {
    id: 34,
    name: "TRANSPORTES SANTA CRISTINA LIMITADA ",
  },
  {
    id: 36,
    name: "TRANSPORTES ROGATY SPA. ",
  },
  {
    id: 37,
    name: "TRANSPORTES RADO INTERNACIONAL LIMITADA ",
  },
  {
    id: 38,
    name: "SOCIEDAD DE TRANSPORTES PATRICIO ROJAS PERALTA Y C ",
  },
  {
    id: 39,
    name: "TRANSPORTES UNION LTDA ",
  },
  {
    id: 40,
    name: "TRANSPORTES MAURICIO CHRISTIAN VIRGILIO JIJENA E.I.R.L ",
  },
  {
    id: 43,
    name: "TRANSPORTES ARMIN LEAL CONTRERAS SPA",
  },
  {
    id: 44,
    name: "DECRE S.A.",
  },
  {
    id: 46,
    name: "INVERSIONES ICA LIMITADA",
  },
  {
    id: 48,
    name: "TRANSPORTES SANTA EMILIA SPA",
  },
];
export const TRANSLOG_ABACUS_TRANSLATOR = {
  thcd: "142",
  thc: "142",
  profitShare: "145",
  drop: "153",
  isps: "158",
  apertura_iva: "107",
  apertura: "108",
  bl: "109",
  bl_iva: "110",
  blEmission: "127",
  blEmission_iva: "126",
  freight_lcl: "102",
  freight_fcl: "101",
};
export const TRANSLOG_PROVIDERS = [
  {
    id: 1,
    name: "HDI SEGUROS S.A. ",
  },
  {
    id: 3,
    name: "BICE VIDA COMPAIA DE SEGUROS S.A. ",
  },
  {
    id: 4,
    name: "DIMERC S.A. ",
  },
  {
    id: 5,
    name: "EDAPI S.A. ",
  },
  {
    id: 6,
    name: "ENTEL PCS TELECOMUNICACIONES S.A. ",
  },
  {
    id: 7,
    name: "GTD TELEDUCTOS S.A. ",
  },
  {
    id: 8,
    name: "GTD TELESAT S.A. ",
  },
  {
    id: 9,
    name: "NOTEBOOK CENTER ",
  },
  {
    id: 10,
    name: "NUBOX SPA ",
  },
  {
    id: 11,
    name: "SERVICIOS EQUIFAX CHILE LIMITADA ",
  },
  {
    id: 12,
    name: "INMOBILIARIA E INVERSIONES DR SPA ",
  },
  {
    id: 13,
    name: "INMOBILIARIA E INVERSIONES ZOAGLI LIMITADA ",
  },
  {
    id: 14,
    name: "SOCIEDAD DE INVERSIONES SANTA ANTONIA SPA ",
  },
  {
    id: 15,
    name: "AFEX AGENTES DE VALORES LTDA. ",
  },
  {
    id: 16,
    name: "COMERCIALIZADORA VODA CHILE LIMITADA ",
  },
  {
    id: 17,
    name: "COMUNIDAD EDIFICIO ALONSO DE CORDOVA ",
  },
  {
    id: 18,
    name: "INMOBILIARIA VALLE GRANDE CENTER LIMITADA ",
  },
  {
    id: 19,
    name: "LUIS ALBERTO ESPINOZA ZURITA ",
  },
  {
    id: 20,
    name: "MMCS SISTEMAS CHILE LIMITADA ",
  },
  {
    id: 21,
    name: "PABLO GRANDON CATALAN ",
  },
  {
    id: 22,
    name: "RICHARD RUBEN LAGOS ARRIAGADA ",
  },
  {
    id: 23,
    name: "TAMARA VERONICA EPUL TAPIA ",
  },
  {
    id: 24,
    name: "AGENCIA DE ADUANA CLAUDIO POLLMANN Y CIA. LTDA. ",
  },
  {
    id: 25,
    name: "OTEC SALES TRAINING SYSTEM CHILE LIMITADA ",
  },
  {
    id: 26,
    name: "GONZALO AMENABAR Y COMPAIA LIMITADA ",
  },
  {
    id: 27,
    name: "CASTILLO HERMANOS LIMITADA ",
  },
  {
    id: 28,
    name: "SERVICIOS INFORMATICOS CARLOS CACERES PINTO E.I.R. ",
  },
  {
    id: 29,
    name: "SERVICIOS GRAFICOS CAMILA FIGUEROA CHAVEZ E.I.R.L ",
  },
  {
    id: 30,
    name: "TESORERIA GENERAL DE LA REPUBLICA ",
  },
  {
    id: 31,
    name: "DORABEL S.A. ",
  },
  {
    id: 33,
    name: "MARISABEL SALOME QUEZADA VIDELA ",
  },
  {
    id: 34,
    name: "HECTOR RAMIRO GONZALEZ REYES ",
  },
  {
    id: 35,
    name: "LUISA LETICIA RODRIGUEZ MONTENEGRO ",
  },
  {
    id: 36,
    name: "PROVIT SPA ",
  },
  {
    id: 37,
    name: "ARIEX RECUPERADORA Y COMERCIALIZADORA DE EXCEDENTE ",
  },
  {
    id: 38,
    name: "EDUARDO PATRICIO CARRASCO PEREZ ",
  },
  {
    id: 39,
    name: "JANAN KNUST ",
  },
  {
    id: 40,
    name: "MARIOLEE SILVA ",
  },
  {
    id: 41,
    name: "ANTHONY ASP ",
  },
  {
    id: 42,
    name: "TIAGO PASSINI MARCONDES CESAR ",
  },
  {
    id: 43,
    name: "CLAUDIO VEGA ",
  },
  {
    id: 44,
    name: "AEROSURLOG LIMITADA ",
  },
  {
    id: 45,
    name: "PACIFIC FEEDER SERVICES S.A. ",
  },
  {
    id: 46,
    name: "SERVICIOS LOGISTICOS LTDA SERVILOG ",
  },
  {
    id: 48,
    name: "ROBERTO KATZ MAGALLANES ",
  },
  {
    id: 49,
    name: "LINEA RESTAURANTE LIMITADA ",
  },
  {
    id: 50,
    name: "INVERSIONES Y SERVICIOS GASTRONOMICOS ALIANZA LTDA ",
  },
  {
    id: 51,
    name: "SOLER CURICO SPA ",
  },
  {
    id: 52,
    name: "RESTAURANT LA TABLA S.A. ",
  },
  {
    id: 53,
    name: "RESTAURANT LOS ADOBES DE ARGOMEDO S.A ",
  },
  {
    id: 54,
    name: "PELAYO IZQUIERDO ",
  },
  {
    id: 55,
    name: "INVERSIONES INFOTEX LTDA. ",
  },
  {
    id: 56,
    name: "BCI SEGUROS DE VIDA S.A ",
  },
  {
    id: 57,
    name: "UNIVERSIDAD DE CHILE ",
  },
  {
    id: 58,
    name: "ANGELA BARLETTI BALLADARES ",
  },
  {
    id: 59,
    name: "SOCCER FACTORY LIMITADA ",
  },
  {
    id: 60,
    name: "MATIAS BAERWALD ",
  },
  {
    id: 61,
    name: "ISABEL DEL CARMEN GUTIERREZ GUAJARDO ",
  },
  {
    id: 62,
    name: "HUMBERTO GARETTO E HIJOS LTDA. ",
  },
  {
    id: 63,
    name: "CREACIONES CORPORATIVAS KENCORP LIMITADA ",
  },
  {
    id: 64,
    name: "SAAM EXTRAPORTUARIO S.A. ",
  },
  {
    id: 65,
    name: "DAVID GONZALEZ PEREZ ",
  },
  {
    id: 66,
    name: "ANDRES BARRY ",
  },
  {
    id: 67,
    name: "LUCIA REYES ",
  },
  {
    id: 68,
    name: "LUIS ARSENIO CONTRERAS SOTO ",
  },
  {
    id: 69,
    name: "SHARON LIAT CAMHI ROZENMAN ",
  },
  {
    id: 70,
    name: "ACEPTA S.A. ",
  },
  {
    id: 71,
    name: "CARLOS VALENTIN CACERES PINTO ",
  },
  {
    id: 72,
    name: "CARLOS GARCIA ",
  },
  {
    id: 73,
    name: "DIEGO GRANDON ",
  },
  {
    id: 74,
    name: "VYG LOGISTICA Y TURISMO SPA ",
  },
  {
    id: 75,
    name: "COMERCIAL EL MAIO LIMITADA ",
  },
  {
    id: 77,
    name: "JUAN ESTEBAN MELGAREJO MATUS ",
  },
  {
    id: 78,
    name: "DIEGO PILQUINIR ESCOBAR ",
  },
  {
    id: 79,
    name: "PRO3D DISEO E IMPRESION LIMITADA ",
  },
  {
    id: 80,
    name: "SOC.COM Y DE ASES COMERCIO EXTERIOR ABT COMEX ",
  },
  {
    id: 81,
    name: "MARIA BELEN BARAHONA SOTO ",
  },
  {
    id: 82,
    name: "KATHERINE MARDONES ",
  },
  {
    id: 83,
    name: "ASESORIAS Y REPRESENTACIONES SCL LIMITADA ",
  },
  {
    id: 84,
    name: "TRANSPORTES INTERNACIONAL Y COMERCIO EXTERIOR LIMI ",
  },
  {
    id: 85,
    name: "DISEOS MODULART LIMITADA ",
  },
  {
    id: 86,
    name: "MAGENS S.A. ",
  },
  {
    id: 87,
    name: "CONSTRUCTORA LAS CONDES LIMITADA ",
  },
  {
    id: 88,
    name: "IMPORTADORA Y COMERCIALIZADORA CAROLINA ISABEL OLI ",
  },
  {
    id: 89,
    name: "VIDRIOS Y ALUMINIOS MARIAN GUAJARDO GUTIERREZ EMPR ",
  },
  {
    id: 90,
    name: "KEYTEC LIMITADA ",
  },
  {
    id: 91,
    name: "TIENDA COMERCIAL ONLINE IVAN CASTRO MUOZ E.I.R.L. ",
  },
  {
    id: 92,
    name: "PAULA AHUMADA LIMITADA ",
  },
  {
    id: 93,
    name: "SKI TOTAL II LIMITADA ",
  },
  {
    id: 94,
    name: "FLORINDO HERNAN VALDEBENITO POLANCO ",
  },
  {
    id: 95,
    name: "SOCIEDAD DE TURISMO 3R LIMITADA ",
  },
  {
    id: 96,
    name: "AGENCIA DE VIAJES FORUM LIMITADA ",
  },
  {
    id: 97,
    name: "ASOC LOGISTICA DE CHILE A G ",
  },
  {
    id: 98,
    name: "GOOGLE INC. ",
  },
  {
    id: 99,
    name: "BIENESTAR CORPORATIVO SPA ",
  },
  {
    id: 100,
    name: "SHIPPTER SPA ",
  },
  {
    id: 101,
    name: "POSBEYIKIAN BUCHTER Y ASOCIADOS SRL ",
  },
  {
    id: 102,
    name: "CONSTRUCTORA VITACURA LIMITADA ",
  },
  {
    id: 103,
    name: "AGENCIA DE ADUANA GUADALUPE MENESES GALLARDO ",
  },
  {
    id: 105,
    name: "PUERTO COLUMBO S.A. ",
  },
  {
    id: 106,
    name: "CLAUDIA NILO ",
  },
  {
    id: 107,
    name: "PRESTADORA DE SERVICIOS LUIS CLAUDIO TRUJILLO PARA ",
  },
  {
    id: 108,
    name: "MATIAS RIVERA TORRES ",
  },
  {
    id: 109,
    name: "KEMEL PALIS ",
  },
  {
    id: 110,
    name: "CAJA CHICA OFICINA CENTRAL ",
  },
  {
    id: 111,
    name: "DEPOSITOS Y CONTENEDORES S.A. ",
  },
  {
    id: 112,
    name: "CARDINALE S.A. ",
  },
  {
    id: 113,
    name: "M ERRAZURIZ Y CIA. LTDA. ",
  },
  {
    id: 114,
    name: "COMERCIAL STARTCOMP SPA ",
  },
  {
    id: 115,
    name: "MARIANELA CATALAN ",
  },
  {
    id: 116,
    name: "CLUB PROVIDENCIA ",
  },
  {
    id: 117,
    name: "ASESORIAS E INVERSIONES KATZ CHAMBERLAIN LIMITADA ",
  },
  {
    id: 118,
    name: "JASLIN COROMOTO BOSCAN MATHEUS ",
  },
  {
    id: 119,
    name: "COSMOPLAS S.A. ",
  },
  {
    id: 121,
    name: "ACCESS BUSSINES ",
  },
  {
    id: 122,
    name: "CRISTIAN IBAEZ HERRERA ",
  },
  {
    id: 123,
    name: "DANIEL KNUST ESRA ",
  },
  {
    id: 124,
    name: "FRANCISCO JIMENEZ ANCAVIL ",
  },
  {
    id: 125,
    name: "MOTOCARGO SPA ",
  },
  {
    id: 126,
    name: "GUILLERMO ANTONIO RUZ ESPINOZA ",
  },
  {
    id: 127,
    name: "TOP TRADE CHILE SPA ",
  },
  {
    id: 128,
    name: "AIRLINE SERVICES AND LOGISTICS LIMITADA ",
  },
  {
    id: 129,
    name: "COMERCIAL COTELA LTDA ",
  },
  {
    id: 130,
    name: "COMERCIAL FOOD AND FANTASY LTDA. ",
  },
  {
    id: 131,
    name: "MIGUEL ANGEL MORALES VERGARA ",
  },
  {
    id: 132,
    name: "IXOM CHILE S.A. ",
  },
  {
    id: 133,
    name: "CHILEXPRESS S.A. ",
  },
  {
    id: 134,
    name: "SERVICIOS AGRICOLAS TECNIPAK LIMITADA ",
  },
  {
    id: 135,
    name: "AZOLA CORRIPIO LIMITADA ",
  },
  {
    id: 136,
    name: "IMPORT DISTRIBUIDORA Y COMER BRASILIA LIMITADA ",
  },
  {
    id: 137,
    name: "KERRY WAREHOUSING CHILE S.A. ",
  },
  {
    id: 138,
    name: "TERMINAL PACIFICO SUR VALPARAISO S.A. ",
  },
  {
    id: 139,
    name: "MARINA HOTELES LIMITADA ",
  },
  {
    id: 140,
    name: "FACTORY SOLUCIONES SPA ",
  },
  {
    id: 141,
    name: "GABRIELA ALEJANDRA GRAF IBANEZ ",
  },
  {
    id: 142,
    name: "NEUTRAL QUALITY SPA ",
  },
  {
    id: 143,
    name: "GLOBAL TRADE SOURCE SPA ",
  },
  {
    id: 144,
    name: "SAN ANTONIO TERMINAL INTERNACIONAL S.A. ",
  },
  {
    id: 145,
    name: "RODRIGO HERNAN TORO SALGADO ",
  },
  {
    id: 146,
    name: "DEPOCARGO DEPOSITO ADUANERO DE CARGA LTDA ",
  },
  {
    id: 147,
    name: "FUNDACION ARTURO LOPEZ PEREZ ",
  },
  {
    id: 148,
    name: "COMERCIAL KMX LTDA ",
  },
  {
    id: 149,
    name: "CRISTIAN PRIETO OPAZO Y CIA LIMITADA ",
  },
  {
    id: 150,
    name: "ESTABLECIMIENTOS COMERCIALES CALIFORNIA S.A. ",
  },
  {
    id: 152,
    name: "CONTAINER OPERATORS S.A. ",
  },
  {
    id: 153,
    name: "SKY AIRLINE S.A ",
  },
  {
    id: 154,
    name: "CELER-IT SPA ",
  },
  {
    id: 155,
    name: "HUGO FERNANDO IBANEZ BALBONTIN ",
  },
  {
    id: 157,
    name: "ROBERTO FERNANDO PUGA PINO ",
  },
  {
    id: 158,
    name: "COMERCIAL TOP ROLLER SPA ",
  },
  {
    id: 159,
    name: "BANCO SANTANDER CHILE ",
  },
  {
    id: 160,
    name: "KLM COMPAIA REAL HOLANDESA DE AVIACION ",
  },
  {
    id: 161,
    name: "SERVICIOS LOGISTICOS LTDA. ",
  },
  {
    id: 164,
    name: "SHIPIT SPA ",
  },
  {
    id: 172,
    name: "TERMINAL DE EXPORTACION INTERNACIONAL S.A. ",
  },
  {
    id: 173,
    name: "CARGO LINE CHILE S.A. ",
  },
  {
    id: 179,
    name: "ALVARO CASANOVA MAQUINARIAS LTDA ",
  },
  {
    id: 181,
    name: "RAM SPA ",
  },
  {
    id: 182,
    name: "COMPANIA DE SEGUROS GENERALES CONTINENTAL S.A. ",
  },
  {
    id: 183,
    name: "TANNYHA ALEJANDRA SALINAS SAAVEDRA ",
  },
  {
    id: 185,
    name: "ORGANISMO TECNICO DE CAPACITACION ALOG CAPACITA SP ",
  },
  {
    id: 186,
    name: "VICTOR MANUEL MORALES ALARCON ",
  },
  {
    id: 187,
    name: "TEKA CHILE S.A. ",
  },
  {
    id: 188,
    name: "ANGELO DELPIN ",
  },
  {
    id: 189,
    name: "EDITORIAL TELEVISA CHILE S A ",
  },
  {
    id: 190,
    name: "EDWARDS Y URETA LIMITADA ",
  },
  {
    id: 191,
    name: "SKY LEASE I INC AGENCIA EN CHILE ",
  },
  {
    id: 192,
    name: "ALVARO SERRANO MUSALEM ",
  },
  {
    id: 193,
    name: "ADELITZA VALENZUELA ",
  },
  {
    id: 194,
    name: "COLORPRO SPA ",
  },
  {
    id: 195,
    name: "COMERCIAL FRANCA PISANI LTDA ",
  },
  {
    id: 196,
    name: "KENNY ANTONIO GARCIA PUGA ",
  },
  {
    id: 197,
    name: "SOCIEDAD COMERCIAL GRUPO YES CHILE SPA ",
  },
  {
    id: 198,
    name: "HOTELERA ICON S.A. ",
  },
  {
    id: 199,
    name: "DANILO YANEZ SILVA ",
  },
  {
    id: 200,
    name: "CLAUDIA SALAMANCA LIMITADA ",
  },
  {
    id: 201,
    name: "AVIATION SERVICE LIMITADA ",
  },
  {
    id: 202,
    name: "ALFONSO ANDRES DIAZ MANRIQUEZ COMERCIAL E I R L ",
  },
  {
    id: 203,
    name: "COMERCIALIZADORA JEANNETTE MACARENA LEIVA PARRA E. ",
  },
  {
    id: 204,
    name: "INMOBILIARIA GERMANIA LIMITADA ",
  },
  {
    id: 205,
    name: "INTER-MEDIOS CONSULTORES EN COMUNICACION LIMITADA ",
  },
  {
    id: 206,
    name: "MUTUAL DE SEGURIDAD CAMARA CHILENA DE LA CONSTRUCC ",
  },
  {
    id: 208,
    name: "CHUBB SEGUROS CHILE S.A. ",
  },
  {
    id: 209,
    name: "FAST AIR ALMACENES DE CARGA S A ",
  },
  {
    id: 210,
    name: "FSC ANALISIS S.A. ",
  },
  {
    id: 211,
    name: "SERVICIOS AEROPORTUARIOS AEROSAN S.A. ",
  },
  {
    id: 212,
    name: "PABLO SATELER DUSSAILLANT ",
  },
  {
    id: 213,
    name: "FERNANDO SILVA BALLESTEROS ",
  },
  {
    id: 214,
    name: "COMERCIALIZADORA IRMA TORREALBA ELVEA E.I.R.L. ",
  },
  {
    id: 215,
    name: "PUERTO CENTRAL S.A ",
  },
  {
    id: 216,
    name: "COMUNICACIONES, MARKETING Y SERVICIOS DIGITALES CO ",
  },
  {
    id: 217,
    name: "SOCIEDAD COMERCIALIZADORA Y PRODUCTORA SINERGICCHE ",
  },
  {
    id: 218,
    name: "INMOBILIARIA PETRA ALFA SPA. ",
  },
  {
    id: 219,
    name: "SITRANS ALMACENES EXTRAPORTUARIOS LIMITADA ",
  },
  {
    id: 220,
    name: "YESSENIA ANDREA PAREDES ROJAS ",
  },
  {
    id: 221,
    name: "ARKO G SPA ",
  },
  {
    id: 222,
    name: "MARISABEL SALOME QUEZADA VIDELA ",
  },
  {
    id: 223,
    name: "HOTELERIA Y TURISMO CASTELLON Y PIFFAUT SPA ",
  },
  {
    id: 224,
    name: "SMART OFFICE SOLUTIONS S.A. ",
  },
  {
    id: 225,
    name: "AGENCIA DE ADUANAS PEDRO SANTIBANEZ LUCO Y CIA LTD ",
  },
  {
    id: 226,
    name: "MYRNA DEL CARMEN SOLIS MONTERO ",
  },
  {
    id: 227,
    name: "MARKETING Y PUBLICIDAD P CUETO SPA ",
  },
  {
    id: 228,
    name: "EMPRESA PORTUARIA IQUIQUE ",
  },
  {
    id: 229,
    name: "TAMMY ELISHEBA WINER APT ",
  },
  {
    id: 230,
    name: "JOSE LUIS VARGAS MOLINA ",
  },
  {
    id: 231,
    name: "COMERCIALIZADORA DE ARTICULOS DE LIMPIEZA Y ASEO Y ",
  },
  {
    id: 232,
    name: "FG LOGISTICA SPA ",
  },
  {
    id: 233,
    name: "LUIS ALFREDO IRARRAZAVAL CHADWICK ",
  },
  {
    id: 234,
    name: "TRANSPORTES EL ANDINO ",
  },
  {
    id: 235,
    name: "CIA PORTUARIA MEJILLONES S A ",
  },
  {
    id: 237,
    name: "ORION SEGUROS GENERALES ",
  },
  {
    id: 238,
    name: "CONSERVADOR DE BIENES RAICES DE SANTIAGO ",
  },
  {
    id: 239,
    name: "ALEJANDRO GABRIEL PINO ZAPATA ",
  },
  {
    id: 241,
    name: "PABLO RENE GABLER ALFARO ",
  },
  {
    id: 242,
    name: "KATHERINE MITJAI MACHUCA TORRES ",
  },
  {
    id: 243,
    name: "OHFFICE SPA ",
  },
  {
    id: 244,
    name: "ROBERTO VASQUEZ DIAZ ",
  },
  {
    id: 246,
    name: "YOLANDA RUTH HERNANDEZ CONTRERAS ",
  },
  {
    id: 247,
    name: "ALDO PATRICIO GALVEZ ZAMORA ",
  },
  {
    id: 248,
    name: "COMPANIA DE SEGUROS DE VIDA CAMARA S.A. ",
  },
  {
    id: 249,
    name: "PROVEEDORES INTEGRALES PRISA S A ",
  },
  {
    id: 250,
    name: "JAVIERA CONSTANZA RODRIGUEZ VALENZUELA ",
  },
  {
    id: 251,
    name: "IMP CHUNHUA WANG EIRL ",
  },
  {
    id: 252,
    name: "SODIMAC S A ",
  },
  {
    id: 253,
    name: "MATRIZ IDEAS S.A. ",
  },
  {
    id: 254,
    name: "LIBRERIAS Y TIMBRES CHILE SPA. ",
  },
  {
    id: 255,
    name: "TERMINAL EXTRAPORTUARIO VALPARAISO S.A. ",
  },
  {
    id: 256,
    name: "SECRET GARDEN SPA ",
  },
  {
    id: 257,
    name: "PATRICIA EDILIA CASTELLON ARGOTE MUELLAJE E.I.R.L. ",
  },
  {
    id: 258,
    name: "RICHARD LAGOS ARRIAGADA ",
  },
  {
    id: 259,
    name: "CAROLINA ISABEL OLIVA SEGUEL ",
  },
  {
    id: 260,
    name: "LILLY EVELYN MARTINEZ ZAPATA ",
  },
  {
    id: 261,
    name: "COMERCIALIZADORA SP DIGITAL LTDA ",
  },
  {
    id: 262,
    name: "MARCELO CLAUDIO GONZALEZ ESPINOZA ",
  },
  {
    id: 263,
    name: "DISTRIBUIDORA DE INDUSTRIAS NACIONALES S A ",
  },
  {
    id: 264,
    name: "WISETECH GLOBAL (ARGENTINA) S.A.U. ",
  },
  {
    id: 265,
    name: "EMPRESAS LA POLAR S.A. ",
  },
  {
    id: 266,
    name: "IMPORTADORA Y COMERCIALIZADORA MKP LIMITADA ",
  },
  {
    id: 267,
    name: "PERSONAL COMPUTER FACTORY SOCIEDAD ANONIMA ",
  },
  {
    id: 268,
    name: "CLICK SPA ",
  },
  {
    id: 269,
    name: "COMERCIAL ECCSA S A ",
  },
  {
    id: 270,
    name: "FRANCISCO ENRIQUE ROJAS VILLANUEVA ",
  },
  {
    id: 271,
    name: "EDUARDO ANDRES VIVANCO ARENAS ",
  },
  {
    id: 273,
    name: "ENEL DISTRIBUCION CHILE S.A. ",
  },
  {
    id: 274,
    name: "COMUNIDAD EDIFICIO PASEO APOQUINDO I ",
  },
  {
    id: 275,
    name: "G3 SERVICIOS GASTRONOMICOS SPA ",
  },
  {
    id: 276,
    name: "SOCIEDAD COMERCIAL ELIPSE LIMITADA ",
  },
  {
    id: 277,
    name: "COPESA S.A. ",
  },
  {
    id: 278,
    name: "ARRIENDOS ELBA DEL PILAR HERNANDEZ VALDES E.I.R.L ",
  },
  {
    id: 279,
    name: "SERVICIOS ISIDORA HENRIQUEZ GANA SPA ",
  },
  {
    id: 281,
    name: "LA PASTE BE SPA ",
  },
  {
    id: 284,
    name: "AGENCIA DE ADUANAS PAULA MENARES VILLAR Y COMPANIA ",
  },
  {
    id: 285,
    name: "NICOLE MICHELLE DE JOURDAN HAVERBECK ",
  },
  {
    id: 288,
    name: "RACKEO SPA ",
  },
  {
    id: 289,
    name: "MICROSOFT CORPORATION ",
  },
  {
    id: 290,
    name: "BRIAN ALDONEY CARIOLA ",
  },
  {
    id: 291,
    name: "SERVICIOS CHERYL ZAMORANO ROQUE EMPRESA INDIVIDUAL ",
  },
  {
    id: 293,
    name: "AXAM COMERCIAL SPA ",
  },
  {
    id: 294,
    name: "NATALIA ANDREA PINILLA RETAMAL ",
  },
  {
    id: 295,
    name: "TOMAS ELGUETA TRUFFELLO ",
  },
  {
    id: 297,
    name: "ANGEL ENRIQUE ESCALA QUIJADA ",
  },
  {
    id: 298,
    name: "ASOCIACION GREMIAL DE EMPRENDEDORES EN CHILE A.G ",
  },
  {
    id: 299,
    name: "JOSE FELICIANO FIERRO CONTRERAS ",
  },
  {
    id: 300,
    name: "PORTATILCHILE ONLINE SPA ",
  },
  {
    id: 301,
    name: "PINGUIMOM SPA ",
  },
  {
    id: 302,
    name: "BECERRA Y GILARDONI LIMITADA ",
  },
  {
    id: 303,
    name: "NATALIA ESTEFANIA ESCUDERO GALVEZ ",
  },
  {
    id: 304,
    name: "CENCOSUD RETAIL S.A. ",
  },
  {
    id: 305,
    name: "MARIA VICTORIA PONCE BLANCO ",
  },
  {
    id: 306,
    name: "CITY TOUR LIMITADA ",
  },
  {
    id: 307,
    name: "EDUARDO EDDIE RIOS DURAN ",
  },
  {
    id: 308,
    name: "AGENCIA DE ADUANAS MARIA ISABEL VARGAS Y COMPANIA ",
  },
  {
    id: 309,
    name: "CONTEMPORA COMPANIA DE SEGUROS GENERALES S.A. ",
  },
  {
    id: 310,
    name: "SELCHILE LIMITADA ",
  },
  {
    id: 311,
    name: "ZEAL SOCIEDAD CONCESIONARIA S.A. ",
  },
  {
    id: 312,
    name: "CRISTIAN HUMBERTO MARAMBIO GUZMAN ",
  },
  {
    id: 313,
    name: "YANIRA EMILIA PIMENTEL ALONZO ",
  },
  {
    id: 314,
    name: "INVERSIONES D.M.G. SOCIEDAD ANONIMA ",
  },
  {
    id: 315,
    name: "ESSBIO S.A. ",
  },
  {
    id: 317,
    name: "SDW SHIPPING CHILE SPA ",
  },
  {
    id: 318,
    name: "FALABELLA RETAIL S.A. ",
  },
  {
    id: 319,
    name: "MINEARC SYSTEMS S.A. ",
  },
  {
    id: 320,
    name: "TELEFONICA MOVILES CHILE S.A. ",
  },
  {
    id: 321,
    name: "IQUIQUE TERMINAL INTERNACIONAL S A ",
  },
  {
    id: 322,
    name: "REGALOS PRO SPA ",
  },
  {
    id: 323,
    name: "JOSE DANIEL RODRIGUEZ ARCAYA ",
  },
  {
    id: 325,
    name: "INVERSIONES TECNOLOGICAS S.A. ",
  },
  {
    id: 326,
    name: "BAUERLE DESIGN SPA ",
  },
  {
    id: 327,
    name: "TALBOT HOTELS S A ",
  },
  {
    id: 328,
    name: "DANIELA SCHUPPER LITVAK ",
  },
  {
    id: 329,
    name: "TRANSPORTES SIMPLY TAKE CHILE LTDA ",
  },
  {
    id: 330,
    name: "CELTRY CHILE SPA ",
  },
  {
    id: 331,
    name: "SOLDADURAS SOLTEC SPA ",
  },
  {
    id: 332,
    name: "ANALIA NORA STUTMAN ZAPATA ",
  },
  {
    id: 334,
    name: "SCHOLLE IPN SPA ",
  },
  {
    id: 335,
    name: "GRUPO INGEPRO CHILE SPA ",
  },
  {
    id: 337,
    name: "SERVICIOS ECYDS LIMITADA ",
  },
  {
    id: 338,
    name: "LOGISTICA Y TRANSPORTE D.ATADIE LIMITADA ",
  },
  {
    id: 340,
    name: "CAMALEîN IMPRESOS SPA ",
  },
  {
    id: 341,
    name: "CAROLE GLISSER TELIAS ",
  },
  {
    id: 342,
    name: "CRISTIAN RODOLFO BUSTOS MANCILLA ",
  },
  {
    id: 343,
    name: "E-PRESS COMUNICACIONES SPA ",
  },
  {
    id: 344,
    name: "MANUELA GARCIA COX ",
  },
  {
    id: 345,
    name: "JAIDY RODRIGO ISAZA BARBOSA ",
  },
  {
    id: 346,
    name: "TERMINAL PUERTO ARICA S.A. ",
  },
  {
    id: 347,
    name: "ORGANISMO TECNICO DE CAPACITACION ACOMTEC SPA ",
  },
  {
    id: 348,
    name: "ASESORIAS KOI SPA ",
  },
  {
    id: 349,
    name: "TCARGO GLOBAL SPA ",
  },
  {
    id: 351,
    name: "FLORENCIA LUCIA GREBE UNGER ",
  },
  {
    id: 352,
    name: "CONSULTORA MACROSCOPE CHILE LIMITADA ",
  },
  {
    id: 353,
    name: "TALI WAINGORTIN CHICHOTKY ",
  },
  {
    id: 354,
    name: "JULIO JOSE ALVEAR ASPILLAGA ",
  },
  {
    id: 355,
    name: "GABRIELA EMILIA CONTRERAS RETAMAL ",
  },
  {
    id: 356,
    name: "IFF LOGISTICA SPA ",
  },
  {
    id: 357,
    name: "COMERCIAL Y SERVICIOS R I LIMITADA ",
  },
  {
    id: 358,
    name: "JOAQUIN ESTEBAN LOPEZ MUSA ",
  },
  {
    id: 359,
    name: "MATHIAS KORTGE MACKENZIE ",
  },
  {
    id: 360,
    name: "CAROL ANDREA SALAZAR REYES ",
  },
  {
    id: 361,
    name: "TAMARA ANDREA MENDOZA RAMOS ",
  },
  {
    id: 362,
    name: "COMERCIAL E INDUSTRIAL SILFA S.A ",
  },
  {
    id: 363,
    name: "DISTRIBUIDORA DE EQUIPAMIENTO COMPUTACIONAL Y DE O ",
  },
  {
    id: 364,
    name: "PUNTA RANCO SPA ",
  },
  {
    id: 365,
    name: "IMPORTADORA DE IDEAS S.A. ",
  },
  {
    id: 366,
    name: "ANA ISABEL ESTABA RODRIGUEZ ",
  },
  {
    id: 367,
    name: "ANDRES FELIPE RIEUTORD ALVARADO ",
  },
  {
    id: 369,
    name: "BUK SPA ",
  },
  {
    id: 370,
    name: "COMERCIAL YU Y COMPANIA LIMITADA ",
  },
  {
    id: 371,
    name: "EYES MARKETING SPA ",
  },
  {
    id: 372,
    name: "JORGE EDUARDO MUNOZ HUERTA ",
  },
  {
    id: 373,
    name: "FELIPE IGNACIO BERRIOS GALDAMES ",
  },
  {
    id: 374,
    name: "CLAUDIO MARCELO CHUHAICURA LOPEZ ",
  },
  {
    id: 375,
    name: "COMERCIAL EL MAIO LIMITADA ",
  },
  {
    id: 376,
    name: "FARUKCHILE SPA ",
  },
  {
    id: 377,
    name: "IDEAS COMPANY SPA ",
  },
  {
    id: 378,
    name: "IMPORTADORA Y COMERCIAL JENNIFER LENG E.I.R.L. ",
  },
  {
    id: 379,
    name: "Industria Textil Talinay S.A. ",
  },
  {
    id: 380,
    name: "Inversiones Tecnologicas S. A. ",
  },
  {
    id: 381,
    name: "SEL LODGEÊ SPA SPA ",
  },
  {
    id: 382,
    name: "SERVICIOS DE ATENCION MEDICA DOMICILIARIA LIMITADA ",
  },
  {
    id: 383,
    name: "SOCIEDAD COMERCIAL Y DE SERVICIOS ARCA DE SAMUEL L ",
  },
  {
    id: 384,
    name: "TROFEOS PREMIUMS SPA ",
  },
  {
    id: 385,
    name: "CONSTANZA DANAE BALBONTIN HERNANDEZ ",
  },
  {
    id: 386,
    name: "FELIPE IGNACIO BERMUDEZ BOZZOLO ",
  },
  {
    id: 387,
    name: "LIAN ALBALA PINO ",
  },
  {
    id: 388,
    name: "COMERCIAL ALCA LIMITADA ",
  },
  {
    id: 389,
    name: "SUBSECRETARIA DEL INTERIOR ",
  },
  {
    id: 390,
    name: "IMPRENTA DIAGRAMA SPA ",
  },
  {
    id: 391,
    name: "MIDOLO SEBASTIAN FRANCO ",
  },
  {
    id: 393,
    name: "OCTAVA SOFTWARE SPA ",
  },
  {
    id: 394,
    name: "RUSTAN PUBLICIDAD SPA ",
  },
  {
    id: 395,
    name: "LUKAS MATIAS SIMUNOVIC RICHARDSON ",
  },
  {
    id: 396,
    name: "FELIPE IGNACIO CATALDO MILLAN ",
  },
  {
    id: 397,
    name: "NATALIA FERNANDA ROJAS PARRA ",
  },
  {
    id: 398,
    name: "CAJA DE COMPENSACION DE ASIGNACION FAMILIAR DE LOS ",
  },
  {
    id: 399,
    name: "AGUNSA EXTRAPORTUARIO S.A. ",
  },
  {
    id: 400,
    name: "ANA YOLEIDA RODRIGUEZ RINCON ",
  },
  {
    id: 401,
    name: "MARCO ANTONIO MENDOZA CASTILLO ",
  },
  {
    id: 402,
    name: "SAFERACK ",
  },
  {
    id: 403,
    name: "MARTIN ISAAC TEMPINE PEREZ ",
  },
  {
    id: 404,
    name: "JOSE ISRAEL MENESES SALINAS ",
  },
  {
    id: 405,
    name: "BIOBRUSH ",
  },
  {
    id: 406,
    name: "SOC COMERCIO EXTERIOR TRANSPORTE LOGISTICA Y ALMAC ",
  },
  {
    id: 407,
    name: "BECH S.O.S SPA ",
  },
  {
    id: 426,
    name: "ELEMAR SOPORTE Y LOGISTICA ",
  },
  {
    id: 435,
    name: "CENTAURI LOGISTICS SPA",
  },
  {
    id: 474,
    name: "HORTENCIA IVETTE VERA PARRA",
  },
  {
    id: 430,
    name: "SEAPORT S.A.",
  },
  {
    id: 496,
    name: "TOMAS ALLEGRI",
  },
];
export const TRANSLOG_CARRIERS = [
  {
    id: 1,
    name: "MARITIME SERVICES LINE BOLIVIA S.R.L. ",
  },
  {
    id: 2,
    name: '"K" LINE ',
  },
  {
    id: 3,
    name: "BICKER ",
  },
  {
    id: 4,
    name: "CIA.SUDAMERICANA DE VAPORES S.A. ",
  },
  {
    id: 5,
    name: "CMA-CGM ",
  },
  {
    id: 6,
    name: "CCNI ",
  },
  {
    id: 7,
    name: "COSCO ",
  },
  {
    id: 8,
    name: "CRAFT MULTIMODAL CHILE SPA ",
  },
  {
    id: 9,
    name: "ECU WORLDWIDE (CHILE) S.A ",
  },
  {
    id: 10,
    name: "GREEN ANDES ",
  },
  {
    id: 11,
    name: "HAMBURG ",
  },
  {
    id: 12,
    name: "(NO USAR) H A P A G LLOYD CHILE AG.MARITIMA LTDA. ",
  },
  {
    id: 13,
    name: "MAERSK ",
  },
  {
    id: 14,
    name: "MEDITERRANEAN SHIPPING COMPANY (CHILE) S.A. ",
  },
  {
    id: 15,
    name: "MSL ",
  },
  {
    id: 16,
    name: "NYK SUDAMERICA (CHILE) LTDA ",
  },
  {
    id: 17,
    name: "PLUSCARGO CHILE SPA ",
  },
  {
    id: 18,
    name: "SERV. PORTUARIOS Y AEROPORT. Y DE CARGA CAROTRANS ",
  },
  {
    id: 19,
    name: "SHIPCO TRANSPORT (CHILE) S.A. ",
  },
  {
    id: 20,
    name: "ULTRAMAR ",
  },
  {
    id: 21,
    name: "YANG MING ",
  },
  {
    id: 22,
    name: "AGENCIAS UNIVERSALES S.A. ",
  },
  {
    id: 23,
    name: "HANJIN SHIPPING ",
  },
  {
    id: 24,
    name: "NAVEGACION DEL PACIFICO AGENCIA MARITIMA LTDA ",
  },
  {
    id: 25,
    name: "IAN TAYLOR CHILE S.A. ",
  },
  {
    id: 26,
    name: "ILS SHIPPING CHILE SPA ",
  },
  {
    id: 27,
    name: "BALMORAL S.A. ",
  },
  {
    id: 28,
    name: "REPRESENTACIONES MOL CHILE LTDA ",
  },
  {
    id: 30,
    name: "AGENCIA COLUMBUS CHILE SPA ",
  },
  {
    id: 31,
    name: "HAPAG-LLOYD CHILE SPA ",
  },
  {
    id: 32,
    name: "A.J.BROOM Y CIA S.A.C. ",
  },
  {
    id: 33,
    name: "SACO SHIPPING S.A. ",
  },
  {
    id: 34,
    name: "SOUTH CARGO CHILE TRANSPORTE Y LOGISTICA SPA ",
  },
  {
    id: 35,
    name: "TRANSPORTES Y REPRESENTACIONES INDUTRANS LTDA ",
  },
  {
    id: 36,
    name: "VANGUARD LOGISTIC SERVICE (CHILE) S.A. ",
  },
  {
    id: 38,
    name: "SOMARCO LIMITADA ",
  },
  {
    id: 39,
    name: "OVER CHILE SPA ",
  },
  {
    id: 40,
    name: "RICCARDI Y COMPAIA LTDA ",
  },
  {
    id: 42,
    name: "UNIVERSAL FREIGHT SERVICE S.A. ",
  },
  {
    id: 43,
    name: "BROOM LOGISTICS S.A. ",
  },
  {
    id: 44,
    name: "AGENCIA MARITIMA KENRICK LTDA ",
  },
  {
    id: 45,
    name: "OCEAN NETWORK EXPRESS (CHILE) SPA ",
  },
  {
    id: 46,
    name: "MSC MEDITERRANEAN SHIPPING COMPANY S.A. ",
  },
  {
    id: 47,
    name: "CLEARFREIGHT CHILE SPA ",
  },
  {
    id: 49,
    name: "EVERGREEN SHIPPING AGENCY (CHILE) SPA ",
  },
  {
    id: 51,
    name: "SEAFAIR CHILE S.A. ",
  },
  {
    id: 54,
    name: "CHILETRANS LOGISTICS S A ",
  },
  {
    id: 55,
    name: "CHARTER LINK LOGISTICS CHILE SPA ",
  },
  {
    id: 58,
    name: "INTEGRAL CHILE S.A. ",
  },
];
export const TRANSLOG_SUBCLIENTS = [
  { id: 601, name: "JUNTA NACIONAL DE CUERPOS DE BOMBEROS" },
  { id: 633, name: "CUERPO DE BOMBEROS DE VALDIVIA" },
  { id: 634, name: "CUERPO DE BOMBEROS DE SAN PEDRO DE LA PAZ" },
  { id: 373, name: "LABORATORIO BALLERINA LIMITADA" },
  { id: 13, name: "ACCESS BUSSINES" },
  { id: 45, name: "RAM SPA" },
  { id: 341, name: "INDUSTRIAL Y COMERCIAL SOLUCORP LIMITADA" },
  { id: 2142, name: "BRONAR SPA" },
  { id: 444, name: "CHILEAN TRADING COMPANY S.A. " },
  { id: 2943, name: "CONGMING LLC" },
  { id: 3033, name: "ITÁLICA SINTERIZADO DENTAL S.L. " },
  { id: 3066, name: "KAMBIO" },
  { id: 3030, name: "QUALITY RUBBER S.A" },
  { id: 3141, name: "Aries Global Logistics, Inc." },
];
export const TRANSLOG_AIRLINES = [
  {
    id: 1,
    name: "AMERICAN AIRLINES ",
  },
  {
    id: 6,
    name: "DELTA AIR LINES INC. ",
  },
  {
    id: 14,
    name: "AIR CANADA ",
  },
  {
    id: 16,
    name: "UNITED AIR LINES AGENCIA EN CHILE ",
  },
  {
    id: 44,
    name: "AEROLINEAS ARGENTINAS S.A ",
  },
  {
    id: 45,
    name: "LATAM AIRLINES GROUP S.A. ",
  },
  {
    id: 47,
    name: "TAP AIR PORTUGAL ",
  },
  {
    id: 55,
    name: "ALITALIA ",
  },
  {
    id: 57,
    name: "AIR FRANCE ",
  },
  {
    id: 71,
    name: "ETHOPIAN AIRLINES ",
  },
  {
    id: 74,
    name: "AG CHILE KLM COMPANIA REAL HOLANDESA DE AVIACION ",
  },
  {
    id: 75,
    name: "IBERIA LINEAS AEREAS DE ESPAA ",
  },
  {
    id: 81,
    name: "QANTAS AYR WAYS ",
  },
  {
    id: 86,
    name: "AIR NEW ZEALAND ",
  },
  {
    id: 125,
    name: "BRITISH AIRWAYS ",
  },
  {
    id: 134,
    name: "AVIANCA ",
  },
  {
    id: 139,
    name: "AEROMEXICO ",
  },
  {
    id: 145,
    name: "LAN CARGO S.A. ",
  },
  {
    id: 157,
    name: "QATAR AIRWAYS ",
  },
  {
    id: 172,
    name: "CARGOLUX AIRLINES INTERNATIONAL SA ",
  },
  {
    id: 176,
    name: "EMIRATES SKY CARGO ",
  },
  {
    id: 180,
    name: "KOREAN AIR LINES CO LTDA ",
  },
  {
    id: 202,
    name: "TACA ",
  },
  {
    id: 230,
    name: "COPA AIRLINES ",
  },
  {
    id: 272,
    name: "Kalitta Air, LLC ",
  },
  {
    id: 307,
    name: "CENTURION AIR CARGO ",
  },
  {
    id: 369,
    name: "ATLAS AIR CARGO ",
  },
  {
    id: 403,
    name: "POLAR AIR CARGO ",
  },
  {
    id: 406,
    name: "UNITED PARCEL SERVICE ",
  },
  {
    id: 549,
    name: "AEROLINHAS BRASILEIRAS S.A ",
  },
  {
    id: 605,
    name: "SKY AIRLINES S.A. ",
  },
  {
    id: 607,
    name: "ETIHAD AIRWAYS ",
  },
  {
    id: 618,
    name: "SINGAPORE AIRLINES ",
  },
  {
    id: 724,
    name: "SWISS WORLD CARGO ",
  },
  {
    id: 729,
    name: "TAMPA CARGO S.A. ",
  },
  {
    id: 745,
    name: "AIR BERLIN GMBH & CO LUFTVERKEHRRS ",
  },
  {
    id: 784,
    name: "CHINA SOUTHERN AIRLINES CO LTDA ",
  },
  {
    id: 805,
    name: "MERCURY WORLD CARGO 4X ",
  },
  {
    id: 932,
    name: "VIRGIN ATLANTIC AIRWAYS LTD ",
  },
  {
    id: 999,
    name: "AIR CHINA CARGO CO. LTD ",
  },
];
export const fillTranslogWithZeroes = ({ id, zeroesQuantity }) => {
  const zeroesNeeded = zeroesQuantity - String(id).length;
  const lengthArray = Array(zeroesNeeded).fill(0);
  return [...lengthArray, ...String(id)].join("");
};
export const translogEntitiesMap = ({ translogEntity, zeroesQuantity }) =>
  translogEntity.map(({ id, name }) => ({
    label: name,
    value: fillTranslogWithZeroes({ id, zeroesQuantity }),
  }));
export const translogEntityFinder =
  (currentId) =>
  ({ translogEntity, zeroesQuantity }) =>
    translogEntity.find(
      ({ id: entityId }) =>
        fillTranslogWithZeroes({ zeroesQuantity, id: entityId }) === currentId
    ) || {};
export const BUY = "buy";
export const SALE = "sale";
export const CMIG = "CMIG";
export const CMEG = "CMEG";
export const CAIG = "CAIG";
