import { Steps as AntdSteps } from "antd";
import { StepsProps } from "antd/lib/steps";
import Router from "next/router";

import styles from "./Steps.module.scss";

const { Step: AntdStep } = AntdSteps;

export const pushStep =
  ({ mixpanel }) =>
  async ({ pathname = null, query, url, trackMixpanel = null }) => {
    if (trackMixpanel && mixpanel) {
      mixpanel.track(trackMixpanel);
    }
    await Router.push(
      {
        query,
        pathname: pathname || Router.pathname,
      },
      url
    );
  };

export const Steps: React.FunctionComponent<StepsProps> = ({
  children,
  ...antdProps
}) => {
  return (
    <div className={styles.stepsContainer}>
      <AntdSteps {...antdProps}>{children}</AntdSteps>
    </div>
  );
};

export const Step = AntdStep;
