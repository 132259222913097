import {
  ApartmentOutlined,
  BarChartOutlined,
  CalendarOutlined,
  CheckSquareOutlined,
  ContactsOutlined,
  ContainerOutlined,
  DashboardOutlined,
  DollarOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  GatewayOutlined,
  HourglassOutlined,
  InteractionOutlined,
  ProfileOutlined,
  ShopOutlined,
  SolutionOutlined,
  ToolOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";
import isNode from "detect-node";
import Router from "next/router";
import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import { REPORTS } from "src/features/embeddedReports/constants";
import { KlogRelease } from "src/features/icons/KlogRelease";
import OperationsRejectedByUserAmount from "@/components/OperationsRejectedByUserAmount/OperationsRejectedByUserAmount";

import { OPERATIVE } from "./constants";

import calendarStyles from "../components/OperationsRejectedByUserAmount/OperationsRejectedByUserAmount.module.css";

export type Route = {
  name: string;
  pathname?: string;
  icon?: any;
  groups?: string[];
  children?: Route[];
  suffix?: ReactElement;
  requiredPermission?: string[];
};

const routes: Route[] = [
  {
    name: "tasks",
    requiredPermission: ["tasksMenuPage", "read"],
    pathname: "/tasks",
    icon: CheckSquareOutlined,
  },
  {
    name: "panel",
    requiredPermission: ["panelMenuPage", "read"],
    pathname: "/panel",
    icon: DashboardOutlined,
    groups: [
      "teuai",
      "customerService",
      "company",
      "public",
      "finance",
      OPERATIVE,
      "companyAdmin",
      "customsAgency",
    ],
  },
  {
    name: "itineraries",
    requiredPermission: ["itinerariesMenuPage", "read"],
    pathname: "/itineraries",
    icon: HourglassOutlined,
    groups: ["teuai", "customerService", "salesPerson", "company", "operative"],
  },
  {
    name: "routingQuotes",
    requiredPermission: ["routingQuotesMenuPage", "read"],
    pathname: "/routingQuotes",
    icon: InteractionOutlined,
    groups: ["teuai"],
  },
  {
    name: "instructions",
    requiredPermission: ["instructionsMenuPage", "read"],
    pathname: "/instructions",
    icon: InteractionOutlined,
    groups: ["teuai", "customerService", "salesPerson"],
  },
  {
    name: "finance",
    requiredPermission: ["financeMenuPage", "read"],
    pathname: "/finance",
    icon: DollarOutlined,
    groups: [
      "teuai",
      "finance",
      "customerService",
      "salesPerson.admin",
      "operative",
    ],
    suffix: <OperationsRejectedByUserAmount />,
  },
  {
    name: "cargos",
    icon: ProfileOutlined,
    groups: [
      "customerService",
      "salesPerson",
      "public",
      "teuai",
      OPERATIVE,
      "companyAdmin",
    ],
    children: [
      {
        name: "cargos",
        requiredPermission: ["cargosMenuPage", "read"],
        pathname: "/cargos?type=shipments&order=descend&field=updatedAt",
        groups: [
          "teuai",
          "customerService",
          "company",
          "salesPerson",
          "operative",
          "companyAdmin",
        ],
      },
      {
        name: "maritimeOperationsCargos",
        requiredPermission: ["maritimeOperationsCargosMenuPage", "read"],
        pathname: "/maritimeOperationsCargos",
        groups: ["teuai", "customerService", OPERATIVE, "salesPerson.admin"],
      },
      {
        name: "aerialOperationsCargos",
        requiredPermission: ["aerialOperationsCargosMenuPage", "read"],
        pathname: "/aerialOperationsCargos",
        groups: ["teuai", "customerService", OPERATIVE, "salesPerson.admin"],
      },
      {
        name: "sidemar",
        requiredPermission: ["sidemarMenuPage", "read"],
        pathname: "/sidemar",
      },
      {
        name: "consolidations",
        requiredPermission: ["consolidationsMenuPage", "read"],
        pathname: "/consolidations",
        groups: ["teuai", OPERATIVE, "salesPerson.admin"],
      },
      {
        name: "createPurchaseOrders",
        requiredPermission: ["createPurchaseOrdersMenuPage", "read"],
        pathname: "/createPurchaseOrders?step=0",
        groups: ["teuai", "customerService"],
      },
      {
        name: "createShipment",
        requiredPermission: ["createShipmentMenuPage", "read"],
        pathname: "/createShipment",
        groups: ["teuai", "customerService"],
      },
      {
        name: "updateMultipleShipments",
        requiredPermission: ["updateMultipleShipmentsMenuPage", "read"],
        pathname: "/updateMultipleShipments?step=0",
        groups: ["teuai", "customerService"],
      },
      {
        name: "consolidateShipments",
        requiredPermission: ["consolidateShipmentsMenuPage", "read"],
        pathname: "/consolidateShipments",
        groups: ["teuai", "customerService"],
      },
    ],
  },
  {
    name: "updateProducts",
    icon: EditOutlined,
    groups: ["teuai", "customerService"],
    children: [
      {
        name: "updatePurchaseOrdersProducts",
        requiredPermission: ["updatePurchaseOrdersProductsMenuPage", "read"],
        pathname: "/updatePurchaseOrdersProducts",
        groups: ["teuai", "customerService"],
      },
      {
        name: "updateShipmentsProducts",
        requiredPermission: ["updateShipmentsProductsMenuPage", "read"],
        pathname: "/updateShipmentsProducts",
        groups: ["teuai", "customerService"],
      },
    ],
  },
  {
    name: "shippers",
    icon: ShopOutlined,
    groups: [
      "teuai",
      "customerService",
      "company",
      "public",
      "salesPerson",
      OPERATIVE,
    ],
    children: [
      {
        name: "shippers",
        requiredPermission: ["shippersMenuPage", "read"],
        pathname: "/shippers",
        groups: [
          "teuai",
          "customerService",
          "company",
          "salesPerson",
          OPERATIVE,
        ],
      },
      {
        name: "uploadShippers",
        requiredPermission: ["uploadShippersMenuPage", "read"],
        pathname: "/uploadShippers",
        groups: ["teuai", "customerService"],
      },
    ],
  },
  {
    name: "demurrage",
    requiredPermission: ["demurrageMenuPage", "read"],
    pathname: "/demurrage",
    icon: GatewayOutlined,
    groups: [
      "teuai",
      "customerService",
      "company",
      "salesPerson",
      "operative.admin",
    ],
  },
  {
    name: "reports",
    icon: SolutionOutlined,
    groups: [
      "teuai",
      "customerService",
      "company",
      "public",
      "operative.admin",
    ],
    children: [
      {
        name: "customReports",
        requiredPermission: ["customReportsMenuPage", "read"],
        pathname: "/customReports",
        groups: [
          "teuai",
          "customerService",
          "company",
          "salesPerson",
          "operative.admin",
        ],
      },
      {
        name: "otherReports",
        requiredPermission: ["otherReportsMenuPage", "read"],
        pathname: "/otherReports",
        groups: ["teuai", "customerService", "company", "salesPerson"],
      },

      {
        name: "lateDemurrage",
        requiredPermission: ["lateDemurrageMenuPage", "read"],
        pathname: "/lateDemurrage",
        groups: ["teuai"],
      },
      ...REPORTS.map(({ name, permissions: groups, requiredPermission }) => ({
        name,
        groups,
        requiredPermission,
        pathname: `/reports/${name}`,
      })),
    ],
  },
  {
    name: "statistics",
    requiredPermission: ["statisticsMenuPage", "read"],
    pathname: "/statistics",
    icon: BarChartOutlined,
    groups: ["teuai", "customerService", "company", "public", "salesPerson"],
  },
  {
    name: "company",
    requiredPermission: ["companyMenuPage", "read"],
    pathname: "/company",
    icon: ContactsOutlined,
    groups: ["company"],
  },
  {
    name: "companies",
    requiredPermission: ["companiesMenuPage", "read"],
    pathname: "/companies",
    icon: ContactsOutlined,
    groups: ["teuai", "customerService", "salesPerson", "finance", "operative"],
  },
  {
    name: "calendar",
    requiredPermission: ["calendarMenuPage", "read"],
    pathname: "/",
    icon: CalendarOutlined,
    groups: ["teuai", "customerService", "company", "public"],
    suffix: (
      <Tag color="geekblue" className={calendarStyles.tag}>
        <FormattedMessage id="soon" defaultMessage="Soon" />
      </Tag>
    ),
  },
  {
    name: "alerts",
    requiredPermission: ["alertsMenuPage", "read"],
    pathname: "/alerts",
    icon: ExclamationCircleOutlined,
    groups: ["teuai", "customerService", OPERATIVE, "salesPerson"],
  },
  {
    name: "settings",
    requiredPermission: ["settingsMenuPage", "read"],
    pathname: "/settings",
    icon: ToolOutlined,
    groups: ["teuai", "company", "customerService", "salesPerson", OPERATIVE],
  },
  {
    name: "admin",
    requiredPermission: ["adminMenuPage", "read"],
    pathname: "/admin",
    icon: UserOutlined,
    groups: [
      "teuai",
      "customerService.admin",
      "operative.admin",
      "customsAgency",
    ],
  },
  {
    name: "kpi",
    requiredPermission: ["kpiMenuPage", "read"],
    pathname: "/Kpi",
    icon: BarChartOutlined,
    groups: ["teuai", "customerService.admin", "salesPerson.admin"],
  },
  {
    name: "agents",
    requiredPermission: ["agentsMenuPage", "read"],
    pathname: "/agents",
    icon: ContactsOutlined,
    groups: [
      "teuai",
      "finance",
      "customerService",
      "salesPerson",
      "company",
      OPERATIVE,
    ],
  },
  {
    name: "customsAgencies",
    requiredPermission: ["customsAgenciesMenuPage", "read"],
    pathname: "/customsAgencies",
    icon: ContainerOutlined,
    groups: ["teuai", "customerService", "salesPerson", OPERATIVE],
  },
  {
    name: "roles",
    requiredPermission: ["rolesMenuPage", "read"],
    pathname: "/roles",
    icon: ApartmentOutlined,
    groups: ["teuai"],
  },
  {
    name: "changelogs",
    requiredPermission: ["changelogsMenuPage", "read"],
    pathname: "/changelogs",
    icon: KlogRelease,
  },
];

export const redirectOnUnauthorized = (user, allowedGroups) => {
  const isAllowedToRoute =
    !allowedGroups.length || (user && allowedGroups.includes(user.group));
  if (!isAllowedToRoute) {
    if (isNode) {
      throw new Error("unauthorized");
    } else {
      Router.push("/");
    }
  }
};

export default routes;
