import auth0 from "auth0-js";
import Auth0Lock from "auth0-lock";
import axios from "axios";
import isNode from "detect-node";
import { EventEmitter } from "events";
import Cookie from "js-cookie";
import jwt from "jsonwebtoken";
import Router from "next/router";
import * as Sentry from "@sentry/nextjs";

import { initializeEnforcer } from "./authorization";

import { auth0PublicKeys } from "@/public/static/authPublicKeys";

const JWK = auth0PublicKeys[process.env.NEXT_PUBLIC_AUTH0_DOMAIN];

type EmitterAuth0LockStatic = Auth0LockStatic & {
  removeAllListeners: EventEmitter["removeAllListeners"];
};

/* eslint-disable no-param-reassign */
const appendAttributesToUser = (user) => {
  if (user) {
    user.group = user[`${process.env.NEXT_PUBLIC_APP_URL}/group`];
    user.accounts = user[`${process.env.NEXT_PUBLIC_APP_URL}/accounts`];
    user.role = user[`${process.env.NEXT_PUBLIC_APP_URL}/role`];
    user.inactiveCompany =
      user[`${process.env.NEXT_PUBLIC_APP_URL}/inactiveCompany`];
    user.userMetadata = user[`${process.env.NEXT_PUBLIC_APP_URL}/userData`];
    user.sendbirdAccessToken =
      user[`${process.env.NEXT_PUBLIC_APP_URL}/sendbirdAccessToken`];
    // eslint-disable-next-line prefer-destructuring
    user.auth0Id = user.sub.split("|")[1];
    user.offices = user[`${process.env.NEXT_PUBLIC_APP_URL}/offices`];
  }
};
/* eslint-enable no-param-reassign */
class Auth {
  public auth0 = new auth0.WebAuth({
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENTID,
  });

  public auth0Lock = isNode
    ? null
    : (new Auth0Lock(
        process.env.NEXT_PUBLIC_AUTH0_CLIENTID,
        process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
        {
          language: "es",
          configurationBaseUrl: "https://cdn.auth0.com",
          autoclose: true,
          allowAutocomplete: true,
          allowShowPassword: true,
          rememberLastLogin: true,
          allowedConnections: ["Username-Password-Authentication"],
          loginAfterSignUp: true,
          auth: {
            redirect: false,
            sso: false,
            redirectUrl: process.env.NEXT_PUBLIC_APP_URL,
            responseType: "id_token token",
            audience: process.env.NEXT_PUBLIC_AUTH0_MANAGEMENT_API,
            params: {
              scope: "openid profile email update:current_user_metadata",
            },
          },
          theme: {
            logo: "/static/images/logos/logo-klog.svg",
            primaryColor: "#0062ff",
          },
          additionalSignUpFields: [
            {
              name: "companyName",
              placeholder: "Nombre compañía",
            },
          ],
        }
      ) as EmitterAuth0LockStatic);

  public handleAuth = (
    setUser,
    redirectTo = "/panel",
    isFromQuoting = false
  ) => {
    this.auth0.parseHash((err, result) => {
      if (err) {
        return Router.push("/");
      }
      this.verifyToken(result.idToken).then((valid) => {
        if (valid) {
          this.saveToken(result.idToken, result.accessToken);
        }
        this.auth0.client.userInfo(result.accessToken, async (_, user) => {
          appendAttributesToUser(user);
          setUser(user);
          // set Sentry's user context
          Sentry.setUser(user);
          if (isFromQuoting) {
            const {
              data: { policies },
            } = await axios.post(
              process.env.NEXT_PUBLIC_ENFORCER_SUBJECTS_URI,
              {
                token: result.idToken,
                force: true,
              }
            );
            await initializeEnforcer(policies);
          }
          await Router.push(redirectTo);
        });
      });
    });
  };

  public standardLogin = ({ email, password, route = "" }) => {
    const redirectUri = new URL(
      `${process.env.NEXT_PUBLIC_APP_URL}/redirect?type=quoting&redirectRoute=${route}`
    );
    const options = {
      redirectUri: redirectUri.href,
      audience: process.env.NEXT_PUBLIC_AUTH0_MANAGEMENT_API,
      responseType: "id_token token",
      scope: "openid profile email update:current_user_metadata",
      realm: "Username-Password-Authentication",
    };
    return this.auth0.login(
      {
        email,
        password,
        ...options,
      },
      (err) => {
        console.error(err);
      }
    );
  };

  public handleLockAuth = async (idToken, accessToken, cb?) => {
    const valid = await this.verifyToken(idToken);
    if (valid) {
      this.saveToken(idToken, accessToken);
      this.auth0Lock.getUserInfo(accessToken, (_, user) => {
        appendAttributesToUser(user);
        cb && cb(user);
      });
    }
  };

  public login = () => {
    const options = {
      audience: process.env.NEXT_PUBLIC_AUTH0_MANAGEMENT_API,
      responseType: "id_token token",
      redirectUri: `${process.env.NEXT_PUBLIC_APP_URL}/redirect`,
      scope: "openid profile email update:current_user_metadata",
    };

    return this.auth0.authorize(options);
  };

  public logout = () => {
    this.auth0.logout({ returnTo: process.env.NEXT_PUBLIC_APP_URL });
    this.deleteToken();
  };

  public lockLogout = async (setUser, client?) => {
    this.auth0.logout({
      returnTo: process.env.NEXT_PUBLIC_LANDING_URL,
    });
    this.deleteToken();
    client.clearStore();
    await Router.push("/");
    setUser(undefined);
    // remove Sentry's user scope
    Sentry.configureScope((scope) => {
      scope.setUser(undefined);
    });
  };

  public getToken = async (ctx?, fromContext?: boolean) => {
    const user = isNode
      ? await this.getTokenForServer(ctx, fromContext)
      : await this.getTokenForBrowser(fromContext);
    if (fromContext) {
      return user;
    }
    appendAttributesToUser(user);
    return user;
  };

  public updateMetadata = async (userMetadata, setUser?) => {
    const { sub } = await this.getToken();
    const accessToken = Cookie.get("accessToken");
    const body = {
      user_metadata: userMetadata,
    };
    await fetch(
      `${
        process.env.NEXT_PUBLIC_AUTH0_MANAGEMENT_API_CUSTOM_DOMAIN ||
        process.env.NEXT_PUBLIC_AUTH0_MANAGEMENT_API
      }users/${sub}`,
      {
        method: "PATCH",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    const checkSession = () =>
      new Promise<void>((resolve) =>
        this.auth0Lock.checkSession(
          {},
          async (_, { idToken, accessToken: newAccessToken }) => {
            await this.handleLockAuth(idToken, newAccessToken, setUser);
            resolve();
          }
        )
      );
    await checkSession();
  };

  private saveToken = (idToken, accessToken) => {
    Cookie.set("user", JSON.stringify(jwt.decode(idToken)));
    Cookie.set("accessToken", accessToken);
    Cookie.set("idToken", idToken);
  };

  private deleteToken = () => {
    Cookie.remove("user");
    Cookie.remove("accessToken");
    Cookie.remove("idToken");
  };

  private verifyToken = async (token) => {
    if (token) {
      const decodedToken = jwt.decode(token, { complete: true });
      let cert = JWK.keys[0].x5c[0];
      cert = cert.match(/.{1,64}/g).join("\n");
      cert = `-----BEGIN CERTIFICATE-----\n${cert}\n-----END CERTIFICATE-----\n`;
      if (JWK.keys[0].kid === decodedToken.header.kid) {
        try {
          jwt.verify(token, cert);
          return true;
        } catch (error) {
          this.deleteToken();
          console.error(error);
        }
      }
    }
  };

  private getTokenForBrowser = async (fromContext) => {
    const token = Cookie.get("idToken");
    const validToken = await this.verifyToken(token);
    if (validToken) {
      return fromContext
        ? Cookie.get("idToken")
        : JSON.parse(Cookie.get("user"));
    }
  };

  private getTokenForServer = async (ctx, fromContext) => {
    if (!ctx || !ctx.req || !ctx.req.headers) return;
    const { cookie } = ctx.req.headers;
    const jwtFromCookie =
      cookie && cookie.split(";").find((c) => c.trim().startsWith("idToken="));

    if (!jwtFromCookie) {
      return;
    }
    const token = jwtFromCookie.split("=")[1];

    const validToken = await this.verifyToken(token);

    if (validToken) {
      return fromContext ? token : jwt.decode(token);
    }
  };
}

export default new Auth();
