import React from "react";
import { FormattedMessage } from "react-intl";

import LoginLockButton from "../../Login/LoginLockButton";

import styles from "./ContainerOverMenu.module.css";

type Props = {};

const ContainerOverMenu: React.FunctionComponent<Props> = () => {
  return (
    <div className={styles.divStyle}>
      <div className={styles.textContainer}>
        <LoginLockButton
          buttonType="link"
          buttonText="signInSignUp"
          buttonSize="small"
          showedInLanding
        />
        <FormattedMessage id="loginToViewAll" />
      </div>
    </div>
  );
};

export default ContainerOverMenu;
