import { Select, Tooltip } from "antd";
import Cookie from "js-cookie";
import React, { FunctionComponent, useEffect } from "react";
import { useIntl } from "react-intl";

import { useRouter } from "next/router";
import { useAuth } from "@/lib/AuthContext";
import { COMPANY_COOKIE, OFFICE_CODE_COOKIE } from "./constants";
import { useOffice } from "./OfficeContext";

type Props = {};

export const conditionalRedirect = ({ router: { route, push, reload } }) => {
  switch (route) {
    case "/shipments/[id]":
      push("/cargos?type=shipments&order=descend&field=updatedAt");
      break;
    case "/purchaseOrders/[id]":
      push("/cargos?type=purchaseOrders&order=descend&field=updatedAt");
      break;
    case "/instructions/[id]":
      push("/instructions");
      break;
    case "/companies/[id]":
      push("/companies");
      break;
    default:
      reload();
      break;
  }
};

const OfficeSelector: FunctionComponent<Props> = () => {
  const intl = useIntl();
  const { officeCode, setOfficeCode } = useOffice();
  const { user: { offices = ["CL", "PE", "MX", "BO"] } = {} } = useAuth();
  const router = useRouter();

  const officesOptions = offices.map((office) => ({
    label: intl.formatMessage({ id: office }),
    value: office,
  }));

  const setCookiesInClient = (newOfficeId) => {
    const currentOfficeId = Cookie.get(OFFICE_CODE_COOKIE);
    if (!newOfficeId) {
      Cookie.remove(OFFICE_CODE_COOKIE);
    } else if (newOfficeId !== currentOfficeId) {
      Cookie.set(OFFICE_CODE_COOKIE, newOfficeId);
    }
  };

  const onChange = (code) => {
    setOfficeCode(code);
    conditionalRedirect({ router });
  };

  useEffect(() => {
    setCookiesInClient(officeCode);
    Cookie.remove(COMPANY_COOKIE);
  }, [officeCode]);
  useEffect(() => {
    if (offices.length === 1) {
      setCookiesInClient(offices[0]);
      setOfficeCode(offices[0]);
    }
  }, [offices]);

  if (offices.length < 2) return null;
  return (
    <Tooltip title={intl.formatMessage({ id: "office" })} placement="leftTop">
      <Select
        defaultValue={officeCode}
        style={{ width: 144 }}
        options={officesOptions}
        onChange={onChange}
      />
    </Tooltip>
  );
};

export default OfficeSelector;
