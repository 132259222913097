import Search, { SearchProps } from "antd/lib/input/Search";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useAllowWith } from "@/lib/authorization";

const PATH_NAME = "/searcher";

export const SearchBar: React.FunctionComponent<SearchProps> = (props) => {
  const [activeSpinner, setActiveSpinner] = useState(false);
  const intl = useIntl();
  const router = useRouter();

  let searchValue;
  if (router) {
    ({
      query: { s: searchValue },
    } = router);
  }

  const handleSearch = async (value) => {
    setActiveSpinner(true);
    await router.push({
      pathname: PATH_NAME,
      query: { s: value },
    });
    setActiveSpinner(false);
  };
  const canSearch = useAllowWith(["searchBySkuOrForwarderReference", "read"]);
  return (
    <>
      {canSearch && (
        <Search
          {...props}
          placeholder={intl.formatMessage({
            id: "searcherDescription",
          })}
          loading={activeSpinner}
          defaultValue={searchValue}
          onSearch={handleSearch}
        />
      )}
    </>
  );
};
