import { Popover as AntdPopover } from "antd";
import { PopoverProps as AntdPopoverProps } from "antd/lib/popover";
import classnames from "classnames/bind";
import Image from "next/image";
import React, { AnchorHTMLAttributes, ReactNode } from "react";

import styles from "./Popover.module.css";

const cx = classnames.bind(styles);

export type Props = {
  src?: string;
  link?: string;
  linkProps?: AnchorHTMLAttributes<HTMLElement>;
  linkTitle?: ReactNode | string;
};

export const Popover: React.FunctionComponent<Props & AntdPopoverProps> = (
  props
) => {
  const { content, overlayClassName, src, link, linkProps, linkTitle } = props;
  return (
    <AntdPopover
      {...props}
      overlayClassName={`${overlayClassName} ${styles.overlay}`}
      content={() => (
        <>
          {src && (
            <div className={cx({ imageWrapper: true, tall: !!link })}>
              <Image src={src} layout="fill" objectFit="contain" />
            </div>
          )}
          {content}
          {link && (
            <div className={styles.linkWrapper}>
              <a href={link} {...linkProps}>
                {linkTitle || link}
              </a>
            </div>
          )}
        </>
      )}
    />
  );
};
