import {
  ADMIN,
  CUSTOMER_SERVICE,
  FINANCE,
  OPERATIVE,
  SALESPERSON,
  TEUAI,
  USER,
} from "@/lib/constants";

const PROSPECTION_OCEAN = "prospectionOcean";
const PROSPECTION_AIR = "prospectionAir";
const ACCOUNT_MANAGEMENT_OCEAN = "accountManagementOcean";
const ACCOUNT_MANAGEMENT_AIR = "accountManagementAir";
const GENERAL_PROFIT = "generalProfit"; // janan's dashboard
const CUSTOMER_SERVICE_OPERATIONS = "customerServiceOperations";
const SALES_TEAM = "salesReport";
const CUSTOMER_SERVICE_ADMIN = "customerServiceAdmin";
const OPERATIVE_ADMIN = "operativeAdminReport";
const REJECTED_OPS = "rejectedOps";
const PROSPECTION_PE = "prospectionPeru";
const BI_COMMERCIAL = "biCommercial";
const ADMIN_BI_COMERCIAL = "adminBiCommercial";
const NEWSLETTER = "newsletter";
const MTS_REPORT = "mtsReport";

const MBL_REPORT = "mblReport";

export const REPORTS = [
  {
    name: GENERAL_PROFIT,
    permissions: [`${TEUAI}.${ADMIN}`],
    requiredPermission: [`${GENERAL_PROFIT}EmbeddedMenuPage`, "read"],
  },
  {
    name: SALES_TEAM,
    permissions: [`${TEUAI}.${ADMIN}`, `${SALESPERSON}.${ADMIN}`],
    requiredPermission: [`${SALES_TEAM}EmbeddedMenuPage`, "read"],
  },
  {
    name: CUSTOMER_SERVICE_OPERATIONS,
    permissions: [`${TEUAI}.${ADMIN}`, `${CUSTOMER_SERVICE}.${ADMIN}`],
    requiredPermission: [
      `${CUSTOMER_SERVICE_OPERATIONS}EmbeddedMenuPage`,
      "read",
    ],
  },
  {
    name: CUSTOMER_SERVICE_ADMIN,
    permissions: [`${TEUAI}.${ADMIN}`, `${CUSTOMER_SERVICE}.${ADMIN}`],
    requiredPermission: [`${CUSTOMER_SERVICE_ADMIN}EmbeddedMenuPage`, "read"],
  },
  {
    name: OPERATIVE_ADMIN,
    permissions: [`${TEUAI}.${ADMIN}`, `${OPERATIVE}.${ADMIN}`],
    requiredPermission: [`${OPERATIVE_ADMIN}EmbeddedMenuPage`, "read"],
  },
  {
    name: REJECTED_OPS,
    permissions: [
      `${TEUAI}.${ADMIN}`,
      `${OPERATIVE}.${ADMIN}`,
      `${CUSTOMER_SERVICE}.${ADMIN}`,
    ],
    requiredPermission: [`${REJECTED_OPS}EmbeddedMenuPage`, "read"],
  },
  {
    name: PROSPECTION_PE,
    permissions: [
      `${TEUAI}.${ADMIN}`,
      `${OPERATIVE}.${ADMIN}`,
      `${CUSTOMER_SERVICE}.${ADMIN}`,
    ],
    requiredPermission: [`${PROSPECTION_PE}EmbeddedMenuPage`, "read"],
  },
  {
    name: MBL_REPORT,
    permissions: [
      `${TEUAI}.${ADMIN}`,
      `${SALESPERSON}.${ADMIN}`,
      `${CUSTOMER_SERVICE}.${ADMIN}`,
    ],
    requiredPermission: [`${MBL_REPORT}EmbeddedMenuPage`, "read"],
  },
  {
    name: ADMIN_BI_COMERCIAL,
    permissions: [
      `${TEUAI}.${ADMIN}`,
      `${SALESPERSON}.${ADMIN}`,
      `${CUSTOMER_SERVICE}.${ADMIN}`,
      `${OPERATIVE}.${ADMIN}`,
      `${FINANCE}.${ADMIN}`,
    ],
    requiredPermission: [`${ADMIN_BI_COMERCIAL}EmbeddedMenuPage`, "read"],
  },
  {
    name: BI_COMMERCIAL,
    requiredPermission: [`${BI_COMMERCIAL}EmbeddedMenuPage`, "read"],
  },
  {
    name: NEWSLETTER,
    requiredPermission: [`${NEWSLETTER}EmbeddedMenuPage`, "read"],
  },
  {
    name: MTS_REPORT,
    requiredPermission: [`${MTS_REPORT}EmbeddedMenuPage`, "read"],
  },
];
