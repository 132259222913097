export const tooltipStyle = {
  stroke: "#e9e9e9",
  fill: "white",
};

const colors = [
  "#0062FF",
  "#B12FC2",
  "#00AFFF",
  "#F5A623",
  "#00C53B",
  "#F5C923",
  "#F85C1C",
];
const charcoal = "#252525";
const grey = "#969696";

// Typography
const sansSerif = "'Open Sans', sans-serif";
const letterSpacing = "normal";
const fontSize = 12;

// Layout
const baseProps = {
  width: 450,
  height: 300,
  padding: 50,
  colorScale: colors,
};

// Labels
const baseLabelStyles = {
  fontSize,
  letterSpacing,
  fontFamily: sansSerif,
  padding: 5,
  fill: charcoal,
  stroke: "transparent",
};
const centeredLabelStyles = Object.assign(
  { textAnchor: "middle" },
  baseLabelStyles
);

// Strokes
const strokeLinecap = "round";
const strokeLinejoin = "round";

// Put it all together...
export const theme = {
  area: Object.assign(
    {
      style: {
        data: {
          fill: charcoal,
        },
        labels: centeredLabelStyles,
      },
    },
    baseProps
  ),
  independentAxis: Object.assign(
    {
      style: {
        axis: {
          strokeLinecap,
          strokeLinejoin,
          fill: "transparent",
          stroke: "#bfbfbf",
          strokeWidth: 1,
        },
        axisLabel: Object.assign({}, centeredLabelStyles, {
          padding: 30,
          fill: "#8c8c8c",
          fontSize: 9,
        }),
        grid: {
          fill: "none",
          stroke: "none",
          pointerEvents: "painted",
        },
        ticks: {
          fill: "#bfbfbf",
          stroke: "#bfbfbf",
          size: 3,
        },
        tickLabels: {
          letterSpacing,
          fontSize: 10,
          fontFamily: sansSerif,
          padding: 5,
          fill: charcoal,
          stroke: "transparent",
        },
      },
    },
    baseProps
  ),
  dependentAxis: Object.assign(
    {
      style: {
        axis: {
          strokeLinecap,
          strokeLinejoin,
          fill: "transparent",
          stroke: "white",
          strokeWidth: 1,
        },
        axisLabel: Object.assign({}, centeredLabelStyles, {
          padding: 30,
          fill: "#8c8c8c",
          fontSize: 9,
        }),
        grid: {
          fill: "#e9e9e9",
          stroke: "#e9e9e9",
          pointerEvents: "painted",
          strokeDasharray: "10,10",
        },
        ticks: {
          fill: "#bfbfbf",
          stroke: "#bfbfbf",
          size: 3,
        },
        tickLabels: {
          letterSpacing,
          fontSize: 10,
          fontFamily: sansSerif,
          padding: 5,
          fill: charcoal,
          stroke: "transparent",
        },
      },
    },
    baseProps
  ),

  bar: Object.assign(
    {
      style: {
        data: {
          fill: charcoal,
          padding: 8,
          strokeWidth: 0,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps
  ),
  boxplot: Object.assign(
    {
      style: {
        max: {
          padding: 8,
          stroke: charcoal,
          strokeWidth: 1,
        },
        maxLabels: baseLabelStyles,
        median: {
          padding: 8,
          stroke: charcoal,
          strokeWidth: 1,
        },
        medianLabels: baseLabelStyles,
        min: {
          padding: 8,
          stroke: charcoal,
          strokeWidth: 1,
        },
        minLabels: baseLabelStyles,
        q1: {
          padding: 8,
          fill: grey,
        },
        q1Labels: baseLabelStyles,
        q3: {
          padding: 8,
          fill: grey,
        },
        q3Labels: baseLabelStyles,
      },
      boxWidth: 20,
    },
    baseProps
  ),
  candlestick: Object.assign(
    {
      style: {
        data: {
          stroke: charcoal,
          strokeWidth: 1,
        },
        labels: centeredLabelStyles,
      },
      candleColors: {
        positive: "#ffffff",
        negative: charcoal,
      },
    },
    baseProps
  ),
  chart: baseProps,
  errorbar: Object.assign(
    {
      borderWidth: 8,
      style: {
        data: {
          fill: "transparent",
          stroke: charcoal,
          strokeWidth: 2,
        },
        labels: centeredLabelStyles,
      },
    },
    baseProps
  ),
  group: Object.assign(
    {
      colorScale: colors,
    },
    baseProps
  ),
  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: "vertical",
    titleOrientation: "top",
    style: {
      data: {
        type: "minus",
        size: 3,
      },
      labels: {
        letterSpacing,
        fontSize: 10,
        fontFamily: sansSerif,
        padding: 5,
        fill: "#8c8c8c",
        stroke: "transparent",
      },
      title: Object.assign({}, baseLabelStyles, { padding: 5 }),
    },
  },
  line: Object.assign(
    {
      style: {
        data: {
          fill: "transparent",
          stroke: charcoal,
          strokeWidth: 2,
        },
        labels: centeredLabelStyles,
      },
    },
    baseProps
  ),
  pie: {
    style: {
      data: {
        padding: 10,
        stroke: "transparent",
        strokeWidth: 1,
      },
      labels: Object.assign(
        {},
        {
          letterSpacing,
          fontSize: 12,
          fontFamily: sansSerif,
          padding: 5,
          fill: charcoal,
          stroke: "transparent",
        },
        { padding: 20 }
      ),
    },
    colorScale: colors,
    width: 400,
    height: 400,
    padding: 50,
  },
  scatter: Object.assign(
    {
      style: {
        data: {
          fill: charcoal,
          stroke: "transparent",
          width: 10,
          height: 10,
          strokeWidth: 0,
        },
        labels: centeredLabelStyles,
      },
    },
    baseProps
  ),
  stack: Object.assign(
    {
      colorScale: colors,
    },
    baseProps
  ),
  tooltip: {
    style: Object.assign({}, centeredLabelStyles, {
      padding: 5,
      pointerEvents: "none",
    }),
    flyoutStyle: {
      stroke: charcoal,
      strokeWidth: 1,
      fill: "#f0f0f0",
      pointerEvents: "none",
    },
    cornerRadius: 5,
    pointerLength: 10,
  },
  voronoi: Object.assign(
    {
      style: {
        data: {
          fill: "transparent",
          stroke: "transparent",
          strokeWidth: 0,
        },
        labels: Object.assign({}, centeredLabelStyles, {
          padding: 5,
          pointerEvents: "none",
        }),
        flyout: {
          stroke: charcoal,
          strokeWidth: 1,
          fill: "#f0f0f0",
          pointerEvents: "none",
        },
      },
    },
    baseProps
  ),
};

export const dashboardTheme = {
  area: Object.assign(
    {
      style: {
        data: {
          fill: charcoal,
        },
        labels: centeredLabelStyles,
      },
    },
    baseProps
  ),
  independentAxis: Object.assign(
    {
      style: {
        axis: {
          strokeLinecap,
          strokeLinejoin,
          fill: "transparent",
          stroke: "#0062ff",
          strokeWidth: 1,
        },
        axisLabel: Object.assign({}, centeredLabelStyles, {
          padding: 30,
          fill: "#8c8c8c",
          fontSize: 9,
        }),
        grid: {
          fill: "none",
          stroke: "none",
          pointerEvents: "painted",
        },
        ticks: {
          fill: "#bfbfbf",
          stroke: "#bfbfbf",
          size: 3,
        },
        tickLabels: {
          letterSpacing,
          fontSize: 10,
          fontFamily: sansSerif,
          padding: 5,
          fill: charcoal,
          stroke: "transparent",
        },
      },
    },
    baseProps
  ),
  dependentAxis: Object.assign(
    {
      style: {
        axis: {
          strokeLinecap,
          strokeLinejoin,
          fill: "transparent",
          stroke: "none",
          strokeWidth: 1,
        },
        axisLabel: Object.assign({}, centeredLabelStyles, {
          padding: 30,
          fill: "#8c8c8c",
          fontSize: 9,
        }),
        grid: {
          fill: "#e9e9e9",
          stroke: "#D2E4FF",
          pointerEvents: "painted",
          strokeDasharray: "10,10",
        },
        ticks: {
          fill: "#bfbfbf",
          stroke: "#bfbfbf",
          size: 3,
        },
        tickLabels: {
          letterSpacing,
          fontSize: 10,
          fontFamily: sansSerif,
          padding: 5,
          fill: charcoal,
          stroke: "transparent",
        },
      },
    },
    baseProps
  ),

  bar: Object.assign(
    {
      style: {
        data: {
          fill: charcoal,
          padding: 8,
          strokeWidth: 0,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps
  ),
  boxplot: Object.assign(
    {
      style: {
        max: {
          padding: 8,
          stroke: charcoal,
          strokeWidth: 1,
        },
        maxLabels: baseLabelStyles,
        median: {
          padding: 8,
          stroke: charcoal,
          strokeWidth: 1,
        },
        medianLabels: baseLabelStyles,
        min: {
          padding: 8,
          stroke: charcoal,
          strokeWidth: 1,
        },
        minLabels: baseLabelStyles,
        q1: {
          padding: 8,
          fill: grey,
        },
        q1Labels: baseLabelStyles,
        q3: {
          padding: 8,
          fill: grey,
        },
        q3Labels: baseLabelStyles,
      },
      boxWidth: 20,
    },
    baseProps
  ),
  candlestick: Object.assign(
    {
      style: {
        data: {
          stroke: charcoal,
          strokeWidth: 1,
        },
        labels: centeredLabelStyles,
      },
      candleColors: {
        positive: "#ffffff",
        negative: charcoal,
      },
    },
    baseProps
  ),
  chart: baseProps,
  errorbar: Object.assign(
    {
      borderWidth: 8,
      style: {
        data: {
          fill: "transparent",
          stroke: charcoal,
          strokeWidth: 2,
        },
        labels: centeredLabelStyles,
      },
    },
    baseProps
  ),
  group: Object.assign(
    {
      colorScale: colors,
    },
    baseProps
  ),
  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: "vertical",
    titleOrientation: "top",
    style: {
      data: {
        type: "minus",
        size: 3,
      },
      labels: {
        letterSpacing,
        fontSize: 10,
        fontFamily: sansSerif,
        padding: 5,
        fill: "#8c8c8c",
        stroke: "transparent",
      },
      title: Object.assign({}, baseLabelStyles, { padding: 5 }),
    },
  },
  line: Object.assign(
    {
      style: {
        data: {
          fill: "transparent",
          stroke: charcoal,
          strokeWidth: 2,
        },
        labels: centeredLabelStyles,
      },
    },
    baseProps
  ),
  pie: {
    style: {
      data: {
        padding: 10,
        stroke: "transparent",
        strokeWidth: 1,
      },
      labels: Object.assign(
        {},
        {
          letterSpacing,
          fontSize: 12,
          fontFamily: sansSerif,
          padding: 5,
          fill: charcoal,
          stroke: "transparent",
        },
        { padding: 20 }
      ),
    },
    colorScale: colors,
    width: 400,
    height: 400,
    padding: 50,
  },
  scatter: Object.assign(
    {
      style: {
        data: {
          fill: charcoal,
          stroke: "transparent",
          width: 10,
          height: 10,
          strokeWidth: 0,
        },
        labels: centeredLabelStyles,
      },
    },
    baseProps
  ),
  stack: Object.assign(
    {
      colorScale: colors,
    },
    baseProps
  ),
  tooltip: {
    style: Object.assign({}, centeredLabelStyles, {
      padding: 5,
      pointerEvents: "none",
    }),
    flyoutStyle: {
      stroke: charcoal,
      strokeWidth: 1,
      fill: "#f0f0f0",
      pointerEvents: "none",
    },
    cornerRadius: 5,
    pointerLength: 10,
  },
  voronoi: Object.assign(
    {
      style: {
        data: {
          fill: "transparent",
          stroke: "transparent",
          strokeWidth: 0,
        },
        labels: Object.assign({}, centeredLabelStyles, {
          padding: 5,
          pointerEvents: "none",
        }),
        flyout: {
          stroke: charcoal,
          strokeWidth: 1,
          fill: "#f0f0f0",
          pointerEvents: "none",
        },
      },
    },
    baseProps
  ),
};
