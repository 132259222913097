import { gql, useMutation } from "@apollo/client";
import { Button, message } from "antd";
import classNames from "classnames/bind";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";

import { useLanguage } from "@/components/Language/LanguageContext";

import { ITaskNotification } from "@/types/taskNotification";

import Icon from "@/public/static/images/icons/icon-alert--gradient.svg";

import styles from "./TaskNotificationItem.module.scss";

const cx = classNames.bind(styles);

const UPDATE_IS_READ_TASK_NOTIFICATION = gql`
  mutation updateIsReadTaskNotification($taskNotificationId: ID!) {
    updateIsReadTaskNotification(taskNotificationId: $taskNotificationId) {
      id
      isRead
    }
  }
`;

type Props = {
  taskNotification: ITaskNotification;
};

export const TaskNotificationItem: React.FunctionComponent<Props> = ({
  taskNotification,
}) => {
  const intl = useIntl();
  const { language } = useLanguage();
  const router = useRouter();

  const [
    updateIsReadTaskNotification,
    { loading: loadingIsReadTaskNotification },
  ] = useMutation(UPDATE_IS_READ_TASK_NOTIFICATION);

  const {
    id,
    createdAt,
    title,
    description,
    isRead,
    tasks = [],
  } = taskNotification;

  const taskIds = tasks.map(({ id: taskId }) => taskId);

  const handleIsReadTaskNotification = async (notificationId) => {
    try {
      router.push({
        pathname: "/tasks",
        query: { taskIds },
      });
      await updateIsReadTaskNotification({
        variables: {
          taskNotificationId: notificationId,
        },
      });
    } catch (error) {
      message.error(
        intl.formatMessage({
          id: "error",
        })
      );
    }
  };
  return (
    <div className={cx({ notification: true, isRead })}>
      <h4>
        <Icon />
        {title || description}
      </h4>
      <div className={styles.info}>
        <span>{moment(createdAt).locale(language).fromNow()}</span>
        <Button
          type="link"
          onClick={() => handleIsReadTaskNotification(id)}
          loading={loadingIsReadTaskNotification}
        >
          {intl.formatMessage({ id: "goToTaskPanel" })}
        </Button>
      </div>
    </div>
  );
};
