import { curry, where, filter, map, pipe } from "ramda";

import { isNotNil } from "src/common/utils";
import { BUY, SALE } from "./constants";

export const findChargeCallback =
  ({ currency: currentCurrency, name: currentName, type: currentType }) =>
  ({
    name,
    currency,
    type,
    // TL ID NECESARIO PARA MIGRACION
  }) => {
    const isAntiType = currentType !== type;
    const sameName = name === currentName;
    const sameCurrency = currency === currentCurrency;
    if (sameName && sameCurrency && isAntiType) return true;
    return false;
  };

export const mergeArrayElements = (buySaleCharges) =>
  buySaleCharges.reduce((accumulator, charge) => {
    const { name, type, [`${type}Currency`]: currency } = charge;
    const foundChargeIndex = accumulator.findIndex(
      findChargeCallback({ name, currency, type })
    );
    if (foundChargeIndex < 0) {
      return [
        ...accumulator,
        {
          ...charge,
          currency,
          buyCondition: "CC",
          saleCondition: "CC",
        },
      ];
    }
    const foundCharge = accumulator[foundChargeIndex];
    accumulator[foundChargeIndex] = Object.assign(foundCharge, {
      ...charge,
      currency,
      buyCondition: "CC",
      saleCondition: "CC",
    });
    return accumulator;
  }, []);

export const hasAmount = where({
  amount: isNotNil,
  fakeAmount: isNotNil,
});

export const chargeFormatterCallback = curry(
  (
    type,
    {
      fakeAmount,
      type: multiplier,
      currency,
      amount,
      minimalAmount,
      fakeMinimalAmount,
      ...charge
    }
  ) => ({
    ...charge,
    type,
    [`${type}FakeMinimalAmount`]: fakeMinimalAmount,
    [`${type}MinimalAmount`]: minimalAmount,
    [`${type}FakeAmount`]: fakeAmount,
    [`${type}Currency`]: currency,
    [`${type}Type`]: multiplier,
    [`${type}Amount`]: amount,
  })
);

export const mapBuySaleCharges = curry((type, charges) =>
  pipe(
    flattenCharges,
    filter(hasAmount),
    map(chargeFormatterCallback(type))
  )(charges)
);

export const mergeInstructionCharges = ({ saleCharges, buyCharges }) =>
  mergeArrayElements([
    ...mapBuySaleCharges(BUY, buyCharges),
    ...mapBuySaleCharges(SALE, saleCharges),
  ]);

export const flattenCharges = (charges) =>
  charges.reduce((accumulator, charge) => {
    const { detailedCharges = [] } = charge;
    if (!detailedCharges.length) {
      return [...accumulator, charge];
    }
    return [...accumulator, ...detailedCharges];
  }, []);

export const conceptHasTax = (conceptName = "") =>
  /^(CMIG|CAIG|CMEG)/.test(conceptName);
