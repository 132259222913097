import { useApolloClient } from "@apollo/client";
import isNode from "detect-node";
import Cookie from "js-cookie";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";

import { useAuth } from "@/lib/AuthContext";
import { useRoles } from "@/lib/authorization";
import { NO_COMPANY, NO_TIER, PATHS_WITH_COMPANY_INFO } from "@/lib/constants";
import { useMixpanel } from "@/lib/MixpanelContext";
import Auth from "@/lib/auth";
import { getCompanies as getUserCompanies } from "@/lib/api";

export interface ICompanyContext {
  companyId?: string;
  setCompanyId?: (companyId: string) => void;
}

const getUserFromCache = (client) => {
  const { getCompanies = {} } =
    client.readQuery({
      query: getUserCompanies,
    }) || {};
  return getCompanies;
};

const CompanyContext = createContext<ICompanyContext>({});

const CompanyProvider = ({ children }) => {
  const { user: { accounts, accounts: [userCompanyId] = [] } = {}, user } =
    useAuth();
  const router = useRouter();
  const { pathname, asPath } = router;
  const { mixpanel } = useMixpanel();
  const { isCompany } = useRoles();
  const client = useApolloClient();

  const firstClientCompany = getUserFromCache(client)[0];
  const isSingleCompany = isCompany && accounts && accounts.length === 1;
  const [companyId, setCompanyId] = useState(undefined);

  const COMPANY_COOKIE = "selectedCompany";
  const isPathWithCompany = PATHS_WITH_COMPANY_INFO.includes(pathname);

  useEffect(() => {
    if (!isNode) {
      Auth.auth0Lock.on("show", () => {
        Cookie.remove(COMPANY_COOKIE);
        setCompanyId(undefined);
      });
    }
  }, []);

  useEffect(() => {
    if (isNode || isSingleCompany) return;
    const selectedCompanyId = Cookie.get(COMPANY_COOKIE);
    setCompanyId(selectedCompanyId);
  }, [companyId]);
  useEffect(() => {
    if (!isNode && !isPathWithCompany && firstClientCompany?.id) {
      mixpanel.register({ companyId: firstClientCompany?.id || NO_COMPANY });
      mixpanel.register({
        companyName: firstClientCompany.name || NO_COMPANY,
      });
      mixpanel.register({ companyTier: firstClientCompany.tier || NO_TIER });
    }
  }, [asPath, firstClientCompany]);

  useEffect(() => {
    isSingleCompany && setCompanyId(userCompanyId);
  }, [user]);

  return (
    <CompanyContext.Provider value={{ companyId, setCompanyId }}>
      {children}
    </CompanyContext.Provider>
  );
};

const CompanyConsumer = CompanyContext.Consumer;
const useCompany = () => useContext(CompanyContext);

export { CompanyContext, CompanyConsumer, CompanyProvider, useCompany };
