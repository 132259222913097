import { NotificationOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Grid, Badge, Button, Layout } from "antd";
import classNames from "classnames/bind";
import gql from "graphql-tag";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { PageFunctionComponent } from "../../@types/pages";
import { useAuth } from "../../lib/AuthContext";
import { withApollo } from "../../lib/initApollo";
import CompanyInformationAlert from "../Alert/CompanyInformationAlert";
import { ChatProvider } from "../Chat/ChatContext";
import { CompanyProvider, useCompany } from "../CompanyContext/CompanyContext";
import { ExchangeRatesProvider } from "../ExchangeRatesContext/ExchangeRatesContext";
import NotificationModal from "../Notifications/NotificationModal";
import { OperationTypesProvider } from "../OperationTypeContext/OperationTypeContext";
import AppFooter from "./AppFooter";
import AppHeader from "./AppHeader";
import AppMenu from "./AppMenu";
import { isFunction } from "../../src/common/utils";
import auth from "@/lib/auth";
import { useAllowWith, useRoles } from "@/lib/authorization";
import { useMixpanel } from "@/lib/MixpanelContext";

import styles from "./AppLayout.module.css";

const { useBreakpoint } = Grid;

const cx = classNames.bind(styles);

const GET_COMPANY_INFO = gql`
  query getCompany($id: ID!) {
    getCompany(id: $id) {
      id
      imageUrl
      inactive
    }
  }
`;

const CHECK_USER_GROUP = gql`
  query checkUserGroup($auth0Group: String!) {
    checkUserGroup(auth0Group: $auth0Group)
  }
`;

type Props = {
  fullHeigth?: boolean;
  footer?: boolean;
  customStyle?: string;
  showHeader?: boolean;
  showMenu?: boolean;
};

const AppLayoutCore: React.FunctionComponent<Props> = ({
  children,
  fullHeigth = false,
  footer = true,
  customStyle,
  showHeader = true,
  showMenu = true,
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [badge, setBadge] = useState(false);
  const [visible, setVisible] = useState(false);

  const { user, setUser } = useAuth();
  const { mixpanel } = useMixpanel();
  const router = useRouter();
  const { companyId } = useCompany();
  const { isSalesPerson, isCompany } = useRoles();

  const canGetNotifications = useAllowWith(["getNotifications", "read"]);
  const allowInactiveBanner = user && (isCompany || isSalesPerson);
  const isQuoting = router && router.pathname === "/quoting";

  const {
    data: { getCompany: { inactive } } = { getCompany: { inactive: false } },
  } = useQuery(GET_COMPANY_INFO, {
    variables: {
      id: companyId,
    },
    ssr: false,
    skip: !user || !companyId,
  });
  const { data } = useQuery(CHECK_USER_GROUP, {
    variables: {
      auth0Group: user?.group,
    },
    skip: !user?.group,
  });

  const toggleDrawer = () => {
    setVisible((prevState) => !prevState);
  };

  useEffect(() => {
    if (allowInactiveBanner && !isQuoting) {
      setShowAlert(inactive);
    }
  }, [inactive]);

  useEffect(() => {
    if (data) {
      if (!data.checkUserGroup) {
        mixpanel.reset();
        auth.lockLogout(setUser);
      }
    }
  }, [data]);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=1536" />
      </Head>
      {showAlert && <CompanyInformationAlert />}
      <ExchangeRatesProvider>
        <CompanyProvider>
          <OperationTypesProvider>
            <ChatProvider>
              <Layout>
                {showMenu && <AppMenu />}
                <Layout className={cx({ content: true, fullHeigth })}>
                  {showHeader && <AppHeader />}
                  {children}
                  {footer && <AppFooter customClassName={customStyle} />}
                </Layout>
              </Layout>
            </ChatProvider>
          </OperationTypesProvider>
        </CompanyProvider>
      </ExchangeRatesProvider>
    </>
  );
};
const AppLayout = withApollo()(AppLayoutCore) as React.FunctionComponent<Props>;

export const getLayout = (page: PageFunctionComponent) => (
  <AppLayout>{page}</AppLayout>
);

export const getReportLayout = (page: PageFunctionComponent) => (
  <AppLayout footer={false}>{page}</AppLayout>
);

export const getWhiteLayout = (page: PageFunctionComponent) => (
  <AppLayout customStyle="appStyle">{page}</AppLayout>
);
export const getCustomLayout = ({
  showFooter = true,
  isFullHeight = false,
  showMenu = true,
  showHeader = true,
} = {}) =>
  function WrappedAppLayout(page: PageFunctionComponent) {
    const breakpoints = useBreakpoint();
    return (
      <AppLayout
        footer={showFooter}
        fullHeigth={isFullHeight}
        showHeader={
          isFunction(showHeader) ? showHeader(breakpoints) : showHeader
        }
        showMenu={isFunction(showMenu) ? showMenu(breakpoints) : showMenu}
      >
        {page}
      </AppLayout>
    );
  };
