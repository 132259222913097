import { Input as AntdInput } from "antd";
import { InputProps as AntdInputProps } from "antd/lib/input";
import classNames from "classnames/bind";
import {
  Input as FormikInput,
  InputProps,
  TextAreaProps,
  InputNumber as FormikInputNumber,
  InputNumberProps,
  PasswordProps,
} from "formik-antd";
import React, { useState } from "react";

import styles from "./Input.module.scss";

const cx = classNames.bind(styles);

const { TextArea, Password } = FormikInput;

type Props = {};

type AltProps = {
  label: React.ReactNode | string;
  asterisk?: boolean;
};

export const Input: React.FunctionComponent<Props & AntdInputProps> = ({
  ...antdProps
}) => {
  return (
    <div className={styles.input}>
      <AntdInput {...antdProps} />
    </div>
  );
};

export const InputAlt: React.FunctionComponent<AltProps & AntdInputProps> = ({
  label,
  asterisk,
  ...antdProps
}) => {
  const [onFocus, setOnFocus] = useState(false);
  return (
    <div className={cx({ inputAlt: true, inputAltFocus: onFocus })}>
      <label>{label}</label>
      <AntdInput
        onFocus={() => setOnFocus(true)}
        onBlur={() => setOnFocus(false)}
        {...antdProps}
      />
    </div>
  );
};

export const InputForm: React.FunctionComponent<InputProps> = ({
  ...inputProps
}) => {
  return (
    <div className={styles.input}>
      <FormikInput {...inputProps} />
    </div>
  );
};

export const InputFormAlt: React.FunctionComponent<AltProps & InputProps> = ({
  label,
  style,
  asterisk,
  ...inputProps
}) => {
  const [onFocus, setOnFocus] = useState(false);
  return (
    <div
      className={cx({ inputAlt: true, inputAltFocus: onFocus })}
      style={style}
    >
      <label>
        {label}
        {asterisk && "*"}
      </label>
      <FormikInput
        onFocus={() => setOnFocus(true)}
        onBlur={() => setOnFocus(false)}
        {...inputProps}
      />
    </div>
  );
};

export const InputNumberForm: React.FunctionComponent<InputNumberProps> = ({
  ...inputNumberProps
}) => {
  return (
    <div className={styles.input}>
      <FormikInputNumber {...inputNumberProps} />
    </div>
  );
};

export const InputNumberFormAlt: React.FunctionComponent<
  AltProps & InputNumberProps
> = ({ label, style, ...inputNumberProps }) => {
  const [onFocus, setOnFocus] = useState(false);

  return (
    <div
      className={cx({ inputAlt: true, inputAltFocus: onFocus })}
      style={style}
    >
      <label>{label}</label>
      <FormikInputNumber
        onFocus={() => setOnFocus(true)}
        onBlur={() => setOnFocus(false)}
        {...inputNumberProps}
      />
    </div>
  );
};

export const InputFormAddon: React.FunctionComponent<InputProps> = ({
  ...inputProps
}) => (
  <div className={styles.inputAddon}>
    <FormikInput {...inputProps} />
  </div>
);

export const TextAreaForm: React.FunctionComponent<TextAreaProps> = ({
  ...props
}) => {
  return (
    <div className={styles.input}>
      <TextArea {...props} />
    </div>
  );
};
export const InputFormAltPassword: React.FunctionComponent<
  AltProps & PasswordProps
> = ({ label, style, asterisk, ...inputProps }) => {
  const [onFocus, setOnFocus] = useState(false);
  return (
    <div
      className={cx({ inputAlt: true, inputAltFocus: onFocus })}
      style={style}
    >
      <label>
        {label}
        {asterisk && "*"}
      </label>
      <Password
        onFocus={() => setOnFocus(true)}
        onBlur={() => setOnFocus(false)}
        bordered={false}
        {...inputProps}
      />
    </div>
  );
};
