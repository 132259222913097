export const auth0PublicKeys = {
  "teuai-local.auth0.com": {
    keys: [
      {
        alg: "RS256",
        kty: "RSA",
        use: "sig",
        n:
          "qK_0V0GikHflmjzqa1heIbeSD37bdiJWuUaYfcKi1TJDQni1c_SVF9N8BfbSxoyitO758Ubi5P1NqhMRo1_v24Zc9kUte4FuFnH6MYOUhqt_ASoX9cK7bs7TMAeI4aK0JR2ezzDW7hPPCWZ1ddvTBYfYnIGZHJsM3mrWHLIASAfyWGMUGbhFL0JcdaLbZcFQs049_XmlV1OZdiaBjz5DhBMlkPeWdn8agxNEEQiJXs8lTlMOHsn4lk_bd7MiaPrK2lmXi-1XwIPkkIbkksRNGzfi7WuTd1wggUHCYd6Pul_uuvi6jVZy27g9GkoMnZhuqdMM5XgVAySeTC5rUpFvKQ",
        e: "AQAB",
        kid: "QUFCRjBCQUVDQzJERURBMTNGRjJFMzYzQjYxMjA4MEREQTA3Qzg1Mg",
        x5t: "QUFCRjBCQUVDQzJERURBMTNGRjJFMzYzQjYxMjA4MEREQTA3Qzg1Mg",
        x5c: [
          "MIIDBTCCAe2gAwIBAgIJM7SS8TZJCvheMA0GCSqGSIb3DQEBCwUAMCAxHjAcBgNVBAMTFXRldWFpLWxvY2FsLmF1dGgwLmNvbTAeFw0xOTAxMDcxMzAwMzVaFw0zMjA5MTUxMzAwMzVaMCAxHjAcBgNVBAMTFXRldWFpLWxvY2FsLmF1dGgwLmNvbTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAKiv9FdBopB35Zo86mtYXiG3kg9+23YiVrlGmH3CotUyQ0J4tXP0lRfTfAX20saMorTu+fFG4uT9TaoTEaNf79uGXPZFLXuBbhZx+jGDlIarfwEqF/XCu27O0zAHiOGitCUdns8w1u4TzwlmdXXb0wWH2JyBmRybDN5q1hyyAEgH8lhjFBm4RS9CXHWi22XBULNOPf15pVdTmXYmgY8+Q4QTJZD3lnZ/GoMTRBEIiV7PJU5TDh7J+JZP23ezImj6ytpZl4vtV8CD5JCG5JLETRs34u1rk3dcIIFBwmHej7pf7rr4uo1Wctu4PRpKDJ2YbqnTDOV4FQMknkwua1KRbykCAwEAAaNCMEAwDwYDVR0TAQH/BAUwAwEB/zAdBgNVHQ4EFgQU4ssCjHotF1arhTM9HSNC+7ivLzYwDgYDVR0PAQH/BAQDAgKEMA0GCSqGSIb3DQEBCwUAA4IBAQAKZvxLeewHY2NWR/UYPFmll6MQawNWTBnHUl4plMURLM9666zkhB5X1ed/SEdQk56lFEiSgcqaVJAEZwbsFxkW2985bAYxnVouc5DyiSFACF8A5JmL/7cqfUQFd4vtPhmvZNrIV7FTIJMTNvxXW7EEGOGCrCHCmSpoWA0VEDhFdnZ6tDi0hT3NqWuKakFIlxcz4ZRZo7biIEtoesGDzr8oBNIVOgPe/t2pYgglVUJW2PtcLH0KZcIo7mt0vLo/Si7CWehTxjxpWzhJNK9VuuzULQ/WtdcDLdevdk1yGff7CwXvoCZeN3evhuipWaFwfzoL257EExE9YDozD/lbIqYG",
        ],
      },
    ],
  },
  "teuai-staging.auth0.com": {
    keys: [
      {
        alg: "RS256",
        kty: "RSA",
        use: "sig",
        x5c: [
          "MIIDCTCCAfGgAwIBAgIJXky3h3KL9O/mMA0GCSqGSIb3DQEBCwUAMCIxIDAeBgNVBAMTF3RldWFpLXN0YWdpbmcuYXV0aDAuY29tMB4XDTE5MDczMDIwMTMxOVoXDTMzMDQwNzIwMTMxOVowIjEgMB4GA1UEAxMXdGV1YWktc3RhZ2luZy5hdXRoMC5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDDItLdiOaj/FFdE81a0PlnkkTdNLiN4RWdmlDZP3I2a5k57MsjmU2YSUyTB+bzFLEG1+WX6Cd0vSPCN6Lhso4IHQrsX1p+08YmzfKYOxevhuaHtF1k+RfDbjVHb1rNePedYrc9RC/B1G7pMo86VnJUQsVm+MhqwqaAY/jZng8bB4e2DOa6P1wjCIfcXtiW7l+d+pkWfgP/eOgPK4+/U30OkHN4M/8X1cMwZ949e46jJ+iefqB/LfZxDZd4K2GtHskbA+q9FAv0m9ehxP2WKbYLTHeGqEyP1JdKL41PRAs2IDifTMtuhPEsQJHoMD7lBraUwqKWPBVir4cuDWcO/wb1AgMBAAGjQjBAMA8GA1UdEwEB/wQFMAMBAf8wHQYDVR0OBBYEFJux99DA5Fu9oEqYIs99nDMIpJutMA4GA1UdDwEB/wQEAwIChDANBgkqhkiG9w0BAQsFAAOCAQEAY0tJShRuwpBE00x62ctCFjXFkCdGyEKIxkZ0jZdmAC3WHTWzocVZJjjm3cP6mYdAcZhC6xPmZEvkmrP/MDtkt0IZDBr6GkCT9rGjIbbKca+styxSNmKubOcJqYiw3M+ogV31+zMIi7IhrN13MYdpybM2cekCadzZAVGpGrdR2eADIGwfg2XcMp02d6/thMKNJnEY1CNW73dKO7zkGDg9yS/dPjMTrfa4LyWSKE7a49jmPGYrK7ewUSaJvxdeXO6TnjNVs5911aWjTMVuCQD/yVJJHHKoKz5u8ta4M8/6mjjpYPATbtg33/NOEkYJXzyajj/SdXTj8HM8RpX6pZOCNw==",
        ],
        n:
          "wyLS3Yjmo_xRXRPNWtD5Z5JE3TS4jeEVnZpQ2T9yNmuZOezLI5lNmElMkwfm8xSxBtfll-gndL0jwjei4bKOCB0K7F9aftPGJs3ymDsXr4bmh7RdZPkXw241R29azXj3nWK3PUQvwdRu6TKPOlZyVELFZvjIasKmgGP42Z4PGweHtgzmuj9cIwiH3F7Ylu5fnfqZFn4D_3joDyuPv1N9DpBzeDP_F9XDMGfePXuOoyfonn6gfy32cQ2XeCthrR7JGwPqvRQL9JvXocT9lim2C0x3hqhMj9SXSi-NT0QLNiA4n0zLboTxLECR6DA-5Qa2lMKiljwVYq-HLg1nDv8G9Q",
        e: "AQAB",
        kid: "ODQ1Q0YxQzI3NUY4RjBGMDhFMjA2NjA0M0U0QUI1MkQ3MEYzOEE4Nw",
        x5t: "ODQ1Q0YxQzI3NUY4RjBGMDhFMjA2NjA0M0U0QUI1MkQ3MEYzOEE4Nw",
      },
    ],
  },
  "auth0.teu.ai": {
    keys: [
      {
        alg: "RS256",
        kty: "RSA",
        use: "sig",
        n:
          "w0us3ZzyMyfHqql20LJjtTRzKLmW2WBQ-TlbpFbmVnFFrnjzZCgXWgF3mew5o4Y1FCH4HrFyOHmX2LKA-LcQYSYLfZpkmJE2Yy5wD0eEVfkKXD8qrfgZoaiRhJCmPUvSUnB7GvEtBql5eBTJDWtj0mbMh-UkQxYITPJ0DVHGnFhuJyseBzggsJ84b4TPbqTOxC5YKG_tmI0sPZt_pCNy3EGFX2ccriUvHfQK_J0Z8Uyd8vMXuw3qnNrbmRQYHvLWfAwAsLPZ879rmxYVP_zFiHJEm45pGs57mN3Muo-g4Up2vVL7U1Ta3OYxnBPOrSB0NN4NYM8W8SXL5Ei5QK9DZQ",
        e: "AQAB",
        kid: "MjE3OEE4Rjc5ODdGRUUwNDQ5NzUyMjcwMEVBMDRERjBGNTQ3NTgzOA",
        x5t: "MjE3OEE4Rjc5ODdGRUUwNDQ5NzUyMjcwMEVBMDRERjBGNTQ3NTgzOA",
        x5c: [
          "MIIC+TCCAeGgAwIBAgIJBBNWZb4EFiE/MA0GCSqGSIb3DQEBCwUAMBoxGDAWBgNVBAMTD3RldWFpLmF1dGgwLmNvbTAeFw0xODEwMTYwMjQ4NTZaFw0zMjA2MjQwMjQ4NTZaMBoxGDAWBgNVBAMTD3RldWFpLmF1dGgwLmNvbTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAMNLrN2c8jMnx6qpdtCyY7U0cyi5ltlgUPk5W6RW5lZxRa5482QoF1oBd5nsOaOGNRQh+B6xcjh5l9iygPi3EGEmC32aZJiRNmMucA9HhFX5Clw/Kq34GaGokYSQpj1L0lJwexrxLQapeXgUyQ1rY9JmzIflJEMWCEzydA1RxpxYbicrHgc4ILCfOG+Ez26kzsQuWChv7ZiNLD2bf6QjctxBhV9nHK4lLx30CvydGfFMnfLzF7sN6pza25kUGB7y1nwMALCz2fO/a5sWFT/8xYhyRJuOaRrOe5jdzLqPoOFKdr1S+1NU2tzmMZwTzq0gdDTeDWDPFvEly+RIuUCvQ2UCAwEAAaNCMEAwDwYDVR0TAQH/BAUwAwEB/zAdBgNVHQ4EFgQUgMkKsxZDzE+w2ytJ8PDcJG4mAjswDgYDVR0PAQH/BAQDAgKEMA0GCSqGSIb3DQEBCwUAA4IBAQAiA8OQoVWSyUwi0RFGgDLDZVbp8m1OZMZfXs/6R1HzCT5ci8FF5Exrrs27p+GqHwUOYbM2Fv/SJrQdIMMCIkmvfkxapgllh4VLpbmr8Ws0wKYx9wvayOvGJhEJFdRclHnqDLqftVKLHwIuzpOmfMw7IVFLoFd0HQ4LLb2KbDKddbi38Q++B8RTzvo0BtGQ8sjL27wWXkPfR7C/kMkfmpilJy6WtwIbMZ7+yplGdE46wRnKhCi6gFde+fEZKtysf2BmUaMthH6RdVebPBl4y9h4XVIACEPlzlStQjBKzEw1349Af2KjBgUL2kZOrsnNRo1tnw+3SBUJ1lfSGS52zPuA",
        ],
      },
    ],
  },
  // new
  "klog-local.auth0.com": {
    keys: [
      {
        alg: "RS256",
        kty: "RSA",
        use: "sig",
        n:
          "qK_0V0GikHflmjzqa1heIbeSD37bdiJWuUaYfcKi1TJDQni1c_SVF9N8BfbSxoyitO758Ubi5P1NqhMRo1_v24Zc9kUte4FuFnH6MYOUhqt_ASoX9cK7bs7TMAeI4aK0JR2ezzDW7hPPCWZ1ddvTBYfYnIGZHJsM3mrWHLIASAfyWGMUGbhFL0JcdaLbZcFQs049_XmlV1OZdiaBjz5DhBMlkPeWdn8agxNEEQiJXs8lTlMOHsn4lk_bd7MiaPrK2lmXi-1XwIPkkIbkksRNGzfi7WuTd1wggUHCYd6Pul_uuvi6jVZy27g9GkoMnZhuqdMM5XgVAySeTC5rUpFvKQ",
        e: "AQAB",
        kid: "QUFCRjBCQUVDQzJERURBMTNGRjJFMzYzQjYxMjA4MEREQTA3Qzg1Mg",
        x5t: "QUFCRjBCQUVDQzJERURBMTNGRjJFMzYzQjYxMjA4MEREQTA3Qzg1Mg",
        x5c: [
          "MIIDBTCCAe2gAwIBAgIJM7SS8TZJCvheMA0GCSqGSIb3DQEBCwUAMCAxHjAcBgNVBAMTFXRldWFpLWxvY2FsLmF1dGgwLmNvbTAeFw0xOTAxMDcxMzAwMzVaFw0zMjA5MTUxMzAwMzVaMCAxHjAcBgNVBAMTFXRldWFpLWxvY2FsLmF1dGgwLmNvbTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAKiv9FdBopB35Zo86mtYXiG3kg9+23YiVrlGmH3CotUyQ0J4tXP0lRfTfAX20saMorTu+fFG4uT9TaoTEaNf79uGXPZFLXuBbhZx+jGDlIarfwEqF/XCu27O0zAHiOGitCUdns8w1u4TzwlmdXXb0wWH2JyBmRybDN5q1hyyAEgH8lhjFBm4RS9CXHWi22XBULNOPf15pVdTmXYmgY8+Q4QTJZD3lnZ/GoMTRBEIiV7PJU5TDh7J+JZP23ezImj6ytpZl4vtV8CD5JCG5JLETRs34u1rk3dcIIFBwmHej7pf7rr4uo1Wctu4PRpKDJ2YbqnTDOV4FQMknkwua1KRbykCAwEAAaNCMEAwDwYDVR0TAQH/BAUwAwEB/zAdBgNVHQ4EFgQU4ssCjHotF1arhTM9HSNC+7ivLzYwDgYDVR0PAQH/BAQDAgKEMA0GCSqGSIb3DQEBCwUAA4IBAQAKZvxLeewHY2NWR/UYPFmll6MQawNWTBnHUl4plMURLM9666zkhB5X1ed/SEdQk56lFEiSgcqaVJAEZwbsFxkW2985bAYxnVouc5DyiSFACF8A5JmL/7cqfUQFd4vtPhmvZNrIV7FTIJMTNvxXW7EEGOGCrCHCmSpoWA0VEDhFdnZ6tDi0hT3NqWuKakFIlxcz4ZRZo7biIEtoesGDzr8oBNIVOgPe/t2pYgglVUJW2PtcLH0KZcIo7mt0vLo/Si7CWehTxjxpWzhJNK9VuuzULQ/WtdcDLdevdk1yGff7CwXvoCZeN3evhuipWaFwfzoL257EExE9YDozD/lbIqYG",
        ],
      },
    ],
  },
  "klog-staging.auth0.com": {
    keys: [
      {
        alg: "RS256",
        kty: "RSA",
        use: "sig",
        x5c: [
          "MIIDCTCCAfGgAwIBAgIJXky3h3KL9O/mMA0GCSqGSIb3DQEBCwUAMCIxIDAeBgNVBAMTF3RldWFpLXN0YWdpbmcuYXV0aDAuY29tMB4XDTE5MDczMDIwMTMxOVoXDTMzMDQwNzIwMTMxOVowIjEgMB4GA1UEAxMXdGV1YWktc3RhZ2luZy5hdXRoMC5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDDItLdiOaj/FFdE81a0PlnkkTdNLiN4RWdmlDZP3I2a5k57MsjmU2YSUyTB+bzFLEG1+WX6Cd0vSPCN6Lhso4IHQrsX1p+08YmzfKYOxevhuaHtF1k+RfDbjVHb1rNePedYrc9RC/B1G7pMo86VnJUQsVm+MhqwqaAY/jZng8bB4e2DOa6P1wjCIfcXtiW7l+d+pkWfgP/eOgPK4+/U30OkHN4M/8X1cMwZ949e46jJ+iefqB/LfZxDZd4K2GtHskbA+q9FAv0m9ehxP2WKbYLTHeGqEyP1JdKL41PRAs2IDifTMtuhPEsQJHoMD7lBraUwqKWPBVir4cuDWcO/wb1AgMBAAGjQjBAMA8GA1UdEwEB/wQFMAMBAf8wHQYDVR0OBBYEFJux99DA5Fu9oEqYIs99nDMIpJutMA4GA1UdDwEB/wQEAwIChDANBgkqhkiG9w0BAQsFAAOCAQEAY0tJShRuwpBE00x62ctCFjXFkCdGyEKIxkZ0jZdmAC3WHTWzocVZJjjm3cP6mYdAcZhC6xPmZEvkmrP/MDtkt0IZDBr6GkCT9rGjIbbKca+styxSNmKubOcJqYiw3M+ogV31+zMIi7IhrN13MYdpybM2cekCadzZAVGpGrdR2eADIGwfg2XcMp02d6/thMKNJnEY1CNW73dKO7zkGDg9yS/dPjMTrfa4LyWSKE7a49jmPGYrK7ewUSaJvxdeXO6TnjNVs5911aWjTMVuCQD/yVJJHHKoKz5u8ta4M8/6mjjpYPATbtg33/NOEkYJXzyajj/SdXTj8HM8RpX6pZOCNw==",
        ],
        n:
          "wyLS3Yjmo_xRXRPNWtD5Z5JE3TS4jeEVnZpQ2T9yNmuZOezLI5lNmElMkwfm8xSxBtfll-gndL0jwjei4bKOCB0K7F9aftPGJs3ymDsXr4bmh7RdZPkXw241R29azXj3nWK3PUQvwdRu6TKPOlZyVELFZvjIasKmgGP42Z4PGweHtgzmuj9cIwiH3F7Ylu5fnfqZFn4D_3joDyuPv1N9DpBzeDP_F9XDMGfePXuOoyfonn6gfy32cQ2XeCthrR7JGwPqvRQL9JvXocT9lim2C0x3hqhMj9SXSi-NT0QLNiA4n0zLboTxLECR6DA-5Qa2lMKiljwVYq-HLg1nDv8G9Q",
        e: "AQAB",
        kid: "ODQ1Q0YxQzI3NUY4RjBGMDhFMjA2NjA0M0U0QUI1MkQ3MEYzOEE4Nw",
        x5t: "ODQ1Q0YxQzI3NUY4RjBGMDhFMjA2NjA0M0U0QUI1MkQ3MEYzOEE4Nw",
      },
    ],
  },
  "auth.klog.co": {
    keys: [
      {
        alg: "RS256",
        kty: "RSA",
        use: "sig",
        n:
          "w0us3ZzyMyfHqql20LJjtTRzKLmW2WBQ-TlbpFbmVnFFrnjzZCgXWgF3mew5o4Y1FCH4HrFyOHmX2LKA-LcQYSYLfZpkmJE2Yy5wD0eEVfkKXD8qrfgZoaiRhJCmPUvSUnB7GvEtBql5eBTJDWtj0mbMh-UkQxYITPJ0DVHGnFhuJyseBzggsJ84b4TPbqTOxC5YKG_tmI0sPZt_pCNy3EGFX2ccriUvHfQK_J0Z8Uyd8vMXuw3qnNrbmRQYHvLWfAwAsLPZ879rmxYVP_zFiHJEm45pGs57mN3Muo-g4Up2vVL7U1Ta3OYxnBPOrSB0NN4NYM8W8SXL5Ei5QK9DZQ",
        e: "AQAB",
        kid: "MjE3OEE4Rjc5ODdGRUUwNDQ5NzUyMjcwMEVBMDRERjBGNTQ3NTgzOA",
        x5t: "MjE3OEE4Rjc5ODdGRUUwNDQ5NzUyMjcwMEVBMDRERjBGNTQ3NTgzOA",
        x5c: [
          "MIIC+TCCAeGgAwIBAgIJBBNWZb4EFiE/MA0GCSqGSIb3DQEBCwUAMBoxGDAWBgNVBAMTD3RldWFpLmF1dGgwLmNvbTAeFw0xODEwMTYwMjQ4NTZaFw0zMjA2MjQwMjQ4NTZaMBoxGDAWBgNVBAMTD3RldWFpLmF1dGgwLmNvbTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAMNLrN2c8jMnx6qpdtCyY7U0cyi5ltlgUPk5W6RW5lZxRa5482QoF1oBd5nsOaOGNRQh+B6xcjh5l9iygPi3EGEmC32aZJiRNmMucA9HhFX5Clw/Kq34GaGokYSQpj1L0lJwexrxLQapeXgUyQ1rY9JmzIflJEMWCEzydA1RxpxYbicrHgc4ILCfOG+Ez26kzsQuWChv7ZiNLD2bf6QjctxBhV9nHK4lLx30CvydGfFMnfLzF7sN6pza25kUGB7y1nwMALCz2fO/a5sWFT/8xYhyRJuOaRrOe5jdzLqPoOFKdr1S+1NU2tzmMZwTzq0gdDTeDWDPFvEly+RIuUCvQ2UCAwEAAaNCMEAwDwYDVR0TAQH/BAUwAwEB/zAdBgNVHQ4EFgQUgMkKsxZDzE+w2ytJ8PDcJG4mAjswDgYDVR0PAQH/BAQDAgKEMA0GCSqGSIb3DQEBCwUAA4IBAQAiA8OQoVWSyUwi0RFGgDLDZVbp8m1OZMZfXs/6R1HzCT5ci8FF5Exrrs27p+GqHwUOYbM2Fv/SJrQdIMMCIkmvfkxapgllh4VLpbmr8Ws0wKYx9wvayOvGJhEJFdRclHnqDLqftVKLHwIuzpOmfMw7IVFLoFd0HQ4LLb2KbDKddbi38Q++B8RTzvo0BtGQ8sjL27wWXkPfR7C/kMkfmpilJy6WtwIbMZ7+yplGdE46wRnKhCi6gFde+fEZKtysf2BmUaMthH6RdVebPBl4y9h4XVIACEPlzlStQjBKzEw1349Af2KjBgUL2kZOrsnNRo1tnw+3SBUJ1lfSGS52zPuA",
        ],
      },
    ],
  },
};
