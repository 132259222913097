import React from "react";
import { useIntl } from "react-intl";

import { KlogLogo } from "../KlogLogo/KlogLogo";

import styles from "./StepsMobile.module.scss";

type Props = {
  currentStep: number;
  steps: any;
};

export const StepsMobile: React.FunctionComponent<Props> = ({
  currentStep,
  steps,
}) => {
  const intl = useIntl();

  const stepsLength = steps.length;
  const stepIndex = currentStep + 1;

  return (
    <div className={styles.stepsWrapper}>
      <div className={styles.stepsLogo}>
        <KlogLogo />
      </div>
      <div className={styles.stepsContainer}>
        <h6 className={styles.currentStep}>
          {intl.formatMessage({ id: "stepOf" }, { stepIndex })}
        </h6>
        <span>{intl.formatMessage({ id: "fromStep" }, { stepsLength })}</span>
      </div>
    </div>
  );
};
