import { gql, useQuery } from "@apollo/client";
import { Badge, Button, Empty, message, notification } from "antd";
import classNames from "classnames/bind";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { isEmpty } from "ramda";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";

import { DotsLoader } from "@/components/Klog";
import { useLanguage } from "@/components/Language/LanguageContext";
import { TaskNotificationItem } from "./components/TaskNotificationItem/TaskNotificationItem";

import { ITaskNotification } from "@/types/taskNotification";
import { useDetectClickOut } from "@/hooks/hooks";

import Icon from "@/public/static/images/icons/icon-alert--gradient.svg";
import NotificationSvg from "@/public/static/images/icons/icon-bell.svg";

import styles from "./TasksNotifications.module.scss";

const cx = classNames.bind(styles);

const notificationWrapperVariants: Variants = {
  initial: {
    opacity: 0,
    y: -4,
    x: "-50%",
  },
  animate: {
    opacity: 1,
    y: 0,
    x: "-50%",
    transition: {
      duration: 0.24,
    },
  },
  exit: {
    opacity: 0,
    y: -2,
    x: "-50%",
    transition: {
      duration: 0.15,
    },
  },
};

const GET_TASK_NOTIFICATION = gql`
  query getTaskNotification($skip: Int, $limit: Int) {
    getTaskNotification(skip: $skip, limit: $limit) {
      id
      createdAt
      title
      description
      isRead
      owner {
        id
      }
      tasks {
        id
        title
      }
    }
  }
`;

const GET_NOTIFICATIONS_WITHOUT_ALERT_SENDED = gql`
  query getNotificationsWithoutAlertSent {
    getNotificationsWithoutAlertSent {
      id
      title
      description
      createdAt
      tasks {
        id
        title
        description
      }
    }
  }
`;

export const TasksNotifications = () => {
  const [moreNotifications, setMoreNotifications] = useState<boolean>(true);
  const [loaderMoreNotifications, setLoaderMoreNotifications] =
    useState<boolean>(false);

  const intl = useIntl();
  const router = useRouter();
  const { language } = useLanguage();
  const {
    show: showNotifications,
    nodeRef,
    triggerRef,
  } = useDetectClickOut(false);

  const {
    data: { getTaskNotification = [] as ITaskNotification[] } = {},
    loading: loadingTasksNotifications,
    fetchMore,
    refetch: refetchNotifications,
  } = useQuery(GET_TASK_NOTIFICATION, {
    variables: { skip: 0, limit: 6 },
    pollInterval: 60000,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const {
    data: {
      getNotificationsWithoutAlertSent: alerts = [] as ITaskNotification[],
    } = {},
  } = useQuery(GET_NOTIFICATIONS_WITHOUT_ALERT_SENDED, {
    pollInterval: 60000,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const inSpanish = language === "es";

  const handleGetMoreNotifications = async () => {
    setLoaderMoreNotifications(true);
    try {
      await fetchMore({
        variables: {
          skip: getTaskNotification.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (isEmpty(fetchMoreResult?.getTaskNotification)) {
            setMoreNotifications(false);
            message.info(intl.formatMessage({ id: "noMoreNotifications" }));
            return prev;
          }
          return Object.assign({}, prev, {
            getTaskNotification: [
              ...prev.getTaskNotification,
              ...fetchMoreResult.getTaskNotification,
            ],
          });
        },
      });
    } catch (e) {
      notification.error({
        message: intl.formatMessage({ id: "error" }),
      });
    } finally {
      setLoaderMoreNotifications(false);
    }
  };

  const openNotification = (title, tasks) => {
    const taskIds = tasks.map(({ id }) => id);
    notification.open({
      icon: <Icon />,
      message: title,
      className: `${styles.alert}`,
      placement: "bottomRight",
      duration: 10,
      btn: (
        <Button
          type="link"
          size="small"
          onClick={() =>
            router.push({
              pathname: "/tasks",
              query: { taskIds },
            })
          }
        >
          {intl.formatMessage({ id: "goToTaskPanel" })}
        </Button>
      ),
    });
  };

  const notificationsRead = getTaskNotification.filter(({ isRead }) => !isRead);

  useEffect(() => {
    alerts.map(({ title, tasks }) => openNotification(title, tasks));
    if (alerts.length) {
      refetchNotifications();
    }
  }, [alerts]);

  return (
    <>
      <button
        type="button"
        className={cx({ button: true, buttonFocus: showNotifications })}
        ref={triggerRef}
      >
        <Badge dot={notificationsRead.length}>
          <NotificationSvg />
        </Badge>
      </button>
      <AnimatePresence>
        {showNotifications && (
          <motion.div
            className={cx({ wrapper: true, wrapperLang: !inSpanish })}
            ref={nodeRef}
            variants={notificationWrapperVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <h2>{intl.formatMessage({ id: "notifications" })}</h2>
            <div className={styles.container}>
              {loadingTasksNotifications && !getTaskNotification.length && (
                <DotsLoader />
              )}
              {isEmpty(getTaskNotification) && !loadingTasksNotifications && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={intl.formatMessage({
                    id: "withoutNotifications",
                  })}
                />
              )}
              {!isEmpty(getTaskNotification) &&
                getTaskNotification.map((taskNotification) => (
                  <TaskNotificationItem
                    key={taskNotification?.id}
                    taskNotification={taskNotification}
                  />
                ))}
              {!isEmpty(getTaskNotification) && !loadingTasksNotifications && (
                <Button
                  type="text"
                  block
                  loading={loaderMoreNotifications}
                  onClick={handleGetMoreNotifications}
                >
                  {intl.formatMessage({ id: "loadMore" })}
                </Button>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
