import isNode from "detect-node";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";

import Auth from "@/lib/auth";
import { IUser } from "../@types/user";

export interface IAuthContext {
  user?: IUser | null;
  setUser?: (state) => void;
  authenticating?: boolean;
  setAuthenticating?: (state) => void;
  lock?: Auth0LockStatic;
  showModal?: (
    opt: Auth0LockShowOptions,
    cb?: (authResult: AuthResult) => void
  ) => void;
}

const AuthContext = createContext<IAuthContext>({});

const AuthProvider = ({ children, initialUser }) => {
  const { pathname, events } = useRouter();
  const [user, setUser] = useState(initialUser);
  const [authenticating, setAuthenticating] = useState(false);
  let showModal: IAuthContext["showModal"];
  if (!isNode) {
    showModal = (opt, cb) => {
      Auth.auth0Lock.removeAllListeners("authenticated");
      Auth.auth0Lock.show(opt);
      Auth.auth0Lock.on("authenticated", (authResult) => cb(authResult));
    };
  }
  useEffect(() => {
    if (!isNode && user && !user.sendbirdAccessToken) {
      Auth.auth0Lock.checkSession({}, (_, newAuthResult) => {
        const { idToken, accessToken } = newAuthResult;
        Auth.handleLockAuth(idToken, accessToken, setUser);
      });
    }
  }, [pathname]);

  async function getUser() {
    try {
      const response = await fetch("/api/me");
      const profile = await response.json();
      if (Object.keys(profile).length === 0 || profile.error) {
        setUser(undefined);
      } else {
        setUser(profile);
      }
      // tslint:disable-next-line:no-empty
    } catch (e) {}
  }

  useEffect(() => {
    getUser();
  }, [pathname]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        authenticating,
        setAuthenticating,
        showModal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthConsumer = AuthContext.Consumer;
const useAuth = () => useContext(AuthContext);

export { AuthContext, AuthConsumer, AuthProvider, useAuth };
