import { AnimatePresence, motion } from "framer-motion";

import styles from "./Loaders.module.scss";

export const DotsLoader: React.FunctionComponent = () => {
  return (
    <AnimatePresence>
      <motion.div
        className={styles.container}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: "tween", duration: 0.42, delayChildren: 0.2 }}
      >
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={styles.dot} />
      </motion.div>
    </AnimatePresence>
  );
};

type RowLoaderProps = {
  rowMinWidth?: number | string;
  rowHeigth?: number | string;
};

export const RowLoader: React.FunctionComponent<RowLoaderProps> = ({
  rowMinWidth,
  rowHeigth,
}) => {
  return (
    <AnimatePresence>
      <motion.div
        className={styles.rowSkeleton}
        style={{ minWidth: rowMinWidth, height: rowHeigth }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      />
    </AnimatePresence>
  );
};
