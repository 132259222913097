import classnames from "classnames/bind";

import styles from "./StatusBorderedContent.module.scss";

const cx = classnames.bind(styles);

type Props = {
  selected?: boolean;
  backup?: boolean;
  disabled?: boolean;
};

export const StatusBorderedContent: React.FunctionComponent<Props> = ({
  selected = false,
  backup = false,
  disabled = false,
  children,
}) => {
  return (
    <div
      className={cx({
        selected,
        backup,
        disabled,
        default: true,
      })}
    >
      {children}
    </div>
  );
};
