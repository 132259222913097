import { createContext, useContext } from "react";

import { IShipment } from "../../@types/shipment";

interface IShipmentContext {
  shipment?: IShipment;
  refetch?: () => void;
}

const ShipmentContext = createContext<IShipmentContext>({});

const ShipmentProvider = ({ children, shipment, refetch }) => {
  return (
    <ShipmentContext.Provider value={{ shipment, refetch }}>
      {children}
    </ShipmentContext.Provider>
  );
};

const ShipmentConsumer = ShipmentContext.Consumer;
const useShipment = () => useContext(ShipmentContext);
export { ShipmentContext, ShipmentConsumer, ShipmentProvider, useShipment };
