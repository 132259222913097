import axios from "axios";
import useSWR from "swr";
import { newEnforcer, newModelFromString, StringAdapter } from "casbin";
import isNode from "detect-node";
import { useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { useApolloClient } from "@apollo/client";
import { isEmpty } from "ramda";

import { useShipment } from "@/components/ShipmentContext/ShipmentContext";
import auth from "@/lib/auth";
import { useAuth } from "./AuthContext";
import {
  COMPANY,
  CUSTOMER_SERVICE,
  CUSTOMS_AGENCY,
  FINANCE,
  MARKETING,
  OPERATIVE,
  SALESPERSON,
  TEUAI,
} from "./constants";
import { GET_USER_PERMISSIONS } from "@/components/Layouts/AppHeader";

const modelConfiguration = `
[request_definition]
r = sub, obj, act

[policy_definition]
p = sub, obj, act, eft

[role_definition]
g = _, _

[policy_effect]
e = some(where (p.eft == allow)) && !some(where (p.eft == deny))

[matchers]
m = g(r.sub, p.sub) && r.obj == p.obj && r.act == p.act
`;

const paramsToFetchPolicies = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

export const ssrFetchPolicies = async (token) =>
  axios.post(
    process.env.NEXT_PUBLIC_ENFORCER_SUBJECTS_URI,
    { token, from: isNode ? "ssr" : "client" },
    paramsToFetchPolicies
  );

export const clientFetchPolicies = async (url) => {
  const userFromJwt = await auth.getToken({}, true);

  return axios.post(
    url,
    { token: userFromJwt, from: isNode ? "ssr" : "client" },
    paramsToFetchPolicies
  );
};
const getUserFromCache = (client) => {
  const { getDBUserPermissions: { permissionToEditFields = {} } = {} } =
    client.readQuery({
      query: GET_USER_PERMISSIONS,
    }) || {};
  return permissionToEditFields;
};

let enforcer;
let firstMount = true;
export const useAllowWith = (requestedPermission = []) => {
  const { shipment: { externalForwarder } = {} } = useShipment();
  const [isAllowed, setIsAllowed] = useState(null);
  const { pathname } = useRouter();
  const { user } = useAuth();
  const client = useApolloClient();
  const { isCompany } = useRoles();
  const { data, mutate } = useSWR(
    process.env.NEXT_PUBLIC_ENFORCER_SUBJECTS_URI,
    clientFetchPolicies,
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
      refreshInterval: 300000,
      onSuccess: async ({ data: { policies } }) => {
        await initializeEnforcer(policies);
      },
    }
  );
  const permissionToEditFields = getUserFromCache(client);
  const [obj, act, field] = requestedPermission;

  useEffect(() => {
    const enforce = async () => {
      if (enforcer) {
        const response = await enforcer.enforce(user?.auth0Id, obj, act);
        if (!isCompany) {
          setIsAllowed(response);
        } else {
          const isByFieldAndHasPermissions =
            field &&
            !!permissionToEditFields &&
            !isEmpty(permissionToEditFields);
          const userIsAllowed = isByFieldAndHasPermissions
            ? permissionToEditFields[obj] &&
              permissionToEditFields[obj].includes(field)
            : response;
          setIsAllowed(userIsAllowed);
          if (!response || externalForwarder === false) setIsAllowed(false);
        }
      }
      if (pathname.startsWith("/share/shipment")) setIsAllowed(false);
    };
    enforce();
  }, [data, enforcer]);

  useEffect(() => {
    const init = async () => {
      firstMount = false;
      await mutate(process.env.NEXT_PUBLIC_ENFORCER_SUBJECTS_URI);
    };
    !enforcer && firstMount && init();
  }, []);
  return isAllowed;
};

const getRoles = (user) => {
  const { group = "" } = user || {};
  const isAdmin = group.includes("Admin");
  const parsedGroup = group.replace("Admin", "");
  const isTeuai = parsedGroup === TEUAI;
  const isCustomerService = parsedGroup === CUSTOMER_SERVICE;
  const isSalesPerson = parsedGroup === SALESPERSON;
  const isFinance = parsedGroup === FINANCE;
  const isOperative = parsedGroup === OPERATIVE;
  const isCompany = parsedGroup === COMPANY;
  const isMarketing = parsedGroup === MARKETING;
  const isCustomsAgency = parsedGroup === CUSTOMS_AGENCY;

  return {
    isAdmin,
    isMarketing,
    isCustomsAgency,
    isTeuai,
    isCustomerService,
    isSalesPerson,
    isFinance,
    isOperative,
    isCompany,
    isTeuaiAdmin: isTeuai && isAdmin,
    isCustomerServiceAdmin: isCustomerService && isAdmin,
    isSalesPersonAdmin: isSalesPerson && isAdmin,
    isFinanceAdmin: isFinance && isAdmin,
    isOperativeAdmin: isOperative && isAdmin,
    isCompanyAdmin: isCompany && isAdmin,
    isTeuaiUser: isTeuai && !isAdmin,
    isCustomerServiceUser: isCustomerService && !isAdmin,
    isSalesPersonUser: isSalesPerson && !isAdmin,
    isFinanceUser: isFinance && !isAdmin,
    isOperativeUser: isOperative && !isAdmin,
    isCompanyUser: isCompany && !isAdmin,
  };
};
export const useRoles = () => {
  const { user } = useAuth();
  const memoizedRoles = useMemo(() => getRoles(user), [user]);
  return memoizedRoles;
};

export const initializeEnforcer = async (policies) => {
  const newestEnforcer = await newEnforcer();
  const policiesAdapter = new StringAdapter(policies || " ");
  const model = newModelFromString(modelConfiguration);
  await newestEnforcer.initWithModelAndAdapter(model, policiesAdapter);
  enforcer = newestEnforcer;
  return enforcer;
};
