import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import { useIntl } from "react-intl";
import pagesTitleTranslator from "../../lib/pagesTitleTranslator";

const PagesTitle = () => {
  const { route } = useRouter();
  const intl = useIntl();

  return (
    <Head>
      <title>{pagesTitleTranslator(route, intl)}</title>
    </Head>
  );
};

export default PagesTitle;
