import { RadioButtonProps } from "antd/lib/radio/radioButton";
import { Radio as FormikAntdRadio, RadioGroupProps } from "formik-antd";
import React from "react";

import styles from "./Radio.module.scss";

const { Button, Group } = FormikAntdRadio;

export const RadioGroup: React.FunctionComponent<RadioGroupProps> = ({
  children,
  ...props
}) => {
  return (
    <div className={styles.radioGroup}>
      <Group {...props}>{children}</Group>
    </div>
  );
};

export const RadioButton: React.FunctionComponent<RadioButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <div className={styles.radioButton}>
      <Button {...props}>{children}</Button>
    </div>
  );
};

export const FormRadio: React.FunctionComponent<RadioGroupProps> = ({
  children,
  ...props
}) => {
  return (
    <FormikAntdRadio className={styles.radioCustom} {...props}>
      {children}
    </FormikAntdRadio>
  );
};
