import gql from "graphql-tag";

export const completeMilestone = gql`
  mutation completeMilestone(
    $id: ID!
    $shipmentMilestoneId: ID
    $completedAt: DateTime
    $milestoneReference: String
  ) {
    completeMilestone(
      id: $id
      completedAt: $completedAt
      milestoneReference: $milestoneReference
      shipmentMilestoneId: $shipmentMilestoneId
    ) {
      message
      shipment {
        id
        containerLoadType
        syncColoaderStatus {
          status
          coloaderName
          shipmentIntegration {
            id
            etd
            eta
            hbl
            carrierCode
            containers
            quantity
            weight
            volume
            vessel
            voyage
          }
        }
        transshipments {
          id
          eta
          ata
          eta
          atd
          transitTime
          transshipmentType
          pod {
            id
            code
            location {
              lat
              lng
            }
          }
          carrier {
            id
            trackingUrl
            scac
            name
            trackableBy {
              id
              bookingNumber
              containerNumber
              mbl
            }
          }
        }
        cargo {
          id
          packageType
          containerNumber
          containerType
          loadedQuantity
          packageType
          sealNumber
          volume
          weight
          freeDays
          purchaseOrderRows
          hasReturned
          returnedAt
        }
        schedule {
          id
          finalEta
          finalEtd
        }
        shipmentBooking {
          id
          finalCarrier {
            id
            scac
            trackableBy {
              id
              bookingNumber
              containerNumber
              mbl
            }
          }
          finalColoader {
            id
            name
          }
        }
        milestones {
          id
          reference {
            title
            important
            order
          }
          completed
          completedAt
          completedBy
          completedIn
        }
        stage
      }
    }
  }
`;

export const createCompany = gql`
  mutation createCompany(
    $companyName: String!
    $taxId: String!
    $representativeName: String!
    $email: String!
    $inactive: Boolean
    $salesPersonId: ID
    $priority: String
    $country: String!
    $operatingOffice: String!
  ) {
    createCompany(
      companyName: $companyName
      taxId: $taxId
      representativeName: $representativeName
      email: $email
      inactive: $inactive
      salesPersonId: $salesPersonId
      priority: $priority
      country: $country
      operatingOffice: $operatingOffice
    ) {
      id
      name
      taxId
      inactive
      createdAt
      verified
      approvedCreditScore
      creditScoreObservations
      salesPerson {
        auth0Id
        name
      }
      customerService {
        auth0Id
        name
      }
      operationsRepresentative {
        id
        name
      }
      quotingInformation {
        id
        active
        containerLoadType
        quotingValidity
      }
    }
  }
`;

export const createForwarder = gql`
  mutation createForwarder($name: String!, $email: String!) {
    createForwarder(name: $name, email: $email) {
      id
      name
    }
  }
`;

export const getCompanies = gql`
  query getCompanies($showValidated: Boolean) {
    getCompanies(showValidated: $showValidated) {
      id
      priority
      taxId
      legalName
      name
      country
      tier
    }
  }
`;
export const getCompaniesOnUser = gql`
  query getCompaniesOnUser(
    $limit: Int
    $order: String
    $field: String
    $skip: Int
    $searchValue: String
    $priority: [String!]
    $salesPersonsIds: [ID!]
    $customerServiceIds: [ID!]
  ) {
    getCompaniesOnUser(
      limit: $limit
      order: $order
      field: $field
      skip: $skip
      searchValue: $searchValue
      priority: $priority
      salesPersonsIds: $salesPersonsIds
      customerServiceIds: $customerServiceIds
    ) {
      companies {
        id
        name
        legalName
        inactive
        createdAt
        verified
        approvedCreditScore
        creditScoreObservations
        priority
        salesPerson {
          auth0Id
          name
        }
        customerService {
          auth0Id
          name
        }
        operationsRepresentative {
          id
          name
        }
        quotingInformation {
          id
          active
          containerLoadType
          quotingValidity
        }
        specialWarehouses {
          id
          name
          code
          pod {
            code
          }
        }
        specialCustomsAgencies {
          id
          name
        }
      }
      totalCompanies
    }
  }
`;

export const getForwarders = gql`
  query getForwarders {
    getForwarders {
      id
      name
    }
  }
`;
export const getForwardersOfCompany = gql`
  query getForwardersOfCompany($companyId: ID) {
    getForwardersOfCompany(companyId: $companyId) {
      id
      name
    }
  }
`;

export const getPosSummaryInformation = gql`
  query getPosSummaryInformation($companyId: ID) {
    getPosSummaryInformation(companyId: $companyId) {
      totalPos
      completedPos
    }
  }
`;

export const getPurchaseOrders = gql`
  query getPurchaseOrders(
    $ids: [ID!] = []
    $withPurchaseOrderRows: Boolean = false
    $withPagination: Boolean = true
    $limit: Int
    $order: String
    $field: String
    $skip: Int
    $searchValue: String
    $companyId: ID
  ) {
    getPurchaseOrders(
      ids: $ids
      limit: $limit
      order: $order
      field: $field
      skip: $skip
      searchValue: $searchValue
      companyId: $companyId
    ) {
      purchaseOrders {
        id
        companyReference
        condition
        shipper {
          id
          name
          email
          code
          phone
          address
          country {
            id
            code
            name
          }
          city
          contactName
        }
        status
        company {
          id
          name
          tier
        }
        pol {
          id
          code
        }
        pod {
          id
          code
        }
        shipments {
          id
          stage
        }
        createdAt
        updatedAt
        totalQuantity
        bookedQuantity
        shippedQuantity
        purchaseOrderRows @include(if: $withPurchaseOrderRows)
        shipmentCount
      }
      totalPos @include(if: $withPagination)
    }
  }
`;
export const createCargoMutation = gql`
  mutation createCargo(
    $shipmentId: String
    $freeDays: Int
    $containerType: String
    $containerNumber: String
    $sealNumber: String
    $volume: Int
    $weight: Int
    $numberOfCopies: Int!
  ) {
    createCargo(
      shipmentId: $shipmentId
      freeDays: $freeDays
      containerType: $containerType
      containerNumber: $containerNumber
      volume: $volume
      weight: $weight
      sealNumber: $sealNumber
      numberOfCopies: $numberOfCopies
    ) {
      id
      cargo {
        id
        packageType
        containerType
        freeDays
        containerNumber
        volume
        weight
        sealNumber
        loadedQuantity
        purchaseOrderRows
        hasReturned
        returnedAt
        createdAt
      }
    }
  }
`;
export const createReservedCargoMutation = gql`
  mutation createReservedCargo(
    $shipmentId: String
    $containerType: String
    $volume: Int
    $weight: Int
    $numberOfCopies: Int!
  ) {
    createReservedCargo(
      shipmentId: $shipmentId
      containerType: $containerType
      volume: $volume
      weight: $weight
      numberOfCopies: $numberOfCopies
    ) {
      id
      reservedCargo {
        id
        packageType
        containerType
        volume
        weight
        loadedQuantity
        createdAt
      }
    }
  }
`;
export const updateCargoInfo = gql`
  mutation updateCargoField(
    $id: ID!
    $fieldName: String
    $value: String
    $dateValue: DateTime
    $shipmentId: ID
  ) {
    updateCargoField(
      id: $id
      fieldName: $fieldName
      value: $value
      dateValue: $dateValue
      shipmentId: $shipmentId
    ) {
      id
      packageType
      containerNumber
      containerType
      loadedQuantity
      volumetricWeight
      sealNumber
      volume
      weight
      freeDays
      sidemarCode
      purchaseOrderRows
      hasReturned
      returnedAt
      createdAt
    }
  }
`;
export const updateReservedCargoInfo = gql`
  mutation updateReservedCargoField(
    $id: ID!
    $fieldName: String
    $value: String
    $shipmentId: ID
  ) {
    updateReservedCargoField(
      id: $id
      fieldName: $fieldName
      value: $value
      shipmentId: $shipmentId
    ) {
      id
      packageType
      containerType
      loadedQuantity
      freeDays
      volume
      weight
      createdAt
    }
  }
`;
export const addProducts = gql`
  mutation addPurchaseOrderRowsToCargo(
    $id: ID!
    $shipmentId: ID!
    $values: [Json!]
  ) {
    addPurchaseOrderRowsToCargo(
      id: $id
      shipmentId: $shipmentId
      values: $values
    )
  }
`;
export const getCargoOnId = gql`
  query getCargoOnCargoId($cargoId: ID!) {
    getCargoOnCargoId(cargoId: $cargoId) {
      id
      purchaseOrderRows
    }
  }
`;

export const getPurchaseOrderShipments = gql`
  query getPurchaseOrderShipments($purchaseOrderId: ID!) {
    getPurchaseOrderShipments(purchaseOrderId: $purchaseOrderId) {
      id
      name
      shipmentType
      incoterm
      containerLoadType
      etd
      eta
      updatedAt
      bookingLoadType
      purchaseOrderRows
      stage
      shippedQuantity
      operationType
      shipmentBooking {
        id
        subscribed
        subscriptionStatus
        suspiciousKey
      }
      purchaseOrders {
        id
        shipper {
          id
          name
          contactName
          code
          email
          phone
          address
          country {
            id
            name
            code
          }
        }
      }
      milestones {
        id
        reference {
          title
          important
          order
        }
        completed
        completedAt
        completedBy
        completedIn
      }
      pol {
        code
        id
        name
      }
      pod {
        code
        id
        name
      }
      forwarderDetails {
        id
        forwarderReference
      }
      cargo {
        id
        loadedQuantity
      }
      company {
        id
        name
      }
      shipper {
        id
        name
        email
        code
        phone
        address
        country {
          id
          code
          name
        }
        city
        contactName
      }
      shipmentBooking {
        id
        subscribed
        subscriptionStatus
        suspiciousKey
      }
    }
  }
`;

export const getCompanyDataset = gql`
  query getCompanyDataset($companyId: ID!) {
    getCompanyDataset(companyId: $companyId) {
      id
      purchaseOrderRows
      createdAt
      shipments {
        id
        purchaseOrderRows
        createdAt
        pol {
          id
          name
        }
        pod {
          id
          name
        }
        schedule {
          id
          finalEta
          ata
          finalEtd
          atd
        }
        cargo {
          id
          containerType
          createdAt
          purchaseOrderRows
          volume
        }
      }
    }
  }
`;
export const getExportableCompanyDataset = gql`
  query getCompanyDataset(
    $companyId: ID!
    $startDate: DateTime
    $endDate: DateTime
    $dateFilterName: String
    $season: String
    $poReference: String
  ) {
    getCompanyDataset(
      companyId: $companyId
      startDate: $startDate
      endDate: $endDate
      dateFilterName: $dateFilterName
      season: $season
      poReference: $poReference
    ) {
      createdAt
      purchaseOrderRows
      id
      season
      comments {
        id
        text
        createdAt
      }
      schedule {
        id
        ecrd
      }
      pol {
        id
        name
        country {
          id
          name
        }
      }
      pod {
        id
        name
      }
      shipper {
        id
        name
        code
      }
      estimatedWarehouseReceiptDate
      shipments {
        id
        purchaseOrderRows
        createdAt
        stage
        consolidated
        bookingLoadType
        shipmentType
        milestones {
          id
          completed
          completedAt
          reference {
            title
          }
        }
        shipments {
          id
        }
        purchaseOrders {
          id
          companyReference
        }

        forwarderDetails {
          id
          hbl
          mbl
          forwarderReference
        }
        containerLoadType
        pol {
          id
          name
          timeZone
          country {
            id
            name
          }
        }
        pod {
          id
          name
          timeZone
        }
        shipmentBooking {
          id
          voyage
          vessel
          subscribed
          subscriptionStatus
          finalVessel
          finalVoyage
          initialCarrier {
            id
            name
          }
          finalCarrier {
            id
            name
          }
        }
        schedule {
          id
          finalEta
          finalEtd
          initialEta
          initialEtd
          ata
          atd
          acrd
          ecrd
          firstCrd
        }
        cargo {
          id
          volume
          containerType
          containerNumber
          createdAt
          purchaseOrderRows
          shipment {
            id
          }
        }
      }
    }
  }
`;

export const getPurchaseOrderOnId = gql`
  query getPurchaseOrderOnId($id: ID!) {
    getPurchaseOrderOnId(id: $id) {
      id
      companyReference
      status
      createdAt
      updatedAt
      description
      totalQuantity
      bookedQuantity
      shippedQuantity
      estimatedWarehouseReceiptDate
      purchaseOrderRows
      totalChannels
      condition
      responsible
      finalDestination {
        id
        code
        name
      }
      channels {
        id
        url
        name
        type
        owner {
          auth0Id
        }
        participants {
          id
          user {
            auth0Id
          }
        }
      }
      shipper {
        id
        name
        code
        email
        phone
      }
      comments {
        id
        text
        createdAt
      }
      matchedPOHeaders {
        ordered
        rowId
        supplier
        purchaseOrder
      }
      schedule {
        id
        ecrd
        acrd
        ewrd
        firstCrd
        ccrd
      }
      pol {
        id
        code
        name
      }
      pod {
        id
        code
        name
      }
      shipments {
        id
        consolidated
        name
        shipmentType
        bookingLoadType
        containerLoadType
        shippedQuantity
        purchaseOrderRows
        createdAt
        eta
        etd
        updatedAt
        stage
        operationType
        shipmentBooking {
          id
          subscribed
          subscriptionStatus
          suspiciousKey
        }
        purchaseOrders {
          id
          shipper {
            id
            name
            email
            phone
            code
          }
        }
        pol {
          id
          name
          timeZone
        }
        pod {
          id
          name
          timeZone
        }
        forwarderDetails {
          id
          forwarderReference
        }
        milestones {
          id
          reference {
            title
            important
            order
          }
          completed
          completedAt
          completedBy
          completedIn
        }
      }
      company {
        id
        name
        tier
        salesPerson {
          auth0Id
        }
        customerService {
          auth0Id
        }
      }
      forwarder {
        name
        id
      }
    }
  }
`;

export const customsFragment = gql`
  fragment customsQuery on Customs {
    id
    customsAgency
    treasuryPayment
    hasTreasuryPayment
    sag
    sagDate
    seremi
    seremiDate
    cda
    uyd
    finalVessel
    capacity
    finalAta
    containerDischarge
    containerLiberation
    gcpPayment
    agaSchedule1
    costProvisionDate
    costProvisionDateSent
    sernapesca
    sernapescaDate
    desconsolidationCapacityDate
    desconsolidationWithoutCapacityDate
    dispatch
  }
`;

export const shipmentQueryFragment = gql`
  fragment shipmentQuery on Shipment {
    id
    name
    shareToken
    shipmentType
    fromQuote
    areProductsShipped
    shippedQuantity
    incoterm
    price
    overwritedFields
    containerLoadType
    bookedQuantity
    purchaseOrderRows
    matchedPoHeaders
    bookingLoadType
    reservedContainers
    awaitingConfirmation
    stage
    totalChannels
    operationType
    preAlertSentAt
    aerealConsolidated
    lastEmailRecievers
    lastCcRecievers
    passedToFinanceAt
    actualPassedToOperationsAt
    emailSentDates
    invoiceEmittedAt
    translogId
    rejectedByOperations
    rejectReason
    externalForwarder
    proration {
      id
      information
      shipments {
        id
        passedToOperationsAt
        forwarderDetails {
          id
          forwarderReference
        }
        cargo {
          id
          volume
          volumetricWeight
          weight
          containerType
          containerNumber
        }
      }
    }
    exportCustomerService {
      id
      name
    }
    customerService {
      id
      name
    }
    salesPerson {
      id
      name
    }
    createdAt
    reserveHistory
    ttnType
    consolidation {
      id
      name
      totalShipments
      packageQuantity
      cargo
      weight
      consolNumber
      loadableWeight
      shipments {
        id
        shipmentType
        cargo {
          weight
          volume
          loadedQuantity
          volumetricWeight
        }
      }
    }
    syncColoaderStatus {
      status
      coloaderName
      shipmentIntegration {
        id
        etd
        eta
        hbl
        carrierCode
        containers
        quantity
        packageType
        weight
        volume
        vessel
        voyage
      }
    }
    manifestInformation
    shipmentOperation {
      id
      operativeChecklist
      blInformation
      blMark
      manifestEmmited
      shortDescription
      deconsolidationDate
      deconsolidationRange
      preAlertReceivedAt
      preAlertSentAt
      agentInvoiceReceivedAt
      sidemarSent
      profit
      buyCharges
      saleCharges
      fullCharges
      paymentRequests
      letterPresented
      paidCheck
      financeCheckRequest
      invoiceRejectedAt
      contractName
      airwaybillEmittedDate
      customsCode
      portTerminal
      goodsType
      invoiceStatus
      specialCharges
      deliveryAddress
      transportAgency
      commodities
      notificationStatus {
        id
        status
      }
    }
    shipmentContract {
      id
      name
      contractType
    }
    transshipments {
      id
      createdAt
      legNumber
      transshipmentType
      transitTime
      flightNumber
      vessel
      eta
      etd
      atd
      ata
      pod {
        id
        code
        name
        timeZone
        location {
          id
          lat
          lng
        }
      }
      carrier {
        id
        trackingUrl
        scac
        name
        trackableBy {
          id
          bookingNumber
          containerNumber
          mbl
        }
      }
    }
    channels {
      id
      url
      name
      type
      owner {
        auth0Id
      }
      participants {
        id
        user {
          auth0Id
        }
      }
    }
    shipments {
      id
      name
      shipmentType
      bookedQuantity
      etd
      eta
      updatedAt
      bookingLoadType
      purchaseOrderRows
      price
      incoterm
      containerLoadType
      stage
      milestones {
        id
        reference {
          title
          important
          order
        }
        completed
        completedAt
        completedBy
        completedIn
      }
      pol {
        code
        id
        name
        timeZone
      }
      pod {
        code
        id
        name
        timeZone
      }
      purchaseOrders {
        id
        companyReference
        shipper {
          id
          name
          email
          code
          phone
          address
          country {
            id
            code
            name
          }
          city
          contactName
        }
      }
      company {
        id
        name
        tier
      }
      shipper {
        id
        name
        email
        code
        phone
        address
        country {
          id
          code
          name
        }
        city
        contactName
      }
      shipmentBooking {
        id
        subscribed
        shipmentTracking {
          id
          shipmentTrackingId
          containerNumber
          status
          lifecycleStatus
          latitude
          longitude
          heading
        }
      }
    }
    comments {
      id
      text
      createdAt
      createdBy {
        id
        name
      }
    }
    quote {
      id
      createdAt
      validUntil
      status
      name
      requestsForNewChanges
      routingQuote {
        id
      }
      company {
        id
        name
        taxId
        industry
        commercialAddress
        legalName
        country
        tier
        operatingOffice {
          id
          code
        }
        customerService {
          id
          name
        }
        sector {
          name
        }
        operationsRepresentative {
          id
          name
          email
          phone
        }
      }
      forwarderReference
      finalContract {
        id
        name
        freeDays
      }
      quoteRequestNew {
        id
        operationType
        transportType
        consigneeReference
        containerLoadType
        incoterm
        pickupAddress
        shipperReference
        goodsType
        isPaletized
        packageQuantity
        looseLoad
        comments
        containers
        volume
        weight
        insurance
        lclDetails
        customs
        localTransport
        direct
        repCity
        repCountry
        repName
        sameAgentOnDestination
        boxes
        commodities
        deliveryTime
        triangulation
        quoteShippers {
          id
          shipperReference
          shipper {
            id
            name
            contactName
            email
            phone
            address
          }
        }
        agent {
          id
          name
          translogId
        }
        quoteContracts {
          id
          saleCharges
          buyCharges
          selected
          fclImpoFreeDays
          contractValidity
          shipmentContract {
            id
            name
            revisionName
          }
          carrier {
            scac
            name
          }
          coloader {
            name
          }
        }
        documents {
          id
          link
          fileName
          rawFileName
          isDocumentProcessed
          shouldValidateDocument
          documentProcessData
          syncedWithNanonets
          validatedFields
          uploadedBy {
            id
            group
            name
          }
        }
        schedule {
          id
          ecrd
        }
        pols {
          code
          name
        }
        pods {
          code
          name
        }
        shipper {
          id
          address
          phone
          email
          name
          contactName
        }
        isSeaAir
        aerealConsolidated
        consolidationType
      }
      quoteRequest {
        id
        containers
        weight
        volume
        containerLoadType
        pols {
          id
          name
          code
        }
        pods {
          id
          name
          code
        }
        schedule {
          id
          ecrd
        }
        fobValue
      }
      rateTickets {
        id
        backup
        carrier {
          id
          name
          scac
        }
        charges {
          total
          detailedCharges {
            total
          }
          type
          name
        }
        transitTime
        contractName
        revisionName
        quoteId
        vessel
        voyage
        etd
        eta
        transshipments
        price
        pod {
          code
          name
        }
        pol {
          code
          name
        }
        selected
      }
      quoteRates {
        id
        backup
        saleCharges {
          id
          name
          total
          type
          detailedCharges {
            id
            total
            name
          }
        }
      }
    }
    shipper {
      id
      name
      contactName
      code
      email
      phone
      address
      taxId
      country {
        id
        name
        code
      }
      company {
        id
      }
      city
    }
    pol {
      id
      code
      name
      timeZone
      location {
        id
        lat
        lng
      }
    }
    pod {
      id
      code
      name
      timeZone
      location {
        id
        lat
        lng
      }
    }
    purchaseOrders {
      id
      companyReference
      purchaseOrderRows
      shipper {
        id
        name
        contactName
        code
        email
        phone
        address
        country {
          id
          name
          code
        }
        company {
          id
        }
        city
      }
    }
    operationsExecutive {
      id
      auth0Id
      name
    }
    company {
      id
      name
      legalName
      commercialAddress
      city
      country
      taxId
      translogId
      priority
      tier
      companySummary {
        id
        insurance
        localTransport
        customsAgency
        customsAgencyName
        localTransportName
        insuranceName
      }
      operatingOffice {
        code
      }
      salesPerson {
        name
      }
      operatingOffice {
        code
      }
      customerService {
        id
        auth0Id
        name
        email
      }
      exportCustomerService {
        id
        name
      }
      salesPerson {
        id
        auth0Id
        name
      }
    }
    shipmentBooking {
      id
      bookingNumber
      documentType
      vessel
      finalVessel
      voyage
      finalVoyage
      serviceType
      omiCode
      finalOmiCode
      finalPort {
        id
        name
        code
      }
      initialColoader {
        id
        name
      }
      initialCarrier {
        id
        scac
        name
      }
      finalColoader {
        id
        name
        translogId
      }
      finalCarrier {
        id
        trackingUrl
        scac
        name
        rank
        note
        translogId
        trackableBy {
          id
          containerNumber
          bookingNumber
          mbl
        }
      }
      suspiciousKey
      subscriptionId
      subscriptionStatus
      subscribed
      transshipments
      shipmentTracking {
        id
        shipmentTrackingId
        containerNumber
        status
        lifecycleStatus
        latitude
        longitude
        heading
      }
    }
    schedule {
      id
      finalEtd
      finalEta
      initialEtd
      initialEta
      awad
      ewad
      etd
      eta
      atd
      ata
      ewrd
      elsd
      ecrd
      awrd
      alsd
      acrd
      ccrd
      cutoff
      stacking
      estimatedPickUp
      actualPickUp
      estimatedWarehouseArrival
      actualWarehouseArrival
      awadEditedBy
    }
    reservedCargo {
      id
      weight
      volume
      freeDays
      loadedQuantity
      packageType
      containerType
      createdAt
      isDangerousCargo
    }
    cargo {
      id
      packageType
      containerNumber
      containerType
      loadedQuantity
      volumetricWeight
      sealNumber
      sidemarCode
      volume
      weight
      freeDays
      purchaseOrderRows
      hasReturned
      returnedAt
      createdAt
      isDangerousCargo
      freight
      imoReference
      dangerousCargoContactName
      dangerousCargoContactPhone
      unIdentifier
      customs {
        ...customsQuery
      }
      trackingInfo {
        id
        lat
        lng
        heading
      }
      schedule {
        id
        finalEtd
        finalEta
        initialEtd
        initialEta
        atd
        ata
      }
      milestones {
        id
        reference {
          title
          important
          order
        }
        completed
        completedAt
        completedBy
        completedIn
      }
      transshipments {
        id
        legNumber
        flightNumber
        vessel
        eta
        etd
        atd
        ata
        pod {
          id
          code
          name
          timeZone
        }
      }
    }
    forwarderDetails {
      id
      direct
      mawb
      hblCourierNumber
      hawb
      hblEmittedOn
      mblEmittedOn
      forwarderReference
      receivedPackingList
      hbl
      hblRedeemedDate
      hblReviewedDate
      hblEmittedDate
      mbl
      consignee
      hblReadyForRedeem
      agentCheck
      breakdownDate
      addressingDate
      manifestNumber
      customs {
        ...customsQuery
      }
      officeReferences {
        id
        reference
        office {
          code
        }
      }
      warehouse {
        id
        legalName
        name
        code
        shortName
      }
      forwarder {
        id
        name
      }
    }
    milestones {
      id
      reference {
        title
        important
        order
      }
      completed
      completedAt
      completedBy
      completedIn
    }
    documents {
      id
      docType
      fileName
      link
      rawFileName
      uploadedAt
      isDocumentProcessed
      shouldValidateDocument
      documentProcessData
      syncedWithNanonets
      validatedFields
    }
  }
  ${customsFragment}
`;

export const updateShipmentField = gql`
  mutation updateShipmentField(
    $id: ID!
    $field: String
    $type: String
    $stringValue: String
    $booleanValue: Boolean
    $dateValue: DateTime
    $numberValue: Float
  ) {
    updateShipmentField(
      id: $id
      field: $field
      type: $type
      stringValue: $stringValue
      booleanValue: $booleanValue
      dateValue: $dateValue
      numberValue: $numberValue
    ) {
      ...shipmentQuery
    }
  }
  ${shipmentQueryFragment}
`;
export const getShipmentQuery = gql`
  query getShipment($id: ID!) {
    getShipment(id: $id) {
      ...shipmentQuery
    }
  }
  ${shipmentQueryFragment}
`;

export const updatePurchaseOrderField = gql`
  mutation updatePurchaseOrderField(
    $id: ID!
    $field: String
    $type: String
    $stringValue: String
    $booleanValue: Boolean
    $dateValue: DateTime
  ) {
    updatePurchaseOrderField(
      id: $id
      field: $field
      type: $type
      stringValue: $stringValue
      booleanValue: $booleanValue
      dateValue: $dateValue
    ) {
      id
      finalDestination {
        id
        code
        name
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($user_id: ID!) {
    getUser(user_id: $user_id) {
      user_id
      name
      email
      nickname
    }
  }
`;

export const getUsersOnTypes = gql`
  query getUsersOnTypes($type: [String!]!) {
    getUsersOnTypes(type: $type) {
      id
      auth0Id
      name
      group
      email
      lastLogin
      apiAccess
    }
  }
`;

export const getPorts = gql`
  query getPorts($shipmentType: String) {
    getPorts(shipmentType: $shipmentType) {
      id
      code
      name
      portType
    }
  }
`;

export const listShipments = gql`
  query listShipments(
    $order: String
    $field: String
    $skip: Int
    $searchValue: String
    $companyId: ID
    $stage: String
    $consolidated: Boolean
    $shipmentType: String
    $operationTypes: [String!]
    $etaRange: [DateTime!]
    $containerLoadType: String
    $forwarderFilter: String
  ) {
    listShipments(
      order: $order
      field: $field
      skip: $skip
      searchValue: $searchValue
      companyId: $companyId
      stage: $stage
      consolidated: $consolidated
      shipmentType: $shipmentType
      operationTypes: $operationTypes
      etaRange: $etaRange
      containerLoadType: $containerLoadType
      forwarderFilter: $forwarderFilter
    ) {
      shipments {
        id
        name
        shipmentType
        bookedQuantity
        etd
        eta
        updatedAt
        bookingLoadType
        purchaseOrderRows
        incoterm
        containerLoadType
        stage
        operationType
        actualPassedToOperationsAt
        lastShipmentNoticeStatus
        operationsExecutive {
          id
          name
        }
        syncColoaderStatus {
          status
          coloaderName
          shipmentIntegration {
            id
            etd
            eta
            hbl
            carrierCode
            containers
            quantity
            weight
            volume
            vessel
            voyage
          }
        }
        shipmentBooking {
          id
          subscribed
          subscriptionStatus
          suspiciousKey
          vessel
          finalVessel
          finalCarrier {
            name
          }
          finalColoader {
            name
          }
        }
        transshipments {
          id
          transshipmentType
          eta
          etd
        }
        milestones {
          id
          reference {
            title
            important
            order
          }
          completed
          completedAt
          completedBy
          completedIn
        }
        pol {
          code
          id
          name
          timeZone
        }
        pod {
          code
          id
          name
          timeZone
        }
        forwarderDetails {
          id
          forwarderReference
          mbl
          officeReferences {
            id
            reference
            office {
              code
            }
          }
        }
        purchaseOrders {
          id
          companyReference
          shipper {
            id
            name
            email
            code
            phone
            address
            country {
              id
              code
              name
            }
            city
            contactName
          }
        }
        cargo {
          id
          loadedQuantity
          milestones {
            id
            reference {
              title
              important
              order
            }
            completed
            completedAt
            completedBy
            completedIn
          }
        }
        company {
          id
          name
          tier
          operatingOffice {
            code
          }
          customerService {
            name
          }
        }
        shipper {
          id
          name
          email
          code
          phone
          address
          country {
            id
            code
            name
          }
          city
          contactName
        }
        quote {
          id
          quoteRequestNew {
            id
            quoteContracts {
              id
              saleCharges
            }
          }
        }
      }
      totalShipments
    }
  }
`;
export const listShipmentsForOperatives = gql`
  query listShipmentsForOperatives(
    $skip: Int
    $searchValue: String
    $companyId: ID
    $shipmentType: String
    $opsAssignment: String
    $etaRange: [DateTime!]
    $sidemarStatusTag: Boolean
    $finishedOperationsTag: Boolean
    $hasBeenNotified: Boolean
    $operativeUserIdFilter: String
    $containerLoadTypeFilter: String
    $selectedMonth: DateTime
    $processed: Boolean
    $opsByFolderStatus: Boolean
    $forwarderFilter: String
  ) {
    listShipmentsForOperatives(
      skip: $skip
      searchValue: $searchValue
      companyId: $companyId
      shipmentType: $shipmentType
      opsAssignment: $opsAssignment
      etaRange: $etaRange
      sidemarStatusTag: $sidemarStatusTag
      finishedOperationsTag: $finishedOperationsTag
      hasBeenNotified: $hasBeenNotified
      operativeUserIdFilter: $operativeUserIdFilter
      containerLoadTypeFilter: $containerLoadTypeFilter
      selectedMonth: $selectedMonth
      processed: $processed
      opsByFolderStatus: $opsByFolderStatus
      forwarderFilter: $forwarderFilter
    ) {
      shipments {
        id
        name
        shipmentType
        bookedQuantity
        etd
        eta
        updatedAt
        bookingLoadType
        purchaseOrderRows
        incoterm
        containerLoadType
        stage
        operationType
        actualPassedToOperationsAt
        passedToOperationsAt
        lastShipmentNoticeStatus
        quote {
          quoteRequestNew {
            agent {
              id
              name
            }
          }
        }
        schedule {
          atd
        }
        operationsExecutive {
          name
        }
        syncColoaderStatus {
          status
          coloaderName
          shipmentIntegration {
            id
            etd
            eta
            hbl
            carrierCode
            containers
            quantity
            weight
            volume
            vessel
            voyage
          }
        }
        shipmentBooking {
          id
          subscribed
          subscriptionStatus
          suspiciousKey
          finalCarrier {
            name
          }
          finalColoader {
            name
          }
        }
        transshipments {
          id
          transshipmentType
          eta
          etd
        }
        milestones {
          id
          reference {
            title
            important
            order
          }
          completed
          completedAt
          completedBy
          completedIn
        }
        pol {
          code
          id
          name
          timeZone
        }
        pod {
          code
          id
          name
          timeZone
        }
        forwarderDetails {
          id
          forwarderReference
          mbl
          hbl
          direct
        }
        purchaseOrders {
          id
          companyReference
          shipper {
            id
            name
            email
            code
            phone
            address
            country {
              id
              code
              name
            }
            city
            contactName
          }
        }
        cargo {
          id
          loadedQuantity
        }
        company {
          id
          name
          legalName
          customerService {
            name
          }
        }
        shipmentOperation {
          invoiceEmittedAt
          notificationStatus {
            status
            id
          }
        }
        shipper {
          id
          name
          email
          code
          phone
          address
          country {
            id
            code
            name
          }
          city
          contactName
        }
      }
      totalShipments
    }
  }
`;

export const getShipmentsSummaryInformation = gql`
  query getShipmentsSummaryInformation(
    $companyId: ID
    $stage: String
    $shipmentType: String
    $operationTypes: [String!]
    $etaRange: [DateTime!]
    $containerLoadType: String
    $forwarderFilter: String
  ) {
    getShipmentsSummaryInformation(
      companyId: $companyId
      stage: $stage
      shipmentType: $shipmentType
      operationTypes: $operationTypes
      etaRange: $etaRange
      containerLoadType: $containerLoadType
      forwarderFilter: $forwarderFilter
    ) {
      totalShipments
      nullifiedShipments
      completedShipments
    }
  }
`;
export const getOperationsSummaryInformation = gql`
  query getOperationsSummaryInformation(
    $companyId: ID
    $operationTypes: [String!]
    $operativeId: ID
  ) {
    getOperationsSummaryInformation(
      companyId: $companyId
      operationTypes: $operationTypes
      operativeId: $operativeId
    ) {
      sidemarNotSent
      notEnteredOperations
      notifiedOperations
      processedOperations
    }
  }
`;

export const createShipmentMutation = gql`
  mutation createShipment(
    $shipmentType: String!
    $shipped: Boolean!
    $pol: ID!
    $pod: ID!
    $incoterm: String!
    $name: String!
    $forwarderDetails: Json
    $purchaseOrders: [Json!]
    $purchaseOrderRows: [Json!]
    $companyId: ID!
    $bookingLoadType: String
    $matchedPoHeaders: Json
    $direct: String
    $operationType: String
    $shipper: String
    $sendEmail: Boolean
    $ttnType: String
  ) {
    createShipment(
      input: {
        shipmentType: $shipmentType
        shipped: $shipped
        pol: $pol
        pod: $pod
        incoterm: $incoterm
        name: $name
        forwarderDetails: $forwarderDetails
        purchaseOrders: $purchaseOrders
        purchaseOrderRows: $purchaseOrderRows
        companyId: $companyId
        bookingLoadType: $bookingLoadType
        matchedPoHeaders: $matchedPoHeaders
        direct: $direct
        operationType: $operationType
        shipper: $shipper
        sendEmail: $sendEmail
        ttnType: $ttnType
      }
    ) {
      id
      name
      createdAt
      shipmentType
      syncColoaderStatus {
        status
        coloaderName
        shipmentIntegration {
          id
          etd
          eta
          hbl
          carrierCode
          containers
          quantity
          weight
          volume
          vessel
          voyage
        }
      }
      pol {
        id
        name
      }
      pod {
        id
        name
      }
      incoterm
      name
      bookingLoadType
      purchaseOrderRows
      bookedQuantity
      schedule {
        id
        finalEta
        finalEtd
      }
      eta
      etd
      updatedAt
    }
  }
`;
export const consolidateShipmentsMutation = gql`
  mutation consolidateShipments(
    $shipmentType: String!
    $pol: String!
    $pod: String!
    $shipments: [Json!]
    $bookingLoadType: String
  ) {
    consolidateShipments(
      input: {
        shipmentType: $shipmentType
        pol: $pol
        pod: $pod
        shipments: $shipments
        bookingLoadType: $bookingLoadType
      }
    ) {
      id
      name
      createdAt
      shipmentType
      pol {
        id
        name
      }
      pod {
        id
        name
      }
      incoterm
      name
      bookingLoadType
      purchaseOrderRows
      bookedQuantity
      schedule {
        id
        initialEta
        initialEtd
      }
      updatedAt
    }
  }
`;

export const addCommentToPo = gql`
  mutation addCommentToPurchaseOrder($id: ID!, $commentText: String!) {
    addCommentToPurchaseOrder(id: $id, commentText: $commentText) {
      id
      comments {
        id
        text
        createdAt
      }
    }
  }
`;

export const addCommentToShipment = gql`
  mutation addCommentToShipment($id: ID!, $commentText: String!) {
    addCommentToShipment(id: $id, commentText: $commentText) {
      id
      comments {
        id
        text
        createdAt
      }
    }
  }
`;
export const shippers = gql`
  query shippers($companyId: ID, $page: Int, $name: String) {
    shippers(companyId: $companyId, page: $page, name: $name) {
      quantity
      shippers {
        id
        name
        contactName
        code
        email
        taxId
        phone
        zipCode
        address
        country {
          id
          name
          code
        }
        company {
          id
        }
        city
      }
    }
  }
`;

export const getCountries = gql`
  query getCountries {
    getCountries {
      id
      name
      code
    }
  }
`;

export const createShipper = gql`
  mutation createShipper($data: Json) {
    createShipper(data: $data) {
      id
      name
      code
      contactName
      email
      zipCode
      taxId
      phone
      address
      country {
        id
        code
        name
      }
      company {
        id
      }
      city
    }
  }
`;

export const updateShipper = gql`
  mutation updateShipper($data: Json, $id: ID!) {
    updateShipper(data: $data, id: $id) {
      id
      name
      taxId
      contactName
      code
      zipCode
      email
      phone
      address
      country {
        id
        code
        name
      }
      company {
        id
      }
      city
    }
  }
`;
export const updateShipperField = gql`
  mutation updateShipperField(
    $id: ID!
    $fieldName: String
    $value: String
    $arrayValue: [String!]
  ) {
    updateShipperField(
      id: $id
      fieldName: $fieldName
      value: $value
      arrayValue: $arrayValue
    ) {
      id
      zipCode
      name
      taxId
      contactName
      code
      email
      phone
      address
      country {
        id
        code
        name
      }
      company {
        id
      }
      city
    }
  }
`;

export const deleteShipper = gql`
  mutation deleteShipper($id: ID!) {
    deleteShipper(id: $id) {
      id
      name
    }
  }
`;

export const uploadShippers = gql`
  mutation uploadShippers($parsedRows: [Json!], $companyId: ID!) {
    uploadShippers(parsedRows: $parsedRows, companyId: $companyId)
  }
`;

export const updateMilestoneCompletion = gql`
  mutation updateMilestone($milestoneId: ID!, $completedAt: String) {
    updateMilestone(milestoneId: $milestoneId, completedAt: $completedAt) {
      id
      reference {
        title
        order
        important
      }
      completedBy
      completedAt
      completed
      completedIn
    }
  }
`;
export const createOrDeleteMilestoneMutation = gql`
  mutation createOrDeleteMilestone($shipmentId: ID!, $milestoneTitle: String!) {
    createOrDeleteMilestone(
      shipmentId: $shipmentId
      milestoneTitle: $milestoneTitle
    ) {
      id
      milestones {
        reference {
          title
        }
      }
    }
  }
`;
export const createShipmentFromQuote = gql`
  mutation createShipmentFromQuote(
    $quoteId: ID!
    $companyId: ID
    $shipperId: ID!
    $excelData: Json
  ) {
    createShipmentFromQuote(
      quoteId: $quoteId
      companyId: $companyId
      shipperId: $shipperId
      excelData: $excelData
    ) {
      id
    }
  }
`;

export const getAllSubscribedShipmentsByCompanyId = gql`
  query getAllSubscribedShipmentsByCompanyId(
    $companyId: ID
    $dateRange: [DateTime!]
  ) {
    getAllSubscribedShipmentsByCompanyId(
      companyId: $companyId
      dateRange: $dateRange
    ) {
      id
      name
      shipmentType
      stage
      forwarderDetails {
        id
        forwarderReference
      }
      pol {
        id
        code
        name
        timeZone
        location {
          lat
          lng
        }
      }
      pod {
        id
        code
        name
        timeZone
        location {
          lat
          lng
        }
      }
      transshipments {
        id
        flightNumber
        vessel
      }
      shipmentBooking {
        id
        bookingNumber
        documentType
        vessel
        subscribed
        subscriptionStatus
        shipmentTracking {
          id
          shipmentTrackingId
          latitude
          longitude
          heading
        }
      }
      milestones {
        id
        reference {
          title
          important
          order
        }
        completed
        completedAt
        completedBy
        completedIn
      }
      cargo {
        id
        containerNumber
        containerType
        trackingInfo {
          id
          lat
          lng
          heading
        }
        schedule {
          id
          finalEta
          ata
        }
      }
    }
  }
`;

export const deleteShipmentContainer = gql`
  mutation deleteShipmentContainer($shipmentId: ID!, $cargoId: ID!) {
    deleteShipmentContainer(shipmentId: $shipmentId, cargoId: $cargoId) {
      id
      purchaseOrderRows
      cargo {
        id
        purchaseOrderRows
      }
    }
  }
`;
export const deleteReservedContainer = gql`
  mutation deleteReservedContainer($shipmentId: ID!, $reservedCargoId: ID!) {
    deleteReservedContainer(
      shipmentId: $shipmentId
      reservedCargoId: $reservedCargoId
    ) {
      id
      reservedCargo {
        id
      }
    }
  }
`;

export const getCarriersQuery = gql`
  query getCarriers($shipmentType: String) {
    getCarriers(shipmentType: $shipmentType) {
      id
      scac
      trackingUrl
      aereal
      twoDigitCode
      name
      trackableBy {
        id
        bookingNumber
        containerNumber
        mbl
      }
    }
  }
`;

export const getColoadersQuery = gql`
  query getColoaders {
    getColoaders {
      id
      name
    }
  }
`;

export const getRepresentantsQuery = gql`
  query getRepresentants(
    $agentId: ID
    $transportType: String
    $repCity: String
    $repName: String
    $isConsolidated: Boolean
    $creditDays: Int
  ) {
    getRepresentants(
      agentId: $agentId
      transportType: $transportType
      repCity: $repCity
      repName: $repName
      isConsolidated: $isConsolidated
      creditDays: $creditDays
    ) {
      id
      city
      country
      address
      shipmentType
      name
      position
      department
      email
      phone
      agent {
        id
        name
        isConsolidated
      }
      translogId
      creditDays
    }
  }
`;

export const createRepresentantMutation = gql`
  mutation createRepresentant(
    $phone: String
    $email: String!
    $representant: String!
    $position: String
    $city: String!
    $country: String!
    $address: String
    $shipmentType: String!
    $agentName: String!
    $isConsolidated: Boolean!
    $creditDays: Int
  ) {
    createRepresentant(
      phone: $phone
      email: $email
      representant: $representant
      position: $position
      city: $city
      country: $country
      address: $address
      shipmentType: $shipmentType
      agentName: $agentName
      isConsolidated: $isConsolidated
      creditDays: $creditDays
    ) {
      id
      city
      country
      address
      shipmentType
      name
      position
      department
      email
      phone
      agent {
        id
        name
      }
      creditDays
    }
  }
`;

export const updateRepresentantMutation = gql`
  mutation updateRepresentant($data: RepresentantUpdateInput, $id: ID!) {
    updateRepresentant(data: $data, id: $id) {
      id
      name
      city
      country
      address
      shipmentType
      position
      department
      email
      phone
      creditDays
    }
  }
`;

export const deleteRepresentantMutation = gql`
  mutation deleteRepresentant($id: ID!, $agentName: String!) {
    deleteRepresentant(id: $id, agentName: $agentName) {
      city
      address
      shipmentType
      id
      name
      country
      position
      department
      email
      phone
      agent {
        id
        name
      }
    }
  }
`;

export const fragments = {
  channel: gql`
    fragment Channel on Channel {
      id
      url
      name
      type
      owner {
        auth0Id
      }
      participants {
        id
        isHidden
        isMuted
        user {
          auth0Id
        }
      }
    }
  `,
};

export const markNotificationsMutation = gql`
  mutation markNotificationsAsRead($id: ID) {
    markNotificationsAsRead(id: $id) {
      id
      type
      typeId
      titleType
      description
      createdAt
      isRead
      hide
    }
  }
`;

export const hideNotificationsMutation = gql`
  mutation hideNotificationsMutation($id: ID) {
    hideNotificationsMutation(id: $id) {
      id
      type
      typeId
      titleType
      description
      createdAt
      isRead
      hide
    }
  }
`;

export const SHIPMENT_FRAGMENT_ON_CONSOLIDATION = gql`
  fragment shipmentInfo on Shipment {
    id
    name
    containerLoadType
    shipmentType
    customerService {
      id
      name
    }
    salesPerson {
      id
      name
    }
    quote {
      id
      quoteRequestNew {
        id
        agent {
          id
          name
        }
      }
    }
    cargo {
      id
      weight
      volume
      loadedQuantity
      volumetricWeight
    }
    company {
      id
      legalName
      tier
      customerService {
        name
      }
      salesPerson {
        id
        name
      }
    }
    operationsExecutive {
      id
      name
    }
    pol {
      name
      code
    }
    pod {
      name
      code
    }
    shipmentBooking {
      id
      vessel
    }
    forwarderDetails {
      id
      mbl
      hbl
      mawb
      hawb
      forwarderReference
    }
  }
`;

export const CREATE_OR_UPDATE_COUNTER = gql`
  mutation createOrUpdateCounter($name: String!) {
    createOrUpdateCounter(name: $name) {
      id
      name
      counter
    }
  }
`;

export const SHARED_SHIPMENT_QUERY = gql`
  query getSharedShipment($shareToken: ID!) {
    getSharedShipment(shareToken: $shareToken) {
      id
      shipmentType
      stage
      ttnType
      name
      containerLoadType
      shareToken
      milestones {
        id
        completed
        completedAt
        reference {
          id
          important
          title
          order
        }
      }
      schedule {
        id
        ata
        atd
        finalEta
        finalEtd
        cutoff
      }
      cargo {
        id
        containerNumber
        containerType
        volume
        weight
        packageType
        loadedQuantity
        freeDays
        milestones {
          id
          completed
          completedAt
          reference {
            id
            important
            title
            order
          }
        }
        transshipments {
          id
          vessel
          flightNumber
        }
        schedule {
          id
          finalEta
          ata
        }
        trackingInfo {
          id
          heading
          lat
          lng
        }
      }
      shipmentBooking {
        id
        finalVessel
        bookingNumber
        finalVoyage
        subscribed
        vessel
        voyage
        finalCarrier {
          id
          scac
          rank
          note
          name
          trackableBy {
            id
            containerNumber
            bookingNumber
            mbl
          }
        }
      }
      forwarderDetails {
        id
        forwarderReference
        mbl
      }
      shipmentOperation {
        id
        contractName
      }
      company {
        id
        operatingOffice {
          id
          code
        }
      }
      pol {
        id
        timeZone
        code
        name
        location {
          id
          lng
          lat
        }
      }
      pod {
        id
        timeZone
        code
        name
        location {
          id
          lng
          lat
        }
      }
      transshipments {
        id
        createdAt
        legNumber
        transshipmentType
        transitTime
        flightNumber
        vessel
        eta
        etd
        atd
        ata
        pod {
          id
          code
          name
          timeZone
          location {
            id
            lat
            lng
          }
        }
        carrier {
          id
          trackingUrl
          scac
          name
          trackableBy {
            id
            bookingNumber
            containerNumber
            mbl
          }
        }
      }
    }
  }
`;

export const GET_TASKS = gql`
  query getTasks(
    $skip: Int
    $limit: Int
    $stage: String
    $selectedResponsible: String
    $selectedType: [String!]
    $searchValue: String
    $calendarValue: [String!]
    $taskIds: [ID!]
  ) {
    getTasks(
      skip: $skip
      limit: $limit
      stage: $stage
      selectedResponsible: $selectedResponsible
      selectedType: $selectedType
      searchValue: $searchValue
      calendarValue: $calendarValue
      taskIds: $taskIds
    ) {
      id
      title
      type
      stage
      deadline
      isFromAPI
      purchaseOrder {
        id
        companyReference
      }
      description
      resolutionDate
      hasDeleted
      shipment {
        id
        forwarderDetails {
          id
          forwarderReference
        }
      }
      owner {
        id
        name
        auth0Id
      }
      responsibleUsers {
        id
        name
        auth0Id
      }
      comments {
        id
        text
        createdAt
        createdBy {
          id
          auth0Id
          name
        }
      }
    }
  }
`;

export const GET_SHIPMENTS_BY_USER_ROL_CONNECTED = gql`
  query getShipmentsByConnectedUserRol {
    getShipmentsByConnectedUserRol {
      forwarderDetails {
        forwarderReference
      }
    }
  }
`;
