import moment, { Moment } from "moment";
import { IntlShape } from "react-intl";
import { INpsStatistic } from "@/types/shipment";

import { ChannelTypes, MessageCustomTypes } from "../@types/channel";

import { tooltipStyle } from "./victoryTheme";

export const SALE_CHARGES = "saleCharges";
export const BUY_CHARGES = "buyCharges";
export const BUY_CAI_HANDLING_VALUE = 11000;

export const NOTE_TYPES = ["plusVat", "noVat", "vatIncluded"];
export const referenceTypes = (transportType, isLcl = false) => {
  const isOcean = transportType === OCEAN;
  const isTtn = TTN_TYPES.includes(transportType);
  const isLand = transportType === LAND || isTtn;
  const isSeaAir = transportType === SEAAIR;
  return [
    ...(isOcean || isLand
      ? [
          "bl",
          "container",
          ...(isLcl
            ? ["W/M", "lump", "pallet", "ton"]
            : [
                ...CONTAINER_TYPES_WITHOUT_GOH,
                "20GOH",
                "40GOH",
                "20OT",
                "40OT",
                "40RF",
                "teu",
              ]),
        ]
      : []),
    ...(transportType === "AIR" || isSeaAir
      ? ["hawb", "chargeableWeight"]
      : []),
  ];
};
export const referenceTranslator = {
  tonm3: "volume",
  chargeableWeightWithinRanges: "chargeableWeight",
};

export const months = moment.monthsShort();
export const days = moment.weekdays();

export const containerTypes = [
  "20ST",
  "40ST",
  "40HC",
  "40RF",
  "40NOR",
  "20GOH",
  "40GOH",
  "20OT",
  "40OT",
  "20FR",
  "40FR",
];

export const maxVolumePerContainer = {
  "20ST": 33.1,
  "40ST": 67.5,
  "40HC": 76.4,
  "40NOR": 59.3,
  "40RF": 54.8,
};

export const maxWeightPerContainer = {
  "20ST": 18000,
  "40ST": 20000,
  "40HC": 25000,
  "40NOR": 22400,
  "40RF": 32000,
};

export const teusPerContainer = {
  "20ST": 1,
  "40ST": 2,
  "40HC": 2,
  "40NOR": 2,
  "40RF": 2,
  "20GOH": 1,
  "40GOH": 2,
  "20DC": 2,
  "40DC": 2,
};

export const optimalVolumePerContainer = {
  "20ST": 0.82,
  "40ST": 0.81,
  "40HC": 0.86,
  "40NOR": 0.92,
};
export const CONTAINER_TYPES_WITHOUT_GOH = ["20ST", "40ST", "40HC", "40NOR"];

export const shipmentTypes = ["OCEAN", "AIR", "SEAAIR"];

export const incotermTypes = [
  "EXW",
  "FCA",
  "FOB",
  "CFR",
  "CIF",
  "DAP",
  "DDP",
  "DAV",
  "CIP",
  "CPT",
  "DAF",
  "DDU",
  "DEQ",
  "DES",
  "FAS",
];

export const bookingLoadTypes = ["FCL_FCL", "LCL_FCL", "LCL_LCL"];

export const [FCL_FCL, LCL_FCL, LCL_LCL] = bookingLoadTypes;

export const shipmentLoadTypes = ["LCL", "FCL"];

export const aerealLoadTypes = [
  "GENERAL_CARGO",
  "FROZEN_CARGO",
  "DANGEROUS_CARGO",
  "TEMPERATURE_CONTROLLED",
];

export const goodsTypes = ["DANGEROUS_GOODS", "GENERAL_GOODS"];

export const formats = {
  aerealShipmentCreateFile: {
    "shipmentRef*": "",
    "companyName*": "",
    poRef: "",
    sku: "",
    shippedQuantity: "",
    shippedCartons: "",
    shippedWeight: "",
    shippedVolume: "",
    "pol*": "",
    "pod*": "",
    "incoterm*": "",
    cmi: "",
    departureFlight: "",
    arrivalFlight: "",
    mawb: "",
    hawb: "",
    originAirportArrival: "",
    originAirportDeparture: "",
    destinationAirportArrival: "",
    ecrd: "",
    acrd: "",
    etd: "",
    eta: "",
    ata: "",
    atd: "",
  },
  shipmentCreateFromFile: {
    "shipmentRef*": "",
    "companyName*": "",
    poRef: "",
    sku: "",
    bookedQuantity: "",
    bookedCartons: "",
    bookedWeight: "",
    bookedVolume: "",
    shippedQuantity: "",
    shippedCartons: "",
    shippedWeight: "",
    shippedVolume: "",
    "bookingLoadType*": "",
    "pol*": "",
    "pod*": "",
    "incoterm*": "",
    cmi: "",
    bookingNumber: "",
    carrier: "",
    coloader: "",
    vessel: "",
    voyage: "",
    mbl: "",
    hbl: "",
    agentContactedDate: "",
    shipperContactedDate: "",
    bookingConfirmedDate: "",
    ecrd: "",
    acrd: "",
    etd: "",
    eta: "",
    ata: "",
    atd: "",
    "20ST": "",
    "40ST": "",
    "40HC": "",
    "40RF": "",
    "40NOR": "",
    "20GOH": "",
    "40GOH": "",
  },
  updateMultipleShipmentsFromFile: {
    "shipmentRef*": "",
    pol: "",
    pod: "",
    incoterm: "",
    cmi: "",
    bookingNumber: "",
    initialCarrier: "",
    initialColoader: "",
    finalCarrier: "",
    finalColoader: "",
    vessel: "",
    voyage: "",
    mbl: "",
    hbl: "",
    agentContactedDate: "",
    shipperContactedDate: "",
    bookingConfirmedDate: "",
    ecrd: "",
    acrd: "",
    initialEtd: "",
    initialEta: "",
    finalEtd: "",
    finalEta: "",
    ata: "",
    atd: "",
    supplier: "",
    "20ST": "",
    "40ST": "",
    "40HC": "",
    "40RF": "",
    "40NOR": "",
    "20GOH": "",
    "40GOH": "",
    shippedQuantity: "",
  },
  updateAerealShipmentsFile: {
    "shipmentRef*": "",
    pol: "",
    pod: "",
    incoterm: "",
    cmi: "",
    departureFlight: "",
    arrivalFlight: "",
    mawb: "",
    hawb: "",
    originAirportArrival: "",
    originAirportDeparture: "",
    destinationAirportArrival: "",
    ecrd: "",
    acrd: "",
    etd: "",
    eta: "",
    ata: "",
    atd: "",
    shipper: "",
    bookedQuantity: "",
    shippedQuantity: "",
  },
  shipmentPackingList: {
    po_ref: "",
    sku: "",
    quantity: "",
    cartons: "",
    weight: "",
    volume: "",
  },
  createContainerPackingList: {
    container: "",
    type: "",
    free_days: "",
    po_ref: "",
    sku: "",
    quantity: "",
    cartons: "",
    weight: "",
    volume: "",
    sealNumber: "",
  },
  updateProductsPurchaseOrders: {
    po_ref: "",
    sku: "",
    ordered: "",
  },
  updateProductsShipments: {
    shipment: "",
    po_ref: "",
    sku: "",
    booked: "",
    bookedCartons: "",
    bookedWeight: "",
    bookedVolume: "",
    shipped: "",
    shippedCartons: "",
    shippedWeight: "",
    shippedVolume: "",
  },
  shippersUploadList: {
    name: "",
    code: "",
    emails: "",
    phones: "",
    address: "",
    city: "",
    country_code: "",
    contactName: "",
  },
  independendentShipmentPackingList: {
    sku: "",
    quantity: "",
    cartons: "",
    weight: "",
    volume: "",
    extras: "",
  },
  changeConditionAdvice: {
    po_ref: "",
  },
};

export const VICTORY_TOOLTIP_PROPS = {
  cornerRadius: 0,
  pointerLength: 5,
  flyoutStyle: tooltipStyle,
};
export const GOODS_TYPES = ["GENERAL_GOODS", "DANGEROUS_GOODS"];

export const WEIGHT_UNITS = {
  KG: "kg",
};

export const VOLUME_UNITS = {
  M3: "m3",
};

export const LENGTH_UNITS = {
  CM: "cm",
  M: "m",
  IN: "in",
};
export const TRANSPORT_TYPES = {
  OCEAN: "OCEAN",
  AIR: "AIR",
  SEAAIR: "SEAAIR",
  LAND: "LAND",
  COURIER: "COURIER",
};
export const OPERATION_TYPES = {
  EXPORT: "EXPORT",
  IMPORT: "IMPORT",
};
export const LOAD_TYPES = {
  FCL: "FCL",
  LCL: "LCL",
};
export const OCEAN = "OCEAN";

export const AIR = "AIR";

export const LCL = "LCL";
export const GENERAL_CARGO = "GENERAL_CARGO";

export const FCL = "FCL";

export const OTHER = "OTHER";

export const CURRENCIES = {
  USD: "USD",
  CLP: "CLP",
  EUR: "EUR",
  GBP: "GBP",
  CHF: "CHF",
  JPY: "JPY",
  AUD: "AUD",
  PEN: "PEN",
  MXN: "MXN",
  NZD: "NZD",
};

export const { CLP, MXN, PEN, USD } = CURRENCIES;

export const NATIONAL_CURRENCIES = {
  CL: CLP,
  MX: MXN,
  PE: PEN,
};

export const TRANSSHIPMENT = "TRANSSHIPMENT";
export const DIRECT = "DIRECT";
export const SERVICE_TYPES = [TRANSSHIPMENT, DIRECT];

export const QUOTE_STATUS = {
  DRAFT: "DRAFT",
  QUOTE_RATE_DRAFT: "QUOTE_RATE_DRAFT",
  QUOTE_RATE_REJECTED: "QUOTE_RATE_REJECTED",
  COMPLETED: "COMPLETED",
  EXPIRED: "EXPIRED",
  REJECTED: "REJECTED",
  ROUTED: "ROUTED",
  ACCEPTED: "ACCEPTED",
  NULLIFIED: "NULLIFIED",
};

export const currencyPrecision = {
  CLP: 0,
  EUR: 2,
  USD: 2,
  GBP: 2,
  CHF: 2,
  JPY: 2,
  AUD: 2,
  PEN: 2,
  MXN: 2,
  NZD: 2,
  BOB: 2,
};

export const rateTicketSortFields = ["price", "etd", "eta", "transitTime"];
export const itinerariesSortFields = ["etd", "eta", "cutoff", "transitTime"];

export const contractTypes = ["fak", "nac", "tierOne", "goh", "tender"];

export const chinaBasePorts = ["CNSHA", "CNNGB", "CNYTN", "CNSHK", "CNXMN"];

export const chileanPorts = ["CLSAI", "CLVAP"];

export const portsByCountry = {
  PE: ["PECLL"],
  CL: ["CLSAI", "CLVAP"],
};

export const PAYMENT_CONDITION_TYPE = {
  PREPAID: "PREPAID",
  DOCUMENTED: "DOCUMENTED",
  CREDIT: "CREDIT",
};
export const FAK = "FAK";
const NAC = "NAC";
export const purchaseOrdersConditions = [FAK, NAC];

export const documentTypes = ["BOOKING_NUMBER", "BL_NUMBER"];

export const shipmentDocumentTypes = (
  shipmentType,
  operationType,
  operatingOffice
) => {
  const isAereal = shipmentType === AIR;
  const isSeaAir = shipmentType === SEAAIR;
  const isMaritime = shipmentType === OCEAN;
  const isExport = operationType === EXPORT;
  const isBolivianOffice = operatingOffice === BO;
  return [
    "CV",
    "NBL",
    "CI",
    ...(isExport ? ["MBL", "CB"] : []),
    ...(isAereal || isSeaAir
      ? ["HAWB", "SDA", "HAWBD", "AWB"]
      : ["HBLCC", "HBLC"]),
    ...(isSeaAir || isMaritime ? ["HBL"] : []),
    ...(isMaritime ? ["CA"] : []),
    "PL",
    "CO",
    "CSEG",
    "TC",
    "SM",
    "PF",
    "FT",
    "CFIT",
    "CSAN",
    "CANA",
    "CPAS",
    "CLO",
    "CMIC",
    "UYD",
    "CDA",
    "CSAL",
    "DI",
    "FA",
    "NBLCC",
    "NBLC",
    "PI",
    "CRT",
    "BLTTN",
    "CTSA",
    "OTHER",
    "STACKING",
    "MSDS",
    "FCO",
    "CVET",
    "FEE",
    "FAE",
    "NCE",
    "NDE",
    "FOM",
    "CRM",
    "CTP",
    "BL",
    "CFU",
    "LOTE",
    "CECAL",
    "BNBL",
    "BNBLC",
    "BNBLCC",
    "FCR",
    "AWB",
    "HBLD",
    "WR",
    "CPT",
    "DDN",
    "DDT",
    "GR",
    "ADL",
    "MBLT",
    "MZ",
    "LIQ",
    "NDC",
    "FC",
    "LDP",
    "FMP",
    "SEN",
    "TRA",
    "PIM",
    "DAM",
    "PP",
    "HP",
    "SP",
    "MPA",
    "FS",
    "DN",
    "MCSC",
    "CDT",
    "LIQUI",
    "DIN",
    "PED",
    "CPORTE",
    "AMS",
    "TI",
    "TTN",
    ...(isBolivianOffice
      ? [
          "CDF",
          "FACT",
          "CDI",
          "ACN",
          "FNA",
          "FNE",
          "FTL",
          "MA",
          "MAC",
          "MBLCC",
          "MBLD",
          "MBLV",
          "MCA",
          "MCPS",
          "MCV",
          "MIC",
          "MPAC",
          "MPAF",
          "MPAI",
          "SI",
          "TA",
          "TAR",
          "TCO",
          "CLD",
          "EIR",
          "BK",
          "ND",
        ]
      : []),
  ];
};

export const quarterDivisionRange = [0, 0.25, 0.5, 0.75, 1];
export const delayedDays = (intl) => [
  intl.formatMessage({ id: "onTime" }),
  "1 - 5",
  "6 - 10",
  "11 - 15",
  "16 - 20",
];

export type ChartProps = {
  companyId: string;
  dateRange: Moment[];
  ports: string[];
};

export const casaideasFilterTypes = [
  "createdAt",
  "etd",
  "eta",
  "negotiatedEtd",
  "season",
  "poReference",
  "updatedAt",
];

export const silfaFilterTypes = ["etd", "eta"];
export const packageTypes = [
  "PALLETS",
  "CARTONS",
  "BOXES",
  "PACKAGES",
  "CRATES",
];
export const CHANNEL_TYPES: { [key: string]: ChannelTypes } = {
  MAIN: "MAIN_CHANNEL",
  SECONDARY: "SECONDARY_CHANNEL",
};

export const MESSAGES_TYPES: {
  [key in MessageCustomTypes]: MessageCustomTypes;
} = {
  MESSAGE: "MESSAGE",
  MILESTONE: "MILESTONE",
  DOCUMENT: "DOCUMENT",
};

export const customReportOptions = [
  "purchaseOrders",
  "shipments",
  "sku",
  "containers",
];
export const customReportFilters = ["createdAt", "etd", "eta"];
export const shipmentTypeIcons = {
  OCEAN: "icon_02maritimo",
  AIR: "icon_01_aereo",
  SEAAIR: "sea-air",
  COURIER: "volante",
  CUSTOM_AGENCY: "volante",
  CARGO_INSURANCE: "volante",
  LOCAL_TRANSPORT: "volante",
};
export const subscriptionStatusInfo = {
  0: { titleId: "newSubscription", iconColor: "#faad14" },
  1: { titleId: "activeSubscription", iconColor: "#52c41a" },
  2: { titleId: "completedSubscription", iconColor: "#1890ff" },
  4: { titleId: "invalidSubscription", iconColor: "#f5222d" },
  error: { titleId: "subscriptionError", iconColor: "#f5222d" },
  noSubscription: { titleId: "noSubscription", iconColor: "#bfbfbf" },
  warn: { titleId: "probablyBadWritten", iconColor: "#FFEFD5	" },
};

export const silfaCarrierFreeDays = {
  "KLOG.CO": {
    ONEY: {
      default: 21,
    },
    EVRG: {
      default: 30,
    },
    SUDU: {
      NOR: 15,
      default: 30,
    },
  },
  EFL: {
    CMA: {
      default: 21,
    },
    HAPAG: {
      default: 15,
    },
  },
  "SPARX LOGISTICS CHILE": {
    HC: 21,
    NOR: 14,
  },
};

export const sanAntonioWarehouse = "SAAM";
export const PLUSCARGO = "PLUSCARGO";
export const CRAFT = "CRAFT";
export const NO_COMPANY = "NO COMPANY";

export const PATHS_WITH_COMPANY_INFO = [
  "/shipments/[id]",
  "/purchaseOrders/[id]",
  "/companies/[id]",
];
export const DANGEROUS_CARGO = "DANGEROUS_GOODS";
export const EXW = "EXW";
export const FOB = "FOB";
export const FCA = "FCA";
export const clpConcepts = ["DESCONSOLIDACION"];
export const LAND = "LAND";
export const IMPORT = "IMPORT";
export const EXPORT = "EXPORT";
export const COURIER = "COURIER";
export const PO = "PO";

export const reasonsForQuoteRejection = [
  "MISSING_INFO",
  "NO_CHARGES",
  "MISSING_DOCS",
  "CANNOT_CONTACT_ORIGIN",
];

export const quoteStatuses = (isHistoric) => [
  ...(isHistoric
    ? ["COMPLETED", "EXPIRED", "NULLIFIED"]
    : [
        "INITIALIZED",
        "DRAFT",
        "SENT_TO_CLIENT",
        "ACCEPTED",
        "REJECTED",
        "ROUTED",
      ]),
];

export const lclFobOrExwConcepts = (isExw) => [
  {
    concept: "GASTOS EN ORIGEN",
    subConcepts: [
      "PICK UP / INLAND FREIGHT",
      "GASTOS FOB",
      ...(isExw ? ["HANDLING", "B/L FEE"] : []),
    ],
  },
  { concept: "FLETE", subConcepts: ["FLETE", "B/L FEE", "HANDLING"] },
  { concept: "DESCONSOLIDACION" },
  { concept: "OTROS GASTOS EN DESTINO" },
  { concept: "OTROS GASTOS EN ORIGEN" },
];
export const airFobConcepts = [
  {
    concept: "GASTOS EN ORIGEN",
    subConcepts: [
      "PROFIT SHARE AGENTE",
      "AIRPORT TRANSFER",
      "FLETE",
      "HANDLING",
      "SECURITY SURCHARGE",
      "RECARGO COMBUSTIBLE / FUEL SURCHARGE",
      "AWA",
    ],
  },
  { concept: "DESCONSOLIDACION" },
  { concept: "OTROS GASTOS EN ORIGEN" },
];
export const airExwConcepts = [
  {
    concept: "GASTOS EN ORIGEN",
    subConcepts: [
      "PICK UP / INLAND FREIGHT",
      "GASTOS FOB",
      "AIRPORT TRANSFER",
      "FLETE",
      "HANDLING",
      "AWA",
      "CUSTOMS CLEARANCE",
    ],
  },
  { concept: "DESCONSOLIDACION" },
  { concept: "OTROS GASTOS EN ORIGEN" },
];
export const currencyNotes = {
  USD: "noVat",
  EUR: "noVat",
  CLP: "plusVat",
};
export const avaivableItineraryCarriers = [
  "CMDU",
  "EVRG",
  "HLCU",
  "MAEU",
  "MSCU",
  "ONEY",
  "SUDU",
  "YMLU",
];
export const COORDINATING = "COORDINATING";
export const IN_ORIGIN = "IN_ORIGIN";
export const IN_TRANSIT = "IN_TRANSIT";
export const ON_DESTINATION = "ON_DESTINATION";
export const NULLIFIED = "NULLIFIED";
export const APPROVED = "APPROVED";
export const GENERATED = "GENERATED";
export const PHANTOM = "PHANTOM";
export const FINISHED = "FINISHED";
export const CNEE_HBL = {
  CL: `KLOG.CO S.A.
Address: Av. Apoquindo 4499, piso 13
Las Condes - Santiago - Chile
RUT: 76.067.255-6
TEL: 52 2 2617 0050
operaciones@klog.co`,
  PE: `KLOG.CO SAC
RUC: 20607210242
+51945384009
Calle Contralmirante Montero 411 oficina 411A, Magdalena del Mar , Lima - Peru
operacionesperu@klog.co
`,
  MX: `KLOG.CO Transportes Mexicana SA de CV
RFC: KTM211108L38
Dirección: Blvd. Palmas Hills Mz 1 Lt1,2 Piso 10 Suite/Oficina 102 Valle De Las Palmas Huixquilucan, Estado De México
Correo: paula@klog.co
Teléfono: +52 55 49404328`,
  BO: `Razón social: Kloglogistics S.R.L.
Dirección: Torre Alas, Calle Las Ramblas, Equipetrol Norte, Piso 3, Santa Cruz de la Sierra, Bolivia.
Celular: +591 67974567
Correo: alejandra@klog.co
NIT: 419575028
`,
};
export const NOTIFY_MBL = {
  CL: "KLOG.CO S.A.",
  PE: "KLOG.CO SAC",
  MX: "KLOG.CO Transportes Mexicana SA de CV",
  BO: "Kloglogistics S.R.L.",
};
export const DESCRIPTION_OF_BL = "AS PER SHIPPER'S INSTRUCTIONS";
export const OCEAN_FREIGHT_MBL = "COLLECT";
export const OCEAN_FREIGHT_HBL = "COLLECT";
export const PLACE_OF_EMISSION_OF_MBL = "ISSUED AT DESTINATION";
export const ADMIN = "admin";
export const USER = "user";
export const OPERATIVE = "operative";
export const CUSTOMER_SERVICE = "customerService";
export const TEUAI_ADMIN = "teuaiAdmin";
export const SALESPERSON_ADMIN = "salesPersonAdmin";
export const CUSTOMER_SERVICE_ADMIN = "customerServiceAdmin";
export const COMPANY_ADMIN = "companyAdmin";
export const OPERATIVE_ADMIN = "operativeAdmin";
export const FINANCE_ADMIN = "financeAdmin";
export const TEUAI = "teuai";
export const SALESPERSON = "salesPerson";
export const COMPANY = "company";
export const FINANCE = "finance";
export const MARKETING = "marketing";
export const REVIEWER = "reviewer";
export const CUSTOMS_AGENCY = "customsAgency";
export const COLOADER = "coloader";
export const MAIN_ROLES_NAMES = [
  SALESPERSON,
  TEUAI,
  CUSTOMER_SERVICE,
  COMPANY,
  FINANCE,
  OPERATIVE,
  REVIEWER,
];

export const FIXED_USER_ROLES = [
  TEUAI,
  SALESPERSON,
  CUSTOMER_SERVICE,
  COMPANY,
  OPERATIVE,
  FINANCE,
  TEUAI_ADMIN,
  SALESPERSON_ADMIN,
  CUSTOMER_SERVICE_ADMIN,
  COMPANY_ADMIN,
  OPERATIVE_ADMIN,
  FINANCE_ADMIN,
  MARKETING,
  CUSTOMS_AGENCY,
  COLOADER,
];

export const USERS_GROUP_EMPLOYEES = [
  FINANCE,
  FINANCE_ADMIN,
  SALESPERSON,
  SALESPERSON_ADMIN,
  CUSTOMER_SERVICE,
  CUSTOMER_SERVICE_ADMIN,
  OPERATIVE,
  OPERATIVE_ADMIN,
];

export const localProfitShareIds = {
  OCEANIMPORT: "ckfy3rjau0a9m0737ou192n4a",
  AIRIMPORT: "ckfy3rjov0abs0737mo9hcwxs",
  OCEANEXPORT: "ckfy3rjtf0adb0737of5q6q8d",
  AIREXPORT: "ckfy3rjyq0aeh07374k6o764m",
};
export const prodProfitShareIds = {
  OCEANIMPORT: "cke0d5t3p2cc90a78yhh0fbph",
  AIRIMPORT: "cke0d5t492ceh0a78byy10xo0",
  OCEANEXPORT: "cke0d5t4l2cfu0a787oti3tsr",
  AIREXPORT: "cke0d5t4z2ch80a78evtcorvk",
};
export const localNeutralId = { AIRIMPORT: "ckgtilwvz0b1n07372yv6iwy9" };
export const prodNeutralId = { AIRIMPORT: "ckgwr9d3fnlgn0934g3v30xcn" };
export const localDeconsolidationId = {
  AIRIMPORT: "ckfy3rjjt0ab80737b2etxfnr",
};
export const prodDeconsolidationId = { AIRIMPORT: "cke0d5t442cdw0a780cl0sqw6" };
export const COMPANY_SIZE = [
  "NO_RECORD",
  "MICRO_ENTERPRISE",
  "SMALL_ENTERPRISE",
  "MEDIUM_ENTERPRISE",
  "LARGE_ENTERPRISE",
];

export const LARGE_ENTERPRISE = "LARGE_ENTERPRISE";
export const PRODUCT_TYPES = [
  "AUTOMOTIVE",
  "RETAIL_FOOD",
  "RETAIL_HOME",
  "RETAIL_TECH",
  "RETAIL_FASHION",
  "RETAIL_OTHER",
  "CONSTRUCTION",
  "CHEMISTRY",
  "WHOLESALERS",
  "PHARMACEUTICAL",
  "TELECOMMUNICATIONS",
  "AGRICULTURE",
  "MINING",
  "MANUFACTURE",
  "HEALTH",
  "MACHINERY",
  "FOOD",
  "ELECTRONICS",
  "INDUSTRIAL",
  "TEXTILE",
  "OTHER",
];
export const VERIFIED = "VERIFIED";
export const PAYMENT_CONDITION_OBJECTION = "PAYMENT_CONDITION_OBJECTION";
const shipmentMilestones = ["CONTACT_ORIGIN_AGENT", "CONTACT_SUPPLIER"];
export const [CONTACT_ORIGIN_AGENT, CONTACT_SUPPLIER] = shipmentMilestones;
export const SEAAIR = "SEAAIR";
export const DEFAULT_MARK = "N/M";
export const SIDEMAR_SERVICE_TYPES = [
  "EMPTY",
  "FCL/FCL",
  "LCL/LCL",
  "BB",
  "FCL/LCL",
  "FCL/BB",
  "LCL/BB",
  "FCL/LCL/BB",
  "CY/CY",
  "CFS/CFS",
  "CFS/CY",
  "CY/CFS",
  "CY/DOOR",
  "DOOR/CY",
  "LCL/FCL",
  "DOOR/DOOR",
  "RoRo",
  "LICTVACIO",
];

export const packageLumpTypes = [
  "POLVO",
  "GRANOS",
  "NODULOS",
  "LIQUIDO",
  "GAS",
  "PIEZA",
  "TUBO",
  "CILINDRO",
  "ROLLO",
  "BARRA",
  "LINGOTE",
  "TRONCO",
  "BLOQUE",
  "ROLLIZO",
  "CAJON",
  "CAJA_DE_CARTON",
  "FARDO",
  "BAUL",
  "COFRE",
  "ARMAZON",
  "BANDEJA",
  "CAJAMADERA",
  "CAJALATA",
  "BOTELLAGAS",
  "BOTELLA",
  "JAULA",
  "BIDON",
  "JABA",
  "CESTA",
  "BARRILETE",
  "TONEL",
  "PIPA",
  "CAJANOESP",
  "JARRO",
  "FRASCO",
  "DAMAJUANA",
  "BARRIL",
  "TAMBOR",
  "CUNETE",
  "TARRO",
  "CUBO",
  "PAQUETE",
  "SACO",
  "MALETA",
  "BOLSA",
  "BALA",
  "RED",
  "SOBRE",
  "CONT20",
  "CONT40",
  "REEFER20",
  "REEFER40",
  "ESTANQUE",
  "CONTNOESP",
  "PALLETS",
  "TABLERO",
  "LAMINA",
  "CARRETE",
  "AUTOMOTOR",
  "ATAUD",
  "MAQUINARIA",
  "PLANCHA",
  "ATADO",
  "BOBINA",
  "BULTONOESP",
  "SIN_BULTO",
  "S_EMBALAR",
];

export const LIABILITY_FORMATS = [
  "COSU",
  "EVRG",
  "ONEY",
  "HDMU",
  "MAEU",
  "CMDU",
];

export const POWER_OF_ATTORNEY_FORMATS = [
  "MAEU",
  "CMDU",
  "SUDU",
  "HLCU",
  "SEAU",
];
export const LIABILITY_LETTER = "Carta de Responsabilidad";
export const POWER_OF_ATTORNEY = "Carta de Poder";

export const TEAMS = [
  "salesTeam",
  "engineeringTeam",
  "customerTeam",
  "operationsTeam",
  "financeTeam",
];
export const STACKING = "STACKING";
export const OCEAN_REPROGRAMMING_REASONS = [
  "portCongestion",
  "consolidatorNotArrived",
  "landFallOmission",
  "delayTransshipmentVessel",
];
export const AIR_REPROGRAMMING_REASONS = [
  "vesselAirDelay",
  "airportCongestion",
  "climateProblems",
  "relocationOfCargo",
  "delayTransshipmentFlight",
];
export const CHILE = "CHILE";
export const FIRST_NOTIFICATION = "PRIMERA NOTIFICACION";
export const AIR_DEPARTURE_TRANSLATION = "salida";
export const OCEAN_DEPARTURE_TRANSLATION = "zarpe";
export const AERIAL_TRANSLATION = "aéreo";
export const MARITIME_TRANSLATION = "marítimo";
export const NOT_DIRECT = "notDirect";
export const WITH_COLOADER = "withColoader";

export const EMPTY_PICKUP = "EMPTY_PICKUP";
export const ORIGIN_PICKUP = "ORIGIN_PICKUP";
export const POL_ARRIVAL = "POL_ARRIVAL";
export const POL_LOADED = "POL_LOADED";
export const POL_VSLDEPARTURE = "POL_VSLDEPARTURE";

export const ORIGIN_MILESTONES = [
  EMPTY_PICKUP,
  ORIGIN_PICKUP,
  POL_ARRIVAL,
  POL_LOADED,
  POL_VSLDEPARTURE,
];

export const POD_VSLARRIVAL = "POD_VSLARRIVAL";
export const POD_DISCHARGE = "POD_DISCHARGE";
export const POD_DEPARTURE = "POD_DEPARTURE";
export const DLV_DELIVERY = "DLV_DELIVERY";
export const EMPTY_RETURN = "EMPTY_RETURN";
export const BOOKING_CONFIRMED = "BOOKING_CONFIRMED";

export const DESTINATION_MILESTONES = [
  POD_VSLARRIVAL,
  POD_DISCHARGE,
  POD_DEPARTURE,
  DLV_DELIVERY,
  EMPTY_RETURN,
];

export const FIRST_TRANSIT_TO_DESTINATION_BO =
  "FIRST_TRANSIT_TO_DESTINATION_BO";
export const BORDER_PROCEDURE_IMPO_BO = "BORDER_PROCEDURE_IMPO_BO";

export const UTC = "UTC";

export const ORIGIN_TZ = "originTz";
export const DESTINATION_TZ = "destinationTz";

export const originDates = ["etd", "atd"];
export const destinationDates = ["eta", "ata"];

export const OCEAN_INSIGHT_RANKS = {
  green: "veryGood",
  yellow: "good",
  red: "regular",
};
export const TTN = "TTN";
export const CONTAINER_LOAD_TYPES = [FCL, LCL];

export const MAIL_FIELDS = [
  { value: "mailContacts", label: "main" },
  { label: "cc", value: "cc" },
];

export const PHONES_BY_COUNTRY = {
  CL: ["+56 2 2617 0050", "+56 9 5659 5548"],
  PE: ["+51 952 153 297"],
  MX: ["+52 55 4940 4328"],
  BO: ["+591 (01) 680 5927"],
};
export const EMAIL_REGEX =
  /^(([a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NPS_DAYS_CONTROL = [
  { label: "today", days: 0 },
  { label: "yesterday", days: 1 },
  { label: "7d", days: 7 },
  { label: "30d", days: 30 },
  { label: "3m", days: 90 },
];

export const NPS_COLUMNS = (intl: IntlShape) => [
  {
    title: intl.formatMessage({ id: "company" }),
    dataIndex: "company",
    key: "company",
  },
  {
    title: intl.formatMessage({ id: "date" }),
    dataIndex: "answeredAt",
    key: "answeredAt",
    sorter: true,
  },
  {
    title: intl.formatMessage({ id: "score" }),
    dataIndex: "rate",
    key: "rate",
    sorter: true,
  },
];

export const NPS_STATS = (
  {
    average = 0,
    detractors = 0,
    passives = 0,
    promoters = 0,
    ratedNpsQuantity = 0,
  } = {} as INpsStatistic
) => [
  {
    title: "average",
    value: average,
    precision: 1,
  },
  {
    title: "detractors",
    value: detractors,
    precision: 0,
    rateRange: [0, 6],
    clickable: true,
  },
  {
    title: "passives",
    value: passives,
    precision: 0,
    rateRange: [7, 8],
    clickable: true,
  },
  {
    title: "promoters",
    value: promoters,
    precision: 0,
    rateRange: [9, 10],
    clickable: true,
  },
  {
    title: "ratedNpsQuantity",
    value: ratedNpsQuantity,
    precision: 0,
  },
];
export const INVOICE_REJECTION_REASONS = [
  "Profit Bajo",
  "Solicitudes de Pago",
  "Diferencia entre invoice y HP",
  "Sin moneda",
  "Falta seguro",
  "Faltante",
  "Otros",
];
export const LOW_PROFIT_REASONS = [
  "Error tarifa/Corregido por vendedor",
  "Igualar la competencia",
  "Error ejecutivo",
  "Volumen de carga",
];
export const PENDING = "PENDING";
export const IN_PROGRESS = "IN_PROGRESS";
export const COMPLETED = "COMPLETED";
export const REJECTED = "REJECTED";
export const ACCEPTED = "ACCEPTED";
export const MISSING = "Faltante";
export const SENT_TO_CLIENT = "SENT_TO_CLIENT";
export const DRAFT = "DRAFT";
export const INITIALIZED = "INITIALIZED";
export const RESUME = "RESUME";
export const LOW_PROFIT = "Profit Bajo";
export const OTHERS = "Otros";
export const PAYMENT_REQUEST = "Solicitudes de Pago";
export const DIFFERENCE_BETWEEN_INVOICE_HP = "Diferencia entre invoice y HP";
export const MISSING_CURRENCY = "Sin moneda";
export const MISSING_INSURANCE = "Falta seguro";
export const MAIL_NOT_SENT = "Correo no enviado";
export const ANTHONY = "Anthony";
export const ANTHONY_MAIL = "anthony@klog.co";
export const MARIANELA = "Marianela";
export const MARIANELA_MAIL = "marianela@klog.co";
export const FERNANDOV = "Fernando";
export const FERNANDOV_MAIL = "fernando@klog.co";
export const MATI = "Matias Rivera";
export const MATI_MAIL = "matias.rivera@klog.co";
export const CUSTOMER_SERVICE_TRADUCTION = "Customer Service";
export const OPERATIONS_TRADUCTION = "Operaciones";
export const CHARGE_TYPES = ["origin", "destination", "freight"];
export const COMPANY_TABLE_FILTERED_GROUPS = [SALESPERSON, CUSTOMER_SERVICE];
export const INSURANCE_AGENCIES = [
  { id: "0", name: "Orion" },
  { id: "1", name: "OTHER" },
];
export const CUSTOMS_AGENCIES = [
  { id: "0", name: "Menares" },
  { id: "1", name: "Hugo Ibañez" },
  { id: "2", name: "Claudio Pollmann" },
  { id: "3", name: "OTHER" },
];
export const TRANSPORT_AGENCIES = [
  { id: "0", name: "Cargotracking SPA" },
  { id: "1", name: "OTHER" },
];

export const companyTiers = [
  {
    name: "XS",
    value: "XS",
  },
  {
    name: "S",
    value: "S",
  },
  {
    name: "M",
    value: "M",
  },
  {
    name: "L",
    value: "L",
  },
];
export const ALERT = "alert";
export const REMAINING = "remaining";
export const TO_SHOW = "toShow";
export const SHIPMENT = "shipment";
export const groupTypesWithoutCompany = [
  "customerService",
  "salesPerson",
  "teuai",
  "operative",
  "finance",
];
export const WITH_CARRIER = "withCarrier";
export const KLOG = "KLOG.CO";
export const COMMON = "common";

export const NOTIFY_CHANGES_LIST = (intl: IntlShape) => [
  {
    value: "costAndSaleChanges",
    label: intl.formatMessage({ id: "costAndSaleChanges" }),
  },
  {
    value: "cargoDetails",
    label: intl.formatMessage({ id: "cargoDetails" }),
  },
];

export const REASONS_FOR_INSTRUCTION_NOTIFICATION = (intl: IntlShape) => [
  // {
  //   value: "NEW_ORIGIN_INFO",
  //   label: intl.formatMessage({ id: "NEW_ORIGIN_INFO" }),
  // },
  {
    value: "CHARGES_NEEDED",
    label: intl.formatMessage({ id: "CHARGES_NEEDED" }),
  },
];
export const OCEAN_INSIGHT_EVENT_PUSH = "oceanInsightEventPush";
export const NEW = "new";
export const EXISTING = "existing";
export const DATE = "date";
export const INITIAL_COLOADER = "initialColoader";
export const INITIAL_CARRIER = "initialCarrier";
export const BOOKING_LOAD_TYPE = "bookingLoadType";

export const SENIORITY_LIST = [
  "JUNIOR",
  "ASSISTANT",
  "EXECUTIVE",
  "KEY_ACCOUNT_SPECIALIST",
];

export const CS_OPERATION_AMOUNT = {
  JUNIOR: 65,
  ASSISTANT: 35,
  EXECUTIVE: 80,
  KEY_ACCOUNT_SPECIALIST: 100,
};
export const PE = "PE";
export const BO = "BO";
export const MX = "MX";
export const PHONE_REGEX = /^[0-9\+\-\s\(\)]+$/;
export const TAX_ID_CL_REGEX = /^[0-9]+[-|‐]{1}[0-9kK]{1}$/;
export const ONLY_LETTERS_REGEX = /^[A-Za-z\s]+$/;
export const ONLY_NUMBERS = /^[0-9]+$/;
export const MAX_LENGTH_FOUR = /^.{1,4}$/;
export const DEFAULT_REGEX =
  /^[A-Za-z0-9\sñÑ]+(?:[\.\-_\&\s\(\)][A-Za-z0-9\s,]+)*\.?\)?$/;
export const ADDRESS_REGEX =
  /^[A-Za-z0-9\sñÑ\*\/\'_\-"]+(?:[\.\-_\&\s\(\)][A-Za-z0-9\s,]+)*\.?\)?$/;
export const LEGACY = "LEGACY";
export const CONSOLIDATION_NUMBER_PREFIX = "CON";
export const BANNED_CARRIERS_TO_TRACK = [
  "OOLU",
  "HDMU",
  "MWHL",
  "ZIMU",
  "SEAU",
];
export const BANNED_CARRIERS_TO_TRACK_FOR_TEUAIADMIN_USER = ["MWHL", "ZIMU"];

export const PHONES = "phones";
export const EMAILS = "emails";
export const MTS_SUBCLIENTS = [
  "ckv11mcsb0000p51bm2n3dmzd",
  "ckv11mg8z0020p51bfqlaymig",
  "ckv11mi9j0042p51bqje6i7rk",
  "ckv11mjxr0062p51bye96z3mn",
  "ckv11mlrj0082p51bn6yatxru",
  "ckv11mn940100p51b9ww6ecs6",
  "ckv11mpgp0124p51bqcv4yt7i",
  "ckv11mr8c0144p51blew131dh",
  "ckv11mskj0160p51bjgs5agnp",
  "ckv11mua10180p51b1p56v4hw",
  "ckv11mw1f0200p51b70rl1pel",
  "ckv11mxxa0222p51bj4pct7ur",
  "ckv11mz7j0238p51b126l2asa",
  "ckv11n10n0258p51b36ynsq35",
  "ckv11n2nv0276p51bskvdjw0q",
  "ckv11n3y80292p51bykpmywje",
  "ckv11n5ba0308p51b8rf94m0e",
  "ckv11n76z0330p51byci5engo",
  "ckv11n8vv0350p51btutou7mf",
  "ckv11nagx0368p51bydhptjxt",
  "ckv11nbrv0384p51buhgtbzx6",
  "ckv11ndvf0408p51bkjfw8t0q",
  "ckv11njdp0466p51buq40umy0",
  "ckv11nfo60426p51b7sp0xxcw",
  "ckv11nhig0446p51beh1ixexl",
  "ckv11nkvs0484p51blwdesym6",
  "ckv11nmdh0502p51bs68w0ju0",
  "ckv11no880522p51barv8mssr",
  "ckv11nrpz0562p51b3cxaf5w3",
  "ckv11ntco0580p51b2z4uawzx",
  "ckv11nun10596p51bujmfbodv",
  "ckv11nwpz0620p51bkytvrebi",
  "ckv11nyak0638p51bnlufbo8s",
  "ckv11o0770660p51b071qbb6j",
  "ckv11o1ol0678p51bl4ituasa",
  "ckv11o3ya0702p51bcm49j0pv",
  "ckv11o5tu0724p51badu875wx",
  "ckv11o7am0740p51b9pr0gaj0",
  "ckv11o8zb0760p51bhlhy2cv3",
  "ckv11oawi0782p51b2ja7rgyk",
  "ckv11ocrl0802p51bpxq6enbi",
  "ckv11oe2a0818p51blo8kdcrm",
  "ckv11ofvd0838p51byhox0kun",
  "ckv11ojgw0878p51b2qkose6m",
  "ckv11ohn60858p51bad4xjmn2",
  "ckv11oky90896p51bpeykorvw",
  "ckv11omwt0918p51bh8yzlzyb",
  "ckv11oo7k0934p51birgaoc33",
  "ckv11ophp0950p51bevq8j5dy",
  "ckv11or2q0968p51bw7f45lhe",
  "ckv11ot920992p51b73axv9gw",
  "ckv11ovay1014p51bvkw8z3vn",
  "ckv11ox071034p51bmb6s9wx4",
  "ckv11oypq1054p51b2vh3ob42",
  "ckv11p0q81076p51bwsgbx1sf",
  "ckv11p2241092p51b32h4k9xo",
  "ckv11p44k1116p51bvvzmt0av",
  "ckv11p6dt1140p51bsvjn3za4",
  "ckv11p89p1162p51bbbbijz09",
  "ckv11p9wc1180p51bpcn7yk26",
  "cko4p4kk607180ula80a2ga52",
];
export const LOCAL_TRANSPORT = "LOCAL_TRANSPORT";
export const CARGO_INSURANCE = "CARGO_INSURANCE";
export const CUSTOM_AGENCY = "CUSTOM_AGENCY";
export const TTN_TYPES = [CUSTOM_AGENCY, LOCAL_TRANSPORT, CARGO_INSURANCE];
export const SPECIAL = "SPECIAL";
export const ALL_TRANSPORT_AGENCIES = ["Cargotracking SPA"];
export const CONTAINER_LOAD_TYPE = "containerLoadType";
export const DEFAULT_VOLUME_VALUES = { value: "", unit: "m3" };
export const DEFAULT_WEIGHT_VALUES = { value: "", unit: "kg" };
export const INSURANCE = "insurance";
export const REAL_CUSTOMS_TAXES = "realCustomsTaxes";
export const THEORETICAL_CUSTOMS_TAXES = "theoreticalCustomsTaxes";
export const TAX = "TAX";
export const REAL_CUSTOMS_AGENT_FEE = "realCustomsAgentFee";
export const CUSTOMS = "customs";
export const THEORETICAL_CUSTOMS_AGENT_FEE = "theoreticalCustomsAgentFee";
export const INTERNATIONAL_CHARGE = "INTERNATIONAL_CHARGE";
export const LOCAL_CHARGE = "LOCAL_CHARGE";
export const BACKUP = "backup";
export const MAIN = "main";
export const ADDITIONAL_SERVICE = "ADDITIONAL_SERVICE";
export const MARITIME = "maritime";
export const CRD = "CRD";

export const CHILEAN_TAX = "chileanTax";
export const SANTIAGO_AIRPORT_CODE = "SCL";

export const QUOTING_SEARCHER_AIR_POL_CODES = [
  "MAD",
  "AMS",
  "FRA",
  "MIA",
  "PVG",
  "SZX",
  "HKG",
  "WUH",
];

export const PAYMENT_METHODS = {
  WIRE_TRANSFER: "WIRE_TRANSFER",
  CREDIT_CARD: "CREDIT_CARD",
};

export const CL = "CL";
export const OFFICE_CODES = {
  CL: "CL",
  PE: "PE",
  MX: "MX",
  BO: "BO",
};

export const PHONE_PREFIX = {
  CL: "+56",
  PE: "+51",
  MX: "+52",
  BO: "+591",
};
export const ENDPOINTS = {
  GET: {
    containerTypes: "/containerTypes",
    carriers: "/carriers",
    shipment: "/shipment",
    coloaders: "/coloaders",
    clients: "/clients",
    packageTypes: "/packageTypes",
    forwarders: "/forwarders",
    warehouses: "/warehouses",
    ports: "/ports",
  },
  POST: {
    purchaseOrders: "/purchaseOrders",
    shipments: "/shipments",
    itineraries: "/itineraries",
  },
  PATCH: {
    coloaderShipments: "/coloaderShipments",
  },
};
export const imoDangerousCargoOptions = [
  { imo: "1", description: "explosive" },
  {
    imo: "2",
    description: "compressedGasesLiquefiedOrDissolvedUnderPressure",
  },
  { imo: "3", description: "flammableLiquids" },
  { imo: "4", description: "flammableSolids" },
  { imo: "5", description: "oxidizingSubstancesOrOrganicPeroxides" },
  { imo: "6", description: "poisonousAndInfectiousSubstances" },
  { imo: "7", description: "radioactiveMaterials" },
  { imo: "8", description: "corrosiveSubstances" },
  { imo: "9", description: "otherDangerousSubstanceOrArticles" },
];
export const METABASE_MTS_REPORT_KEY = 91;
export const METABASE_ACCO_REPORT_KEY = 105;

export const CC_CONDITION = "CC";
export const PP_CONDITION = "PP";
export const NO_TIER = "NO TIER";
export const OVERWRITED_FIELD_TRANSLATOR = {
  pol_vsldeparture_planned_last: "etd",
  pod_vslarrival_planned_last: "eta",
};
