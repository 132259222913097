import { Layout, Menu, Spin, Image } from "antd";
import { gql, useQuery } from "@apollo/client";

import Link from "next/link";
import { useRouter } from "next/router";
import React, { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useAllowWith, useRoles } from "@/lib/authorization";
import { useAuth } from "@/lib/AuthContext";
import { useMixpanel } from "@/lib/MixpanelContext";
import routes from "@/lib/routes";

import { Button } from "@/components/Klog";
import ContainerOverMenu from "../Menu/ContainerOverMenu/ContainerOverMenu";
import SiderMenuLink from "../Menu/SiderMenuLink/SiderMenuLink";

import styles from "./AppMenu.module.css";

const { Sider } = Layout;
const { Item: MenuItem, SubMenu } = Menu;

export const GET_COMPANY_USER_LOGO = gql`
  query getCompanyUserLogo {
    getCompanyUserLogo {
      id
      imageUrl
    }
  }
`;

const DEFAULT_LOGO = "/static/images/logos/logo-klog.svg";

const AppMenu: FunctionComponent = () => {
  const { pathname } = useRouter();
  const [selectedKey, setSelectedKey] = useState("panel");
  const { mixpanel } = useMixpanel();
  const [openKeys, setOpenKeys] = useState([]);

  const { user } = useAuth();

  const { isCustomsAgency, isCompany } = useRoles();
  const isPublic = user?.group === "public";
  const canQuote = !isCustomsAgency;
  const filteredRoutes = routes.reduce((acc, route) => {
    const { requiredPermission, children } = route;
    if (children && children.length) {
      const filteredChildren = children.filter(({ requiredPermission }) =>
        useAllowWith(requiredPermission)
      );
      if (filteredChildren.length === 1) {
        acc.push({ ...filteredChildren[0], icon: route.icon });
      } else if (filteredChildren.length) {
        acc.push({ ...route, children: filteredChildren });
      }
    } else if (requiredPermission && useAllowWith(requiredPermission)) {
      acc.push(route);
    }
    return acc;
  }, []);

  useEffect(() => {
    filteredRoutes.forEach(({ children = [], name }) => {
      if (children.some((child) => child.name === selectedKey)) {
        setOpenKeys([name]);
      }
    });
  }, [selectedKey]);

  useEffect(() => {
    setSelectedKey(pathname.split("/")[1]);
  }, [pathname]);

  const rootSubmenuKeys = filteredRoutes.reduce(
    (accumulator, { children = [], name }) =>
      children.length ? [...accumulator, name] : accumulator,
    []
  );

  const handleOpenChange = (newOpenKeys) => {
    const latestOpenKey = newOpenKeys.find(
      (key) => openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(newOpenKeys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const generateMenu = (menuRoutes) =>
    menuRoutes.map(
      ({
        name: routeName = "",
        pathname: pathName = "",
        icon: IconMenu = null,
        children = [],
        suffix,
      }) => {
        return children.length && !isPublic ? (
          <SubMenu
            className={styles.submenu}
            key={routeName}
            title={
              <>
                <IconMenu />
                <FormattedMessage id={routeName} />
              </>
            }
            children={generateMenu(children)}
          />
        ) : (
          <MenuItem key={routeName} disabled={isPublic}>
            <SiderMenuLink
              href={pathName}
              text={<FormattedMessage id={routeName} />}
              iconType={IconMenu}
              suffix={suffix}
            />
          </MenuItem>
        );
      }
    );

  const { data: { getCompanyUserLogo } = {}, loading: loadingCompanyLogo } =
    useQuery(GET_COMPANY_USER_LOGO, {
      fetchPolicy: "cache-and-network",
      skip: !isCompany,
    });
  const { imageUrl = DEFAULT_LOGO } = getCompanyUserLogo || {};

  return (
    <Sider
      className={styles.sider}
      theme="light"
      width={256}
      breakpoint="md"
      collapsedWidth={0}
    >
      <div className={styles.siderWrapper}>
        <div>
          <Link href={user ? "/panel" : process.env.NEXT_PUBLIC_LANDING_URL}>
            {loadingCompanyLogo ? (
              <Spin className={styles.appLogo} />
            ) : (
              <a>
                <Image
                  preview={false}
                  alt="klog.co"
                  className={styles.appLogo}
                  src={imageUrl}
                />
              </a>
            )}
          </Link>
        </div>
        {isPublic && <ContainerOverMenu />}
        {canQuote && (
          <div className={styles.quotingButtonWrapper}>
            <Link href={process.env.NEXT_PUBLIC_LANDING_URL}>
              <a>
                <Button
                  className={styles.quotingButton}
                  block
                  type="primary"
                  onClick={() => mixpanel.track("quotingStep1")}
                >
                  <FormattedMessage id="quoting" />
                </Button>
              </a>
            </Link>
          </div>
        )}
        {!user && <ContainerOverMenu />}
        <Menu
          theme="light"
          mode="inline"
          openKeys={openKeys}
          onOpenChange={handleOpenChange}
          selectedKeys={isPublic ? null : [selectedKey]}
          className={styles.menu}
          inlineIndent={20}
        >
          {generateMenu(filteredRoutes)}
        </Menu>
      </div>
    </Sider>
  );
};

export default AppMenu;
