import Link from "next/link";
import React, { ReactElement } from "react";

type Props = {
  href?: string | object;
  text: JSX.Element;
  iconType?: any;
  suffix?: ReactElement;
};

const SiderMenuLink: React.FunctionComponent<Props> = ({
  href,
  text,
  iconType: IconMenu,
  suffix,
}) => {
  return (
    <Link href={href}>
      <a>
        {IconMenu ? <IconMenu /> : null}
        <span>
          {text}
          {suffix}
        </span>
      </a>
    </Link>
  );
};

export default SiderMenuLink;
