import { useQuery } from "@apollo/client";
import { Select } from "antd";
import Cookie from "js-cookie";
import deepClone from "lodash.clonedeep";
import Router from "next/router";
import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { useOffice } from "src/features/officeReferences/OfficeContext";
import CompanyPriorityTooltip from "./CompanyPriorityTooltip";

import { getCompanies } from "../../lib/api";
import { CompanyContext } from "../CompanyContext/CompanyContext";

import { useRoles } from "@/lib/authorization";
import styles from "./CompanySelector.module.css";

const { Option } = Select;

type Props = {};

const CompanySelector: React.FunctionComponent<Props> = () => {
  const { companyId, setCompanyId } = useContext(CompanyContext);
  const { officeCode } = useOffice();
  const {
    data = {},
    loading: companyLoading,
    refetch,
  } = useQuery(getCompanies, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { isCompany } = useRoles();
  useEffect(() => {
    refetch();
  }, [officeCode]);

  const isNotCompanyUser = !isCompany;
  const { getCompanies: companies = [] } = data;

  const conditionalRedirect = () => {
    const currRoute = Router.route;
    const routerInfo = Router.router.query;

    switch (currRoute) {
      case "/shipments/[id]":
        Router.push("/cargos?type=shipments&order=descend&field=updatedAt");
        break;
      case "/purchaseOrders/[id]":
        Router.push(
          "/cargos?type=purchaseOrders&order=descend&field=updatedAt"
        );
        break;
      case "/quoting":
        if (routerInfo.step !== "quoting") {
          Router.push("/quoting");
        }
        break;
    }
  };

  const setCookiesInClient = (newCompanyId) => {
    const currentCompanyId = Cookie.get("selectedCompany");
    if (!newCompanyId) {
      Cookie.remove("selectedCompany");
    } else if (newCompanyId !== currentCompanyId) {
      Cookie.set("selectedCompany", newCompanyId);
    }
  };

  const handleChange = (selectedCompany) => {
    conditionalRedirect();
    setCookiesInClient(selectedCompany);
    setCompanyId(selectedCompany);
  };
  const clonedCompanies = deepClone(companies);
  const sortedCompanies = clonedCompanies.sort((a, b) =>
    a?.name?.localeCompare(b?.name)
  );
  return (
    <Select
      showSearch
      allowClear
      loading={companyLoading}
      onChange={handleChange}
      className={styles.selectCompany}
      size="small"
      placeholder={<FormattedMessage id="chooseCompany" />}
      value={companyId}
      filterOption={(input, option) =>
        option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {sortedCompanies.length &&
        sortedCompanies.map(({ name, legalName, id, priority }) => (
          <Option key={id} value={id}>
            {isNotCompanyUser && <CompanyPriorityTooltip priority={priority} />}
            {legalName || name}
          </Option>
        ))}
    </Select>
  );
};

export default CompanySelector;
