import { InfoCircleOutlined } from "@ant-design/icons";
import { PopoverProps as AntdPopoverProps } from "antd/lib/popover";
import { Popover, Props } from "../Popover/Popover";

import styles from "./InfoCircle.module.css";

export const InfoCircle: React.FunctionComponent<Props & AntdPopoverProps> = ({
  children,
  ...props
}) => {
  return (
    <Popover {...props}>
      <InfoCircleOutlined className={styles.infoCircle} />
    </Popover>
  );
};
