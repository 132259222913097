import { Select as AntdSelect } from "antd";
import { SelectProps } from "antd/lib/select";

import styles from "./InlineSelect.module.css";

type Props = {};

export const InlineSelect: React.FunctionComponent<Props &
  SelectProps<any>> = ({ children, ...selectProps }) => {
  const { className } = selectProps;
  return (
    <AntdSelect
      bordered={false}
      className={`${styles.selector} ${className}`}
      {...selectProps}
    >
      {children}
    </AntdSelect>
  );
};
