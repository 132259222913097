import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { createContext, useContext } from "react";

interface IExchangeRatesContext {
  rates?: any;
}

const GET_EXCHANGE_RATES_QUERY = gql`
  query getExchangeRates {
    getExchangeRates {
      id
      name
      currency
      rates
    }
  }
`;
const ExchangeRatesContext = createContext<IExchangeRatesContext>({});

const ExchangeRatesProvider = ({ children }) => {
  const { data: { getExchangeRates = [] } = {}, loading } = useQuery(
    GET_EXCHANGE_RATES_QUERY,
    { fetchPolicy: "cache-first" }
  );

  const usdExchangeRates =
    !loading && getExchangeRates.length
      ? getExchangeRates.find(({ name }) => name === "usdExchangeRates")
      : {};

  return (
    <ExchangeRatesContext.Provider
      value={{
        rates: usdExchangeRates?.rates || {},
      }}
    >
      {children}
    </ExchangeRatesContext.Provider>
  );
};
const ExchangeRatesConsumer = ExchangeRatesContext.Consumer;
const useExchangeRates = () => useContext(ExchangeRatesContext);

export {
  ExchangeRatesContext,
  ExchangeRatesConsumer,
  ExchangeRatesProvider,
  useExchangeRates,
};
