import { useWindowDimensions } from "@/components/WindowDimensionsContext/WindowDimensionsContext";
import { Col, Modal as AntdModal, Row } from "antd";
import { ModalProps } from "antd/lib/modal";

import styles from "./Modal.module.css";

type Props = {
  imageSrc?: string;
  title?: React.ReactNode;
  colSpan?: number;
  isMobile?: boolean;
};

export const Modal: React.FunctionComponent<ModalProps & Props> = ({
  children,
  className = "",
  imageSrc = null,
  title,
  colSpan = 24,
  isMobile = false,
  ...antdProps
}) => {
  const { title: antdTitle } = antdProps;
  const { width } = useWindowDimensions();

  return (
    <AntdModal
      className={`${styles.modal} ${className}`}
      {...antdProps}
      width="100%"
    >
      <Row gutter={12}>
        {imageSrc && (
          <div className={styles.imgContainer}>
            <img src={imageSrc} />
          </div>
        )}
        <Col span={24} md={imageSrc ? 12 : 24}>
          <Row justify="center" className={imageSrc && styles.row}>
            <Col span={24} lg={colSpan}>
              {title && <h2 className={styles.title}>{title}</h2>}
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    </AntdModal>
  );
};
