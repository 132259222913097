import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect, useRef, useState } from "react";
import { useShipment } from "@/components/ShipmentContext/ShipmentContext";
import { OCEAN } from "@/lib/constants";
import { IShipmentMilestonesHelper } from "@/types/shipment";
import { sumDetailedCharges } from "../lib/currencyHelper";
import {
  getMilestones,
  getMilestonesHelper,
  getStories,
  getTimeZonesOfShipment,
} from "../lib/helpers";

const SHIPMENT_MAILS = gql`
  query getShipmentMail(
    $shipmentId: ID!
    $pageToken: String
    $maxResults: Int
    $chosenCustomerService: ID!
  ) {
    getShipmentMail(
      shipmentId: $shipmentId
      pageToken: $pageToken
      maxResults: $maxResults
      chosenCustomerService: $chosenCustomerService
    ) {
      mails {
        messageId
        subject
        from
        date
        mailContent
        attachments
      }
      nextPageToken
    }
  }
`;

export const useChargesSum = (calculatedCharges, rates) => {
  let formattedCharges = [];
  useEffect(() => {
    const addCharges = async () => {
      const sumCharges = await sumDetailedCharges(calculatedCharges, rates);
      formattedCharges = sumCharges;
    };
    addCharges();
  }, [calculatedCharges]);
  return formattedCharges;
};

export const useInterval = (callback, delay) => {
  const [reset, setReset] = useState(false);
  const savedCallback = useRef(null);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, reset]);
  return () => setReset((prevReset) => !prevReset);
};

export const useOnScreen = (ref, rootMargin = "0px") => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    const element = ref.current;
    if (element) {
      observer.observe(element);
      return () => {
        observer.unobserve(element);
      };
    }
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
};

export const useRotateKeys = (keys, delay = 5000) => {
  const [index, setIndex] = useState(0);

  const resetInterval = useInterval(() => {
    setIndex((prevIndex) => (prevIndex + 1) % keys.length);
  }, delay);
  const setCurrentKey = (newKey) => {
    const newIndex = keys.findIndex((key) => key === newKey);
    newIndex >= 0 && setIndex(newIndex);
    resetInterval();
  };

  return [keys[index], setCurrentKey];
};

export const useMail = ({ shipmentId, chosenCustomerService }) => {
  const MAX_RESULTS = 5;
  const {
    loading,
    data: { getShipmentMail: mailsResponse = [] } = [],
    fetchMore,
  } = useQuery(SHIPMENT_MAILS, {
    variables: { shipmentId, chosenCustomerService, maxResults: MAX_RESULTS },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const { nextPageToken, mails = [] } = mailsResponse || {};

  useEffect(() => {
    if (nextPageToken) {
      fetchMore({
        variables: {
          pageToken: nextPageToken,
          maxResults: MAX_RESULTS,
        },
        updateQuery: (
          prev,
          {
            fetchMoreResult: {
              getShipmentMail: { mails: newMails = [], nextPageToken },
            },
          }
        ) => {
          const { getShipmentMail: {} = {} } = prev;
          const {
            getShipmentMail: { mails: prevMails = [] },
          } = prev;
          const newQuery = Object.assign({}, prev, {
            getShipmentMail: {
              ...prev.getShipmentMail,
              nextPageToken,
              mails: [...prevMails, ...newMails],
            },
          });
          const {
            getShipmentMail: { mails },
          } = newQuery;

          const sortedMails = mails.sort((a, b) => a.date - b.date);
          return { mails: sortedMails, ...newQuery };
        },
      });
    }
  }, [nextPageToken, chosenCustomerService]);
  return { mails, loading, nextPageToken };
};

export const useTimeZone = () => {
  const { shipment } = useShipment();
  return getTimeZonesOfShipment(shipment);
};

export const useMilestones = (): IShipmentMilestonesHelper => {
  const {
    shipment: { milestones, shipmentType },
  } = useShipment();
  const [milestoneHelper, setMilestoneHelper] = useState({});
  const isOcean = shipmentType === OCEAN;
  useEffect(() => {
    setMilestoneHelper(getMilestonesHelper(milestones, isOcean));
  }, [milestones]);
  return milestoneHelper;
};

export const useDetectClickOut = (initState) => {
  const triggerRef = useRef(null);
  const nodeRef = useRef(null);
  const [show, setShow] = useState(initState);

  const handleClickOutside = (event) => {
    if (triggerRef.current && triggerRef.current.contains(event.target)) {
      return setShow(!show);
    }
    if (nodeRef.current && !nodeRef.current.contains(event.target)) {
      return setShow(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return {
    triggerRef,
    nodeRef,
    show,
    setShow,
  };
};
