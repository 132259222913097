import { useApolloClient, useQuery, gql } from "@apollo/client";
import { Col, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useInterval } from "react-use";

import OfficeSelector from "@/src/features/officeReferences/OfficeSelector";
import { TasksNotifications } from "@/src/features/tasksNotifications/TasksNotifications";
import CompanySelector from "../CompanySelector/CompanySelector";
import OperationTypeSelector from "../Header/OperationTypeSelector";
import LoginLockButton from "../Login/LoginLockButton";
import { SearchBar } from "../Searcher/SearchBar";
import { useWindowDimensions } from "../WindowDimensionsContext/WindowDimensionsContext";

import { getCompanies } from "@/lib/api";
import Auth from "@/lib/auth";
import { useAllowWith, useRoles } from "@/lib/authorization";
import { useAuth } from "@/lib/AuthContext";
import {
  CUSTOMER_SERVICE,
  CUSTOMER_SERVICE_ADMIN,
  FINANCE,
  FINANCE_ADMIN,
  MARKETING,
  OPERATIVE,
  OPERATIVE_ADMIN,
  SALESPERSON,
  SALESPERSON_ADMIN,
  TEUAI,
  TEUAI_ADMIN,
} from "@/lib/constants";
import { useMixpanel } from "@/lib/MixpanelContext";

import styles from "./AppHeader.module.css";
import YearSelector from "@/src/features/header/YearSelector";

const { Header } = Layout;

export const GET_USER_PERMISSIONS = gql`
  query getDBUserPermissions {
    getDBUserPermissions {
      id
      permissionToEditFields
    }
  }
`;

const AppHeader: React.FunctionComponent = () => {
  const [readyOcular, setReadyOcular] = useState(false);
  const [delay, setDelay] = useState(8000);

  const { user, setUser } = useAuth();
  const { isCustomsAgency, isCompany } = useRoles();
  const { md, sm, xs } = useWindowDimensions();
  const client = useApolloClient();
  const { mixpanel } = useMixpanel();

  const { data: userData = {} } = useQuery(GET_USER_PERMISSIONS, {
    fetchPolicy: "cache-first",
  }); // do not remove
  const { data = {} } = useQuery(getCompanies, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !isCompany,
  });

  const canGetNotifications = useAllowWith(["getTaskNotification", "read"]);
  const isDevelopment = process.env.NODE_ENV === "development";
  const smallLayout = xs || sm || md;
  const gutter = smallLayout ? 12 : 24;
  const showForCompanyUser =
    user && isCompany && user.accounts && user.accounts.length > 1;
  const showForForwarder =
    user &&
    [
      TEUAI,
      TEUAI_ADMIN,
      CUSTOMER_SERVICE,
      CUSTOMER_SERVICE_ADMIN,
      SALESPERSON,
      SALESPERSON_ADMIN,
      OPERATIVE_ADMIN,
      OPERATIVE,
      MARKETING,
      FINANCE,
      FINANCE_ADMIN,
    ].includes(user.group);

  useInterval(
    () => {
      if (ocularWidget) {
        setReadyOcular(true);
      }
    },
    isDevelopment ? null : delay
  );

  useEffect(() => {
    if (readyOcular) {
      if (isCompany && data && data?.getCompanies) {
        const companyNames = data.getCompanies
          .map(({ name }) => name)
          .join(" / ");
        ocularWidget.setCustomer({
          name: user.name,
          email: user.email,
          companyName: companyNames,
        });
        ocularWidget.setPopUpContent(
          "<h4><b>¿Necesitas ayuda?</b> Comunícate con nosotros en tiempo real</h4>"
        );
        ocularWidget.show();
        setTimeout(() => {
          ocularWidget.showPopUp(true);
        }, 2000);
      }
      setDelay(null);
    }
  }, [readyOcular, data]);

  return user ? (
    <Header className={styles.header} id="ocularHook">
      <div style={{ display: "none" }} id="auth0id_user">
        {user?.auth0Id}
      </div>
      <Row
        className={styles.headerMenu}
        justify="space-between"
        align="middle"
        gutter={gutter}
      >
        <Col lg={10}>
          <Row align="middle" className={styles.noWrap} gutter={12}>
            <Col>
              <OperationTypeSelector />
            </Col>
            {!isCustomsAgency && (
              <Col>
                <OfficeSelector />
              </Col>
            )}
            <Col className={styles.searchBarWrapper} lg={18}>
              <SearchBar size={smallLayout ? "small" : "default"} />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row
            justify="space-around"
            align="middle"
            gutter={gutter}
            className={styles.noWrap}
          >
            <Col>
              <YearSelector />
            </Col>
            <Col>
              {user && (showForForwarder || showForCompanyUser) && (
                <CompanySelector />
              )}
            </Col>
            {user ? (
              <>
                {canGetNotifications && (
                  <Col>
                    <TasksNotifications />
                  </Col>
                )}
                <Col>
                  <a
                    className={styles.noWrap}
                    onClick={() => {
                      mixpanel.reset();
                      Auth.lockLogout(setUser, client);
                    }}
                  >
                    <FormattedMessage id="logout" />
                  </a>
                </Col>
              </>
            ) : (
              <Col>
                <LoginLockButton buttonType="link" />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Header>
  ) : null;
};

export default AppHeader;
