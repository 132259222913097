import { ExclamationCircleFilled } from "@ant-design/icons";
import { Alert, Button, Col, Row } from "antd";
import Router from "next/router";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useRoles } from "@/lib/authorization";

import { CompanyContext } from "../CompanyContext/CompanyContext";

import styles from "./CompanyInformationAlert.module.css";

type Props = {};

const CompanyInformationAlert: React.FunctionComponent<Props> = () => {
  const { isCompany } = useRoles();
  const alertMessageId = isCompany
    ? "companyNotVerifiedAlertDescription"
    : "companyNotVerifiedAlertDescriptionSalesperson";
  const { companyId } = useContext(CompanyContext);
  const pathname = isCompany ? "/company" : `/companies/${companyId}`;
  const query = isCompany
    ? {
        fromBanner: 1,
      }
    : {
        keyTab: "customerForm",
      };
  const message = (
    <Row justify="space-between" align="middle">
      <Col className={styles.alertDescription}>
        <FormattedMessage id={alertMessageId} />
      </Col>
      <Col>
        <Button
          size="small"
          onClick={() =>
            Router.push({
              pathname,
              query,
            })
          }
        >
          <ExclamationCircleFilled />
          <FormattedMessage id="completeInformation" />
        </Button>
      </Col>
    </Row>
  );
  return (
    <div className={styles.divStyle}>
      <Alert message={message} banner showIcon={false} />
    </div>
  );
};

export default CompanyInformationAlert;
