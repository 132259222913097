import React, { FunctionComponent } from "react";
import moment from "moment";
import { Select } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";

import { useYear } from "src/common/Context/YearContext";
import { YEAR_COOKIE } from "src/common/constants";
import { cookieSetter } from "src/common/helpers";
import { conditionalRedirect } from "../officeReferences/OfficeSelector";

type Props = {};

const YEAR_FLOOR = 2020;
const YEAR_CEILING = moment().year();

const YearSelector: FunctionComponent<Props> = () => {
  const intl = useIntl();
  const router = useRouter();
  const { year, setYear } = useYear();

  const onYearChange = (newYear) => {
    setYear(newYear);
    cookieSetter({ cookieName: YEAR_COOKIE, newValue: newYear });
    conditionalRedirect({ router });
  };

  const YEARS_OPTIONS = Array(YEAR_CEILING - YEAR_FLOOR + 1)
    .fill()
    .map((_, index) => ({
      label: String(YEAR_FLOOR + index),
      value: String(YEAR_FLOOR + index),
    }));

  return (
    <Select
      defaultValue={year}
      value={year}
      options={YEARS_OPTIONS}
      style={{ width: "4.5rem" }}
      size="small"
      onChange={onYearChange}
      allowClear
      placeholder={intl.formatMessage({ id: "year" })}
      suffixIcon={<CalendarOutlined />}
    />
  );
};

export default YearSelector;
