import { Badge, Tooltip } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { priorityBadgeConfig } from "../../lib/helpers";

type Props = {
  priority: string;
};

const CompanyPriorityTooltip: React.FunctionComponent<Props> = ({
  priority,
}) => {
  const { status, color = "" } = priority
    ? priorityBadgeConfig.find(({ key }) => key === priority)
    : { status: "default" };

  return (
    <Tooltip
      title={
        <FormattedMessage id="companyPriorityMessage" values={{ priority }} />
      }
    >
      <Badge {...{ status, color }} />
    </Tooltip>
  );
};
export default CompanyPriorityTooltip;
