import { gql, useMutation } from "@apollo/client";
import { Button, notification } from "antd";
import { ButtonProps, ButtonSize, ButtonType } from "antd/lib/button";
import axios from "axios";
import Router, { useRouter } from "next/router";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Auth from "@/lib/auth";
import { useAuth } from "@/lib/AuthContext";
import { initializeEnforcer } from "@/lib/authorization";
import { COMPANY } from "@/lib/constants";
import { useMixpanel } from "@/lib/MixpanelContext";

const APP_URL = process.env.NEXT_PUBLIC_APP_URL;

const quoteToUserHook = gql`
  mutation quoteToUserHook(
    $quoteId: ID
    $userId: String!
    $companyName: String
    $companyId: ID
  ) {
    quoteToUserHook(
      quoteId: $quoteId
      userId: $userId
      companyName: $companyName
      companyId: $companyId
    )
  }
`;

type Props = {
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  buttonText?: string;
  showedInLanding?: boolean;
  showAsUrl?: boolean;
  redirect?: boolean;
  buttonProps?: ButtonProps;
  callback?: () => void;
};

const LoginLockButton: React.FunctionComponent<Props> = ({
  showedInLanding = false,
  showAsUrl = false,
  buttonType = "primary",
  buttonSize,
  buttonText = "login",
  redirect,
  buttonProps = {},
  callback,
}) => {
  const [createNewUser] = useMutation(quoteToUserHook, {
    onCompleted: async ({ quoteToUserHook: isRegisterSuccessful }) => {
      if (isRegisterSuccessful) {
        await Auth.auth0Lock.checkSession({}, async (_, newAuthResult) => {
          const { idToken: newIdToken, accessToken: newAccessToken } =
            newAuthResult;
          authenticate(newIdToken, newAccessToken);
        });
      }
    },
  });

  const intl = useIntl();
  const { mixpanel } = useMixpanel();
  const { user, setUser, showModal, authenticating, setAuthenticating } =
    useAuth();
  const {
    push,
    query: { quoteId, type },
    pathname,
  } = useRouter();

  const pathsLogin = [
    "/quoting/[id]/[stepKey]",
    "/payments/wireTransfer/[paymentId]",
    "/customerForm/[id]",
    "/redirect",
  ].includes(pathname);

  const lockModalOptions = {
    allowSignUp: false,
    languageDictionary: {
      title: "",
      signUpTitle: "",
      signUpLabel: intl.formatMessage({ id: "signUp" }),
      loginLabel: intl.formatMessage({ id: "login" }),
      signUpSubmitLabel: intl.formatMessage({ id: "submit" }),
      loginSubmitLabel: intl.formatMessage({ id: "enter" }),
      forgotPasswordAction: intl.formatMessage({
        id: "forgotPassword",
      }),
      emailInputPlaceholder: intl.formatMessage({ id: "email" }),
      passwordInputPlaceholder: intl.formatMessage({ id: "password" }),
      signUpTerms: intl.formatMessage({ id: "signUpTerms" }),
      forgotPasswordTitle: intl.formatMessage({
        id: "forgotPasswordTitle",
      }),
      forgotPasswordInstructions: intl.formatMessage({
        id: "forgotPasswordInstructions",
      }),
      forgotPasswordSubmitLabel: intl.formatMessage({
        id: "forgotPasswordSubmitLabel",
      }),
      success: {
        forgotPassword: intl.formatMessage({
          id: "forgotPasswordSuccess",
        }),
      },
    },
  };

  const authenticate = (tokenId, tokenAccess) => {
    Auth.handleLockAuth(tokenId, tokenAccess, (user) => {
      setUser(user);
      mixpanel.identify(user.email);
      mixpanel.track("logIn");
      mixpanel.people.set({
        $email: user.email,
        $name: user.name,
        userGroup: user.group,
        userRole: user.role,
        lastLogIn: new Date(),
        userCompanyId: user.group === "company" ? user.accounts : "",
      });
      mixpanel.people.increment("logInCount");
    });
  };

  const handleClick = () => {
    showModal(
      lockModalOptions,
      async ({ idToken, accessToken, idTokenPayload }) => {
        setAuthenticating(true);
        let isNewUser = false;
        const authenticate = (tokenId, tokenAccess) => {
          Auth.handleLockAuth(tokenId, tokenAccess, async (user) => {
            setUser(user);
            mixpanel.identify(user.email);
            mixpanel.track("logIn");
            mixpanel.people.set({
              $email: user.email,
              $name: user.name,
              userGroup: user.group,
              userRole: user.role,
              lastLogIn: new Date(),
              userCompanyId: user.group.includes(COMPANY) ? user.accounts : "",
            });
            if (pathsLogin) {
              const {
                data: { policies },
              } = await axios.post(
                process.env.NEXT_PUBLIC_ENFORCER_SUBJECTS_URI,
                { token: tokenId, force: true }
              );
              await initializeEnforcer(policies);
            }
            callback && callback();
            mixpanel.people.increment("logInCount");
          });
        };
        const isCompanyUser = idTokenPayload[`${APP_URL}/group`] === "company";

        if (showedInLanding) {
          authenticate(idToken, accessToken);
          if (redirect) {
            await push(type === "PDF" ? "/admin" : "/panel");
          }
          setAuthenticating(false);
          return;
        }
        if (isCompanyUser) {
          const userCompany = idTokenPayload[`${APP_URL}/accounts`];

          isNewUser = !userCompany.length;

          if (isNewUser) {
            const userId = idTokenPayload.sub;
            const newCompanyName =
              idTokenPayload[`${APP_URL}/userData`].companyName;
            const { taxId } = idTokenPayload[`${APP_URL}/userData`];
            try {
              await createNewUser({
                variables: {
                  userId,
                  quoteId,
                  taxId,
                  companyName: newCompanyName,
                },
              });
            } catch (e) {
              notification.error({
                message: e.message,
              });
              console.log(e);
            }

            setAuthenticating(false);
            return;
          }
        }

        if (!isNewUser) authenticate(idToken, accessToken);
        setAuthenticating(false);
      }
    );
  };

  const handleLogin = async () => {
    if (!user) {
      await handleClick();
    } else {
      setAuthenticating(true);
      await push("/panel");
      setAuthenticating(false);
    }
  };

  return showAsUrl ? (
    <a onClick={handleLogin}>
      <FormattedMessage id={buttonText} />
    </a>
  ) : (
    <Button
      {...buttonProps}
      loading={authenticating || buttonProps.loading}
      id="loginButton"
      type={buttonType}
      size={buttonSize}
      onClick={handleLogin}
    >
      {!user ? (
        <FormattedMessage id={buttonText} />
      ) : (
        <FormattedMessage id="panel" />
      )}
    </Button>
  );
};

export default LoginLockButton;
