import { Select as AntdSelect } from "antd";
import { SelectProps as AntdSelectProps } from "antd/lib/select";
import classNames from "classnames/bind";
import { Select as FormikSelect, SelectProps } from "formik-antd";
import React, { useRef } from "react";

import { useDetectClickOut } from "@/hooks/hooks";

import styles from "./Select.module.scss";

const cx = classNames.bind(styles);

type Props = {};

type AltProps = {
  label: React.ReactNode | string;
};

export const Select: React.FunctionComponent<Props & AntdSelectProps<any>> = ({
  children,
  ...selectProps
}) => {
  const { className = "" } = selectProps;

  return (
    <AntdSelect
      dropdownClassName={styles.dropdownClassName}
      className={`${styles.select} ${className}`}
      {...selectProps}
    >
      {children}
    </AntdSelect>
  );
};

export const SelectAlt: React.FunctionComponent<
  AltProps & AntdSelectProps<any>
> = ({ label, children, ...selectProps }) => {
  const popUpRef = useRef(null);
  const { show, nodeRef, triggerRef, setShow } = useDetectClickOut(false);

  return (
    <div
      className={cx({ selectAlt: true, selectAltFocus: show })}
      ref={triggerRef}
    >
      <label>{label}</label>
      <AntdSelect
        open={show}
        dropdownRender={(menu) => <div ref={nodeRef}>{menu}</div>}
        onSearch={selectProps.showSearch ? () => setShow(true) : null}
        onSelect={() => setShow(false)}
        getPopupContainer={() => popUpRef.current}
        dropdownClassName={styles.dropdownRefContainer}
        dropdownMatchSelectWidth={false}
        {...selectProps}
      >
        {children}
      </AntdSelect>
      <div className={styles.dropdownRef} ref={popUpRef} />
    </div>
  );
};

export const SelectForm: React.FunctionComponent<SelectProps<any>> = ({
  children,
  ...props
}) => {
  const { className = "" } = props;

  return (
    <FormikSelect
      dropdownClassName={styles.dropdownClassName}
      className={`${styles.select} ${className}`}
      {...props}
    >
      {children}
    </FormikSelect>
  );
};

export const SelectFormAlt: React.FunctionComponent<
  AltProps & SelectProps<any>
> = ({ label, children, style, ...props }) => {
  const popUpRef = useRef(null);
  const { show, nodeRef, triggerRef, setShow } = useDetectClickOut(false);

  return (
    <div
      style={style}
      className={cx({ selectAlt: true, selectAltFocus: show })}
      ref={triggerRef}
    >
      <label>{label}</label>
      <FormikSelect
        open={show}
        dropdownRender={(menu) => <div ref={nodeRef}>{menu}</div>}
        onSearch={props.showSearch ? () => setShow(true) : null}
        onSelect={() => setShow(false)}
        getPopupContainer={() => popUpRef.current}
        dropdownClassName={styles.dropdownRefContainer}
        dropdownMatchSelectWidth={false}
        {...props}
      >
        {children}
      </FormikSelect>
      <div className={styles.dropdownRef} ref={popUpRef} />
    </div>
  );
};
