import React from "react";
import { Button as AntdButton } from "antd";
import { ButtonProps } from "antd/lib/button";
import classNames from "classnames/bind";
import { motion, MotionProps } from "framer-motion";
import { useIntl } from "react-intl";

import ChevronSvg from "@/public/static/images/icons/icon-chevron_down--blue.svg";

import styles from "./Button.module.scss";

declare const ButtonColors: ["success", "danger", "secondary"];
declare type ButtonColor = typeof ButtonColors[number];

type Props = {
  color?: ButtonColor;
  upperCase?: boolean;
  block?: boolean;
};

type BackButtonProps = {
  onClick: () => void;
  textId?: string;
  block?: boolean;
};

const cx = classNames.bind(styles);

export const Button: React.FunctionComponent<Props & ButtonProps> = ({
  children,
  color,
  upperCase = false,
  block: isBlock,
  ...antdProps
}) => {
  const colorButton = (color) => {
    const colorBase = "";
    switch (color) {
      case "success":
        return "Success";
      case "danger":
        return "Danger";
      case "secondary":
        return "Secondary";
      default:
        return colorBase;
    }
  };

  return (
    <div
      className={cx({
        upperCase,
        isBlock,
        button: true,
        [`${colorButton(color)}`]: true,
      })}
    >
      <AntdButton block={isBlock} {...antdProps}>
        {children}
      </AntdButton>
    </div>
  );
};

export const BackButton: React.FunctionComponent<
  BackButtonProps & MotionProps
> = ({ onClick, textId = "goBack", block: isBlock, ...motionProps }) => {
  const intl = useIntl();

  return (
    <motion.button
      className={cx({
        isBlock,
        backButton: true,
      })}
      type="button"
      onClick={onClick}
      {...motionProps}
    >
      <ChevronSvg />
      {intl.formatMessage({ id: textId })}
    </motion.button>
  );
};
