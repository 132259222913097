import React, { FunctionComponent, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Tag } from "antd";

import { useRoles } from "@/lib/authorization";
import { useCompany } from "../CompanyContext/CompanyContext";

import styles from "./OperationsRejectedByUserAmount.module.css";

export const OPERATIONS_REJECTED_BY_USER_AMOUNT = gql`
  query operationsRejectedByUserAmount($companyId: ID) {
    operationsRejectedByUserAmount(companyId: $companyId)
  }
`;

const OperationsRejectedByUserAmount: FunctionComponent = () => {
  const { companyId } = useCompany();
  const { isTeuai, isSalesPerson, isFinance } = useRoles();
  const { data: { operationsRejectedByUserAmount = 0 } = {} } = useQuery(
    OPERATIONS_REJECTED_BY_USER_AMOUNT,
    {
      fetchPolicy: "cache-and-network",
      skip: isTeuai || isSalesPerson || isFinance,
      variables: {
        companyId,
      },
    }
  );

  const badgeText =
    operationsRejectedByUserAmount > 99
      ? "+99"
      : operationsRejectedByUserAmount;

  return (
    !!badgeText && (
      <Tag color="geekblue" className={styles.tag}>
        {badgeText}
      </Tag>
    )
  );
};

export default OperationsRejectedByUserAmount;
