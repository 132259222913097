import Dinero from "dinero.js";
import { conceptHasTax } from "@/src/features/translog/chargesHelper";
import { AIR, currencyPrecision } from "./constants";

export const currencyConverter = async (
  { currency, amount = 0 },
  rates,
  finalCurrency = "USD"
) => {
  if (currency !== finalCurrency) {
    const currentExchangeRate = rates[currency];
    const convertedCharge = await Dinero({
      amount: parseInt(amount.toString(), 10),
      ...(currency === "CLP" ? { precision: 0 } : {}),
    }).convert("USD", {
      endpoint: new Promise((resolve) =>
        resolve({ rates: { USD: currentExchangeRate } })
      ),
      roundingMode: "HALF_UP",
    });
    if (finalCurrency !== "USD") {
      const finalRate = 1 / rates[finalCurrency];
      const finalConvertedCharge = await Dinero({
        amount: convertedCharge.getAmount(),
        ...(currency === "CLP" ? { precision: 0 } : {}),
      }).convert(finalCurrency, {
        endpoint: new Promise((resolve) =>
          resolve({ rates: { [finalCurrency]: finalRate } })
        ),
        roundingMode: "HALF_UP",
      });
      return finalConvertedCharge;
    }
    return convertedCharge;
  }

  return Dinero({
    amount: amount || 0,
    currency: finalCurrency,
    precision: currencyPrecision[finalCurrency],
  });
};

export const lclFeeSimulator = async (
  { tonm3: priceByTonm3, fixed: baseFee, min: minimalFee },
  { tonm3: profitByCbm = 0, simulatedCbm, minProfit: minSimulatedProfit = 0 },
  rates
) => {
  const minimalFeeDinero = await currencyConverter(minimalFee, rates);
  const tonm3Dinero = await currencyConverter(priceByTonm3, rates);
  const baseFeeDinero = await currencyConverter(baseFee, rates);
  const profitDinero = Dinero({
    amount: profitByCbm * 100,
    currency: "USD",
  });
  const minSimulatedProfitDinero = Dinero({
    amount: minSimulatedProfit * 100,
    currency: "USD",
  });
  const potentialTotalFee = tonm3Dinero
    .add(profitDinero)
    .multiply(simulatedCbm);
  if (!simulatedCbm) return 0;
  const totalFee = minimalFeeDinero.greaterThan(potentialTotalFee)
    ? minimalFeeDinero
    : potentialTotalFee;
  const simulatedTotal = totalFee
    .add(baseFeeDinero)
    .greaterThan(minSimulatedProfitDinero)
    ? totalFee.add(baseFeeDinero)
    : minSimulatedProfitDinero;
  return simulatedTotal.toFormat("0,0");
};

export const moneyStringFormatter = (
  { currency, amount, taxable },
  summarized = false
) => {
  const taxableString = "+IVA";
  const precision = currencyPrecision[currency];
  const currencySymbol = currency === "EUR" ? "€" : "$";
  const dineroFormatString =
    currency === "CLP" || summarized ? "0,0" : "0,0.00";
  const moneyString = Dinero({ currency, amount, precision }).toFormat(
    dineroFormatString
  );
  const formattedCurrencyString = `${currency} ${currencySymbol}${moneyString}`;
  return taxable
    ? `${formattedCurrencyString} ${taxableString}`
    : formattedCurrencyString;
};
export const moneyProfitFormatter = async (
  { currency, amount },
  profit = 0,
  rates,
  minProfitPercentage = 0
) => {
  const precision = currencyPrecision[currency];
  const dineroParsedProfit = Dinero({
    currency: "USD",
    precision: 2,
    amount: profit * 10 ** precision,
  });
  const priceInUsd = await currencyConverter({ currency, amount }, rates);
  const dineroMinimalProfit = priceInUsd.multiply(
    1 + minProfitPercentage / 100
  );
  const totalWithProfit = priceInUsd.add(dineroParsedProfit);
  const finalPrice = dineroMinimalProfit.greaterThan(totalWithProfit)
    ? dineroMinimalProfit
    : totalWithProfit;
  const profitPercentage = dineroMinimalProfit.greaterThan(totalWithProfit)
    ? minProfitPercentage.toFixed(1)
    : ((dineroParsedProfit.getAmount() * 100) / amount).toFixed(1);

  const dineroFormatString = "0,0";
  const moneyString = priceInUsd.toFormat(dineroFormatString);
  const profitString = finalPrice.toFormat(dineroFormatString);
  if (!profit && !minProfitPercentage) return moneyString;
  const formattedCurrencyString = `${moneyString}/${profitString} (${profitPercentage}%)`;
  return formattedCurrencyString;
};

export const currencyAccumulatorHelper = async (
  initialValue,
  charges,
  rates
) => {
  let accumulator = initialValue;
  const summarizedChargesByCurrency = charges.reduce((summary, { total }) => {
    const { currency: currentCurrency } = total;
    const registeredCurrencyIndex = summary.findIndex(
      ({ currency }) => currency === currentCurrency
    );
    if (registeredCurrencyIndex !== -1) {
      const dineroObject = Dinero({
        ...summary[registeredCurrencyIndex],
        precision: currencyPrecision[currentCurrency],
      });
      const accumulatedDineroObject = dineroObject.add(
        Dinero({
          ...total,
          precision: currencyPrecision[currentCurrency],
        })
      );
      summary[registeredCurrencyIndex] = accumulatedDineroObject.toObject();
      return summary;
    }
    summary.push(total);
    return summary;
  }, []);

  for (const charge of charges) {
    const {
      name,
      total: { currency, amount },
    } = charge;
    if (name === "cargoHelper") continue;
    if (currency !== "USD") {
      const currentExchangeRate = rates[currency];
      const convertedCharge = await Dinero({
        amount,
        ...(currency === "CLP" ? { precision: 0 } : {}),
      }).convert("USD", {
        endpoint: new Promise((resolve) =>
          resolve({ rates: { USD: currentExchangeRate } })
        ),
        roundingMode: "HALF_UP",
      });
      accumulator = accumulator.add(convertedCharge);
      continue;
    }
    accumulator = accumulator.add(Dinero({ amount, currency }));
  }
  return { summarizedChargesByCurrency, moneyObject: accumulator };
};
export const sumDetailedCharges = async (charges, rates) => {
  const finalCharges = [];
  for (const charge of charges) {
    const { total, detailedCharges, type } = charge;
    if (!detailedCharges.length || total) {
      finalCharges.push({
        ...charge,
        total,
      });
      continue;
    }
    let accumulator = Dinero({
      amount: 0,
      currency: type === "TAX" ? "CLP" : "USD",
      precision: type === "TAX" ? 0 : 2,
    });
    for (const detailedCharge of detailedCharges) {
      const {
        total: { amount, currency, precision },
      } = detailedCharge;
      if (currency !== "USD" && type !== "TAX") {
        const currentExchangeRate = rates[currency];
        const convertedCharge = await Dinero({
          amount,
          ...(currency === "CLP" ? { precision: 0 } : {}),
        }).convert("USD", {
          endpoint: new Promise((resolve) =>
            resolve({ rates: { USD: currentExchangeRate } })
          ),
        });
        accumulator = accumulator.add(convertedCharge);
        continue;
      } else if (type === "TAX") {
        accumulator = accumulator.add(Dinero({ amount, currency, precision }));
        continue;
      }
      accumulator = accumulator.add(Dinero({ amount, currency }));
    }
    finalCharges.push({
      ...charge,
      total: accumulator.toObject(),
    });
  }
  return finalCharges;
};

const initializeTotals = (charges) =>
  charges.reduce((accumulator, charge) => {
    const { currency, notes } = charge;
    const hasTax = notes === "plusVat";
    const taxedKey = `vat${currency}`;
    const precision = currencyPrecision[currency];
    if (accumulator[currency]) return accumulator;
    accumulator[currency] = Dinero({
      currency,
      precision,
      amount: 0,
    }).toObject();
    if (accumulator[taxedKey] || !hasTax) return accumulator;
    accumulator[taxedKey] = Dinero({
      currency,
      precision,
      amount: 0,
    }).toObject();
    return accumulator;
  }, {});

const formatTotals = (totals) => {
  const formattedTotals = Object.entries(totals).reduce(
    (accumulator, [currency, amountObject]) => {
      accumulator[currency] = Dinero(amountObject);
      return accumulator;
    },
    {}
  );
  const { vatCLP, ...restOfTotals } = formattedTotals;

  return restOfTotals;
};

export const formattedCharges = ({
  charges,
  chargeConcepts,
  conceptMultipliers,
  checkTax = false,
}) => {
  const TAX_MULTIPLIER = 1.19;
  const liftedCharges = charges.reduce((accumulator, charge) => {
    const { detailedCharges = [] } = charge;
    if (!detailedCharges.length) {
      return [...accumulator, charge];
    }
    return [...accumulator, ...detailedCharges];
  }, []);
  const totals = initializeTotals(liftedCharges);
  const chargesArray = liftedCharges.map((saleCharge) => {
    const {
      name,
      notes,
      type = "else",
      minimalAmount = 0,
      ...charge
    } = saleCharge;
    const currentMultiplier = conceptMultipliers[type] || 1;
    const initialCharge = Dinero(charge).multiply(currentMultiplier);
    const useMinimal = type !== "else";
    const minimalCharge = Dinero({
      ...charge,
      amount: minimalAmount,
    });
    const dineroCharge = useMinimal
      ? Dinero.maximum([initialCharge, minimalCharge])
      : initialCharge;
    const { name: conceptName = "" } =
      chargeConcepts.find(({ translogId }) => translogId === name) || {};
    const currentCurrency = dineroCharge.getCurrency();

    const hasTax = checkTax && conceptHasTax(conceptName);
    const isClp = currentCurrency === "CLP";
    const formatToUse = isClp ? "USD0,0" : "USD0,0.00";

    const formattedCharge = dineroCharge.toFormat(formatToUse);

    const accumulatedTotal = Dinero(totals[currentCurrency]);
    const taxedCharge = dineroCharge.multiply(TAX_MULTIPLIER);
    const chargeToAdd = hasTax ? taxedCharge : dineroCharge;

    totals[currentCurrency] = accumulatedTotal.add(chargeToAdd).toObject();
    return {
      concept: conceptName || name,
      amount: formattedCharge,
    };
  });

  const formattedTotals = formatTotals(totals);
  return { chargesArray, formattedTotals };
};

export const sumOfCharges = async (charges, rates, currentCurrency) => {
  let initial = Dinero({ currency: currentCurrency, amount: 0 });
  for (const dineroObj of Object.values(charges)) {
    const parsedUsd = await currencyConverter(
      {
        currency: dineroObj.getCurrency(),
        amount: dineroObj.getAmount(),
      },
      rates,
      currentCurrency
    );
    initial = initial.add(parsedUsd);
  }
  return initial;
};
export const dineroFormatter = ({ amount = 0, currency }) => {
  const isInClp = currency === "CLP";
  const formatToUse = isInClp ? "USD0,0" : "USD0,0.00";
  return Dinero({
    amount,
    currency,
    precision: currencyPrecision[currency],
  }).toFormat(formatToUse);
};
export const chargeMultiplierCalculator = ({ shipmentType, cargo = [] }) => {
  const isAereal = shipmentType === AIR;
  const containerTypeMultipliers = {
    "20ST": 0,
    "40ST": 0,
    "40HC": 0,
    "40NOR": 0,
    "40RF": 0,
    "20OT": 0,
    "40OT": 0,
    "20GOH": 0,
    "40GOH": 0,
  };

  let weight = 0;
  let volume = 0;
  let loadedQuantity = 0;
  let volumetricWeight = 0;
  let teu = 0;
  cargo.forEach(
    ({
      weight: containerWeight = 0,
      volume: containerVolume = 0,
      loadedQuantity: containerLoadedQuantity = 0,
      containerType,
      volumetricWeight: currentVolumetricWeight,
    }) => {
      volumetricWeight += currentVolumetricWeight;
      weight += containerWeight;
      volume += containerVolume;
      loadedQuantity += containerLoadedQuantity;
      if (containerType && containerType.startsWith("20")) teu += 1;
      if (containerType && containerType.startsWith("40")) teu += 2;
      if (!isNaN(containerTypeMultipliers[containerType])) {
        containerTypeMultipliers[containerType] =
          containerTypeMultipliers[containerType] + 1;
      }
    }
  );
  const chargeableWeight = Math.max(weight, volumetricWeight);
  return {
    volume,
    volumetricWeight,
    chargeableWeight,
    teu,
    "W/M": Math.max(weight / 1000, volume),
    ...(isAereal ? { "W/M": chargeableWeight } : {}),
    ton: weight / 1000,
    container: cargo.length,
    lump: loadedQuantity,
    pallet: loadedQuantity,
    ...containerTypeMultipliers,
    else: 1,
  };
};

export const multiplyChargeCore = ({
  amount = 0,
  currency,
  multiplier,
  minimalAmount = 0,
}) => {
  const currentCharge = Dinero({
    amount,
    currency,
    precision: currencyPrecision[currency],
  }).multiply(multiplier);
  const minimalCharge = Dinero({
    currency,
    amount: minimalAmount,
    precision: currencyPrecision[currency],
  });
  const finalCharge = Dinero.maximum([currentCharge, minimalCharge]);
  return finalCharge;
};

export const multiplyCharge = ({
  amount = 0,
  currency,
  multiplier,
  minimalAmount = 0,
}) => {
  const multipliedCharge = multiplyChargeCore({
    amount,
    currency,
    multiplier,
    minimalAmount,
  });
  return dineroFormatter(multipliedCharge.toObject());
};

export const dineroObjectFormatter = (dineroObject) => {
  const isInClp = dineroObject.getCurrency() === "CLP";
  const formatToUse = isInClp ? "USD0,0" : "USD0,0.00";
  return dineroObject.toFormat(formatToUse);
};
