import Cookie from "js-cookie";

export const cookieSetter = ({ cookieName, newValue }) => {
  const currentValue = Cookie.get(cookieName);
  if (!newValue) {
    Cookie.remove(cookieName);
  } else if (newValue !== currentValue) {
    Cookie.set(cookieName, newValue);
  }
};
