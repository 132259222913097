import isNode from "detect-node";
import React, { createContext, useContext } from "react";

import { debounce } from "../../lib/helpers";

const BREAKPOINTS = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1600,
};

export interface IWindowDimensionsContext {
  width?: number;
  height?: number;
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  xxl?: boolean;
}

export const WindowDimensionsContext = createContext<IWindowDimensionsContext>(
  {}
);

const calculateDimensions = () => {
  if (isNode) return {};
  const width = window.innerWidth;
  const height = window.innerHeight;
  return {
    width,
    height,
    xs: width <= BREAKPOINTS.SM,
    sm: width > BREAKPOINTS.SM && width <= BREAKPOINTS.MD,
    md: width > BREAKPOINTS.MD && width <= BREAKPOINTS.LG,
    lg: width > BREAKPOINTS.LG && width <= BREAKPOINTS.XL,
    xl: width > BREAKPOINTS.XL && width <= BREAKPOINTS.XXL,
    xxl: width > BREAKPOINTS.XXL,
  };
};
export class WindowDimensionsProvider extends React.Component {
  public state = {
    dimensions: {},
  };

  public handleResize = debounce(
    () => this.setState({ dimensions: calculateDimensions() }),
    500
  );

  public componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  public render() {
    return (
      <WindowDimensionsContext.Provider value={this.state.dimensions}>
        {this.props.children}
      </WindowDimensionsContext.Provider>
    );
  }
}

export const useWindowDimensions = () => useContext(WindowDimensionsContext);
