import { Button, Card, Checkbox, Col, Dropdown, Row } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useRouter } from "next/router";
import { useOperationTypes } from "../OperationTypeContext/OperationTypeContext";

import { useRoles } from "@/lib/authorization";
import styles from "./OperationTypeSelector.module.css";

type Props = {};

const { Group: CheckboxGroup } = Checkbox;

const OperationTypeSelector: React.FunctionComponent<Props> = () => {
  const Router = useRouter();
  const intl = useIntl();
  const { isCustomsAgency } = useRoles();

  const [visible, setVisible] = useState(false);
  const { operationTypes, setOperationTypes } = useOperationTypes();
  const conditionalRedirect = () => {
    const currRoute = Router.route;
    if (currRoute === "/shipments/[id]") Router.push("/cargos");
  };

  const handleOperationType = (e) => {
    conditionalRedirect();
    setOperationTypes({ key: e.target.value, value: e.target.checked });
  };
  const dropdownMenu = (
    <Card>
      <Row>
        <Col>
          <div className={styles.sectionContainer}>
            <CheckboxGroup value={operationTypes}>
              <Row align="middle" gutter={12}>
                <Col>
                  <Checkbox value="IMPORT" onChange={handleOperationType}>
                    <FormattedMessage id="IMPORT" />
                  </Checkbox>
                </Col>
                <Col>
                  <Checkbox value="EXPORT" onChange={handleOperationType}>
                    <FormattedMessage id="EXPORT" />
                  </Checkbox>
                </Col>
              </Row>
            </CheckboxGroup>
          </div>
        </Col>
      </Row>
    </Card>
  );
  return (
    <>
      {!isCustomsAgency && (
        <Dropdown overlay={dropdownMenu} trigger={["click"]} visible={visible}>
          <Button onClick={() => setVisible(!visible)}>
            {operationTypes
              .map((type) => intl.formatMessage({ id: type }))
              .join("/")}
          </Button>
        </Dropdown>
      )}
    </>
  );
};

export default OperationTypeSelector;
