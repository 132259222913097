import { Divider, Layout } from "antd";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames/bind";
import Link from "next/link";
import some from "lodash/fp/some";

import { useRoles } from "@/lib/authorization";

import styles from "./AppFooter.module.css";

const cx = classNames.bind(styles);

const { Footer } = Layout;

const AppFooter = ({ customClassName }) => {
  const version = process.env.NEXT_PUBLIC_VERSION;
  const {
    isTeuai,
    isSalesPerson,
    isCustomerService,
    isFinance,
    isOperative,
    isMarketing,
  } = useRoles();

  return (
    <Footer
      className={cx({
        footer: true,
        ...(customClassName ? { [customClassName]: true } : {}),
      })}
    >
      <FormattedMessage
        id="footerTextCredits"
        values={{ year: moment().year() }}
      />
      <Divider type="vertical" />
      {some(Boolean, [
        isTeuai,
        isSalesPerson,
        isCustomerService,
        isFinance,
        isOperative,
        isMarketing,
      ]) ? (
        <Link href={`/changelogs/v-${version.split(".").join("-")}`}>
          <a rel="noopener noreferrer" target="_blank">
            <FormattedMessage id="footerTextVersion" values={{ version }} />
          </a>
        </Link>
      ) : (
        <FormattedMessage id="footerTextVersion" values={{ version }} />
      )}
    </Footer>
  );
};

export default AppFooter;
