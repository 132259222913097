import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import isNode from "detect-node";
import Cookie from "js-cookie";
// import fetch from "isomorphic-unfetch";
import { withApollo as withApolloNext } from "next-apollo";

import { OFFICE_CODE_COOKIE } from "src/features/officeReferences/constants";
import { YEAR_COOKIE } from "src/common/constants";
import Auth from "@/lib/auth";

let apolloClient: ApolloClient<NormalizedCacheObject> = null;

// Polyfill fetch() on the server (used by apollo-client)
// if (isNode) {
//   global.fetch = fetch;
// }

function create(initialState, context) {
  const authLink = setContext(async (_, { headers }) => {
    const token = await Auth.getToken(context, true);
    const officeCode = Cookie.get(OFFICE_CODE_COOKIE);
    const year = Cookie.get(YEAR_COOKIE);
    return {
      headers: {
        ...headers,
        officeCode,
        year: year || "",
        authorization: token || undefined,
      },
    };
  });

  let httpLink;
  const httpLinkOptions = {
    // Disables triggering ssr apollo calls because:
    // - state is not being rehydrated on our app so it is useless,
    // - context is not being set so most calls with fail and throw 'unathorized'
    // Both points are addressable and we should revisit this soon.
    fetch: isNode ? async () => new Response() : fetch,
    uri: process.env.NEXT_PUBLIC_APOLLO_URI,
    credentials: "same-origin",
  };
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const { BatchHttpLink } = require("@apollo/client/link/batch-http");
    httpLink = new BatchHttpLink(httpLinkOptions);
  } else {
    httpLink = new HttpLink(httpLinkOptions);
  }
  const httpLinkWithToken = authLink.concat(httpLink);

  return new ApolloClient({
    link: httpLinkWithToken.setOnError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map((error) => {
          return console.log(
            `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`
          );
        });
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    connectToDevTools: true,
    ssrMode: isNode,
    cache: new InMemoryCache().restore(initialState || {}),
  });
}

export const initApollo = (initialState, context) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad
  if (isNode) {
    return create(initialState, context);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, context);
  }
  return apolloClient;
};

export const withApollo = withApolloNext(initApollo({}, {}));
