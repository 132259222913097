import { AppPropsType } from "next/dist/shared/lib/utils";
import Head from "next/head";
import React from "react";
import { YearProvider } from "src/common/Context/YearContext";
import { OfficeProvider } from "src/features/officeReferences/OfficeContext";

import { PageFunctionComponent } from "../@types/pages";
import { LanguageProvider } from "../components/Language/LanguageContext";
import { getLayout as getDefaultLayout } from "../components/Layouts/AppLayout";
import PagesTitle from "../components/PagesTitle/PagesTitle";
import { WindowDimensionsProvider } from "../components/WindowDimensionsContext/WindowDimensionsContext";
import { AuthProvider } from "../lib/AuthContext";
import { MixpanelProvider } from "../lib/MixpanelContext";

import "./_app.scss";

const App = ({
  Component,
  pageProps,
  Component: { getLayout = getDefaultLayout },
  err,
}: AppPropsType & { Component: PageFunctionComponent }) => {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="msapplication-config" content="/static/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="#101858" />
        <meta name="theme-color" content="#101858" />
        <title>KLog.co</title>
        <link rel="manifest" href="/static/site.webmanifest" />
        <link
          rel="preload"
          href="/static/fonts/open-sans-v18-latin-300.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/static/fonts/open-sans-v18-latin-regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/static/fonts/open-sans-v18-latin-600.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/static/fonts/open-sans-v18-latin-700.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/static/fonts/open-sans-v18-latin-800.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/static/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/static/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/static/favicon-16x16.png"
        />
        <link
          rel="mask-icon"
          href="/static/safari-pinned-tab.svg"
          color="#101858"
        />
        <link rel="icon" href="/static/favicon.ico" />
      </Head>
      <YearProvider>
        <MixpanelProvider>
          <AuthProvider initialUser={pageProps.user}>
            <OfficeProvider>
              <LanguageProvider>
                <PagesTitle />
                <WindowDimensionsProvider>
                  {getLayout(<Component {...pageProps} />)}
                </WindowDimensionsProvider>
              </LanguageProvider>
            </OfficeProvider>
          </AuthProvider>
        </MixpanelProvider>
      </YearProvider>
    </>
  );
};

export default App;
