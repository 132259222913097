import axios from "axios";
import { getIn } from "formik";
import deepClone from "lodash.clonedeep";
import { all, create } from "mathjs";
import moment from "moment";
import momentTz from "moment-timezone";
import { isValidElement } from "react";
import XLSX from "xlsx";
import { find, propEq } from "ramda";
import { useRoles } from "./authorization";
import { IUser } from "@/types/user";
import {
  IConsolidation,
  IShipment,
  IShipmentMilestone,
  IShipmentMilestonesHelper,
  ITimeZoneHelper,
} from "@/types/shipment";

import {
  ADMIN,
  AIR,
  BANNED_CARRIERS_TO_TRACK,
  chileanPorts,
  COMPANY,
  CONTACT_SUPPLIER,
  CUSTOMER_SERVICE,
  DESTINATION_MILESTONES,
  destinationDates,
  EMAIL_REGEX,
  EXPORT,
  FCL,
  IMPORT,
  LAND,
  LCL,
  LCL_LCL,
  months,
  OCEAN,
  ORIGIN_MILESTONES,
  originDates,
  QUOTING_SEARCHER_AIR_POL_CODES,
  SEAAIR,
  STACKING,
  TTN,
  UTC,
  WITH_COLOADER,
  FCL_FCL,
  BANNED_CARRIERS_TO_TRACK_FOR_TEUAIADMIN_USER,
  COMPANY_ADMIN,
  GENERAL_CARGO,
  WITH_CARRIER,
} from "./constants";

const isProduction = process.env.NODE_ENV === "production";
const math = create(all, {
  epsilon: 1e-12,
  matrix: "Matrix",
  number: "number",
  precision: 64,
  predictable: false,
  randomSeed: null,
});

export const stringInField = (search: string, target: string) => {
  return (
    target && target.toString().toLowerCase().includes(search.toLowerCase())
  );
};

export const fieldSorter = (field: string) => {
  return (objectA: any, objectB: any) => {
    const a = getIn(objectA, field);
    const b = getIn(objectB, field);
    switch (typeof a) {
      case "number":
        return a - b;
      case "string":
        return a && a.localeCompare(b);
    }
  };
};

export const debounce = (fn, time) => {
  let timeout;

  return function () {
    const functionCall = () => fn.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export const orderMilestones = (milestones = [], reverse = false) =>
  milestones.sort(
    ({ reference: { order: orderA } }, { reference: { order: orderB } }) => {
      if (reverse) return orderB - orderA;
      return orderA - orderB;
    }
  );
export const truncateText = (text = "", maxCharacters = 100) =>
  text && text.length > maxCharacters
    ? `${text.substring(0, maxCharacters - 3)}...`
    : text;

export const quoteRequestContainers = ({ containers }) =>
  containers
    .map(({ count }, containerIndex) => {
      switch (containerIndex) {
        case 0:
          return count > 0 ? { count, containerType: "20ST" } : {};
        case 1:
          return count > 0 ? { count, containerType: "40ST" } : {};
        case 2:
          return count > 0 ? { count, containerType: "40HC" } : {};
        case 3:
          return count > 0 ? { count, containerType: "40NOR" } : {};
        case 4:
          return count > 0 ? { count, containerType: "40RF" } : {};
        default:
          return {};
      }
    })
    .filter(({ count }) => count > 0);

export const generateId = () => Math.random().toString(36);

export const trimAllCells = (sheet) => {
  const range = XLSX.utils.decode_range(sheet["!ref"]);
  for (let R = range.s.r; R <= range.e.r; R += 1) {
    for (let C = range.s.c; C <= range.e.c; C += 1) {
      const coord = XLSX.utils.encode_cell({ r: R, c: C });
      const cell = sheet[coord];
      if (!cell || !cell.v) continue;
      if (cell.t === "s") cell.v = cell.v.trim();
      if (cell.w) cell.w = cell.w.trim();
    }
  }
};

export const getLastCompletedMilestoneTitle = (milestones) => {
  const lastCompletedMilestone = milestones
    .sort(
      ({ reference: { order: orderA } }, { reference: { order: orderB } }) =>
        orderB - orderA
    )
    .find(({ completed }) => !!completed);
  return lastCompletedMilestone.reference.title;
};
export const dateRangeHandler = (quotingArray) => {
  const dateRange = [null, null];
  for (const quoteInfo of quotingArray) {
    const { quotingValidity } = quoteInfo;
    const startDate = quotingValidity[0];
    const endDate = quotingValidity[1];
    if (!dateRange[0] && !dateRange[1]) {
      dateRange[0] = startDate;
      dateRange[1] = endDate;
      continue;
    }
    if (moment(startDate).isSameOrBefore(moment(dateRange[0]))) {
      dateRange[0] = startDate;
    }
    if (moment(endDate).isSameOrAfter(moment(dateRange[1]))) {
      dateRange[1] = endDate;
    }
  }
  return dateRange;
};
export const generateCustomerFormDataset = (company) => {
  const {
    name: companyName,
    legalName: companyLegalName,
    taxId,
    commercialAddress,
    city,
    country,
    phone,
    createdAt,
    invoiceEmail,
    legalRepresentative: {
      name: legalRepresentativeName,
      taxId: legalRepresentativeTaxId,
    },
    operationsRepresentative: {
      name: operationsRepresentativeName,
      phone: operationsRepresentativeTel,
      email: operationsRepresentativeEmail,
    },
    paymentsRepresentative: {
      name: paymentsRepresentativeName,
      phone: paymentsRepresentativeTel,
      email: paymentsRepresentativeEmail,
    },
    customerService,
    salesPerson,
  } = company;

  const dateOfAdmission = moment(createdAt).format("DD/MM/YYYY");

  const legalName = !companyLegalName ? companyName : companyLegalName;

  const data = [];

  const executiveCommercial = salesPerson ? salesPerson.name : "";
  const executiveOperational = customerService ? customerService.name : "";

  const client = {
    legalName,
    taxId,
    commercialAddress,
    city,
    country,
    phone,
    dateOfAdmission,
    invoiceEmail,
    legalRepresentativeName,
    legalRepresentativeTaxId,
    operationsRepresentativeName,
    operationsRepresentativeTel,
    operationsRepresentativeEmail,
    paymentsRepresentativeName,
    paymentsRepresentativeTel,
    paymentsRepresentativeEmail,
    executiveCommercial,
    executiveOperational,
  };

  const creditApplication = [
    "",
    ["creditTitle", "creditAdmission", "creditApproval"],
    "",
    ["airExportEtd", ""],
    ["airImportEta", "cash"],
    ["seaExportEtd", ""],
    ["seaImportEta", "cash"],
    "",
  ];

  const customs = [
    ["localExpenses"],
    ["customsAgent"],
    ["client"],
    "",
    ["creditDescription"],
    "",
    "",
    "",
    "",
    "",
  ];

  const signatures = [
    ["approvalSignatures"],
    "",
    "",
    "",
    "",
    "",
    ["salesPerson", "salesPersonManager", "generalManager"],
  ];

  const clientArray = Object.entries(client);

  data.push(...clientArray, ...creditApplication, ...customs, ...signatures);

  return data;
};
export const formatExcelRows = (uploadedRows, name) => {
  if (name === "shipmentInfoFile") {
    const uploadedShipmentReferences = uploadedRows.reduce(
      (accumulator, uploadedRow) => {
        const [shipmentReference] = Object.values(uploadedRow);
        if (accumulator.includes(shipmentReference)) return accumulator;
        accumulator.push(shipmentReference);
        return accumulator;
      },
      []
    );

    return uploadedShipmentReferences.map((currentRef) => {
      const currentReferenceData = uploadedRows.filter(
        (row) => currentRef === row[Object.keys(row)[0]]
      );
      let shippedQuantity = 0;
      let bookedQuantity = 0;
      const shipmentReferences = [];
      const poReferences = [];
      currentReferenceData.forEach((currentData) => {
        const [
          shipmentReference,
          ,
          poRef,
          ,
          excelBookedQuantity,
          ,
          ,
          ,
          excelShippedQuantity,
        ] = Object.values(currentData);
        if (!shipmentReferences.includes(shipmentReference)) {
          shipmentReferences.push(shipmentReference);
        }
        if (!poReferences.includes(poRef)) {
          poReferences.push(poRef);
        }
        bookedQuantity += parseInt(excelBookedQuantity || 0, 10);
        shippedQuantity += parseInt(excelShippedQuantity || 0, 10);
      });
      return {
        bookedQuantity,
        shippedQuantity,
        shipmentReferences,
        poReferences,
        name: currentRef,
      };
    });
  }
  if (name === "updateShipmentsFile") {
    return uploadedRows.map((uploadedRow) => {
      const [
        reference,
        ,
        ,
        incoterm,
        forwarderReference,
        ,
        carrier,
        coloader,
        ,
        ,
        vessel,
        voyage,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        etd,
        eta,
        ,
        ,
        ,
        ,
        shipper,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        shippedQuantity,
      ] = Object.values(uploadedRow);
      return {
        shipper,
        carrier,
        coloader,
        vessel,
        voyage,
        etd,
        eta,
        forwarderReference,
        incoterm,
        shippedQuantity,
        name: reference,
      };
    });
  }
  return [];
};
export const getCarrierName = (carriersList, carrierScac) => {
  const currentCarrier = carriersList.find(
    ({ value }) => value === carrierScac
  );
  if (currentCarrier) return currentCarrier.name;
  return undefined;
};

export const getColoaderName = (coloadersList, coloaderName) => {
  const currentColoader = coloadersList.find(
    ({ value }) => value === coloaderName
  );
  if (currentColoader) return currentColoader.name;
  return undefined;
};

export const getTzBasedInDate = (date, { originTz, destinationTz }) => {
  if (originDates.includes(date)) return originTz;
  if (destinationDates.includes(date)) return destinationTz;

  return UTC;
};

export const groupByRange = (
  dataset,
  initialAccumulator,
  scheduleA,
  scheduleB
) =>
  dataset.reduce((accumulator, shipment) => {
    const { schedule } = shipment;
    const tzInfo = getTimeZonesOfShipment(shipment);
    const timeZoneOfScheduleA = getTzBasedInDate(scheduleA, tzInfo);
    const timeZoneOfScheduleB = getTzBasedInDate(scheduleB, tzInfo);
    const parseScheduleB = momentTz(schedule[scheduleB])
      .tz(timeZoneOfScheduleB)
      .startOf("date");
    const parseScheduleA = momentTz(schedule[scheduleA])
      .tz(timeZoneOfScheduleA)
      .startOf("date");
    const daysDifferential = parseScheduleB.diff(parseScheduleA, "days");
    const isLessThanZero = daysDifferential < 0;
    const quotient = isLessThanZero ? 0 : Math.floor(daysDifferential / 5);
    const remainder = isLessThanZero ? 0 : daysDifferential % 5;

    const normalCases = !remainder ? quotient : quotient + 1;
    const accIndex = quotient > 3 ? 4 : normalCases;

    accumulator[accIndex].numberOfOcurrences += 1;
    accumulator[accIndex].y =
      accumulator[accIndex].numberOfOcurrences / dataset.length;
    accumulator[accIndex].label = `${(
      (accumulator[accIndex].numberOfOcurrences * 100) /
      dataset.length
    ).toFixed(2)}% (${accumulator[accIndex].numberOfOcurrences})`;

    return accumulator;
  }, initialAccumulator);

export const getFiveDaysRangeArray = (intl) => [
  intl.formatMessage({ id: "onTime" }),
  "1 - 5",
  "6 - 10",
  "11 - 15",
  "16+",
];

export const generateInitializer = (fiveDaysRangeArray) => {
  return fiveDaysRangeArray.map((range) => ({
    x: range,
    numberOfOcurrences: 0,
    y: 0,
    label: 0,
  }));
};
export const generateTickValuesForX = (array) => array.map((x) => ({ x }));

export const addBasePorts = (setFieldValue, pols, basePorts) => {
  const basePortsNotInFormik = basePorts.filter((code) => !pols.includes(code));
  const updatedPortsArray = pols.concat(basePortsNotInFormik);
  setFieldValue("pols", updatedPortsArray);
};

export const shipmentCargoes = (fragment) => {
  const query = `
    query getShipmentCargoes(
      $companyId: ID
      $startDate: DateTime
      $endDate: DateTime
      $containerLoadType: String
      $pol: String
      $pod: String
      $operationTypes:[String!]
    ) {
      getShipmentCargoes(
        companyId: $companyId
        startDate: $startDate
        endDate: $endDate
        containerLoadType: $containerLoadType
        pol: $pol
        pod: $pod
        operationTypes: $operationTypes
      ) {
        ${fragment}
      }
    }
  `;
  return query;
};

export const shipmentSchedules = (fragment) => {
  const query = `
    query getShipmentsBySchedule(
      $companyId: ID
      $startDate: DateTime
      $endDate: DateTime
      $pol: String
      $pod: String
      $scheduleFilterA: String
      $scheduleFilterB: String
      $operationTypes: [String!]
    ) {
      getShipmentsBySchedule(
        companyId: $companyId
        startDate: $startDate
        endDate: $endDate
        pol: $pol
        pod: $pod
        scheduleFilterA: $scheduleFilterA
        scheduleFilterB: $scheduleFilterB
        operationTypes: $operationTypes
      ) {
        ${fragment}
      }
    }
  `;
  return query;
};

export const timelineDataset = (array, startDate, endDate) => {
  let dataset = [];
  const monthsWithData = array.map(({ x }) => ({
    id: `${moment(x).format("MYYYY")}`,
  }));
  if (startDate.year() === endDate.year()) {
    const monthRange = months.slice(0, endDate.month()).map((el) => ({
      id: `${months.indexOf(el)}${endDate.format("YYYY")}`,
      x: new Date(endDate.year(), months.indexOf(el), 1),
      y: 0,
    }));
    dataset = filteredMonthsOnRange(monthRange, startDate, endDate, array);
  } else {
    const monthRange = months
      .slice(startDate.month())
      .map((el) => ({
        id: `${months.indexOf(el) + 1}${startDate.format("YYYY")}`,
        y: 0,
        x: new Date(startDate.year(), months.indexOf(el), 1),
      }))
      .concat(
        months.slice(0, endDate.month() + 1).map((el) => ({
          id: `${months.indexOf(el) + 1}${endDate.format("YYYY")}`,
          y: 0,
          x: new Date(endDate.year(), months.indexOf(el), 1),
        }))
      );

    monthsWithData.forEach(({ id: idData }) => {
      if (monthRange.find(({ id }) => id === idData)) {
        monthRange.splice(
          monthRange.findIndex(({ id }) => id === idData),
          1
        );
      }
      dataset = filteredMonthsOnRange(monthRange, startDate, endDate, array);
    });
  }
  return dataset;
};
export const shipmentStageTagConfig = [
  { stage: "COORDINATING", color: "orange", tagName: "coordinatingTag" },
  { stage: "IN_ORIGIN", color: "orange", tagName: "originTag" },
  { stage: "IN_TRANSIT", color: "green", tagName: "transitTag" },
  { stage: "ON_DESTINATION", color: "green", tagName: "destinationTag" },
  { stage: "FINISHED", color: "geekblue", tagName: "finishedTag" },
  { stage: "NULLIFIED", tagName: "nullifiedTag" },
  { stage: "PHANTOM", tagName: "nullifiedTag" },
];
export const notificationStatusTagConfig = [
  {
    status: "TRANSBORDO PENDIENTE",
    color: "orange",
  },
  {
    status: "BL ORIGINAL CORREGIDO",
    color: "green",
  },
  {
    status: "BL CON EMISION EN DESTINO",
    color: "blue",
  },
  {
    status: "HAY INFORMACION",
    color: "geekblue",
  },
  {
    status: "NO HAY INFORMACION",
    color: "grey",
  },
  {
    status: "PRIMERA NOTIFICACION",
    color: "grey",
  },
];
export const priorityBadgeConfig = [
  {
    priority: "nonTeuPriority",
    status: "default",
    key: "NON_TEU",
    color: "purple",
  },
  { priority: "highPriority", status: "success", key: "HIGH" },
  { priority: "mediumPriority", status: "warning", key: "MEDIUM" },
  { priority: "lowPriority", status: "default", key: "LOW" },
  { priority: "subClient", status: "default", key: "SUB_CLIENT" },
  { priority: "prospect", status: "default", key: "PROSPECT", color: "orange" },
];

export const extractShipperNames = (purchaseOrders, shippers = []) => {
  const names = [];
  if (purchaseOrders.length) {
    purchaseOrders.forEach(({ shipper: { name } }) => {
      names.push(name);
    });
  }
  if (shippers.length) {
    shippers.forEach(({ name }) => {
      names.push(name);
    });
  }
  return [...new Set(names)];
};

export const sortObjectNumerically = (object) =>
  Object.entries(object)
    .sort(([keyA], [keyB]) => {
      const start = parseInt(keyA.replace(/^\D+/g, ""), 10);
      const end = parseInt(keyB.replace(/^\D+/g, ""), 10);
      return start - end;
    })
    .reduce((accumulator, [key, value]) => {
      accumulator[key] = value;
      return accumulator;
    }, {});

const toRadians = (deg) => deg * (Math.PI / 180);
const toDegrees = (rad) => rad / (Math.PI / 180);
export const findCenter = (
  { lat: lat1, lng: lon1 },
  { lat: lat2, lng: lon2 }
) => {
  const dLon = toRadians(lon2 - lon1);

  const radlat1 = toRadians(lat1);
  const radlat2 = toRadians(lat2);
  const radlon1 = toRadians(lon1);

  const Bx = Math.cos(radlat2) * Math.cos(dLon);
  const By = Math.cos(radlat2) * Math.sin(dLon);
  const lat3 = Math.atan2(
    Math.sin(radlat1) + Math.sin(radlat2),
    Math.sqrt((Math.cos(radlat1) + Bx) * (Math.cos(radlat1) + Bx) + By * By)
  );
  const lon3 = radlon1 + Math.atan2(By, Math.cos(radlat1) + Bx);
  return {
    lat: toDegrees(lat3),
    lng: toDegrees(lon3),
  };
};
export const groupNotificationsByTypeAndChannel = (notifications) => {
  const translator = {
    SHIPMENT: "shipment",
    QUOTE: "quote",
  };
  const { unreadCount, ...groupedNotificationsObject } = notifications.reduce(
    (acc, notification) => {
      const { forwarderReference, type, typeId, ...rest } = notification;
      if (!acc[typeId]) {
        acc[typeId] = {
          typeId,
          forwarderReference,
          type: translator[type],
          notifications: [rest],
        };
      } else {
        acc[typeId].notifications.push(rest);
      }
      if (!rest.isRead) acc.unreadCount += 1;
      return acc;
    },
    { unreadCount: 0 }
  );

  return {
    unreadCount,
    groupedNotifications: Object.values(groupedNotificationsObject),
  };
};
export const swapArrayElements = (arr, indexA, indexB) => {
  const copy = [...arr];
  const temp = copy[indexA];
  copy[indexA] = copy[indexB];
  copy[indexB] = temp;
  return copy;
};
export const seaAirLocation = (
  transshipments,
  { reference } = {},
  trackedVesselPosition,
  podLocation
) => {
  const [{ pod: oceanPod }, { pod: landPod }] = transshipments;
  if (trackedVesselPosition && reference?.order < 4) {
    return trackedVesselPosition;
  }
  let currentVesselPosition;
  switch (reference?.order) {
    case 4:
      currentVesselPosition = oceanPod?.location;
      break;
    case 5:
      currentVesselPosition = landPod?.location;
      break;
    case 6:
      currentVesselPosition = findCenter(landPod?.location, podLocation);
      break;
    case 7:
      currentVesselPosition = podLocation;
      break;
    default:
      currentVesselPosition = {};
      break;
  }
  return currentVesselPosition;
};
export const aerealLocation = (
  polLocation,
  podLocation,
  { reference } = {},
  forcedVesselPosition
) => {
  if (forcedVesselPosition) return forcedVesselPosition;
  let currentVesselPosition;
  switch (reference?.order) {
    case 3:
      currentVesselPosition = polLocation;
      break;
    case 4:
      currentVesselPosition = findCenter(polLocation, podLocation);
      break;
    case 5:
      currentVesselPosition = podLocation;
      break;
    default:
      currentVesselPosition = {};
      break;
  }
  return currentVesselPosition;
};

export const uploadToS3 = async (file, signedRequest) => {
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": file.type,
    },
  };
  await axios.put(signedRequest, file, options);
};
export const formatFilename = (filename) => {
  const date = moment().format("YYYYMMDD-HH-mm");
  const cleanFileName = filename.toLowerCase().replace(/[^a-z0-9]/g, "-");
  const newFilename = `documents/${date}-${cleanFileName}`;
  return newFilename.substring(0, 58);
};

export const quoteStatusTagConfig = [
  { status: "INITIALIZED", color: "gray", tagName: "initializedTag" },
  { status: "SENT_TO_CLIENT", color: "orange", tagName: "sentToClientTag" },
  { status: "DRAFT", color: "orange", tagName: "draftTag" },
  {
    status: "ACCEPTED",
    color: "orange",
    tagName: "acceptedTag",
    statusLabelForQuotes: "QUOTE_RATE_ACCEPTED",
  },
  { status: "ROUTED", color: "green", tagName: "routedTag" },
  {
    status: "COMPLETED",
    statusLabel: "QUOTE_COMPLETED",
    color: "blue",
    tagName: "completedTag",
  },
  {
    status: "EXPIRED",
    statusLabel: "QUOTE_EXPIRED",
    color: "red",
    tagName: "expiredTag",
  },
  {
    status: "REJECTED",
    color: "red",
    tagName: "rejectedTag",
    statusLabelForQuotes: "rejectedQuote",
  },
  {
    status: "NULLIFIED",
    color: "gray",
    tagName: "nullifiedTag",
  },
];

export const twoHighestCharges = (charges) =>
  charges.sort((a, b) => b - a).slice(0, 2);

export const filteredDisplayDataset = (dataset, filtersList) => {
  let total = 0;
  const filteredDataset = dataset.filter(({ originalName, y }) => {
    const isIncluded = filtersList.includes(originalName);
    if (isIncluded) total += y;
    return isIncluded;
  });
  const amounts = filteredDataset.map(({ y }) => y);
  return filteredDataset.map(({ y, ...rest }) => ({
    ...rest,
    y,
    showLabel: twoHighestCharges(amounts).includes(y),
    lab: `${((y / total) * 100).toFixed(2)}%`,
  }));
};
export const onChangeSelects = ({
  allRepresentants,
  selectedShipmentType: currentShipmentType,
  selectedCityValue: currentCityValue,
  selectedCountryValue: currentCountryValue,
  selectedAgentValue: currentAgentValue,
}) => {
  return allRepresentants.filter(
    ({ agent: { name: agentName }, shipmentType, city, country }) => {
      const isCityMatched = currentCityValue ? city === currentCityValue : true;
      const isCountryMatched = currentCountryValue
        ? country === currentCountryValue
        : true;
      const isAgentMatched = currentAgentValue
        ? agentName === currentAgentValue
        : true;
      const isShipmentTypeMatched = currentShipmentType.includes(shipmentType);
      return (
        isCityMatched &&
        isAgentMatched &&
        isShipmentTypeMatched &&
        isCountryMatched
      );
    }
  );
};

export const containersToString = (containers) =>
  containers &&
  Object.entries(containers).reduce(
    (str, [container, count]) =>
      str ? `${str}, ${count}x${container}` : `${count}x${container}`,
    ""
  );

export const boxesToChargeableWeight = (boxes) => {
  let volume = 0;
  let weight = 0;
  let boxesQuantity = 0;
  const metricBoxes = (boxes || []).map((box) => {
    const {
      length = 0,
      width = 0,
      height = 0,
      quantity = 0,
      weight: { value: boxWeight = 0, unit: boxWeightUnit = "kg" },
      unit: boxUnit = "m",
    } = box;
    const boxMetricLength = math.unit(length, boxUnit).toNumber("m");
    const boxMetricWidth = math.unit(width, boxUnit).toNumber("m");
    const boxMetricHeight = math.unit(height, boxUnit).toNumber("m");
    const boxMetricVolume = boxMetricLength * boxMetricWidth * boxMetricHeight;
    const boxMetricWeight = math.unit(boxWeight, boxWeightUnit).toNumber("kg");
    volume += boxMetricVolume * quantity;
    weight += boxMetricWeight * quantity;
    boxesQuantity += parseInt(quantity, 10);
    return {
      quantity,
      weight: {
        value: boxMetricWeight,
        unit: "kg",
      },
      length: boxMetricLength,
      width: boxMetricWidth,
      height: boxMetricHeight,
      unit: "m",
    };
  });

  volume = Math.round(volume * 100) / 100;
  weight = Math.round(weight * 100) / 100;
  const volumetricWeight = Math.round(Math.round(volume * 167) * 100) / 100;
  const chargeableWeight =
    Math.round(Math.max(volumetricWeight, weight) * 100) / 100;

  return {
    chargeableWeight,
    volumetricWeight,
    metricBoxes,
    volume,
    weight,
    boxesQuantity,
  };
};
export const translateAndFillDataset = (dataset, intl) => {
  const translateValue = [
    "stage",
    "shipmentType",
    "containerLoadType",
    "hblEmittedOn",
    "packageType",
  ];

  return dataset.map((obj) =>
    Object.entries(obj).reduce(
      (prev, [key, value]) => ({
        ...prev,
        ...(value
          ? {
              [key]: translateValue.includes(key)
                ? `${intl.formatMessage({ id: value })}`
                : value,
            }
          : { [key]: `${intl.formatMessage({ id: "toBeConfirmed" })}` }),
      }),
      {}
    )
  );
};
export const filteredMonthsOnRange = (
  monthRange,
  startDate,
  endDate,
  array
) => {
  const monthsOnRange = monthRange.filter(({ x }) =>
    moment(x).isBetween(startDate, endDate, "month", "[]")
  );
  const dataset = array.concat(monthsOnRange);
  const filteredMonths = dataset.reduce(
    (accumulator, pod) =>
      accumulator.find(({ id }) => id === pod.id)
        ? accumulator
        : [...accumulator, pod],
    []
  );
  return filteredMonths;
};

export const getVersion = async () => {
  const versionResponse = await fetch(
    `https://api.storyblok.com/v1/cdn/spaces/me?token=${process.env.NEXT_PUBLIC_STORYBLOK_API_TOKEN}`
  );

  const {
    space: { version },
  } = await versionResponse.json();

  return version;
};

export interface IStory {
  id: string;
  content: {
    title: string;
    image: string;
    published_at: string;
  };
}

export const getStories = async ({ page, pageSize }): Promise<IStory[]> => {
  const version = await getVersion();
  // `https://api.storyblok.com/v1/cdn/stories?token=${process.env.NEXT_PUBLIC_STORYBLOK_API_TOKEN}&cv=${version}`
  const res = await fetch(
    `https://api.storyblok.com/v1/cdn/stories/?per_page=${pageSize}&page=${page}&filter_query[component][in]=PostSchema&token=${process.env.NEXT_PUBLIC_STORYBLOK_API_TOKEN}&cv=${version}`
  );

  const { stories } = await res.json();

  return stories;
};

export const getStoryById = async (id): Promise<IStory> => {
  const version = await getVersion();
  const res = await fetch(
    `https://api.storyblok.com/v1/cdn/stories/${id}?token=${process.env.NEXT_PUBLIC_STORYBLOK_API_TOKEN}&cv=${version}`
  );
  const { story } = await res.json();

  return story;
};

export const getStoryBySlug = async () => {
  const version = await getVersion();
  const res = await fetch(
    `https://api.storyblok.com/v1/cdn/stories?filter_query[component][in]=jobs&token=${process.env.NEXT_PUBLIC_STORYBLOK_API_TOKEN}&cv=${version}`
  );
  const { stories } = await res.json();
  return stories;
};

export const validateEmail = (email) => {
  const regEx =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  return regEx.test(email);
};

export const validatePhone = (phone) => {
  const regEx = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
  return regEx.test(phone);
};

export const downloadHblValidationMessages = (shipment: IShipment) => {
  const {
    shipmentOperation: { blInformation, shortDescription },
    forwarderDetails: { hbl, hblEmittedOn },
  } = shipment;

  const missingFields = [
    !blInformation && "blInformation",
    !shortDescription && "shortCargoDescription",
    !hbl && "hbl",
    !hblEmittedOn && "hblEmittedOn",
  ];

  return missingFields.filter((item) => !!item);
};
export const amanacValidationMessages = (shipment: IShipment) => {
  const {
    shipmentType,
    containerLoadType,
    cargo = [],
    company: {
      legalName: consigneeName,
      commercialAddress: consigneeAddress,
      taxId: consigneeTaxId,
      country: consigneeCountry,
      city: consigneeCity,
    },
    pod: { code: podCode },
    pol: { code: polCode },
    shipmentBooking: { finalCarrier, finalVessel: vessel, finalVoyage: voyage },
    shipmentOperation: { blInformation },
    shipper = [],
    forwarderDetails: { mawb, hawb, mbl, hbl, direct },
  } = shipment;
  const { scac: carrierCode } = finalCarrier || {};
  const isAerial = shipmentType === AIR;
  const isLclOrAerial =
    containerLoadType === LCL || containerLoadType === GENERAL_CARGO;
  const directWithCarrier = direct === WITH_CARRIER;
  let missingShipperName;
  let missingShipperTaxId;
  let missingShipperAddress;
  let missingShipperCity;
  let missingShipperCountry;
  let missingShipper;

  const missingConsigneeName = !consigneeName && "companyName";
  const missingConsigneeTaxId = !consigneeTaxId && "companyTaxId";
  const missingConsigneeAddress = !consigneeAddress && "companyAddress";
  const missingCompanyCity = isAerial && !consigneeCity && "companyCity";
  const missingCompanyCountry =
    isAerial && !consigneeCountry && "companyCountry";
  if (shipper.length) {
    const [
      {
        name: shipperName,
        address: shipperAddress,
        taxId: shipperTaxId,
        country: { code: shipperCountry },
        city: shipperCity,
      },
    ] = shipper || [];
    missingShipperName = !shipperName && "shipperName";
    missingShipperTaxId = !shipperTaxId && "shipperTaxId";
    missingShipperAddress = !shipperAddress && "shipperAddress";
    missingShipperCity = isAerial && !shipperCity && "shipperCity";
    missingShipperCountry = isAerial && !shipperCountry && "shipperCountry";
  } else {
    missingShipper = "supplier";
  }

  const cargoMessages = [];
  let missingCargoDetails;
  if (cargo.length) {
    if (isLclOrAerial) {
      const missingLoadedQuantity =
        !cargo[0].loadedQuantity && "loadedQuantity";
      const missingWeight = !cargo[0].weight && "weight";
      const missingContainerNumber =
        !isAerial && !cargo[0].containerNumber && "containerNumber";
      cargoMessages.push(
        missingLoadedQuantity,
        missingWeight,
        missingContainerNumber
      );
    } else {
      cargo.forEach(
        ({ weight, loadedQuantity, containerNumber, containerType }) => {
          const missingWeight = !weight && "weight";
          const missingLoadedQuantity = !loadedQuantity && "loadedQuantity";
          const missingContainerNumber = !containerNumber && "containerNumber";
          const missingContainerType = !containerType && "containerType";
          cargoMessages.push(
            missingLoadedQuantity,
            missingWeight,
            missingContainerNumber,
            missingContainerType
          );
        }
      );
    }
  } else {
    missingCargoDetails = "cargoDetails";
  }

  const filteredCargoMessages = cargoMessages.length
    ? cargoMessages.filter(
        (message, index) => cargoMessages.indexOf(message) === index
      )
    : [];
  const missingFields = [
    !shipmentType && "shipmentType",
    !podCode && "podCode",
    !polCode && "polCode",
    !carrierCode && (isAerial ? "airline" : "carrier"),
    !isAerial && !vessel && "vessel",
    !isAerial && !voyage && "voyage",
    isAerial ? !hawb && "hawb" : !directWithCarrier && !hbl && "hbl",
    isAerial ? !mawb && "mawb" : !mbl && "mbl",
    missingConsigneeName,
    missingConsigneeTaxId,
    missingConsigneeAddress,
    missingShipperName,
    missingShipperTaxId,
    missingShipperAddress,
    missingShipper,
    missingCargoDetails,
    ...filteredCargoMessages,
    !blInformation && "blInformation",
    !containerLoadType && "containerLoadType",
    missingShipperCity,
    missingShipperCountry,
    missingCompanyCity,
    missingCompanyCountry,
  ];

  return missingFields.filter((item) => !!item);
};

export const preAlertValidationMessages = ({
  schedule: { initialEta, initialEtd, finalEta, finalEtd, ecrd = "" },
  bookingLoadType,
  shipmentBooking: {
    initialCarrier: carrier,
    initialColoader: coloader,
    finalCarrier,
    bookingNumber,
    vessel,
    voyage,
    finalVoyage,
  },
  forwarderDetails: { hawb, mbl, forwarderReference, warehouse },
  shipmentType,
  shipper = [],
  purchaseOrders,
  transshipments,
  reservedCargo,
  cargo,
  operationType,
  documents,
  seaAirShipmentType,
  schedule,
  shipmentBooking,
  ...rest
}) => {
  const isExport = operationType === EXPORT;
  const isImport = operationType === IMPORT;
  const isAereal = shipmentType === AIR;
  const isMaritime = shipmentType === OCEAN;
  const isSeaAir = shipmentType === SEAAIR;
  const isLcl = bookingLoadType === LCL_LCL;
  const hasPurchaseOrders = !!purchaseOrders.length;
  const isFcl = bookingLoadType !== LCL_LCL && isMaritime;

  const {
    officeStatus: { isMexicanOffice },
  } = rest;

  const cargoMessages = [];
  let missingReservedCargoDetails = "";
  if (isMaritime && isImport) {
    if (reservedCargo.length) {
      reservedCargo.map(({ containerType }) => {
        const missingContainerType = !containerType && "containerType";

        cargoMessages.push(...(isLcl ? [] : [missingContainerType]));
      });
    }
    if (!reservedCargo.length) {
      missingReservedCargoDetails = "reservedCargoDetails";
    }
  }
  const filteredReservedCargoMessages = cargoMessages.length
    ? cargoMessages.filter(
        (message, index) => cargoMessages.indexOf(message) === index
      )
    : [];

  const missingColoader = isLcl && !isSeaAir && !coloader && "initialColoader";
  const missingCargoReady = isExport ? false : isFcl && !ecrd && "ecrd";
  const missingVessel = isExport && !vessel && "vessel";
  let missingVoyage = isExport && !voyage && "voyage";

  const missingForwarderReference = !forwarderReference && "forwarderReference";
  const missingStackingDate =
    isExport && isMaritime && !schedule.stacking && "stacking";
  const missingShipper =
    isImport && !hasPurchaseOrders && !shipper.length && "shipper";

  let missingCarrier = isFcl && !carrier && "initialCarrier";
  let missingEta = !initialEta && "initialEta";
  let missingEtd = !initialEtd && "initialEtd";
  let missingDocumentNumber = isFcl && !bookingNumber && "bookingNumber";
  const tssMissingInfo = [];

  if (isAereal) {
    missingCarrier = !finalCarrier && "airline";
    missingEta = !finalEta && "eta";
    missingEtd = !finalEtd && "etd";
    const hasTransshipments = transshipments.length > 0;
    if (hasTransshipments) {
      !finalVoyage && tssMissingInfo.push("arrivalFlight");
      const [{ eta, etd, carrier: tsCarrier, flightNumber, pod }] =
        transshipments;
      !eta && tssMissingInfo.push("transshipmentFinalEta");
      !etd && tssMissingInfo.push("transshipmentFinalEtd");
      !tsCarrier && tssMissingInfo.push("transshipmentAirline");
      !flightNumber && tssMissingInfo.push("transshipmentFlightNumber");
      !pod && tssMissingInfo.push("transshipmentPod");
    } else {
      missingVoyage = !voyage && "flight";
    }
  }
  const distinctTssMsgs = [...new Set(tssMissingInfo)];
  if (isSeaAir) {
    const sortedTransshipments = seaAirTssParser({
      ...rest,
      schedule,
      shipmentBooking,
      transshipments,
    } as IShipment);
    const isAir = seaAirShipmentType === AIR;
    const aerealTransShipment =
      sortedTransshipments.find(
        ({ transshipmentType }) => transshipmentType === AIR
      ) || {};
    const {
      eta: aerialEta,
      carrier: aerialCarrier,
      etd: aerialEtd,
    } = aerealTransShipment;
    if (isAir) {
      missingDocumentNumber = !hawb && "HAWB";
      missingCarrier = !aerialCarrier && "airline";
      missingEta = !aerialEta && "aerialEta";
      missingEtd = !aerialEtd && "aerialEtd";
    } else {
      const oceanTransShipment = sortedTransshipments.find(
        ({ transshipmentType }) => transshipmentType === OCEAN
      );
      const {
        eta: oceanEta,
        etd: oceanEtd,
        carrier: maritimeCarrier,
      } = oceanTransShipment;
      missingCarrier = !maritimeCarrier && "carrier";
      missingDocumentNumber = !mbl && "MBL";
      missingEta = !oceanEta && "oceanEta";
      missingEtd = !oceanEtd && "oceanEtd";
    }
  }

  const missingFields = [
    missingStackingDate,
    missingCarrier,
    missingColoader,
    missingEta,
    missingEtd,
    missingDocumentNumber,
    missingShipper,
    missingCargoReady,
    missingForwarderReference,
    missingReservedCargoDetails,
    ...filteredReservedCargoMessages,
    missingVessel,
    missingVoyage,
    ...distinctTssMsgs,
  ];

  if (isAereal || isSeaAir) {
    const shipmentCargo = cargo;
    const [cargoData = {}] = shipmentCargo;
    const missingVolume = !cargoData.volume && "volume";
    const missingWeight = !cargoData.weight && "weight";
    const missingPackageType = !cargoData.packageType && "packageType";
    missingFields.push(missingPackageType, missingVolume, missingWeight);
  }
  if (isExport) {
    const [consignee = {}] = shipper;
    const missingWarehouse = !warehouse && "warehouse";
    const missingStackingDocument =
      !documents.find(({ docType }) => docType === STACKING) &&
      "stackingDocument";
    const missingPackingList =
      !documents.find(({ docType }) => docType === "PL") &&
      "packingListDocument";
    const missingInvoiceDocument =
      !documents.find(({ docType }) => docType === "CI") && "invoiceDocument";
    const missingConsignee = !consignee.name && "consignee";
    if (isMexicanOffice) {
      missingFields.push(missingConsignee);
    } else {
      missingFields.push(
        missingConsignee,
        missingWarehouse,
        missingStackingDocument,
        missingPackingList,
        missingInvoiceDocument
      );
    }
  }
  return missingFields.filter((field) => !!field);
};

export const cargoDetailsValidationMessage = ({
  shipmentType,
  reservedCargo,
  bookingLoadType,
  forwarderDetails: { forwarderReference },
  cargo,
}) => {
  const isLcl = bookingLoadType === LCL_LCL;
  const isMaritime = shipmentType === OCEAN;
  const isAereal = shipmentType === AIR;
  const cargoMessages = [];
  let missingReservedCargoDetails;
  const missingForwarderReference = !forwarderReference && "forwarderReference";
  if (isMaritime) {
    if (reservedCargo.length) {
      reservedCargo.map(({ packageType, containerType }) => {
        const missingContainerType = !containerType && "containerType";
        const missingPackageType = !packageType && "packageType";
        cargoMessages.push(
          ...(isLcl ? [missingPackageType] : [missingContainerType])
        );
      });
    } else {
      missingReservedCargoDetails = "reservedCargoDetails";
    }
  }
  if (isAereal) {
    const [{ packageType }] = cargo;
    const missingPackageType = !packageType && "packageType";
    cargoMessages.push(missingPackageType);
  }
  const filteredReservedCargoMessages = cargoMessages.length
    ? cargoMessages.filter(
        (message, index) => cargoMessages.indexOf(message) === index
      )
    : [];
  const missingFields = [
    missingReservedCargoDetails,
    missingForwarderReference,
    ...filteredReservedCargoMessages,
  ];
  return missingFields.filter((field) => !!field);
};
export const draftBlValidationMessage = ({
  shipmentType,
  documents,
  forwarderDetails: { forwarderReference },
}) => {
  const isMaritime = shipmentType === OCEAN;
  const isAerial = shipmentType === AIR;
  const draftHawb =
    !!documents.length && documents.find(({ docType }) => docType === "HAWBD");
  const draftBl =
    !!documents.length && documents.find(({ docType }) => docType === "HBLD");
  const missingDraftBl = isMaritime && !draftBl && "uploadDraftBl";
  const missingDraftHawb = isAerial && !draftHawb && "uploadDraftHawb";
  const missingForwarderReference = !forwarderReference && "forwarderReference";

  const missingFields = [
    missingForwarderReference,
    missingDraftBl,
    missingDraftHawb,
  ];
  return missingFields.filter((field) => !!field);
};
export const departureConfirmationValidationMessages = ({
  schedule: { finalEta, atd },
  bookingLoadType,
  documents,
  shipmentType,
  shipmentBooking: {
    finalCarrier: carrier,
    finalColoader: coloader,
    vessel,
    finalVessel,
    voyage,
    finalVoyage,
  },
  forwarderDetails: { hbl: hblNumber, hawb: hawbNumber },
  transshipments,
  seaAirShipmentType,
  pol,
  pod,
  schedule,
  shipmentBooking,
}) => {
  const hbl =
    !!documents.length && documents.find(({ docType }) => docType === "HBL");
  const hawb =
    !!documents.length && documents.find(({ docType }) => docType === "HAWB");
  const isMaritime = shipmentType === OCEAN;
  const isAereal = shipmentType === AIR;
  const isSeaAir = shipmentType === SEAAIR;
  const isLcl = bookingLoadType === LCL_LCL;
  let missingDocument;
  let missingDocumentNumber;
  let missingVoyage;
  let missingCarrier;
  let missingVessel;
  const hasTransshipments = transshipments.length > 0;

  const missingColoader = isLcl && !isSeaAir && !coloader && "finalColoader";
  let missingEta = !finalEta && "finalEta";
  const transshipmentMessages = [];
  let missingAtd;

  if (isMaritime) {
    missingVessel = hasTransshipments
      ? !transshipments[0].vessel && "vessel"
      : !finalVessel && "vessel";
    missingCarrier = !carrier && "finalCarrier";
    missingDocument = !hbl && "uploadHbl";
    missingDocumentNumber = !hblNumber && "hbl";
    // missingVoyage = hasTransshipments
    //   ? !transshipments[0].flightNumber && "voyage"
    //   : !finalVoyage && "voyage";
    missingAtd = !atd && "atd";
  }
  if (isAereal) {
    missingDocument = !hawb && "uploadHawb";
    missingDocumentNumber = !hawbNumber && "hawb";
    missingVoyage = !voyage && "arrivalFlight";
    missingCarrier = !carrier && "airline";
    if (hasTransshipments) {
      missingVoyage = !finalVoyage && "arrivalFlight";

      transshipments.forEach(
        ({
          eta,
          carrier: transshipimentCarrier,
          flightNumber,
          pod: tssPod,
        }) => {
          missingEta = !eta && "transshipmentFinalEta";
          const missingTransshipmentCarrier =
            !transshipimentCarrier && "transshipmentAirline";
          const missingTransshipmentVoyage =
            !flightNumber && "transshipmentFlightNumber";
          const missingTransshipmentPod = !tssPod && "transshipmentPod";
          transshipmentMessages.push(
            ...(isLcl
              ? []
              : [
                  missingTransshipmentCarrier,
                  missingTransshipmentVoyage,
                  missingTransshipmentPod,
                ])
          );
        }
      );
    }
  }
  if (isSeaAir) {
    const sortedTransshipments = seaAirTssParser({
      pol,
      pod,
      schedule,
      shipmentBooking,
      transshipments,
    } as IShipment);
    const isAir = seaAirShipmentType === AIR;
    if (isAir) {
      const aerealTransShipment = sortedTransshipments.find(
        ({ transshipmentType }) => transshipmentType === AIR
      );
      const {
        eta: aerialEta,
        carrier: aerealCarrier,
        flightNumber,
      } = aerealTransShipment;
      missingDocument = !hawb && "uploadHawb";
      missingDocumentNumber = !hawbNumber && "hawb";
      missingVoyage = !flightNumber && "flightNumber";
      missingCarrier = !aerealCarrier && "airline";
      missingEta = !aerialEta && "aerialEta";
    } else {
      const oceanTransShipment = sortedTransshipments.find(
        ({ transshipmentType }) => transshipmentType === OCEAN
      );
      const {
        eta: oceanEta,
        carrier: maritimeCarrier,
        atd: oceanAtd,
      } = oceanTransShipment;
      missingCarrier = !maritimeCarrier && "carrier";
      missingDocument = !hbl && "uploadHbl";
      missingDocumentNumber = !hblNumber && "hbl";
      missingEta = !oceanEta && "oceanEta";
      missingAtd = !oceanAtd && "oceanAtd";
    }
  }
  const filteredTransshipmentMessages = transshipmentMessages.length
    ? transshipmentMessages.filter(
        (message, index) => transshipmentMessages.indexOf(message) === index
      )
    : [];

  const missingFields = [
    missingDocument,
    missingCarrier,
    missingColoader,
    missingEta,
    missingAtd,
    missingVoyage,
    missingVessel,
    missingDocumentNumber,
    ...filteredTransshipmentMessages,
  ];
  return missingFields.filter((field) => !!field);
};
export const flightArrivalValidationMessages = ({
  schedule: { ata },
  forwarderDetails: { hawb },
  shipmentBooking: {
    finalCarrier: carrier,
    finalVoyage: arrivalFlight,
    voyage,
  },
  transshipments: sortedGenuineTransshipment,
}) => {
  const transshipmentsCount = sortedGenuineTransshipment.length;
  const hasTransshipments = !!transshipmentsCount;
  const missingHawb = !hawb && "hawb";
  let missingVoyage = !voyage && "arrivalFlight";
  const missingCarrier = !carrier && "finalAirline";
  const missingAtd = !ata && "ata";
  if (hasTransshipments) {
    missingVoyage = !arrivalFlight && "arrivalFlight";
  }
  const missingFields = [
    missingCarrier,
    missingAtd,
    missingVoyage,
    missingHawb,
  ];
  return missingFields.filter((field) => !!field);
};
export const passToOperationsValidationMessages = ({
  containerLoadType,
  cargo,
  bookingLoadType,
  forwarderDetails: { hbl, hblEmittedDate, direct, hawb, mbl, mawb },
  shipmentOperation: { profit, preAlertReceivedAt, contractName },
  shipmentBooking: { subscribed, finalCarrier },
  pod: { code: podCode = "" },
  shipmentType,
  operationType,
  schedule: { atd },
  quote: {
    quoteRequestNew: { agent },
  },
  isTeuaiAdmin,
}) => {
  const isFcl = containerLoadType === FCL;
  const isBookingFcl = bookingLoadType === FCL_FCL;
  const isChileanPod = podCode.substring(0, 2) === "CL";
  const isMaritime = shipmentType === OCEAN;
  const isAereal = shipmentType === AIR;
  const isExport = operationType === EXPORT;
  const isDirectWithColoader = direct === WITH_COLOADER;
  const areFreeDaysFilled = cargo.length && !!cargo[0].freeDays;
  let missingHbl;
  let missingHblEmittedDate = "";
  let missingTracking;
  let missingAtd;
  let missingAgent;
  let missingContract;
  if (!isDirectWithColoader) missingAgent = !agent && "agent";
  let missingHawb;
  let missingMbl;
  let missingMawb;
  let missingFreeDays;
  const missingProfit = !profit && "profit";
  const missingPreAlertDate = !preAlertReceivedAt && "preAlertReceivedAt";
  if (isMaritime && !isExport) {
    missingAtd = !atd && "atd";
    missingHbl = !hbl && "hbl";
    missingMbl = isDirectWithColoader ? false : !mbl && "mbl";
    missingContract = isFcl && !contractName && "contractName";
    const bannedCarriersToUse = isTeuaiAdmin
      ? BANNED_CARRIERS_TO_TRACK_FOR_TEUAIADMIN_USER
      : BANNED_CARRIERS_TO_TRACK;
    const subscriptionCondition = bannedCarriersToUse.includes(
      finalCarrier?.scac
    )
      ? false
      : !subscribed;
    missingTracking =
      subscriptionCondition && isProduction && "activateTracking";
    if (isChileanPod && !isDirectWithColoader) {
      missingHblEmittedDate = !hblEmittedDate && "hblEmittedDate";
    }
  }
  if (isBookingFcl) {
    missingFreeDays = !areFreeDaysFilled && "freeDays";
  }
  if (isAereal) {
    missingHawb = !hawb && "hawb";
    missingMawb = !mawb && "mawb";
  }
  const missingFields = [
    missingHbl,
    missingMbl,
    missingHblEmittedDate,
    missingProfit,
    missingPreAlertDate,
    missingTracking,
    missingAtd,
    missingAgent,
    missingHawb,
    missingMawb,
    missingContract,
    missingFreeDays,
  ];
  return missingFields.filter((field) => !!field);
};

export const passToFinanceValidationMessages = ({ schedule: { atd } }) => {
  const missingAtd = !atd && "atd";
  const missingFields = [missingAtd];
  return missingFields.filter((field) => !!field);
};

export const documentsReadyValidationMessages = ({
  forwarderDetails: { hawb },
  shipmentBooking: {
    finalCarrier: carrier,
    finalVoyage: arrivalFlight,
    voyage,
  },
  transshipments: sortedGenuineTransshipment,
}) => {
  const transshipmentsCount = sortedGenuineTransshipment.length;
  const hasTransshipments = !!transshipmentsCount;
  let missingVoyage = !voyage && "arrivalFlight";
  const missingCarrier = !carrier && "finalAirline";
  const missingHawb = !hawb && "hawb";
  if (hasTransshipments) {
    missingVoyage = !arrivalFlight && "arrivalFlight";
  }
  const missingFields = [missingCarrier, missingVoyage, missingHawb];
  return missingFields.filter((field) => !!field);
};
export const exportDocumentsValidationMessages = ({
  shipmentBooking: { finalCarrier: carrier },
  documents,
}) => {
  const carrierBookingDocument =
    !!documents.length && documents.find(({ docType }) => docType === "CB");
  const missingCBDocument = !carrierBookingDocument && "cbDocument";
  const missingCarrier = !carrier && "finalCarrier";

  const missingFields = [missingCBDocument, missingCarrier];
  return missingFields.filter((field) => !!field);
};
export const rightsPaymentValidationMessages = ({ documents }) => {
  const document = !!documents.length;
  const missingCIDocument = !document && "document";
  const missingFields = [missingCIDocument];
  return missingFields.filter((field) => !!field);
};
export const comercialInvoiceValidationMessages = ({ documents }) => {
  const comercialInvoiceDocument =
    !!documents.length && documents.find(({ docType }) => docType === "CI");
  const missingCIDocument = !comercialInvoiceDocument && "ciDocument";

  const missingFields = [missingCIDocument];
  return missingFields.filter((field) => !!field);
};
export const pickUpScheduleValidationMessages = ({
  forwarderDetails: { hawb },
  documents,
}) => {
  const hawbDocument =
    !!documents.length && documents.find(({ docType }) => docType === "HAWB");
  const missingHawbDocument = !hawbDocument && "hawbDocument";
  const missingHawb = !hawb && "hawb";

  const missingFields = [missingHawbDocument, missingHawb];
  return missingFields.filter((field) => !!field);
};
export const bookingProgramToAgentValidationMessages = ({
  shipmentBooking: { vessel, voyage },
  schedule: { initialEta, initialEtd },
  documents,
  quote,
}) => {
  const {
    quoteRequestNew: { agent },
  } = quote;
  const missingAgent = !agent && "agent";

  const missingVessel = !vessel && "vessel";
  const missingVoyage = !voyage && "voyage";
  const missingEta = !initialEta && "eta";
  const missingEtd = !initialEtd && "etd";
  const missingInvoiceDocument =
    !documents.find(({ docType }) => docType === "CI") && "invoiceDocument";

  const missingFields = [
    missingInvoiceDocument,
    missingVessel,
    missingVoyage,
    missingEta,
    missingEtd,
    missingAgent,
  ];
  return missingFields.filter((field) => !!field);
};
export const mblToAgentValidationMessages = ({ documents, quote }) => {
  const {
    quoteRequestNew: { agent },
  } = quote;
  const missingAgent = !agent && "agent";
  const missingMblDocument =
    !documents.find(({ docType }) => docType === "MBL") && "uploadMbl";
  const missingHblDocument =
    !documents.find(({ docType }) => docType === "HBL") && "uploadHbl";

  const missingFields = [missingMblDocument, missingHblDocument, missingAgent];
  return missingFields.filter((field) => !!field);
};
export const liabilityLetterValidationMessages = ({
  forwarderDetails: { mbl, hbl, forwarderReference, warehouse },
  shipmentBooking: { finalCarrier: carrier, finalVessel, finalVoyage },
}) => {
  const missingFinalVoyage = !finalVoyage && "finalVoyage";
  const missingFinalVessel = !finalVessel && "finalVessel";
  const missingCarrier = !carrier && "finalCarrier";
  const missingForwarderReference = !forwarderReference && "forwarderReference";
  const missingHbl = carrier.scac === "ONEY" && !hbl && "hbl";
  const missingWarehouse = !warehouse && "warehouse";
  const missingMbl = !mbl && "mbl";
  const missingFields = [
    missingCarrier,
    missingFinalVoyage,
    missingFinalVessel,
    missingMbl,
    missingForwarderReference,
    missingHbl,
    missingWarehouse,
  ];
  return missingFields.filter((field) => !!field);
};
export const changeWarehouseLetterValidationMessages = ({
  forwarderDetails: { mbl, warehouse },
  shipmentBooking: { finalCarrier: carrier },
}) => {
  const missingCarrier = !carrier && "finalCarrier";
  const missingWarehouse = !warehouse && "warehouse";
  const missingMbl = !mbl && "mbl";
  const missingFields = [missingCarrier, missingMbl, missingWarehouse];
  return missingFields.filter((field) => !!field);
};
export const customsLetterValidationMessages = ({
  forwarderDetails: { mbl, hbl },
  shipmentBooking: { finalVessel, vessel },
}) => {
  const missingVessel = !finalVessel && !vessel && "vessel";
  const missingHbl = !hbl && "hbl";
  const missingMbl = !mbl && "mbl";
  const missingFields = [missingVessel, missingMbl, missingHbl];
  return missingFields.filter((field) => !!field);
};
export const shipmentNoticeValidationMessages = ({
  shipper,
  shipmentType,
  purchaseOrders,
  transshipments,
  forwarderDetails: { hawb, hbl, forwarderReference },
  shipmentBooking: { finalVessel, finalVoyage, vessel, voyage },
  containerLoadType,
  cargo,
  schedule: { finalEtd },
  seaAirShipmentType,
  schedule,
  shipmentBooking,
  shipmentBooking: { subscribed },
  pod,
  pol,
}) => {
  const lcl = containerLoadType === LCL;
  const isMaritime = shipmentType === OCEAN;
  const isAereal = shipmentType === AIR;
  const isSeaAir = shipmentType === SEAAIR;
  const cargoMessages = [];
  let missingCargoDetails;
  if (cargo.length) {
    if (lcl) {
      missingCargoDetails = !cargo[0].packageType && "packageType";
    } else {
      cargo.map(({ loadedQuantity, packageType }) => {
        const missingLoadedQuantity = !loadedQuantity && "loadedQuantity";
        const missingPackageType = !packageType && "packageType";
        cargoMessages.push(missingLoadedQuantity, missingPackageType);
      });
    }
  } else {
    missingCargoDetails = "cargoDetails";
  }

  const filteredCargoMessages = cargoMessages.length
    ? cargoMessages.filter(
        (message, index) => cargoMessages.indexOf(message) === index
      )
    : [];
  const { name: shipperName } = shipper[0] || {};
  const missingShipper = !shipperName && !purchaseOrders.length && "shipper";
  const missingFinalEtd = !finalEtd && "finalEtd";
  let missingVoyage =
    !voyage && !finalVoyage && (isMaritime ? "voyage" : "flight");
  const missingVessel = isMaritime && !vessel && !finalVessel && "vessel";
  const missingForwarderReference = !forwarderReference && "forwarderReference";
  const missingHbl = isMaritime ? !hbl && "hbl" : !hawb && "hawb";
  let transshipmentEta;
  if (isMaritime && transshipments.length && !subscribed) {
    const tsCount = transshipments.length;
    const indexToUse = tsCount - 1;
    transshipmentEta = !transshipments[indexToUse].eta && "transshipmentEta";
  }
  if (isAereal) {
    const hasTss = !!transshipments.length;
    if (hasTss) {
      missingVoyage = !finalVoyage && "arrivalFlight";
    } else {
      missingVoyage = !voyage && "flight";
    }
  }
  let missingAtd;
  let missingEta;
  if (isSeaAir) {
    const parsedTss = seaAirTssParser({
      transshipments,
      schedule,
      shipmentBooking,
      pod,
      pol,
    } as IShipment);
    const isAir = seaAirShipmentType === AIR;
    if (isAir) {
      const aerealTransShipment = parsedTss.find(
        ({ transshipmentType }) => transshipmentType === AIR
      );
      const {
        eta: aerialEta,
        etd: aerialEtd,
        flightNumber,
      } = aerealTransShipment;
      missingVoyage = !flightNumber && "flightNumber";
      missingEta = !aerialEta && "aerialEta";
    } else {
      const oceanTransShipment = parsedTss.find(
        ({ transshipmentType }) => transshipmentType === OCEAN
      );
      const { etd: oceanEtd, atd } = oceanTransShipment;
      missingAtd = !atd && "oceanAtd";
      missingVoyage = undefined;
      // missingFinalEtd = !oceanEtd && "oceanEtd";
    }
  }
  const missingFields = [
    transshipmentEta,
    missingShipper,
    missingVoyage,
    missingVessel,
    missingForwarderReference,
    missingHbl,
    missingAtd,
    missingEta,
    missingCargoDetails,
    missingFinalEtd,
    ...filteredCargoMessages,
  ];
  return missingFields.filter((field) => !!field);
};

export const aerealGuideValidationMessages = ({
  forwarderDetails: { hawb, mawb },
  quote,
  shipper = [],
  cargo,
  schedule: { finalEtd },
  shipmentBooking: { finalCarrier, voyage, finalVoyage },
  shipmentOperation: { saleCharges, airwaybillEmittedDate, fullCharges },
  transshipments,
  shipmentType,
}) => {
  const isAerial = shipmentType === AIR;
  const isSeaAir = shipmentType === SEAAIR;
  const {
    quoteRequestNew: { agent = {} },
  } = quote || { quoteRequestNew: {} };
  const [{ weight, loadedQuantity, volume }] = cargo;

  const hasTransshipments = transshipments.length > 0;

  const missingShipper = !shipper.length && "shipper";
  let missingCarrier;
  let missingTransshipmentAirline;
  let missingTransshipmentAod;
  const missingAgent = !agent && "agent";
  const missingHawb = !hawb && "hawb";
  const missingMawb = !mawb && "mawb";
  let missingDepartureFlight;
  const missingWeight = !weight && "weight";
  const missingVolume = !volume && "volume";
  const missingLoadedQty = !loadedQuantity && "loadedQuantity";
  let missingEtd;
  const missingSaleCharges =
    !fullCharges.some(({ saleAmount }) => !!saleAmount) && "saleConcept";
  const missingAirwaybillEmittedDAte =
    !airwaybillEmittedDate && "airwaybillEmittedDate";
  const lastTransshipment = transshipments[transshipments.length - 1];

  missingCarrier = !finalCarrier && "airline";
  if (isAerial) {
    missingDepartureFlight = !voyage && "departureFlight";
    missingEtd = !finalEtd && "etd";
    if (hasTransshipments) {
      missingDepartureFlight = !finalVoyage && "arrivalFlight";
      missingEtd = !lastTransshipment.etd && "transshipmentEtd";
      missingTransshipmentAirline =
        !transshipments.every(({ carrier }) => !!carrier) &&
        "transshipmentAirline";
      missingTransshipmentAod =
        !transshipments.every(({ pod }) => !!pod) && "transshipmentAod";
    }
  }
  if (isSeaAir) {
    missingDepartureFlight = !finalVoyage && "flightNumber";
    missingEtd = !lastTransshipment.etd && "etd";
  }

  const missingFields = [
    missingTransshipmentAod,
    missingTransshipmentAirline,
    missingCarrier,
    missingShipper,
    missingHawb,
    missingAgent,
    missingMawb,
    missingWeight,
    missingDepartureFlight,
    missingVolume,
    missingLoadedQty,
    missingEtd,
    missingSaleCharges,
    missingAirwaybillEmittedDAte,
  ];
  return missingFields.filter((field) => !!field);
};
export const sidemarValidationMessages = (
  {
    manifestInformation,
    forwarderDetails: { hbl, hblEmittedDate, warehouse },
    shipmentOperation: { blInformation, fullCharges },
    shipmentBooking: { serviceType, finalVessel, finalCarrier },
    cargo = [],
    quote,
    shipper,
    purchaseOrders,
    containerLoadType,
    bookingLoadType,
    transshipments,
    schedule: { finalEta: eta, finalEtd: etd, ata },
  } = {} as IShipment
) => {
  const {
    quoteRequestNew: { agent = {} },
  } = quote || { quoteRequestNew: {} };
  const cargoMessages = [];
  const isLcl = checkIfLcl({ containerLoadType, bookingLoadType });
  const {
    name: shipperName,
    email: shipperEmail,
    country: shipperCountry,
    contactName: shipperContactName,
  } = shipper[0] || {};
  let missingCargoDetails;
  const freight = fullCharges?.find(
    (charge) =>
      charge.name === "101" ||
      charge.name === "102" ||
      charge.name === "202" ||
      charge.name === "201"
  );
  const missingEta = !eta && "eta";
  const missingEtd = !etd && "etd";
  const missingFreight = !freight && "freight";
  const missingFinalCarrier = !finalCarrier && "finalCarrier";
  if (cargo.length) {
    cargo.map(
      ({
        loadedQuantity,
        packageType,
        sealNumber,
        containerNumber,
        containerType,
        sidemarCode,
      }) => {
        const missingLoadedQuantity = !loadedQuantity && "loadedQuantity";
        const missingPackageType =
          (!packageType || (isLcl && !sidemarCode)) && "packageType";
        const missingSealNumber = !sealNumber && "sealNumber";
        const missingContainerNumber = !containerNumber && "containerNumber";
        const missingContainerType = !containerType && "containerType";
        cargoMessages.push(
          missingLoadedQuantity,
          missingPackageType,
          missingSealNumber,
          missingContainerNumber,
          missingContainerType
        );
      }
    );
  } else {
    missingCargoDetails = "cargoDetails";
  }
  const filteredCargoMessages = cargoMessages.length
    ? cargoMessages.filter(
        (message, index) => cargoMessages.indexOf(message) === index
      )
    : [];
  const missingHbl = !hbl && "hbl";
  const missingHblEmittionDate = !hblEmittedDate && "hblEmittedDate";
  const missingManifest = !manifestInformation && "manifestInformation";
  const missingBlInformation = !blInformation && "blInformation";
  const missingServiceType = !serviceType && "serviceType";
  const missingAgent = !agent && "agent";
  const missingFinalVessel = !finalVessel && "finalVessel";
  const missingWarehouse = !warehouse && "warehouse";
  let missingShipper =
    !shipper.length &&
    !shipperName &&
    !shipperEmail &&
    !shipperCountry &&
    !shipperContactName &&
    "shipper";
  if (purchaseOrders.length) missingShipper = false;
  const missingFields = [
    missingCargoDetails,
    missingHblEmittionDate,
    missingManifest,
    missingHbl,
    missingServiceType,
    missingBlInformation,
    missingAgent,
    missingWarehouse,
    missingFinalVessel,
    missingShipper,
    missingEta,
    missingEtd,
    missingFreight,
    missingFinalCarrier,
    ...filteredCargoMessages,
  ];
  return missingFields.filter((field) => !!field);
};
export const sintadValidationMessages = (
  {
    shipmentType,
    operationType,
    forwarderDetails: { hbl, warehouse, hawb, mbl, mawb, manifestNumber },
    shipmentOperation: { blInformation, customsCode, blMark, portTerminal },
    cargo = [],
    shipper,
    purchaseOrders,
    schedule: { atd, finalEta },
    company: { taxId, commercialAddress, country, legalName },
  } = {} as IShipment
) => {
  const cargoMessages = [];
  const isAereal = shipmentType === AIR;
  const isExport = operationType === EXPORT;
  const {
    name: shipperName,
    address: shipperAddress,
    country: shipperCountry,
  } = shipper[0] || {};
  let missingCargoDetails;
  if (cargo.length) {
    cargo.map(
      ({
        loadedQuantity,
        packageType,
        sealNumber,
        containerNumber,
        containerType,
      }) => {
        const missingLoadedQuantity = !loadedQuantity && "loadedQuantity";
        const missingPackageType = !packageType && "packageType";
        const missingSealNumber = !sealNumber && "sealNumber";
        const missingContainerNumber = !containerNumber && "containerNumber";
        const missingContainerType = !containerType && "containerType";
        cargoMessages.push(
          missingLoadedQuantity,
          missingPackageType,
          missingSealNumber,
          missingContainerNumber,
          missingContainerType
        );
      }
    );
  } else {
    missingCargoDetails = "cargoDetails";
  }
  const filteredCargoMessages = cargoMessages.length
    ? cargoMessages.filter(
        (message, index) => cargoMessages.indexOf(message) === index
      )
    : [];
  const missingHbl = !hbl && "hbl";
  const missingHawb = !hawb && "hawb";

  const missingMawb = !mawb && "mawb";
  const missingMbl = !mbl && "mbl";

  const missingManifestNumber = !manifestNumber && "manifestNumber";
  const missingCustomsCode = !customsCode && "customsCode";
  const missingBlInformation = !blInformation && "blInformation";

  const missingAtd = !atd && "atd";
  const missingEta = !finalEta && "eta";

  const missingBlMark = !blMark && "blMark";
  const missingPortTerminal = !portTerminal && "portTerminal";

  const missingMaster = isAereal ? missingMawb : missingMbl;
  const missingHouse = isAereal ? missingHawb : missingHbl;

  const missingWarehouse = !warehouse && "warehouse";

  let missingShipper =
    (!shipper.length || !shipperName || !shipperAddress || !shipperCountry) &&
    "shipper";

  const missingCompany =
    (!taxId || !commercialAddress || !country || !legalName) &&
    "fillCustomerForm";

  if (purchaseOrders.length) missingShipper = false;
  const missingFields = [
    missingMaster,
    missingHouse,
    missingBlInformation,
    missingWarehouse,
    missingShipper,
    missingCustomsCode,
    missingEta,
    missingAtd,
    missingManifestNumber,
    missingCompany,
    missingCargoDetails,
    ...(isExport ? [missingWarehouse, missingPortTerminal] : []),
    ...filteredCargoMessages,
  ];
  return missingFields.filter((field) => !!field);
};
export const detailsAndItineraryValidationMessage = (shipment) => {
  const missingFields = [
    ...preAlertValidationMessages(shipment),
    ...cargoDetailsValidationMessage(shipment),
  ];
  return missingFields.reduce((accumulator, field) => {
    if (accumulator.includes(field)) return accumulator;
    return [...accumulator, field];
  }, []);
};

export const generateMailContactsOptions = (mailContacts = []) => {
  return mailContacts.map(({ name: label, email: value }) => ({
    label,
    value,
  }));
};
export const formatEmailContacts = (mailContacts) =>
  mailContacts.map((item) => (typeof item === "object" ? item.value : item));

export const asyncPipe =
  (...fns) =>
  (x?) =>
    fns.reduce(async (g, f) => f(await g), x);

const DEFAULT_LABEL = "TRACE";
export const trace =
  (label = DEFAULT_LABEL) =>
  (value) => {
    let text;
    try {
      text = `${label}: ${JSON.stringify(value, null, 2)}`;
    } catch {
      text = `${label}: ${value}`;
    } finally {
      console.log(text);
    }
    return value;
  };

export const listAgentsInformation = ({ representants, agentId }) => {
  const agentList = [];
  const cityList = [];
  const countryList = [];
  const agentNamesList = [];

  representants.forEach(({ agent, city, country, name }) => {
    if (!agentNamesList.includes(name) && (agent.id === agentId || !agentId)) {
      agentNamesList.push(name);
    }
    if (!cityList.includes(city) && (agent.id === agentId || !agentId)) {
      cityList.push(city);
    }
    if (!countryList.includes(country) && (agent.id === agentId || !agentId)) {
      countryList.push(country);
    }
    const repeatedAgent = agentList.find(
      ({ id: currentAgentId }) => currentAgentId === agent.id
    );

    if (!repeatedAgent) {
      agentList.push(agent);
    }
  });
  agentList.sort(({ name: nameA }, { name: nameB }) =>
    nameA.localeCompare(nameB)
  );
  countryList.sort((nameA, nameB) => nameA.localeCompare(nameB));
  cityList.sort((nameA, nameB) => nameA.localeCompare(nameB));
  return { agentList, cityList, countryList, agentNamesList };
};

export const formatArrayForEditableField = ({
  parentArray,
  valueKey,
  nameKey,
}) =>
  parentArray.map(({ [valueKey]: value, [nameKey]: name }) => ({
    value,
    name: `${name} - ${value}`,
  }));

export const correctedHblTypeDetector = ({ hbl }) => {
  const formattedHbl = hbl || "";
  const isHbl = formattedHbl.startsWith("(H)");
  const isNbl = formattedHbl.startsWith("(N)");
  const isBnbl = formattedHbl.startsWith("(BN)");
  let hblType;
  if (isHbl) hblType = "HBLC";
  if (isNbl) hblType = "NBLC";
  if (isBnbl) hblType = "BNBLC";
  return hblType || "HBLC";
};

export const getDateBasedOnTz = (tz) => (date) =>
  date ? moment(date).tz(tz).format("YYYY-MM-DD HH:mm") : null;

export const getTimeZonesOfShipment = (
  shipment: IShipment
): ITimeZoneHelper => {
  const {
    shipmentBooking: { subscribed, subscriptionStatus } = {},
    pol = {},
    pod = {},
    shipmentType,
  } = shipment;
  const isCorrectlySubscribed =
    subscribed && ![0, 4].includes(subscriptionStatus);
  const polTz = pol.timeZone || UTC;
  const podTz = pod.timeZone || UTC;
  const isSeaAir = shipmentType === SEAAIR;

  return {
    isCorrectlySubscribed,
    originTz: isCorrectlySubscribed ? polTz : UTC,
    destinationTz: isCorrectlySubscribed && !isSeaAir ? podTz : UTC,
  };
};

export const getTimeZoneOfMilestone = (
  milestoneTitle,
  { originTz, destinationTz }: ITimeZoneHelper
) => {
  const isOriginMilestone = ORIGIN_MILESTONES.includes(milestoneTitle);
  const isDestinationMilestone =
    DESTINATION_MILESTONES.includes(milestoneTitle);
  if (isOriginMilestone) return { timeZone: originTz };
  if (isDestinationMilestone) return { timeZone: destinationTz };
  return { timeZone: UTC };
};

export const addTzToShipmentsInArray = (
  shipments: IShipment[]
): Array<IShipment & ITimeZoneHelper> =>
  shipments.map((shipment) => {
    const tzProps = getTimeZonesOfShipment(shipment);
    return {
      ...shipment,
      ...tzProps,
    };
  });

export const isTTN = (reference) =>
  !!reference && reference.includes(TTN) && reference.startsWith(TTN);

const findSeaAirTransshipment = (type) => (transshipments) =>
  transshipments.find(({ transshipmentType }) => transshipmentType === type);

export const findSeaAirMaritimeRoute = findSeaAirTransshipment(OCEAN);

export const portOfLoading = (shipmentType) =>
  shipmentType === OCEAN || shipmentType === SEAAIR ? "pol" : "aol";
export const portOfDischarge = (shipmentType) =>
  shipmentType === OCEAN ? "pod" : "aod";
export const isShipmentSeaAir = (shipmentType) => shipmentType === SEAAIR;
export const getConsolidationCargoDetails = (shipments) => {
  let currentWeight = 0;
  let currentLoadedQuantity = 0;
  let currentContainerQuantity = 0;
  let currentVolume = 0;
  let currentLoadableWeight = 0;
  shipments.forEach(({ cargo: shipmentCargo }) => {
    let cargoWeight = 0;
    let cargoLoadedQuantity = 0;
    shipmentCargo.forEach(
      ({
        volume: currentCargoVolume = 0,
        weight: currentCargoWeight,
        loadedQuantity: currentCargoQuantity,
        volumetricWeight: currentCargoVolumetricWeight = 0,
      }) => {
        currentLoadableWeight += Math.max(
          currentCargoWeight,
          currentCargoVolumetricWeight
        );
        currentVolume += currentCargoVolume;
        cargoWeight += currentCargoWeight;
        cargoLoadedQuantity += currentCargoQuantity;
        currentContainerQuantity += 1;
      }
    );
    currentLoadedQuantity += cargoLoadedQuantity;
    currentWeight += cargoWeight;
  });
  return {
    currentLoadedQuantity,
    currentLoadableWeight,
    currentContainerQuantity,
    currentVolume: Math.round(currentVolume * 100) / 100,
    currentWeight: Math.round(currentWeight * 100) / 100,
  };
};
export const showConsolidationCargoAlert = ({ consolidation }) => {
  const {
    shipments = [],
    weight = 0,
    cargo = 0,
    packageQuantity = 0,
    loadableWeight = 0,
  } = consolidation || ({} as IConsolidation);
  const { shipmentType } = shipments.length
    ? shipments[0]
    : { shipmentType: "OCEAN" };
  const isAereal = shipmentType === AIR;
  const {
    currentWeight,
    currentContainerQuantity,
    currentLoadedQuantity,
    currentVolume,
    currentLoadableWeight,
  } = getConsolidationCargoDetails(shipments);
  const weightDifferential = weight - currentWeight;
  const loadedQuantityDifferential = packageQuantity - currentLoadedQuantity;
  const cargoQuantityDifferential = cargo - currentContainerQuantity;
  const loadableWeightDifferential = loadableWeight - currentLoadableWeight;

  const maritimeAlert =
    weightDifferential ||
    loadedQuantityDifferential ||
    cargoQuantityDifferential;

  const aerealAlert =
    weightDifferential ||
    loadableWeightDifferential ||
    loadedQuantityDifferential;

  const showAlert = isAereal ? aerealAlert : maritimeAlert;
  return !!showAlert;
};

export const removeArrayDuplicates = (array) => [...new Set(array)];

export const emailValidator = (email) => {
  let error;
  if (email) {
    const isValidRegex = EMAIL_REGEX.test(email);

    if (!isValidRegex) {
      error = "invalidEmailFormat";
    }
  }

  return error;
};
export const formatSeconds = (seconds) => {
  if (seconds >= 3600) {
    return { time: Math.floor(seconds / 3600), unit: "h" };
  }
  if (seconds >= 60) {
    return { time: Math.floor(seconds / 60), unit: "m" };
  }
  return { time: Math.floor(seconds), unit: "s" };
};

export const formatDateButtons = (dateButtons, intl) =>
  dateButtons.reduce(
    (acc, { days, label }) => ({
      ...acc,
      [intl.formatMessage({ id: label })]: [
        moment().subtract(days, "days").startOf("days"),
        moment().endOf("days"),
      ],
    }),
    {}
  );
export const getMilestones = (
  shipment: IShipment
): IShipmentMilestonesHelper => {
  const { milestones = [{}], stage, cargo, shipmentType } = shipment;
  const isMaritime = shipmentType === OCEAN;
  const parsed = milestones.sort(
    (
      { reference: { order: orderA } = {} },
      { reference: { order: orderB } = {} }
    ) => orderB - orderA
  );
  const lastCompletedMilestone =
    parsed.find((milestone) => {
      return milestone && milestone.completed;
    }) || {};
  return {
    lastCompletedMilestone,
    parsedMilestones: parsed,
    importantMilestones: parsed.filter((milestone) => {
      const { reference } = milestone || {};
      return (
        !!reference &&
        (reference.important ||
          (!isMaritime && reference.title === CONTACT_SUPPLIER))
      );
    }),
  };
};

export const getMilestonesHelper = (
  milestones: IShipmentMilestone[],
  isOcean = true
): IShipmentMilestonesHelper => {
  const sorted = orderMilestones(deepClone(milestones), true);
  const lastCompletedMilestone =
    sorted.find((milestone) => {
      return milestone && milestone.completed;
    }) || {};
  const importantMilestones = isOcean
    ? sorted.filter((milestone) => {
        const { reference } = milestone || {};
        return !!reference && reference.important;
      })
    : sorted;
  return {
    lastCompletedMilestone,
    sortedMilestones: sorted,
    importantMilestones,
  };
};

export const getCurrentJourneyInfo = (
  lastMsOrder,
  sortedTss,
  shipmentBooking
) => {
  const tsCount = sortedTss.length;
  if (tsCount) {
    if (lastMsOrder <= 10) {
      return sortedTss[0];
    }
    if (lastMsOrder < 14) {
      return sortedTss[1];
    }
    if (lastMsOrder < 18) {
      return sortedTss[2];
    }
    if (lastMsOrder < 22) {
      return sortedTss[3];
    }
  }
  return {
    vessel: shipmentBooking.finalVessel,
    flightNumber: shipmentBooking.finalVoyage,
  };
};

export const seaAirTssParser = ({
  transshipments: sortedTss,
  shipmentBooking,
  schedule,
  pol,
  pod,
}: IShipment) => {
  const { finalEtd, finalEta, ata, atd } = schedule;
  const { finalVessel, finalVoyage, finalCarrier } = shipmentBooking;

  return sortedTss.reduce((acc, currentLeg, i) => {
    const { pod: legPod, legNumber, ...rest } = currentLeg;
    const pushInitialLeg = !i;
    const tsCount = sortedTss.length;
    const pushFinalLeg = i === tsCount - 1;
    if (pushInitialLeg) {
      acc.push({
        ...rest,
        atd,
        legNumber,
        pol,
        pod: legPod,
        etd: finalEtd,
        transshipmentType: OCEAN,
      });
    } else {
      acc.push({
        ...currentLeg,
        legNumber,
        pod: legPod,
        pol: sortedTss[i - 1].pod,
        etd: sortedTss[i - 1].etd,
        atd: sortedTss[i - 1].atd,
      });
    }
    if (pushFinalLeg) {
      acc.push({
        ...rest,
        ata,
        pod,
        eta: finalEta,
        pol: legPod,
        legNumber: tsCount + 1,
        vessel: finalVessel,
        flightNumber: finalVoyage,
        carrier: finalCarrier,
        transshipmentType: AIR,
      });
    }
    return acc;
  }, []);
};

export const getRemainingDaysStatus = (remainingDays) => {
  if (remainingDays > 5) {
    return "success";
  }
  if (remainingDays > 0) {
    return "warning";
  }
  return "error";
};
export const isFcl = (containerLoadType) => containerLoadType === FCL;
export const isCompany = ({ group }) =>
  group === COMPANY || group === COMPANY_ADMIN;
export const isChileanPort = ({ code }) => chileanPorts.includes(code);

export const isEmptyObj = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const getCurrentStep = (current) => (steps) => steps[current].content;

export const isComponent = (value) => {
  return value && isValidElement(value);
};

export const isAerial = (transportType) => transportType === AIR;
export const isLand = (transportType) => transportType === LAND;
export const isMaritime = (transportType) => transportType === OCEAN;

export const quotingSearcherPODCodes = (transportType) => {
  switch (transportType) {
    case OCEAN:
      return [
        "CLVAP",
        "CLSAI",
        "CLARI",
        "MXZLO",
        "PECLL",
        "COBUN",
        "CLCNL",
        "CLLQN",
        "CLIQQ",
        "CLANF",
        "CLPAG",
        "CLVAP/CLSAI",
      ];
    case AIR:
      return ["SCL"];
  }
};

export const filterQuotingSearcherPols =
  (transportType, loadType) => (polsArray) => {
    if (isAerial(transportType)) {
      return polsArray.filter(({ code }) =>
        QUOTING_SEARCHER_AIR_POL_CODES.includes(code)
      );
    }

    return polsArray;
  };
export const specialChargesValidationMessages = ({ passedToFinanceAt }) => {
  const missingPassedToFinanceAt = !passedToFinanceAt && "passedToFinanceAt";
  const missingFields = [missingPassedToFinanceAt];
  return missingFields.filter((field) => !!field);
};
export const checkIfOcean = ({ shipmentType }) => shipmentType === OCEAN;
export const checkIfAerial = ({ shipmentType }) => shipmentType === AIR;
export const checkIfSeaAir = ({ shipmentType }) => shipmentType === SEAAIR;
export const checkIfFcl = ({ containerLoadType, bookingLoadType }) =>
  containerLoadType === FCL || bookingLoadType.includes("_FCL");
export const checkIfLcl = ({ containerLoadType, bookingLoadType }) =>
  containerLoadType === LCL || bookingLoadType.includes("_LCL");

export const isMenaresAgency = (customsAgency) => customsAgency === "Menares";

export const getBlDocumentFromShipment = (shipment) => {
  const { documents = [] } = shipment;

  const sortedDocs = [...documents].sort((a, b) =>
    moment(b.uploadedAt).diff(a.uploadedAt)
  );

  const lastBlDocument = sortedDocs.find(({ docType }) => docType === "HBL");
  return lastBlDocument;
};

export const getCargoValidityById = ({ containersValidationList, id }) => {
  const foundCargo = containersValidationList.find(
    (containerWithValidation) => {
      return id === containerWithValidation?.id?.value;
    }
  );

  return foundCargo || {};
};

export const getCargoValidatedFields = (containerValidatedFields) => (id) =>
  find(propEq("cargoId", id))(containerValidatedFields);
